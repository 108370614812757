import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/common/common.service';
import { ExcelService } from '../../common/excel.service';
import { ToastrService } from 'ngx-toastr';
import { CUSTOMER_STATUS } from 'src/app/config/commonConfig';
import * as _ from 'underscore';
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.component.html',
  styleUrls: ['./coupons.component.css'],
})
export class CouponsComponent implements OnInit {

  // variable declaration 
  p: number = 1
  total: number
  loader = false
  couponList: any = []
  searchText;
  isReadMore = true

  constructor(
    private api: CommonService,
    private excelService: ExcelService,
    private toastr: ToastrService,
    public datepipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.getList()
  }

  getList() {
    this.loader = true
    this.api.listCoupon().subscribe((result) => {
      this.couponList = result['couponcodes'].sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
      this.total = this.couponList.length

      // 
      this.couponList = _.each(this.couponList,((item)=>{
        let expired :any
        let currentDate = new Date()
        let today = this.formatDate(currentDate)
        let expiry = item.expiry
        if(expiry >= today){
          item.expired = false
        }else{
          item.expired = true
        }
        return item
      }))
      console.log(this.couponList,'list')
      this.loader = false
    }, (error) => {
      this.loader = false
    })
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  showText() {
    this.isReadMore = !this.isReadMore
  }

}
