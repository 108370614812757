import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CommonService } from '../../common/common.service';
import * as _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-manage-flavours',
  templateUrl: './manage-flavours.component.html',
  styleUrls: ['./manage-flavours.component.css']
})
export class ManageFlavoursComponent implements OnInit {

  modalRef: BsModalRef;
  flavourForm: FormGroup;
  boxColorForm: FormGroup;
  flavoursArray
  imageURL: string;
  name
  extra_price
  image
  flavour_id
  flavourId: any;
  colorArray: any;
  color_id: any;
  ColorId: any;
  flavoursId: any;
  img_url
  submitted = false
  saveFlavour = false
  saveColor = false
  loader = false
  constructor(
    private fb: FormBuilder,
    private router: Router,
    public api: CommonService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.loader = true
    this.getFlavours();
    this.getColors();
    this.colorForm();
    this.productFlavourForm();
  }
  getFlavours() {
    this.api.getFlavour().subscribe(res => {
      this.flavoursArray = res['data']['Items']
      this.loader = false
      console.log(res, 'result')
    })
  }
  getColors() {
    this.api.getColor().subscribe(res => {
      this.colorArray = res['data']['Items']
      console.log(res, 'get color')
    })
  }
  productFlavourForm() {
    this.flavourForm = this.fb.group({
      name: ['', Validators.required],
      extra_price: ['', Validators.required],
      fileSource: ['', Validators.required],
      image: '',
    })
  }
  colorForm() {
    this.boxColorForm = this.fb.group({
      name: ['', Validators.required],
      // extra_price:[''],
      code: ['', Validators.required]
    })
  }
  addFlav() {
    this.imageURL = '';
  }
  addFlavour() {
    if (this.flavourForm.valid) {
      this.saveFlavour = true
      let data = {
        "name": this.flavourForm.controls.name.value,
        "extra_price": this.flavourForm.controls.extra_price.value
      }
      console.log(this.flavourForm.controls.image.value);
      let formData = new FormData();
      formData.append('filename', this.flavourForm.controls.image.value);
      this.api.addFlavour(data, { filename: this.flavourForm.controls.image.value }).subscribe(res => {
        console.log(res);
        this.saveFlavour = false
        this.toastr.success(res['message'])
        this.cancel();
        this.ngOnInit();
      })
    }
    else {
      this.submitted = true
    }
  }
  editFlavour(flavour: any) {
    this.flavourForm.patchValue({
      name: flavour['name'],
      extra_price: flavour['extra_price'],
      fileSource: ['']
    })
    this.imageURL = flavour.image
    this.flavour_id = flavour.SK
    this.flavoursId = this.flavour_id
  }
  updateFlavour(id: any) {
    if (this.flavourForm.valid) {
      this.saveFlavour = true
      let data = {
        "SK": this.flavour_id,
        "name": this.flavourForm.controls.name.value,
        "extra_price": this.flavourForm.controls.extra_price.value
      }
      let file
      if (typeof (this.flavourForm.controls.image.value) == 'object') {
        if (this.flavourForm.controls.image.value != 'undefined' || this.flavourForm.controls.image.value !== null) {
          file = { "filename": this.flavourForm.controls.image.value };
        }
      }
      else {
        if (this.flavourForm.controls.image.value.startsWith('http')) {
          data['image'] = this.flavourForm.controls.image.value
        }
      }
      console.log(data, file, 'data')
      this.api.updateFlavour(data, file).subscribe(res => {
        this.saveFlavour = false
        this.toastr.success(res['message'])
        window.location.reload();
        this.cancel();
        console.log(res);
      })
    }
    else {
      this.submitted = true
    }
  }
  removeFlavour(id: any) {
    this.flavourId = id
    this.ColorId = ''
  }
  deleteFlavour(id: any) {
    this.api.deleteFlavour({ "SK": id }).subscribe(data => {
      console.log(data, 'data')
      this.toastr.success(data['message'])
      this.cancel();
      this.getFlavours();
    })
  }
  showPreview(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.flavourForm.patchValue({
      image: file
    });
    this.flavourForm.get('image').updateValueAndValidity()

    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.imageURL = reader.result as string;
      console.log(this.imageURL, 'img url')
    }
    reader.readAsDataURL(file)
  }
  cancel() {
    this.flavourForm.reset();
    this.boxColorForm.reset();
    this.flavourId = '';
    this.ColorId = '';
    this.flavoursId = ''
    this.color_id = '';
    this.flavour_id = ''
    this.imageURL = ''
  }
  addColor() {
    if (this.boxColorForm.valid) {
      this.saveColor = true
      let data = {
        "name": this.boxColorForm.controls.name.value,
        // "extra_price":this.boxColorForm.controls.extra_price.value,
        "code": this.boxColorForm.controls.code.value
      }
      this.api.addColor(data).subscribe(data => {
        console.log(data, 'data');
        this.saveColor = false
        this.toastr.success(data['message'])
        this.cancel();
        this.ngOnInit();
      })
    } else {
      this.submitted = true
    }
  }
  editColour(color: any) {
    console.log(color.name, 'color')
    this.boxColorForm = this.fb.group({
      SK: color.SK,
      name: [color.name ? color.name : ''],
      // extra_price:[color.extra_price ? color.extra_price : ''],
      code: [color.code ? color.code : '']
    })
    this.color_id = color.SK
  }
  updateColor(id: any) {
    if (this.boxColorForm.valid) {
      this.saveColor = true
      let data = {
        "SK": id,
        "name": this.boxColorForm.controls.name.value,
        // "extra_price":this.boxColorForm.controls.extra_price.value,
        "code": this.boxColorForm.controls.code.value,
      }
      this.api.updateColor(data).subscribe(res => {
        this.toastr.success('Color updated successfully')
        this.saveColor = false
        this.cancel();
        this.ngOnInit();
      })
    } else {
      this.submitted = true;
    }
  }
  removeColor(id: any) {
    this.ColorId = id
    this.flavourId = ''
  }
  deleteColor(id: any) {
    this.api.deleteColor({ "SK": id }).subscribe(res => {
      console.log(res)
      this.toastr.success('Color deleted successfully')
      this.cancel();
      this.ngOnInit();
    })
  }
}
