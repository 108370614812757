import { Component, OnInit } from '@angular/core';
import * as _ from 'underscore';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/common/common.service';
import { ExcelService } from 'src/app/common/excel.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-ledger-details',
  templateUrl: './ledger-details.component.html',
  styleUrls: ['./ledger-details.component.css']
})
export class LedgerDetailsComponent implements OnInit {

  ledgerArray;
  total_revenue;
  ledgerCount: number
  searchText;
  extra: any = [];
  navigationExtras: NavigationExtras;
  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig(); // date range picker
  p: number = 1;
  total: number = 50
  currentPage = 1
  startPage: number = 1;
  lastPage: number = 5;
  ledgerArrayCopy = [];
  loader = false
  today:Date
  startDate:any
  endDate:any
  filterName:any
  ledgerForm:FormGroup

  // variable declaration  
  constructor(private fb: FormBuilder,
    private router: Router,
    public api: CommonService,
    private toastr: ToastrService,
    private excelService: ExcelService
  ) {
    this.dpConfig.rangeInputFormat = 'DD/MM/YYYY'; // date range picker
    this.dpConfig.isAnimated = true; // date range picker
    this.dpConfig.showWeekNumbers = false; // date range picker
   
    this.ledgerForm = this.fb.group({
      searchText:[''],
      filterdate:['']
    })
  }


  ngOnInit(): void {
    this.getLedger();
    this.today = new Date()
  }
  getLedger() {
    this.api.getLedger().subscribe(re => {
      this.ledgerArray = re['data']
      this.ledgerArray = _.sortBy(this.ledgerArray, function (data) {
        return data.SK
      })
      this.ledgerArrayCopy = this.ledgerArray.reverse();
      this.ledgerCount = this.ledgerArray?.length
      this.total_revenue = _.reduce(this.ledgerArray, function (memo, num) {
        if (num.amount) {
          memo += parseInt(num.amount)
        }

        return memo;
      }, 0);
      console.log(this.ledgerArray)
    })
  }
  // filterDate($event) {
  //   let fromDate = $event[0]
  //   fromDate = this.convert($event[0])
  //   this.startDate = fromDate
  //   let endDate = $event[1]
  //   endDate = this.convert($event[1])
  //   this.endDate = endDate
  //   if(this.filterName){
  //     this.ledgerArray = _.filter(this.ledgerArrayCopy, function (res) {
  //       return res.SK.slice(0, 10) >= fromDate && res.SK.slice(0, 10) <= endDate && res.payment_ref == this.filterName
  //     })
  //   }else{
  //     this.ledgerArray = _.filter(this.ledgerArrayCopy, function (res) {
  //       return res.SK.slice(0, 10) >= fromDate && res.SK.slice(0, 10) <= endDate
  //     })
  //   }
  //   this.ledgerCount = this.ledgerArray?.length
  //   this.total_revenue = _.reduce(this.ledgerArray, function (memo, num) {
  //     if (num.amount) {
  //       memo += parseInt(num.amount)
  //     }

  //     return memo;
  //   }, 0);
  //   this.p = 1
  // }
  applyFilter(){
    this.ledgerArray = this.ledgerArrayCopy
    if(this.ledgerForm.controls.searchText.value){
      let searchText = this.ledgerForm.controls.searchText.value.replace(/\s+/g, ' ').trim()
      this.ledgerArray = _.filter(this.ledgerArray, function (res) {
        return res.payment_ref == searchText
      })
    }
    console.log(this.ledgerForm.controls.filterdate.value)
    if(this.ledgerForm.controls.filterdate.value.length > 0){
     let fromDate = this.convert(this.ledgerForm.controls.filterdate.value[0])
     let endDate = this.convert(this.ledgerForm.controls.filterdate.value[1])
     this.ledgerArray = _.filter(this.ledgerArray, function (res) {
      return res.SK.slice(0, 10) >= fromDate && res.SK.slice(0, 10) <= endDate
    })
    }
    this.ledgerCount = this.ledgerArray?.length
    this.total_revenue = _.reduce(this.ledgerArray, function (memo, num) {
      if (num.amount) {
        memo += parseInt(num.amount)
      }

      return memo;
    }, 0);
    this.p = 1
  }
  // getByReference(){
  //   debugger
  //   let searchText = this.ledgerForm.controls.searchText.value.replace(/\s+/g, ' ').trim()
  //   this.filterName = searchText
  //   if(this.startDate){
  //     let fromDate = this.startDate
  //     let endDate = this.endDate
  //     this.ledgerArray = _.filter(this.ledgerArrayCopy, function (res) {
  //       return res.SK.slice(0, 10) >= fromDate && res.SK.slice(0, 10) <= endDate
  //     })
  //     this.ledgerArray = _.filter(this.ledgerArray, function (res) {
  //       return res.payment_ref == searchText
  //     })
  //   }else{
  //     this.ledgerArray = _.filter(this.ledgerArrayCopy, function (res) {
  //       return res.payment_ref == searchText
  //     })
  //   }
   
    
  //   this.ledgerCount = this.ledgerArray?.length
  // }
  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  clearAll() {
    window.location.reload();
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
  getOrderDetails(order) {
    this.extra = {
      "order_id": order.order_ref,
      "user_email": order.customer_email
    }
    this.router.navigate(['/order-details'])

    this.navigationExtras = {
      queryParams: this.extra,
      skipLocationChange: true,
      queryParamsHandling: 'merge'
    };
    localStorage.removeItem('products')
    localStorage.removeItem('edited')
    this.router.navigate(['/order-details'], {
      queryParams: this.extra
    });
  }
  dateInReverse(date) {
    var datetime = date
    var bits = datetime.split(/\D/);
    var dateObject = new Date(bits[0], --bits[1], bits[2], bits[3], bits[4]);
    const formattedDate = dateObject.toLocaleDateString('en-GB', {
      day: 'numeric', month: 'short', year: 'numeric'
    }).replace(/ /g, '-');
    return formattedDate
  }
  exportToExcel() {
    console.log(this.ledgerArray, 'dsd')
    let obj = []
    let exel_sheet_data = []
    let data = {}
    const header = ['Date', 'Transaction ref', 'Payment ref', 'Payment mode', 'Customer name', 'Manufacture name', 'Amount'];
    let title = 'Ledger report of snape Cookies';
    let worksheet = 'Ledger Details'
    let merge_cells = 'A1:G2'
    let footer_row = 'Snape cookies ledger list.'
    let filename = 'ledger_report'
    let excel_array = [
      data['header'] = header,
      data['title'] = title,
      data['worksheet'] = worksheet,
      data['merge_cells'] = merge_cells,
      data['footer_row'] = footer_row,
      data['filename'] = filename
    ]
    const date = new Date();
    const formattedDate = date.toLocaleDateString('en-GB', {
      day: '2-digit', month: 'short', year: 'numeric'
    }).replace(/ /g, '-');
    for (var i in this.ledgerArray) {
      obj = [this.dateInReverse(this.ledgerArray[i].SK), this.ledgerArray[i].trans_ref, this.ledgerArray[i].payment_ref,
      this.ledgerArray[i].payment_mode, this.ledgerArray[i].customer_name, this.ledgerArray[i].manufacture_name, this.ledgerArray[i].amount]
      exel_sheet_data.push(obj)
    }
    this.excelService.exportAsExcelFile(exel_sheet_data, formattedDate, excel_array);
  }
}
