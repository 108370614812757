import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthInterceptor } from './common/auth.interceptor';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { VerifyEmailComponent } from './auth/verify-email/verify-email.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfirmPasswordComponent } from './auth/confirm-password/confirm-password.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { CustomerListComponent } from './components/customer-list/customer-list.component';
import { VendorListComponent } from './components/vendor-list/vendor-list.component';
import { OrderDetailsComponent } from './components/order-details/order-details.component';
import { ProductListComponent } from './components/product-list/product-list.component';
import { ManageFlavoursComponent } from './components/manage-flavours/manage-flavours.component';
import { ContentManagementComponent } from './components/content-management/content-management.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { AsyncPipe, DatePipe } from '../../node_modules/@angular/common';
import { MessagingService } from './common/messaging.service';
import { environment } from '../environments/environment';
import { SettingsComponent } from './components/settings/settings.component';
import { CreateNewProductComponent } from './components/create-new-product/create-new-product.component';
import { HeadMenuComponent } from './components/admin-menu-components/admin-header-menu/admin-head-menu.component';
import { SideMenuComponent } from './components/admin-menu-components/admin-side-menu/admin-side-menu.component';
import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';
// ref : cuppalabs - https://cuppalabs.github.io/components/multiselectDropdown/
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { TempOrderListComponent } from './components/temp-order-list/temp-order-list.component';
import { TempVendorListComponent } from './components/temp-vendor-list/temp-vendor-list.component';
import { ChangeEmailComponent } from './components/change-email/change-email.component';
import { OneTimePasswordComponent } from './components/one-time-password/one-time-password.component';
import { EnterOtpComponent } from './components/enter-otp/enter-otp.component';
import { PasswordResetSucessComponent } from './components/password-reset-sucess/password-reset-sucess.component';
import { ExcelService } from './common/excel.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { TempOrderDetailsComponent } from './components/temp-order-details/temp-order-details';
import { LedgerDetailsComponent } from './components/ledger-details/ledger-details.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { EditImageComponent } from './components/edit-image/edit-image.component';
import { FaqComponent } from './components/faq/faq.component';
import { } from './components/faq/faq.component';
import { TemplateImagesComponent } from './components/template-images/template-images.component'
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NewsLetterComponent } from './components/news-letter/news-letter.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MessageCenterComponent } from './components/message-center/message-center.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { GeneralQueriesComponent } from './components/general-queries/general-queries.component'; //https://www.npmjs.com/package/ngx-bootstrap
// import {NgxPrintModule} from 'ngx-print';
import { NgxPrinterModule, ngxPrintMarkerPosition } from 'projects/ngx-printer/src/public_api';
import { PrintComponent } from './components/print/print.component';
import { MessageComponent } from './components/message/message.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { CouponsComponent } from './components/coupons/coupons.component';
import { CreateNewCouponComponent } from './components/create-new-coupon/create-new-coupon.component';
import { BulkOrderComponent } from './components/bulk-order/bulk-order.component';
import { CustomizeCookieComponent } from './components/customize-cookie/customize-cookie.component';
import { CalendarModule } from 'primeng/calendar';
import { ZoneComponent } from './components/zone/zone.component';


const appRoutes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'forgotpassword', component: ForgotPasswordComponent },
  { path: 'confirm-password', component: ConfirmPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'verify-email', component: VerifyEmailComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'customer-list', component: CustomerListComponent },
  { path: 'vendor-list', component: VendorListComponent },
  { path: 'order-details', component: OrderDetailsComponent },
  { path: 'product-list', component: ProductListComponent },
  { path: 'manage-flavours', component: ManageFlavoursComponent },
  { path: 'content-management', component: ContentManagementComponent },
  { path: 'notifications', component: NotificationsComponent },
  { path: 'settings', component: SettingsComponent },
  { path: 'new-product', component: CreateNewProductComponent },
  // { path: 'admin/dashboard', component: AdminDashboardComponent},
  // { path: 'order/list', component: OrderListComponent},
  // temporary order list
  { path: 'edit-image/:id/:index', component: EditImageComponent },
  { path: 'order/list', component: TempOrderListComponent },
  { path: 'vendor/list', component: TempVendorListComponent },
  { path: 'change-email', component: ChangeEmailComponent },
  { path: 'otp', component: OneTimePasswordComponent },
  { path: 'enter/otp', component: EnterOtpComponent },
  { path: 'sucessfull/reset/password', component: PasswordResetSucessComponent },
  { path: 'order/details', component: TempOrderDetailsComponent },
  { path: 'ledger-details', component: LedgerDetailsComponent },
  { path: 'zone', component: ZoneComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'template/images', component: TemplateImagesComponent },
  { path: 'news/letter', component: NewsLetterComponent },
  { path: 'message/center', component: MessageCenterComponent },
  { path: 'general/queries', component: GeneralQueriesComponent },
  { path: 'print-preview', component: PrintComponent },
  { path: 'message', component: MessageComponent },
  { path: 'coupons', component: CouponsComponent },
  { path: 'new-coupon', component: CreateNewCouponComponent },
  { path: 'bulk-order', component: BulkOrderComponent },
  { path: 'customize-cookie', component: CustomizeCookieComponent },


  {
    path: '', redirectTo: '/login', pathMatch: 'full'
  },
]

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    VerifyEmailComponent,
    ConfirmPasswordComponent,
    DashboardComponent,
    CustomerListComponent,
    VendorListComponent,
    OrderDetailsComponent,
    ProductListComponent,
    ManageFlavoursComponent,
    ContentManagementComponent,
    NotificationsComponent,
    SettingsComponent,
    CreateNewProductComponent,
    SideMenuComponent,
    HeadMenuComponent,
    AdminDashboardComponent,
    TempOrderListComponent,
    TempVendorListComponent,
    ChangeEmailComponent,
    OneTimePasswordComponent,
    EnterOtpComponent,
    PasswordResetSucessComponent,
    TempOrderDetailsComponent,
    LedgerDetailsComponent,
    EditImageComponent,
    FaqComponent,
    TemplateImagesComponent,
    NewsLetterComponent,
    MessageCenterComponent,
    GeneralQueriesComponent,
    PrintComponent,
    MessageComponent,
    SpinnerComponent,
    CouponsComponent,
    CreateNewCouponComponent,
    BulkOrderComponent,
    CustomizeCookieComponent,
    ZoneComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule, FormsModule,
    RouterModule.forRoot(appRoutes),
    NgbModule,
    // NgxPrintModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(
      {
        timeOut: 800,
        preventDuplicates: true
      }
    ), // ToastrModule added
    ImageCropperModule,
    NgxPaginationModule,
    AngularMultiSelectModule,
    CKEditorModule,
    Ng2SearchPipeModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 64] // [x, y]
    }),
    BsDatepickerModule.forRoot(),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    NgxPrinterModule.forRoot({ printOpenWindow: true }),
    CalendarModule
  ],
  providers: [
    ExcelService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    MessagingService, AsyncPipe, DatePipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
