<div *ngIf="loader == true">
  <app-spinner></app-spinner>
</div>
<section class="vendor-list-sec">
  <div class="row p-t-20">
    <div class="col-md-8 col-xs-12 col-lg-8">
      <div class="vendor-list-heading mob-text-align">
        <h1>Vendor List</h1>
      </div>
    </div>
    <div class="col-md-4 col-lg-4 col-xs-12">
      <div class="create-btn mob-text-align">
        <button type="button" class="btn btn-pink mob-m-b-5 height-35 line-height tab-btn-width"
          [routerLink]="['/vendor-list']">Create account</button>
      </div>
    </div>
  </div>
  <div class="row p-t-20 row-border">
    <div class="col-md-5 col-lg-7 col-xs-12">
      <div class="total-vendor mob-text-align">
        <p><span>{{totalVendor}}</span> companies</p>
      </div>
    </div>
    <div class="col-md-3 col-lg-2 col-xs-12">
      <div class="mob-p-b-10">
        <input type="text" #queryDate (change)="filterDate(queryDate.valueAsDate)"
          class="form-control cursor-pointer height-30" placeholder="Join Date" onfocus="(this.type='date')">
      </div>
    </div>
    <div class="col-md-3 col-lg-2 col-xs-12">
      <div class="mob-p-b-10">
        <angular2-multiselect [data]="statusArray" [(ngModel)]="selectedStatus" [settings]="statusDropdownSettings"
          (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
          (onDeSelectAll)="onDeSelectAll($event)">
        </angular2-multiselect>
      </div>
    </div>
    <div class="col-md-1 col-lg-1 col-xs-12 col-xl-1">
      <div class="reload-icon">
        <i class="fas fa-sync-alt" (click)="clearAll()"></i>
      </div>
    </div>
  </div>
  <div class="row p-t-20">
    <div class="col-md-12 col-xs-12 col-lg-12">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Company</th>
              <th scope="col">Email</th>
              <th scope="col">Join Date</th>
              <th scope="col">Contact Person</th>
              <th scope="col">Contact Number</th>
              <th scope="col">Status</th>
              <th scope="col">Permission</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let vendors of vendorList | paginate:  { itemsPerPage: 10,
              currentPage: p,
              totalItems: totalVendor}" class="cursor-pointer">
              <td>{{vendors.company_name}}
              </td>
              <td>{{vendors.email}}</td>
              <td>{{vendors.created_at |
                date:'dd-MMM-YYYY'}}</td>
              <td>{{vendors.contact_name}}
              </td>
              <td>{{vendors.mobile_number}}
              </td>
              <td class="active-color" *ngIf="vendors.status == 'active'">Active</td>
              <td class="inactive-color" *ngIf="vendors.status == 'inactive'">Inactive</td>
              <td>
                <!-- <div>
                    <button type="button" class="btn deactivate-btn">Deactivate account</button>
                  </div> -->
                <div>
                  <i class="far fa-edit mr-3" aria-hidden="true" [routerLink]="['/vendor-list']"
                    [queryParams]="{ email_id: vendors.email}"></i>
                  <button type="button" class="btn deactivate-btn" *ngIf="vendors.status == 'active'"
                    data-toggle="modal" data-target="#deactivateModal"
                    (click)="deactiveOrRevokeAccount(vendors.id, vendors.status, vendors.email)">Deactivate
                    account</button>
                  <button type="button" class="btn revoke-btn" *ngIf="vendors.status == 'inactive'" data-toggle="modal"
                    data-target="#deactivateModal"
                    (click)="deactiveOrRevokeAccount(vendors.id, vendors.status, vendors.email)">Revoke account</button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <hr>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6 col-xs-12 col-lg-6">
      <div class="pagination mob-text-align">
        <p>Showing {{(p-1)*10}} - {{((p-1)*10)+10}} of {{totalVendor}}</p>
      </div>
    </div>
    <pagination-controls (pageChange)="p = $event"></pagination-controls>
  </div>
</section>

<!-- delete modal start -->

<div class="modal fade" id="deactivateModal" tabindex="-1" role="dialog" aria-labelledby="deactivateModalModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <!-- <div class="row">
          <div class="col-md-12">
            <div class="text-center">
              <h6 class="modal-title" id="exampleModalLongTitle" *ngIf="vendorStatus =='active'">Confirm deactivate?
              </h6>
              <h6 class="modal-title" id="exampleModalLongTitle" *ngIf="vendorStatus =='inactive'">Confirm activate?
              </h6>
            </div>
          </div>
        </div> -->
        <div class="row">
          <div class="col-md-12">
            <div class="info-icon-img">
              <img src="../../../assets/exclamation.png">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="confirm-txt">
              <h6 class="mb-3">Are you sure?</h6>
              <p class="mb-3"> Do you really want to {{popupText}} this account?</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mfooterBtn d-flex justify-content-end">
            <button type="button" class="btn color-cancel-btn" data-dismiss="modal">No</button>
            <button data-dismiss="modal" class="btn submit-btn" data-dismiss="modal" type="button"
              (click)="changeVendorStatus()">Yes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- delete modal end -->

<!-- <div class="modal fade" id="deactivateModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title" id="exampleModalLongTitle" *ngIf="vendorStatus =='active'">Confirm deactivate?
          </h6>
          <h6 class="modal-title" id="exampleModalLongTitle" *ngIf="vendorStatus =='inactive'">Confirm activate?
          </h6>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body text-center">
          Are you sure you want to {{popupText}} this account?
        </div>
        <div class="row margin">
          <div class="col-md-6 d-flex justify-content-end align-items-center">
            <button type="button" class="btn-no-grey cursor-pointer form-control" data-dismiss="modal">No</button>
          </div>
          <div class="col-md-6">
            <button data-dismiss="modal" class="btn-snape-red cursor-pointer form-control" data-dismiss="modal"
              type="button" (click)="changeVendorStatus()">Yes</button>
          </div>
        </div>
      </div>
    </div>
  </div> -->