import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/common/common.service';
import { ExcelService } from '../../common/excel.service';
import { ToastrService } from 'ngx-toastr';
import { CUSTOMER_STATUS } from 'src/app/config/commonConfig';
import * as _ from 'underscore';

@Component({
  selector: 'app-temp-vendor-list',
  templateUrl: './temp-vendor-list.component.html',
  styleUrls: ['./temp-vendor-list.component.css']
})
export class TempVendorListComponent implements OnInit {
  // variable declaration 

  statusDropdownSettings;
  selectedStatus = [];
  statusArray = CUSTOMER_STATUS;
  vendorList = [];
  loader = false
  totalVendor
  selectedItems = [];
  activeList = [];
  inactiveList = [];
  filteredDate = []
  currentList = []
  vendorId: any
  vendorStatus
  vendorListCopy = []
  popupText: string;
  vendorEmail: any;
  p = 1
  constructor(
    private api: CommonService,
    private excelService: ExcelService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.loader = true
    this.getVendorList();
    this.getdropDownSettings();
  }
  getdropDownSettings() {
    this.statusDropdownSettings = {
      singleSelection: false,
      primaryKey: 'id',
      labelKey: 'name',
      text: "Filter by Status",
      enableSearchFilter: false,
      lazyLoading: true,
      showCheckbox: true,
      clearAll: false,
      badgeShowLimit: 1,
      classes: "myclass custom-class"
    };
  }
  onItemSelect(item: any) {
    if (item.id == '1' && this.selectedStatus?.length == 1) {
      this.vendorList = this.activeList
    }
    else if (item.id == '2' && this.selectedStatus?.length == 1) {
      console.log(item, 'iii');
      this.vendorList = this.inactiveList
    }
    if (this.selectedStatus?.length == 2)
      this.vendorList = this.activeList.concat(this.inactiveList)
    this.totalVendor = this.vendorList?.length
  }
  OnItemDeSelect(item: any) {
    console.log(this.selectedStatus, 'sele status')
    if (item.id == '1' && this.selectedStatus?.length == 1) {
      this.vendorList = this.inactiveList
    }
    else if (item.id == '2' && this.selectedStatus?.length == 1) {
      console.log(item, 'iii');
      this.vendorList = this.activeList
    }
    if (this.selectedStatus?.length == 0)
      this.vendorList = this.activeList.concat(this.inactiveList)
    this.totalVendor = this.vendorList?.length
  }
  onSelectAll(items: any) {
    this.vendorList = this.activeList.concat(this.inactiveList)
    this.totalVendor = this.vendorList?.length
  }
  onDeSelectAll(items: any) {
    this.vendorList = this.activeList.concat(this.inactiveList)
    this.totalVendor = this.vendorList?.length
  }
  getVendorList() {
    this.api.getVendorList().subscribe(item => {
      this.vendorList = item['users']['Items']
      this.loader = false
      this.vendorList = _.sortBy(this.vendorList, function(o){
        return o.created_at 
      })
      this.vendorListCopy = this.vendorList.sort((a: any, b: any) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
      // this.vendorList = this.vendorList.reverse();
      this.totalVendor = item['users']['ScannedCount']
      console.log(this.vendorList, 'list')
      this.activeList = _.filter(this.vendorListCopy, function (res) {
        return res.status === 'active'
      })
      this.inactiveList = _.filter(this.vendorListCopy, function (res) {
        return res.status === 'inactive'
      })
    })
  }
  filterDate(queryDate: any) {
    let filter_date = this.formatDate(queryDate)
    this.currentList = this.activeList.concat(this.inactiveList)
    this.vendorList = _.filter(this.vendorListCopy, function (res) {
      return res.created_at.slice(0, 10) === filter_date
    })
    this.activeList = _.filter(this.vendorList, function (res) {
      return res.status === 'active'
    })
    this.inactiveList = _.filter(this.vendorList, function (res) {
      return res.status === 'inactive'
    })
    this.totalVendor = this.vendorList?.length
    this.p = 1
    console.log(this.totalVendor, 'ghjk')
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
  dateInReverse(date) {
    var datetime = date
    var bits = datetime.split(/\D/);
    var dateObject = new Date(bits[0], --bits[1], bits[2], bits[3], bits[4]);
    const formattedDate = dateObject.toLocaleDateString('en-GB', {
      day: 'numeric', month: 'short', year: 'numeric'
    }).replace(/ /g, '-');
    return formattedDate
  }
  deactiveOrRevokeAccount(id: any, status, email) {
    this.vendorId = id
    this.vendorStatus = status
    this.vendorEmail = email
    if (this.vendorStatus == 'inactive') { this.popupText = 'activate' }
    if (this.vendorStatus == 'active') { this.popupText = 'deactivate' }
  }
  changeVendorStatus() {
    let data
    if (this.vendorStatus == 'active') {
      data = {
        "email": this.vendorEmail,
        "status": "inactive",
      }
    }
    else {
      data = {
        "email": this.vendorEmail,
        "status": "active",
      }
    }
    this.api.updateVendorStatus(data).subscribe(res => {
      this.toastr.success(res['message'])
      this.ngOnInit();
    })
  }
  clearAll() {
    window.location.reload();
  }
  exportToExcel() {
    console.log(this.vendorList, 'dsd')
    let obj = []
    let exel_sheet_data = []
    let data = {}
    const header = ['Company', 'Email', 'Join date', 'Contact Person', 'Contact Number', 'Status'];
    let title = 'Vendor list of snape Cookies';
    let worksheet = 'Vendor Data'
    let merge_cells = 'A1:F2'
    let footer_row = 'Snape cookies vendor list.'
    let filename = 'vendor_list'
    let excel_array = [
      data['header'] = header,
      data['title'] = title,
      data['worksheet'] = worksheet,
      data['merge_cells'] = merge_cells,
      data['footer_row'] = footer_row,
      data['filename'] = filename
    ]
    const date = new Date();
    const formattedDate = date.toLocaleDateString('en-GB', {
      day: '2-digit', month: 'short', year: 'numeric'
    }).replace(/ /g, '-');
    for (var i in this.vendorList) {
      obj = [this.vendorList[i].name, this.vendorList[i].email,
      this.dateInReverse(this.vendorList[i].created_at), this.vendorList[i].user_status]
      exel_sheet_data.push(obj)
    }
    console.log(exel_sheet_data, 'wew')
    this.excelService.exportAsExcelFile(exel_sheet_data, formattedDate, excel_array);
  }

}
