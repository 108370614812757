import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import * as AdminLte from 'admin-lte';

@Component({
    selector: 'app-admin-side-menu',
    templateUrl: './admin-side-menu.component.html',
    styleUrls: ['./admin-side-menu.component.css']
})
export class SideMenuComponent implements OnInit {

    _toggle = false;
    constructor(public router: Router) { }

    ngOnInit() {

        $('[data-widget="treeview"]').each(function () {
            AdminLte.Treeview._jQueryInterface.call($(this), 'init');
        });

        $('[data-widget="pushmenu"]').click(function () {
            $('body').toggleClass('sidebar-collapse');
        });
    }

    @Input()
    set menuFlag(event) {
        this._toggle = event;
    }

}
