<section class="settings-sec">
  <div class="row p-t-20">
    <div class="col-md-12 col-xs-12 col-lg-12">
      <div class="settings-heading">
        <h1>Settings</h1>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <div class="epro-div">
        <p class="title-grey">Email</p>
        <input class="w95" type="text" disabled placeholder="{{userMail}}" />
      </div>
    </div>
    <div class="col-md-4">
      <div class="epro-div mob-m-t-10">
        <p class="title-grey">Password</p>
        <input class="w95" type="password" disabled placeholder="***********" />
        <span class="cursor-pointer">
          <i class="fas fa-snape-red fa-pencil-alt" aria-hidden="true" data-toggle="modal"
            data-target="#editPasswordCon"></i>
        </span>
      </div>
    </div>
  </div>
  <!-- <div class="row p-t-75">
    <div class="col-md-6 col-lg-6 col-xs-12">
      <div class="printr-caption">
        <h3>Printer Settings</h3>
      </div>
    </div>
    <div class="col-md-6 col-lg-6 col-xs-12">
      <div class="float-right mob-m-b-10">
        <button type="button" class="btn new-device-btn" data-toggle="modal" data-target="#addNewDevice">New
          device</button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-8 col-lg-8 col-xs-8 col-border">
      <div class="row p-t-15">
        <div class="col-md-2 col-xs-12 col-lg-2">
          <div class="printer-png">
            <img src="../../../assets/printer.png">
          </div>
        </div>
        <div class="col-md-6 col-xs-12 col-lg-6">
          <table class="table table-borderless">
            <thead>
              <tr>
                <td>Printer name</td>
                <td>:</td>
                <td>Thornton</td>
              </tr>
            </thead>
            <tbody>
              <tr class="p-t-0">
                <td>Port</td>
                <td>:</td>
                <td>Thornton</td>
              </tr>
              <tr class="p-t-0">
                <td>Connection type</td>
                <td>:</td>
                <td>@fat</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-lg-12 col-xs-12">
          <div class="float-right">
            <button type="button" class="btn connect-btn">Connect</button>
          </div>
        </div>
      </div>
    </div>
  </div> -->
</section>

<!-- add new device Modal start-->

<div class="modal fade" id="addNewDevice" tabindex="-1" role="dialog" aria-labelledby="addNewDeviceTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <form [formGroup]="printerForm">
          <div class="row">
            <div class="col-md-12">
              <div class="printer-caption">
                <h2 class="modal-title pink-color" id="exampleModalLongTitle">Printer</h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mt-2 mb-2">
              <div class="form-group has-float-label mobile-m-t-20">
                <input class="form-control" type="text" formControlName="name" placeholder="Enter printer model name" />
                <label>Enter printer model name</label>
              </div>
            </div>
            <div class="col-md-12 mt-2 mb-2">
              <div class="form-group has-float-label mobile-m-t-20">
                <input class="form-control" type="text" formControlName="port" placeholder="Port" />
                <label>Port</label>
              </div>
            </div>
            <div class="col-md-12 mt-2 mb-2">
              <div class="form-group has-float-label mobile-m-t-20">
                <input class="form-control" type="text" formControlName="type" placeholder="Connection type" />
                <label>Connection type</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-lg-12 col-xs-12 mt-2 mb-2">
              <div class="add-flavour-btns mob-text-align">
                <button type="button" class="btn btn-snape-red-outline" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn add-printer-btn" data-dismiss="modal">Add Printer</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- add new device Modal end-->

<!-- edit pssword modal start -->

<div class="modal fade" id="editPasswordCon" tabindex="-1" role="dialog" aria-labelledby="examplePasswordLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <form  [formGroup]="resetPasswordForm">
          <div class="row">
            <div class="col-md-12 col-xs-12 col-lg-12">
              <div class="row">
                <div class="col-md-12">

                  <div class="m-b-20">
                    <h6 class="mt-2 reset-password">Reset Password</h6>

                    <div class="form-group has-float-label mobile-m-t-20 mt-3">
                      <input class="form-control" type="password" placeholder="Enter new password"
                        formControlName="password" />
                      <label>Password</label>
                      <div class="text-danger"
                        *ngIf="(resetPasswordForm.controls.password.touched || submitted) && resetPasswordForm.controls.password.errors?.required">
                        Password is required
                    </div>
                      <div class="text-danger"
                        *ngIf="resetPasswordForm.controls.password.touched && resetPasswordForm.controls.password.errors?.invalidPassword">
                        Password should have minimum 8 characters, at least 1 uppercase letter, 1 lowercase
                        letter, 1 special character and 1 number
                    </div>
                    </div>
                  </div>

                  <div class="form-group has-float-label mobile-m-t-20 mt-3">
                    <input class="form-control" type="password" placeholder="Re enter new password"
                      formControlName="passwordConfirm" />
                    <label>Re enter password</label>
                    <div class="text-danger"
                      *ngIf="(resetPasswordForm.controls.passwordConfirm.touched || submitted) && resetPasswordForm.controls.passwordConfirm.errors?.required">
                      Confirm Password is required
                  </div>
                    <div class="text-danger"
                      *ngIf="resetPasswordForm.controls.passwordConfirm.touched && resetPasswordForm.controls.passwordConfirm.errors?.passwordMismatch">
                      Passwords doesnot match
                </div>
                  </div>

                  <div class="mt-3">
                    <button type="button" class="btn continue-btn" data-toggle="modal" data-dismiss="modal"
                      data-target="#editPassword" [disabled]="resetPasswordForm.invalid"
                      (click)="continue()">CONTINUE</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- edit pssword modal end -->

<!-- OTP modal end -->

<div class="modal fade" id="editPassword" tabindex="-1" role="dialog" aria-labelledby="examplePasswordLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <form [formGroup]="resendOtpForm">
          <h6 class="mt-2 font-weight reset-password">Reset Password</h6>
          <p>Please enter the OTP sent to {{userMail}}</p>
          <div class="form-group has-float-label mobile-m-t-20 mt-3">
            <input class="form-control" type="number" placeholder="Enter OTP" formControlName="otp" min="0" />
            <label>Enter OTP</label>
            <div class="text-danger"
              *ngIf="(resendOtpForm.controls.otp.touched || submitted) && resendOtpForm.controls.otp.errors?.required">
              Otp is required
          </div>
          </div>

          <p class="snape-red mt-3 fontSize12px d-flex justify-content-end cursor-pointer" (click)="resendOtp()">Resend OTP</p>
          <div class="row">
            <div class="col-md-12 mfooterContinue">
              <button type="button" class="btn btn-snape-red text-uppercase" data-toggle="modal" data-dismiss="modal"
                [disabled]="resendOtpForm.invalid" (click)="changePassword()">CHANGE PASSWORD</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- OTP modal end -->