<!-- https://codepen.io/tonycorp/pen/LRdpYZ -->
<!-- https://github.com/tonycorporated/bootstrap-float-label -->
<div *ngIf="loader == true">
  <app-spinner></app-spinner>
</div>
<section class="vendor-details-sec">
  <div class="row">
    <div class="col-md-12 mt-4">
      <div class="vendor-list-heading">
        <h1 *ngIf="!vendorDetails">Create Vendor</h1>
        <h1 *ngIf="vendorDetails">Edit Vendor</h1>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 mt-4">
      <div class="vendor-list-breadcrumb">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/vendor/list"><i class="fas fa-building"></i> Vendor List</a>
            </li>
            <li *ngIf="!vendorDetails" class="breadcrumb-item active" aria-current="page">Create account</li>
            <li *ngIf="vendorDetails" class="breadcrumb-item active" aria-current="page">Edit vendor</li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 mt-4">
      <ul class="nav nav-pills nav-fill" id="myTab" role="tablist">
        <li class="nav-item active">
          <a class="nav-link active btn-arrow-right left-arrow" data-toggle="tab" href="#home"
            [ngClass]="{ 'active':activeTab ==='home'}" (click)="search('home')">Company <span
              class="mob-display-none">Details</span></a>
        </li>
        <li class="nav-item">
          <a class="nav-link btn-arrow-right" data-toggle="tab" href="#bank" [ngClass]="{ 'active':activeTab==='bank'}"
            (click)="bank('bank')">Bank <span class="mob-display-none">Details</span></a>
        </li>
        <li class="nav-item">
          <a class="nav-link btn-arrow-right right-arrow" data-toggle="tab" href="#contact"
            [ngClass]="{ 'active':activeTab==='contact'}" (click)="contact('contact')">Document <span
              class="mob-display-none">Details</span> </a>
        </li>
      </ul>

      <div class="tab-content m-t-20" id="myTabContent">
        <div class="tab-pane" id="home" role="tabpanel" aria-labelledby="company-tab"
          [ngClass]="{ 'active':activeTab==='home'}">
          <div class="card" style="height: 100%;">
            <div class="card-body">
              <form [formGroup]="companyDetailsForm">
                <div class="row">
                  <div class="col-md-12 d-flex justify-content-end">
                    <span class="cancelbtn" (click)="cancelCompany()">Cancel</span>
                    <button class="btn-snape-red buton-style cursor-pointer btn-pink"
                      (click)="search('bank')">Next</button>
                  </div>
                  <div class="col-md-12 mt-4">
                    <div class="row">
                      <div class="col-md-8">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group has-float-label">
                              <input class="form-control" type="text" formControlName="company_name"
                                placeholder="Company name" />
                              <label>Company name</label>
                            </div>
                            <span class="text-danger"
                              *ngIf="(companyDetailsForm.controls.company_name.touched || submitted) && companyDetailsForm.controls.company_name.errors?.required">
                              Company name is required
                            </span>
                          </div>
                          <div class="col-md-6">

                            <div class="form-group has-float-label mobile-m-t-20">
                              <input class="form-control" type="text" formControlName="cr_number"
                                placeholder="CR number" />
                              <label>CR number</label>
                            </div>
                            <span class="text-danger"
                              *ngIf="(companyDetailsForm.controls.cr_number.touched || submitted) && companyDetailsForm.controls.cr_number.errors?.required">
                              CR number is required
                            </span>
                          </div>
                          <div class="col-md-6 mt-4">

                            <div class="form-group has-float-label">
                              <textarea class="form-control h-95" type="text" formControlName="company_address"
                                placeholder="Company address"></textarea>
                              <label>Company address</label>
                            </div>
                            <span class="text-danger"
                              *ngIf="(companyDetailsForm.controls.company_address.touched || submitted) && companyDetailsForm.controls.company_address.errors?.required">
                              Company Address is required
                            </span>
                          </div>
                          <div class="col-md-6 mt-4">
                            <div class="form-group has-float-label">
                              <input class="form-control" type="text" formControlName="contact_name"
                                placeholder="Contact Name" />
                              <label>Contact Name</label>
                            </div>
                            <span class="text-danger"
                              *ngIf="(companyDetailsForm.controls.contact_name.touched || submitted) && companyDetailsForm.controls.contact_name.errors?.required">
                              Contact name is required
                            </span>

                            <div class="form-group has-float-label input-group m-t-20">
                              <div class="country-code">
                                +974
                              </div>
                              <input class="form-control country-code-txt-box" formControlName="mobile_number"
                                type="text" placeholder="Mobile number" required>
                              <label class="label-margin">Mobile number</label>
                            </div>
                            <span class="text-danger"
                              *ngIf="(companyDetailsForm.controls.mobile_number.touched || submitted) && companyDetailsForm.controls.mobile_number.errors?.required">
                              Mobile number is required
                            </span>
                            <span class="text-danger"
                              *ngIf="companyDetailsForm.controls.mobile_number.touched && companyDetailsForm.controls.mobile_number.errors?.pattern">
                              Enter a valid number
                            </span>
                          </div>

                          <div class="col-md-6 mt-4">

                            <div class="form-group has-float-label" *ngIf="vendorDetails?.email">
                              <input class="form-control disable-pointer-events" type="text" formControlName="email_id"
                                placeholder="Email ID" />
                              <label>Email ID</label>
                            </div>
                            <div class="form-group has-float-label" *ngIf="!vendorDetails?.email">
                              <input class="form-control" type="text" formControlName="email_id"
                                placeholder="Email ID" />
                              <label>Email ID</label>
                            </div>
                            <span class="text-danger"
                              *ngIf="(companyDetailsForm.controls.email_id.touched || submitted) && companyDetailsForm.controls.email_id.errors?.required">
                              Email id is required
                            </span>
                            <span class="text-danger"
                              *ngIf="companyDetailsForm.controls.email_id.touched && companyDetailsForm.controls.email_id.errors?.pattern">
                              Enter a valid email address
                            </span>
                            <div class="form-group has-float-label m-t-20">
                              <input class="form-control" type="text" formControlName="website" placeholder="Website" />
                              <label>Website</label>
                            </div>
                            <span class="text-danger"
                              *ngIf="(companyDetailsForm.controls.website.touched || submitted) && companyDetailsForm.controls.website.errors?.required">
                              Webiste is required
                            </span>
                          </div>
                          <div class="col-md-6 mt-1">
                            <div class="form-group has-float-label input-group m-t-20">
                              <div class="country-code">
                                +974
                              </div>
                              <input class="form-control country-code-txt-box" formControlName="phone_number"
                                type="text" placeholder="Phone number" required>
                              <label class="label-margin">Phone number</label>
                            </div>
                            <span class="text-danger"
                              *ngIf="(companyDetailsForm.controls.phone_number.touched || submitted) && companyDetailsForm.controls.phone_number.errors?.required">
                              Phone number is required
                            </span>
                            <span class="text-danger"
                              *ngIf="companyDetailsForm.controls.phone_number.touched && companyDetailsForm.controls.phone_number.errors?.pattern">
                              Enter a valid number
                            </span>
                            <span *ngIf="vendorDetails?.email">
                              <input class="form-control m-t-20 disable-pointer-events" type="password"
                                formControlName="password" placeholder="************" />
                            </span>
                            <span *ngIf="!vendorDetails">
                              <div class="form-group has-float-label m-t-20">
                                <input class="form-control" type="text" formControlName="password"
                                  placeholder="Password" />
                                <label>Password</label>
                              </div>
                            </span>
                            <span class="text-danger"
                              *ngIf="(companyDetailsForm.controls.password.touched || submitted) && companyDetailsForm.controls.password.errors?.required">
                              Password is required
                            </span>
                            <span class="text-danger"
                              *ngIf="companyDetailsForm.controls.password.touched && companyDetailsForm.controls.password.errors?.invalidPassword">
                              Password should have minimum 8 characters, at least 1 uppercase letter, 1 lowercase
                              letter, 1 special character and 1 number
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="mob-text-align">
                              <button class="cursor-pointer add-new-acnt mobile-m-t-20 display" data-toggle="modal"
                                data-target="#add-contact" (click)="contactForm.reset()">
                                <i class="fas fa-user"></i>
                                <br>
                                <span class="font-size-13">Add new contact</span></button>
                            </div>
                          </div>
                          <div class="col-md-6" *ngFor="let details of contactList; let i=index">
                            <div class="add-new-acnt2 m-b-15 mob-text-align">
                              <div class="contact-usr-image">
                                <i class="fas fa-user"></i>
                                <br>
                                <span class="font-size-13" data-toggle="tooltip" data-placement="top"
                                  title={{details.name}}>
                                  {{details.name}}
                                </span>
                                <br>
                                <span class="font-size-13" data-toggle="tooltip" data-placement="top"
                                  title={{details.mobile}}>
                                  {{details.mobile}}
                                </span>
                              </div>
                              <div class="editable-iocns">
                                <i class="far fa-edit m-r-10 cursor-pointer" data-toggle="modal"
                                  data-target="#add-contact" (click)="editContact(details)"></i>
                                <i class="far fa-trash-alt cursor-pointer" data-toggle="modal" data-target="#delModal"
                                  (click)="removeContact(details.id)"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="tab-pane" id="bank" role="tabpanel" aria-labelledby="bank-tab"
          [ngClass]="{ 'active':activeTab==='bank'}">
          <div class="card" style="height: 100%;">
            <div class="card-body">
              <form [formGroup]="bankDetailsForm">
                <div class="row">
                  <div class="col-md-12 d-flex justify-content-end">
                    <span class="cancelbtn" (click)="cancelBank()">Cancel</span>
                    <button class="btn-snape-red buton-style cursor-pointer btn-pink" *ngIf="!vendorDetails"
                      (click)="saveCompanyBankDetails()">Save</button>
                    <button class="btn-snape-red buton-style cursor-pointer btn-pink" *ngIf="vendorDetails"
                      (click)="updateCompanyBankDetails()">Update</button>
                  </div>
                  <div class="col-md-12 mt-4">
                    <div class="row">
                      <div class="col-md-4">

                        <div class="form-group has-float-label mobile-m-t-20">
                          <input class="form-control" type="text" formControlName="bank_name" placeholder="Bank name" />
                          <label>Bank name</label>
                        </div>
                        <span class="text-danger"
                          *ngIf="(bankDetailsForm.controls.bank_name.touched || submitted) && bankDetailsForm.controls.bank_name.errors?.required">
                          Bank name is required
                        </span>
                      </div>
                      <div class="col-md-4">

                        <div class="form-group has-float-label mobile-m-t-20">
                          <input class="form-control" type="text" formControlName="branch_name"
                            placeholder="Branch name" />
                          <label>Branch name</label>
                        </div>
                        <span class="text-danger"
                          *ngIf="(bankDetailsForm.controls.branch_name.touched || submitted) && bankDetailsForm.controls.branch_name.errors?.required">
                          Branch name is required
                        </span>
                      </div>
                      <div class="col-md-4">

                        <div class="form-group has-float-label mobile-m-t-20">
                          <input class="form-control" type="text" formControlName="branch_address"
                            value="{{vendorDetails?.branch_address}}" placeholder="Branch address" />
                          <label>Branch address</label>
                        </div>
                        <span class="text-danger"
                          *ngIf="(bankDetailsForm.controls.branch_address.touched || submitted) && bankDetailsForm.controls.branch_address.errors?.required">
                          Branch Address is required
                        </span>
                      </div>
                      <div class="col-md-4 mt-4">

                        <div class="form-group has-float-label">
                          <input class="form-control" type="text" formControlName="account_holder_name"
                            placeholder="Account holder name" />
                          <label>Account holder name</label>
                        </div>
                        <span class="text-danger"
                          *ngIf="(bankDetailsForm.controls.account_holder_name.touched || submitted) && bankDetailsForm.controls.account_holder_name.errors?.required">
                          Account holder name is required
                        </span>
                      </div>
                      <div class="col-md-4 mt-4">

                        <div class="form-group has-float-label">
                          <input class="form-control" type="text" formControlName="account_number"
                            placeholder="Account number" />
                          <label>Account number</label>
                        </div>
                        <span class="text-danger"
                          *ngIf="(bankDetailsForm.controls.account_number.touched || submitted) && bankDetailsForm.controls.account_number.errors?.required">
                          Account number is required
                        </span>
                      </div>
                      <div class="col-md-4 mt-4">

                        <div class="form-group has-float-label">
                          <input class="form-control" type="text" formControlName="iban" placeholder="IBAN" />
                          <label>IBAN</label>
                        </div>
                        <span class="text-danger"
                          *ngIf="(bankDetailsForm.controls.iban.touched || submitted) && bankDetailsForm.controls.iban.errors?.required">
                          IBAN is required
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="tab-pane" id="contact" role="tabpanel" aria-labelledby="flavour-tab"
          [ngClass]="{ 'active':activeTab==='contact'}">
          <div class="card" style="height: 100%;">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="float-right mob-text-align">
                    <button type="button" class="buton-style btn-pink" data-toggle="modal" data-target="#sucessModal"
                      (click)="documentSubmit()">Submit</button>
                  </div>
                </div>
                <div class="col-md-4 mt-4 col-border" id="second">
                  <form [formGroup]="documentDetailsForm" id="file-upload-form" class="uploader">
                    <!-- Upload start-->
                    <div class="input-group">
                      <input id="file-upload" type="file" name="fileUpload" accept="image/*"
                        formControlName="fileSource" (change)="handleFileInput($event.target.files)" />
                      <label for="file-upload" id="file-drag" class="label2">
                        <div class="imagePreview">
                          <img id="file-image" alt="">
                        </div>
                        <div id="start">
                          <div class="upload-icon"><i class="fas fa-upload" aria-hidden="true"></i></div>
                          <div class="upload-doc">Upload Document</div>
                        </div>
                      </label>
                    </div>
                    <!-- Upload  ends-->
                    <!-- Uploaded file name start-->
                    <!-- <div class="input-group">
                      <label id="file-drag" class="label2">
                        <div class="uploaded-doc-name">file name here</div>
                      </label>
                    </div> -->
                    <!-- Uploaded file name end-->
                    <div class="m-t-15">
                      <select class="custom-select" id="inputGroupSelect01" formControlName="choose_document"
                        placeholder="Choose Document">
                        <option value="" disabled>Choose Document</option>
                        <option value="cr_document">CR Document</option>
                        <option value="establishment_id">Establishment ID</option>
                        <option value="owner_id">Owner ID</option>
                        <option value="agreement">Agreement</option>
                      </select>
                    </div>
                    <div class="expiryDate">File expiry date</div>
                    <div class="text-align">
                      <input class="form-control" type="date" min="{{today | date:'yyyy-MM-dd'}}" formControlName="expiry_date" placeholder="Expiry date" />

                      <!-- <input type="text" #queryDate class="form-control" placeholder="Date" onfocus="(this.type='date')"
                        min="{{today | date:'yyyy-MM-dd'}}" (change)="filterDate(queryDate.valueAsDate)"> -->

                    </div>
                    <div class="text-align">
                      <!-- <button class="btn-snape-red doc-upload-btn" (click)="upload()">UPLOAD</button> -->
                    </div>
                  </form>
                </div>

                <div class="col-md-8 mt-4">
                  <div>
                    <table class="table table-borderless">
                      <thead>
                        <tr *ngIf="fileToUpload?.name">
                          <td scope="col"><i class="far fa-file-image"></i> <span
                              class="font-size-14">{{fileToUpload?.name}}</span></td>
                          <!-- <td scope="col" class="font-size-14">Agreement with company</td>
                              <td scope="col" class="font-size-14">Exp date : 05-Feb-2021</td> -->
                          <td><i class="fas fa-times" (click)="fileToUpload = ''"></i></td>
                        </tr>
                      </thead>
                      <tbody>
                        <!-- <tr>
                              <td scope="col"><i class="far fa-file-alt"></i> <span
                                  class="font-size-14">Filename.jpg</span></td>
                              <td scope="col" class="font-size-14">Agreement with company</td>
                              <td scope="col" class="font-size-14">Exp date : 05-Feb-2021</td>
                              <td><i class="fas fa-times"></i></td>
                            </tr> -->
                        <div *ngIf="vendorDetails?.doc_data">
                          <tr *ngFor="let doc of vendorDetails?.doc_data">
                            <td scope="col"><i class="far fa-file-pdf"></i> <span class="font-size-14">{{doc}}
                                <!-- Filename.jpg -->
                              </span></td>
                            <!-- <td scope="col" class="font-size-14">Agreement with company</td>
                            <td scope="col" class="font-size-14">Exp date : 05-Feb-2021</td> -->
                            <td><i class="fas fa-times"></i></td>
                          </tr>
                        </div>
                        <tr>

                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- add new contact modal starts -->
      <div class="modal fade" id="add-contact" tabindex="-1" role="dialog" aria-labelledby="addModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div class="modal-content">
            <form [formGroup]="contactForm">
              <div class="modal-header">
                <h4 class="modal-title snape-red font-weight" id="exampleModalLabel">Add new Contact
                </h4>
                <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button> -->
                <!-- <div class="form-check">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                    <label class="form-check-label" for="inlineCheckbox1">Primary contact</label>
                  </div>
                </div> -->
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-md-12 mt-2">
                    <div class="form-group has-float-label">
                      <input class="form-control" type="text" formControlName="contact_name" placeholder="Enter name" />
                      <label>Enter name</label>
                    </div>
                    <!-- <input class="form-control" type="text" formControlName="contact_name" placeholder="Enter name" /> -->
                    <span class="text-danger"
                      *ngIf="(contactForm.controls.contact_name.touched || contact_submitted) && contactForm.controls.contact_name.errors?.required">
                      Name is required
                    </span>
                  </div>
                  <div class="col-md-12 mt-3">
                    <div class="form-group has-float-label input-group">
                      <div class="country-code">
                        +974
                      </div>
                      <input class="form-control country-code-txt-box" formControlName="contact_number" type="text"
                        placeholder="Contact number" required>
                      <label class="label-margin">Contact number</label>
                    </div>

                    <!-- <div class="form-group input-group">
                      <div class="country-code">
                        +974
                      </div>
                      <input class="form-control country-code-txt-box" formControlName="contact_number" type="text"
                        placeholder="Contact number" required>
                    </div> -->
                    <span class="text-danger"
                      *ngIf="(contactForm.controls.contact_number.touched || contact_submitted) && contactForm.controls.contact_number.errors?.required">
                      Contact number is required
                    </span>
                    <span class="text-danger"
                      *ngIf="contactForm.controls.contact_number.touched && contactForm.controls.contact_number.errors?.pattern">
                      Enter a valid number
                    </span>
                  </div>
                </div>

                <!-- </div>
              <div class="modal-footer"> -->
                <div class="row">
                  <div class="col-md-12">
                    <div class="float-right mt-3">

                      <button type="button" class="btn cancel-btn" data-dismiss="modal"
                        (click)="contactForm.reset()">Cancel</button>
                      <button type="button" *ngIf="!contactId" class="btn add-btn" (click)="addContact()"
                        [disabled]="contactForm.invalid" data-dismiss="modal">Save</button>
                      <button type="button" *ngIf="contactId" class="btn add-btn" (click)="updateContact(contactId)"
                        [disabled]="contactForm.invalid" data-dismiss="modal">Save</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- add new contact modal ends -->
    </div>
  </div>
</section>

<!-- secess modal start-->
<!-- Modal -->
<!-- <div class="modal fade" id="sucessModal" tabindex="-1" role="dialog" aria-labelledby="sucessModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div class="success-png"><img src="../../../assets/success.png"></div>
          </div>
          <div class="col-md-12">
            <div class="text-align">
              <h6 class="sucess-msg-head">Successfull</h6>
            </div>
          </div>
          <div class="col-md-12">
            <div class="text-align">
              <p class="sucess-msg">Added new vendor successfully</p>
            </div>
          </div>
          <div class="col-md-12">
            <div>
              <button type="button" class="btn continue-btn" data-dismiss="modal">Continue</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
<!-- delete modal starts-->
<div class="modal fade" id="delModal" tabindex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-body">

        <div class="row">
          <div class="col-md-12">
            <div class="info-icon-img">
              <img src="../../../assets/exclamation.png">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="confirm-txt">
              <h6 class="mb-3">Are you sure?</h6>
              <p class="mb-3"> Do you want to delete this contact?</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mfooterBtn d-flex justify-content-end">
            <button type="button" class="btn color-cancel-btn mr-2" data-dismiss="modal">Cancel</button>
            <button type="button" class="btn submit-btn ml-2" data-dismiss="modal"
              (click)="deleteContact(contactId)">Delete</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<script type="text/javascript">
  $('.nexttab').click(function () {
    $('.nav-tabs > .active').next('li').find('a').trigger('click');
  });
</script>