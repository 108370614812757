<div class="container">
    <div class="row p-t-20">
      <div class="col-md-12 col-xs-12 col-lg-12">
        <div class="heading">
          <h1>Manage Profile</h1>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-2 col-lg-3 col-xs-12">
      </div>
      <div class="col-md-8 col-lg-6 col-xs-12">
        <div class="card">
          <div class="box">
            <h3>Change email address</h3>
            <input type="email" class="form-control" placeholder="Enter OTP">
            <input type="password" class="form-control m-t-10" placeholder="Enter your password">
            <button type="submit" class="btn btn-snape-bak btn-block text-uppercase">Submit</button>
          </div>
        </div>
      </div>
      <div class="col-md-2 col-lg-3 col-xs-12">
      </div>
    </div>
  </div>
  