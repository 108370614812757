import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from '../../common/common.service'
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.css']
})
export class VerifyEmailComponent implements OnInit{
  emailVerifyForm : FormGroup;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    public api: CommonService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.emailVerifyForm = this.fb.group({
      code: ['', Validators.required]
    })
  }
sendEmail(){
  this.api.emailVerify(this.emailVerifyForm.controls.code.value).subscribe(res=>{
    console.log(res, 'result')
    let message = res['message']
    this.toastr.success(message)
    let status = res['status']
    if(status == true){
      this.router.navigate(['login'])
    }
  })
}
}
