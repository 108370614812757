<div *ngIf="loader == true">
  <app-spinner></app-spinner>
</div>
<section class="order-details-sec">
  <div class="row p-t-20">
    <div class="col-md-12 col-xs-12 col-lg-12">
      <div class="order-details-heading">
        <h1>Order Details</h1>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 mt-4">
      <div class="vendor-list-breadcrumb">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item cursor-pointer" routerLink="/order/list" routerLinkActive="router-link-active">
              <a><i class="fas fa-shopping-cart"></i> Order list</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Order id : {{this.orderDetails?.order_id}}</li>
          </ol>
        </nav>
      </div>
    </div>
    <!-- </div>
  <div class="row"> -->
    <div class="col-md-6">
      <div class="d-flex justify-content-end">
        <div class="print-cookie-btn">
          <button type="button" class="btn btn-link" (click)="printAll()">Preview Cookie <i
              class="fas fa-print"></i></button>
        </div>

        <div class="ml-3"
          >
          <div class="cancel-bulk-order-btn">
            <button type="button" *ngIf="orderData?.orderstatus != 'Order Cancelled'" (click)="cancelOrder()" class="btn cancel-btn">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- tab view -->
  <!-- ref : https://codepen.io/ariwinokur/pen/rxKZEp -->
  <div class="row">
    <div class="col-md-12 col-xs-12 col-lg-12">
      <div>
        <ul class="nav nav-pills nav-fill" id="myTab" role="tablist">
          <li class="nav-item">
            <a class="nav-link active btn-arrow-right left-arrow" id="cookies-tab" data-toggle="tab" href="#Cookies"
              role="tab" aria-controls="home" aria-selected="true">
              <span class="ellipsis2">Cookie details</span></a>
          </li>
          <li class="nav-item" *ngIf="orderData?.home_delivery =='home_delivery'">
            <a class="nav-link btn-arrow-right right-arrow" id="address-tab" data-toggle="tab" href="#Address"
              role="tab" aria-controls="contact" aria-selected="false"><span class="ellipsis">Delivery
                address</span></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- order details -->
  <div class="row tab-content">
    <div class="col-md-12 col-xs-12 col-lg-12 tab-pane fade show active" id="Cookies" role="tabpanel"
      aria-labelledby="cookies-tab">
      <div class="d-flex mt-3 justify-content-between flex-wrap pr-2">
        <div class="">
          <div class="mob-text-align">
            <p class="ref-id-ellipsis" data-toggle="tooltip" data-placement="top" title={{orderData?.reference_id}}>
              Ref id : <span class="font-bold">{{orderData?.reference_id}}</span></p>
          </div>
        </div>
        <div class="">
          <div>
            <p class="text-align">Order date : <span class="font-bold">{{orderData?.created_at |
                date:'dd-MM-YYYY'}}
              </span></p>
          </div>
        </div>
        <div class="">
          <div>
            <p class="text-align">Delivery date : <span class="font-bold">{{orderData?.delivery_date}} </span></p>
          </div>
        </div>
        <div class="">
          <div>
            <p class="text-align">Delivery time : <span class="font-bold">{{orderData?.delivery_time | date:
                'shortTime'}} </span></p>
          </div>
        </div>
        <div>
          <div>
            <p *ngIf="orderData?.ordertype !='bulkorder'" class="text-align">Invoice ID : <span
                class="font-bold">{{orderData?.invoiceID}} </span></p>
            <!-- <p class="text-align">Invoice ID : <span class="font-bold">{{orderData?.invoiceID}} </span></p> -->
          </div>
        </div>
        <div class="">
          <div class="d-flex justify-content-between">
            <div class="mr-3">
              <!-- <p class="text-align">Current status: {{orderData?.orderstatus}}</p> -->
              <p *ngIf="orderData?.orderstatus == 'Order Cancelled'" class="text-align">Status :
                {{orderData?.orderstatus}}</p>
              <p *ngIf="orderData?.orderstatus != 'Order Cancelled'" class="text-align">Status : </p>
            </div>
            <div class="" *ngIf="orderData?.orderstatus != 'Order Cancelled'">
              <div class="m-t-minus-5 mob-status-align">
                <select class="form-control status-order-control" (change)="changeOrderStatus($event)">
                  <option class="order-status" *ngFor="let status of orderStatus" [value]="status.status"
                    [selected]="status.status == orderData?.orderstatus" [disabled]="status.status == disableStatus || status.status == disableStatus2 || 
                    status.status == disableStatus3 || status.status == disableStatus4 || status.status == disableStatus5
                    || status.status == disableStatus6 || status.status == disableStatus7">{{status.status}}</option>
                </select>
              </div>
            </div>
          </div>
          <div>
            <!-- <span class="font-bold">{{orderDetails?.current_status}} </span> -->
          </div>
        </div>

        <!-- <div class="col-md-2 col-lg-2 col-xs-12">
          <div class="float-right mob-m-t-10">
            <p class="pink-color text-align">
              <span class="envelope-mail cursor-pointer">
                <i class="far fa-envelope"></i>
              </span>
              <span class="msg-content-span cursor-pointer">Unread message</span>
            </p>
          </div>
        </div> -->
      </div>
      <div *ngFor="let products of productList">
        <!-- cookie box 1 -->
        <div class="row mt-2" *ngIf="products.product.cookie_number == 1">
          <div class="col-md-6 col-lg-6 col-xs-12">
            <div class="row m-b-50">
              <div class="col-md-6 col-lg-4 col-sm-6 col-xs-12">
                <div class="cookie-box-one text-align">
                  <img src="{{products.box_img_url}}">
                </div>
              </div>
              <div class="col-md-6 col-lg-8 col-sm-6 col-xs-12">
                <div class="text-align m-t-15 justify-content">
                  <p class="cookie-box-caption">1 COOKIE BOX
                    <span *ngIf="products?.message_status == 'unread'" class="pink-color cursor-pointer">
                      <span class="envelope-mail">
                        <i class="far fa-envelope"></i>
                      </span>
                      Unread message
                    </span>
                  </p>
                  <p class="line-height">Cookie Qty : {{products.quantity}} </p>
                  <p class="line-height">Box Color : <span class="pink-color">{{products.box_color}} </span></p>
                  <p class="pink-color m-l-20 line-height">
                    <!-- <input class="form-check-input checkbox-label m-t-minus-1" type="checkbox" value=""
                      id="flexCheckDefault">
                    <label class="form-check-label checkbox-label" for="flexCheckDefault" [checked]="products.is_gift">
                      This order contains a gift
                    </label> -->


                    <!-- <input class="form-check-input checkbox-label" type="checkbox" value="" id="defaultCheck1">
                  <label class="form-check-label checkbox-label" for="defaultCheck1">
                    Primary Contact
                  </label> -->


                  </p>
                  <div *ngFor="let flavor of products.category |keyvalue">
                    <p class="line-height">
                      {{flavor.key}} : {{flavor.value.length}}</p>
                  </div>
                </div>
                <div class="mob-text-align" *ngIf="orderData?.orderstatus != 'Order Cancelled'">
                  <button type="button" class="btn btn-royal-pink" (click)="message(products)">Message</button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-xs-12">
            <div class="row">
              <div class="col-md-2 col-lg-2 col-sm-6 col-xs-12" *ngFor="let item of products.customize; let i=index">
                <div class="d-flex align-items-center justify-content-center snape-bgColor1 ckieAdd">
                  <div class="ckieAdd-bg-color-one-cookie tooltip-top" id="demo">
                    <img src="{{products.customize[i]?.image_url}}" (click)="boxNumber(i, item, products)"
                      class="cursor-pointer" data-toggle="modal" data-target="#flavourUpdateModal">
                    <span class="tooltip">{{item.flavor.name}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- cookie box 2 -->
      <div *ngFor="let products of productList">
        <div class="row" *ngIf="products.product.cookie_number == 2">
          <div class="col-md-6 col-lg-6 col-xs-12">
            <div class="row m-b-50">
              <div class="col-md-6 col-lg-4 col-sm-6 col-xs-12">
                <div class="cookie-box-one text-align">
                  <img src="{{products.box_img_url}}">
                </div>
              </div>
              <div class="col-md-6 col-lg-8 col-sm-6 col-xs-12">
                <div class="text-align m-t-15 justify-content">
                  <p class="cookie-box-caption">2 COOKIE BOX
                    <span *ngIf="products?.message_status == 'unread'" class="float-right pink-color">
                      Unread message
                      <span class="envelope-mail cursor-pointer float-right">
                        <i class="far fa-envelope"></i>
                      </span>
                    </span>
                  </p>
                  <p class="line-height">Cookie Qty : {{products.quantity}}</p>
                  <p class="line-height">Box Color : <span class="pink-color">{{products.box_color}}</span></p>
                  <p class="pink-color m-l-20 line-height">
                    <!-- <input class="form-check-input m-t-minus-1" type="checkbox" value="" id="flexCheckDefault"
                      [checked]="products.is_gift">
                    <label class="form-check-label" for="flexCheckDefault">
                      This order contains a gift
                    </label> -->
                  </p>
                  <div *ngFor="let flavor of products.category |keyvalue">
                    <p class="line-height">
                      {{flavor.key}} : {{flavor.value.length}}</p>
                  </div>
                </div>
                <div class="mob-text-align">
                  <button type="button" class="btn btn-royal-pink" (click)="message(products)">Message</button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-xs-12">
            <div class="row">
              <div class="col-md-3 col-lg-3 col-sm-6 col-xs-12"
                *ngFor="let item of counter(products.customize.length  / 2); let i=index;">
                <div class="d-flex align-items-center justify-content-center snape-bgColor2 ckieAdd2 m-b-10">
                  <span *ngFor="let num of counter(2);let j=index;" id="demo">
                    <div class="ckieAdd-bg-color-testing tooltip-top">
                      <img src="{{products.customize[i*2+j]?.image_url}}"
                        (click)="boxNumber(i*2+j, products.customize[i*2+j], products)" class="cursor-pointer"
                        data-toggle="modal" data-target="#flavourUpdateModal">
                      <span class="tooltip">{{products.customize[i*2+j]?.flavor.name}}</span>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- cookie box 3 -->
      <div *ngFor="let products of productList">
        <div class="row" *ngIf="products.product.cookie_number == 3">
          <div class="col-md-6 col-lg-6 col-xs-12">
            <div class="row m-b-50">
              <div class="col-md-6 col-lg-4 col-sm-6 col-xs-12">
                <div class="cookie-box-one text-align">
                  <img src="{{products.box_img_url}}">
                </div>
              </div>
              <div class="col-md-6 col-lg-8 col-sm-6 col-xs-12">
                <div class="text-align m-t-15 justify-content">
                  <p class="cookie-box-caption">3 COOKIE BOX
                    <span *ngIf="products?.message_status == 'unread'" class="float-right pink-color">
                      Unread message
                      <span class="envelope-mail cursor-pointer float-right">
                        <i class="far fa-envelope"></i>
                      </span>
                    </span>
                  </p>
                  <p class="line-height">Cookie Qty : {{products.quantity}}</p>
                  <p class="line-height">Box Color : <span class="pink-color">{{products.box_color}}</span></p>
                  <p class="pink-color m-l-20 line-height">
                    <!-- <input class="form-check-input m-t-minus-1" type="checkbox" value="" id="flexCheckDefault"
                      [checked]="products.is_gift">
                    <label class="form-check-label" for="flexCheckDefault">
                      This order contains a gift
                    </label> -->
                  </p>
                  <div *ngFor="let flavor of products.category |keyvalue">
                    <p class="line-height">
                      {{flavor.key}} : {{flavor.value.length}}</p>
                  </div>
                </div>
                <div class="mob-text-align">
                  <button type="button" class="btn btn-royal-pink" (click)="message(products)">Message</button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-xs-12">
            <div class="row">
              <div class="col-md-6 col-lg-4 col-sm-6 col-xs-12"
                *ngFor="let item of counter(products.customize.length  / 3); let i=index;">
                <div class="d-flex align-items-center justify-content-center snape-bgColor4 ckieAdd3 m-b-10 flex">
                  <div class="d-flex m-t-5">
                    <span *ngFor="let num of counter(3);let j=index;" id="demo">
                      <div class="ckieAdd-bg-color-three-cookie tooltip-top">
                        <img src="{{products.customize[3*((i+1)-1)+((j+1)-1)]?.image_url}}" class="cursor-pointer"
                          (click)="boxNumber(3*((i+1)-1)+((j+1)-1), products.customize[3*((i+1)-1)+((j+1)-1)], products)"
                          data-toggle="modal" data-target="#flavourUpdateModal">
                        <span class="tooltip">{{products.customize[3*((i+1)-1)+((j+1)-1)]?.flavor.name}}</span>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- cookie box 6 -->
      <div *ngFor="let products of productList">
        <div class="row" *ngIf="products.product.cookie_number == 6">
          <div class="col-md-6 col-lg-6 col-xs-12">
            <div class="row m-b-50">
              <div class="col-md-6 col-lg-4 col-sm-6 col-xs-12">
                <div class="cookie-box-one text-align">
                  <img src="{{products.box_img_url}}">
                </div>
              </div>
              <div class="col-md-6 col-lg-8 col-sm-6 col-xs-12">
                <div class="text-align m-t-15 justify-content">
                  <p class="cookie-box-caption">6 COOKIE BOX
                    <span *ngIf="products?.message_status == 'unread'" class="float-right pink-color">
                      Unread message
                      <span class="envelope-mail cursor-pointer float-right">
                        <i class="far fa-envelope"></i>
                      </span>
                    </span>
                  </p>
                  <p class="line-height">Cookie Qty : {{products.quantity}}</p>
                  <p class="line-height">Box Color : <span class="pink-color">{{products.box_color}}</span></p>
                  <p class="pink-color m-l-20 line-height">
                    <!-- <input class="form-check-input m-t-minus-1" type="checkbox" value="" id="flexCheckDefault">
                    <label class="form-check-label" for="flexCheckDefault" [checked]="products.is_gift">
                      This order contains a gift
                    </label> -->
                  </p>
                  <div *ngFor="let flavor of products.category |keyvalue">
                    <p class="line-height">
                      {{flavor.key}} : {{flavor.value.length}}</p>
                  </div>
                </div>
                <div class="mob-text-align">
                  <button type="button" class="btn btn-royal-pink" (click)="message(products)">Message</button>

                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-md-6 col-lg-6 col-xs-12">
            <div class="row">
              <div class="col-md-6 col-lg-4 col-sm-6 col-xs-12"
                *ngFor="let item of counter(products.customize.length  / 6); let i=index;">
                <div class="align-items-center justify-content-center snape-bgColor6 six-ckieAdd m-b-10 flex">
                  <div class="d-flex mob-p-t-38">
                    <div *ngFor="let num of counter(6);let j=index;">
                      <div class="ckieAdd-bg-color-6cookie">
                        <img src="{{products.customize[6*((i+1)-1)+((j+1)-1)]?.image_url}}"
                          (click)="boxNumber(6*((i+1)-1)+((j+1)-1), products.customize[6*((i+1)-1)+((j+1)-1)], products)"
                          class="cursor-pointer" data-toggle="modal" data-target="#flavourUpdateModal">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

          <div class="col-md-6 col-lg-6 col-xs-12">
            <div class="row">
              <div class="col-md-4" *ngFor="let item of counter(products.customize.length  / 6); let i=index;">
                <div class="row snape-bgColor6 six-ckieAdd">
                  <div class="col-md-4 p-l-r" *ngFor="let num of counter(6);let j=index;" id="demo">
                    <!-- <div *ngIf="cookieBoxArray[6*((i+1)-1)+((j+1)-1)]?.image_url" class="ckieAdd-bg-image-6cookie" [ngStyle]="{'background-image':' url('+ cookieBoxArray[6*((i+1)-1)+((j+1)-1)]?.flavor?.image +')'}"> -->
                    <div class="ckieAdd-bg-color-6cookie tooltip-top">
                      <img src="{{products.customize[6*((i+1)-1)+((j+1)-1)]?.image_url}}"
                        (click)="boxNumber(6*((i+1)-1)+((j+1)-1), products.customize[6*((i+1)-1)+((j+1)-1)], products)"
                        class="cursor-pointer" data-toggle="modal" data-target="#flavourUpdateModal">
                      <span class="tooltip">{{products.customize[6*((i+1)-1)+((j+1)-1)]?.flavor.name}}</span>
                    </div>
                    <!-- </div>  -->
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="col-md-12 col-xs-12 col-lg-12 tab-pane fade" id="Address" role="tabpanel" aria-labelledby="address-tab">
      <div class="row m-t-20">
        <div class="col-md-6">
          <div class="address-area">
            <p>{{orderData?.order_address?.name}}</p>
            <p>Area: {{orderData?.order_address?.area}}</p>
            <p>Building: {{orderData?.order_address?.building}}</p>
            <p>City: {{orderData?.order_address?.city}}</p>
            <p>Flat/Villa :{{orderData?.order_address?.flat_villa}}</p>
            <p>Street: {{orderData?.order_address?.street}}</p>
            <p>Zone: {{orderData?.order_address?.zone}}</p>
            <p>Contact Number: {{orderData?.order_address?.contact_number}}</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="map-area">
            <!-- <img src="../../../assets/qatar-map-3.png"> -->
          </div>
        </div>

      </div>
    </div>
  </div>
</section>

<!-- flavour Update Modal start -->
<div class="modal fade" id="flavourUpdateModal" tabindex="-1" role="dialog" aria-labelledby="flavourUpdateModalTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Flavour : <span class="flavour-span">{{flavorName}}</span>
        </h5>
        <p *ngIf="orderData?.orderstatus == 'Order Placed' || orderData?.orderstatus == 'Order Accepted' || orderData?.orderstatus == 'Processing'"
          (click)="updateCookie(itemId, index)">Update Cookie</p>
      </div>
      <div class="modal-body m-t-minus25">
        <div class="row">
          <div class="col-md-12 col-xs-12 col-lg-12">
            <div class="cookie-img" [style.backgroundImage]="'url('+ cookieData?.flavor?.image +')'">
              <div class="align-center">
                <img src="{{cookie_image}}" alt="">
              </div>
            </div>
          </div>
          <div class="col-md-12 col-xs-12 col-lg-12">
            <div class="align-center">
              <h3>Print Cookie</h3>
              <p>Do you want to print this image</p>
            </div>
          </div>
          <div class="col-md-12 col-xs-12 col-lg-12">
            <div class="align-center">
              <button type="button" class="btn btn-grey" data-dismiss="modal">Cancel</button>
              <button type="button" class="btn btn-pink" (click)="printById()">Preview Cookie <i
                  class="fas fa-print"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- flavour Update Modal end -->