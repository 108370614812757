<div *ngIf="loader == true">
  <app-spinner></app-spinner>
</div>
<section class="notifications-list-sec">
  <div class="row p-t-20">
    <div class="col-md-4 col-xs-12 col-lg-4 col-xl-7">
      <div class="notification-heading">
        <h1>Notifications</h1>
      </div>
    </div>
    <div class="col-md-3 col-xs-12 col-lg-3 col-xl-2">
      <div class="mob-p-b-20">
        <input type="text" #queryDate (change)="filterDate(queryDate.valueAsDate)" class="form-control cursor-pointer" [max]="getToday()"
          placeholder="Choose date" onfocus="(this.type='date')">
      </div>
    </div>
    <div class="col-md-4 col-xs-12 col-lg-4 col-xl-2">
      <div class="mob-p-b-20">
        <angular2-multiselect [data]="statusArray" [(ngModel)]="selectedStatus" (onSelect)="onItemSelect($event)"
        (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
        (onDeSelectAll)="onDeSelectAll($event)" [settings]="statusDropdownSettings">
        </angular2-multiselect>
      </div>
    </div>
    <div class="col-md-1 col-lg-1 col-xs-12 col-xl-1">
      <div class="reload-icon">
        <i class="fas fa-sync-alt" (click)="clear()"></i>
      </div>
    </div>
  </div>
  <div class="row card-border">
    <div class="col-md-12 col-xs-12 col-lg-12">
      <div class="row">
        <div class="col-md-6 col-lg-6 col-xs-12">
          <div class="notify-date mob-text-align">
            <!-- <p>Jan-26-2021</p> -->
          </div>
        </div>
        <div class="col-md-6 col-lg-6 col-xs-12">
          <div class="clear-all-btn mob-text-align">
            <button type="button" class="btn btn-link" data-toggle="modal" data-target="#clearAllModal">Clear
              all</button>
          </div>
        </div>
      </div>
      <div class="row" *ngFor="let notification of notificationArray | paginate:  { itemsPerPage: 10,
        currentPage: p,
        totalItems: totalNotification}">
        <div class="col-md-12 col-lg-12 col-xs-12">
          <div class="row row-hovr" *ngIf="notification.type== 'message'">
            <div class="col-md-11" (click)="updateStatus(notification)">
              <div class="row">
                <div class="col-md-1 col-lg-1 col-xs-12">
                  <div class="notify-icons mob-p-b-20">
                    <img src="../../../assets/message.png">
                  </div>
                </div>
                <div class="col-md-7 col-lg-7 col-xs-12">
                  <div class="sub-heading mob-text-align">
                    <h3>{{notification.content}}</h3>
                    <p>Order Reference : <span>{{notification.order_id}}</span></p>
                  </div>
                </div>
                <div class="col-md-3 col-lg-3 col-xs-12">
                  <div class="date-area mob-text-align">
                    <p>{{notification.created_at | date: MMM}} <span></span></p>
                  </div>
                </div>
                <div class="col-md-1 col-lg-1 col-xs-12">
                  <div class="time-area mob-text-align">
                    <p>{{notification.created_at | date:'h:mm a'}}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-1 col-lg-1 col-xs-12">
              <div class="delete-icon m-t-15">
                <i class="far fa-trash-alt" data-toggle="modal" data-target="#delete"
                  (click)="remove(notification.id)"></i>
              </div>
            </div>
          </div>
          <div class="row row-hovr" *ngIf="notification.type== 'order'">
            <div class="col-md-11" (click)="updateStatus(notification)">
              <div class="row">
                <div class="col-md-1 col-lg-1 col-xs-12">
                  <div class="notify-icons mob-p-b-20">
                    <img src="../../../assets/cookie.png">
                  </div>
                </div>
                <div class="col-md-7 col-lg-7 col-xs-12">
                  <div class="sub-heading mob-text-align">
                    <h3>{{notification.content}}</h3>
                    <p>Order Reference : <span>{{notification.order_id}}</span></p>
                  </div>
                </div>
                <div class="col-md-3 col-lg-3 col-xs-12">
                  <div class="date-area mob-text-align">
                    <p>{{notification.created_at | date: MMM}} <span></span></p>
                  </div>
                </div>
                <div class="col-md-1 col-lg-1 col-xs-12">
                  <div class="time-area mob-text-align">
                    <p>{{notification.created_at | date:'h:mm a'}}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-1 col-lg-1 col-xs-12">
              <div class="delete-icon m-t-15">
                <i class="far fa-trash-alt" data-toggle="modal" data-target="#delete"
                  (click)="remove(notification.id)"></i>
              </div>
            </div>
          </div>
          <hr>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-md-6 col-xs-12 col-lg-6">
      <div class="pagination mob-text-align">
        <p>Showing {{(p-1)*10}} - {{((p-1)*10)+10}} of {{totalNotification}}</p>
      </div>
    </div>
    <pagination-controls (pageChange)="p = $event"></pagination-controls>
  </div>
</section>

<!-- delete modal starts-->
<div class="modal fade" id="delete" tabindex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <!-- <div class="row">
          <div class="col-md-12">
            <h4 class="snape-red mt-2">Delete notification</h4>
          </div>
        </div> -->
        <div class="row">
          <div class="col-md-12">
            <div class="info-icon-img">
              <img src="../../../assets/exclamation.png">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="confirm-txt">
              <h6 class="mb-3">Are you sure?</h6>
              <p class="mb-3"> Do you want to delete this notification?</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mfooterBtn d-flex justify-content-end">
            <button type="button" class="btn color-cancel-btn mr-2" data-dismiss="modal">Cancel</button>
            <button type="button" class="btn submit-btn ml-2" data-dismiss="modal"
              (click)="deleteNotification(notificationId)">Delete</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- delete modal end-->

<!-- clear all modal starts-->
<div class="modal fade" id="clearAllModal" tabindex="-1" role="dialog" aria-labelledby="clearAllModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div class="info-icon-img">
              <img src="../../../assets/exclamation.png">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="confirm-txt">
              <h6 class="mb-3">Are you sure?</h6>
              <p class="mb-3"> Do you want to clear this notifications?</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mfooterBtn d-flex justify-content-end">
            <button type="button" class="btn color-cancel-btn mr-2" data-dismiss="modal">Cancel</button>
            <button type="button" class="btn submit-btn ml-2" data-dismiss="modal" (click)="clearAll()">Clear</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- clear all modal end-->