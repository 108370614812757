import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class CommonService {

  baseUrl = environment.baseUrl
  public loggedIn = new BehaviorSubject(false);
  userDetails
  forgotemail
  otpForgotOtp;
  imageFileDict: {};
  imageData
  constructor(private httpClient: HttpClient, private router: Router) { }

  memberSignup(obj: any) {
    let url = environment.memberSignup
    return this.httpClient.post(url, obj, httpOptions)
  }
  //get username and password for email verification in creation of account
  verification(user: any) {
    console.log(user)
    this.userDetails = {
      "username": user.username,
      "password": user.password
    }
  }
  //get verification code and call verify email function
  emailVerify(obj: any): Observable<any> {
    let url = environment.verifyEmail
    let data = {
      "username": this.userDetails.username,
      "password": this.userDetails.password,
      "code": obj
    }
    return this.httpClient.post(url, data)
  }
  // account login
  Signin(obj: any) {
    let url = environment.signIn
    return this.httpClient.post(url, obj, httpOptions)
  }
  // verification code send to email address for forgot password
  forgotPassword(obj: any) {
    let url = environment.forgotPassword
    return this.httpClient.post(url, obj)
  }
  forgetEmail(obj: any) {
    this.forgotemail = obj.username;
  }
  forgotOtp(obj: any) {
    this.otpForgotOtp = obj.code;
  }
  //reset password for forgot password
  confirmPassword(obj: any) {
    let url = environment.confirmPassword
    let data = {
      "username": obj.email,
      "code": obj.code,
      "password": obj.password
    }
    return this.httpClient.post(url, data)
  }
  getProfile() {
    let url = environment.getProfile
    return this.httpClient.get(url, httpOptions)
  }
  profileUpdate(obj: any): Observable<any> {
    let formdata = new FormData()
    let data = JSON.stringify(obj)
    formdata.append('Metadata', data)
    let url = environment.getProfile
    return this.httpClient.put(url, formdata)
  }
  //add flavour
  addFlavour(obj: any, file: any) {
    let url = environment.flavour
    let formdata = new FormData();
    console.log(file)
    if (file.filename != '') { formdata.append('filename', file.filename, JSON.stringify(file.filename.name)); }
    formdata.append('Metadata', JSON.stringify(obj));
    return this.httpClient.post(url, formdata);
  }
  addBulkOrder(obj:any){
    let url = environment.bulkOrder
    return this.httpClient.post(url,obj)
  }
  //getFlavour
  getFlavour() {
    let url = environment.flavour
    return this.httpClient.get(url);
  }
  //update flavour
  updateFlavour(obj: any, file: any) {
    let url = environment.flavour
    let formdata = new FormData();
    if (file) {
      if (file.filename != '' && file.filename.name != undefined) {
        formdata.append('filename', file.filename, JSON.stringify(file.filename.name));
      }
      else if (file.filename != '') {
        formdata.append('filename', file.filename);
      }
    }
    formdata.append('Metadata', JSON.stringify(obj));
    return this.httpClient.put(url, formdata);
  }
  //delete flavour
  deleteFlavour(obj: any) {
    let data = JSON.stringify(obj)
    let url = environment.flavour
    return this.httpClient.request('delete', url, { body: data })
  }
  //api
  //get favourite image
  getFavouriteImage() {
    let url = environment.favourite
    return this.httpClient.get(url)
  }
  //get template images
  getTemplateImages() {
    let url = environment.templates
    return this.httpClient.get(url, { headers: { skip: "true" } })
  }
  // add color
  addColor(obj: any) {
    let url = environment.color
    let formdata = new FormData();
    formdata.append('Metadata', JSON.stringify(obj))
    return this.httpClient.post(url, formdata);
  }
  //get color
  getColor() {
    let url = environment.color
    return this.httpClient.get(url)
  }
  // update color
  updateColor(obj: any) {
    let url = environment.color
    let formdata = new FormData();
    formdata.append('Metadata', JSON.stringify(obj))
    return this.httpClient.put(url, formdata);
  }
  //delete color
  deleteColor(obj: any) {
    let url = environment.color
    return this.httpClient.request('delete', url, { body: obj })
  }
  //add product
  addProduct(obj: any, files: any, product_file) {
    debugger
    // console.log(files)
    // let formdata = new FormData();
    // formdata.append('Metadata', JSON.stringify(obj));
    // let url = 'https://8ihex1qk65.execute-api.us-east-2.amazonaws.com/dev/products';
    // if (files) {
    //   let res = files.map(x => Object.keys(x)[0]);
    //   console.log(res)
    //   let val = files.map(x => Object.values(x)[0]);
    //   console.log(val)
    //   files.forEach((filename, index) => {
    //     let file = res[index]
    //     let img = val[index];
    //     console.log(img);
    //     formdata.append(file, img, JSON.stringify(img.name));
    //     console.log(file, img, JSON.stringify(img.name))
    //   });
    // }
    // return this.httpClient.post(url, formdata)
    if (files) {
      // this.imageFileDict = {}
      // Object.keys(files).map((k) => {
      //   let val = files[k]
      //   console.log(Object.keys(val)[0], 'kkkk')
      //   let filename = Object.keys(val)[0]
      //   this.imageFileDict[filename] = Object.values(val)[0]
      //   this.getImage(Object.keys(val)[0]).then(res => {
      //     this.imageData = res['URL']['fields']
      //     this.postImage(this.imageData, this.imageFileDict[res['URL']['fields'].key]).then(res => {
      //       console.log(res, 'res')
      //     }).catch((err) => {
      //       console.log(err)
      //     })
      //   }).catch((err) => {
      //     console.log(err)
      //   })
      //   // });
      // })
      let url = environment.product
      let formdata = new FormData();
      formdata.append('Metadata', JSON.stringify(obj))
      if (product_file) {
        formdata.append('filename', product_file.filename, JSON.stringify(product_file.filename.name));
      }
      return this.httpClient.post(url, formdata)
    }
  }
  async getImage(filename) {
    let url = environment.imageUpload + filename
    return await this.httpClient.get(url, { headers: { skip: "true" } }).toPromise();
  }
  async postImage(imageData, file) {
    let imageFormData = new FormData();
    imageFormData.append('key', imageData.key)
    imageFormData.append('x-amz-algorithm', imageData['x-amz-algorithm'])
    imageFormData.append('x-amz-credential', imageData['x-amz-credential'])
    imageFormData.append('x-amz-date', imageData['x-amz-date'])
    imageFormData.append('x-amz-security-token', imageData['x-amz-security-token'])
    imageFormData.append('x-amz-signature', imageData['x-amz-signature'])
    imageFormData.append('policy', imageData.policy)
    imageFormData.append('file', file, JSON.stringify(file.name))
    // imageFormData.append('file', file)
    let url = environment.s3bucket
    return await this.httpClient.post(url, imageFormData, { headers: { skip: "true" } }).toPromise();
  }
  listProduct() {
    let url = environment.userProducts
    // let url = 'https://8ihex1qk65.execute-api.us-east-2.amazonaws.com/dev/products';
    return this.httpClient.get(url, { headers: { skip: "true" } })
  }
  addAboutUs(obj: any) {
    let url = environment.about
    let formData = new FormData();
    formData.append('Metadata', JSON.stringify(obj))
    return this.httpClient.post(url, formData);
  }
  updateCookie(obj: any) {
    let url = environment.updateCookie
    // let formData = new FormData();
    // formData.append('Metadata', JSON.stringify(obj))
    return this.httpClient.post(url, obj)
  }
  uploadBoxcolor(obj: any) {
    let url = environment.boxcolor
    // let formData = new FormData();
    // formData.append('Metadata', JSON.stringify(obj))
    return this.httpClient.post(url, obj)
  }
  getAboutUs() {
    let url = environment.about
    return this.httpClient.get(url);
  }
  addPrivacyPolicy(obj: any) {
    let url = environment.privacy
    return this.httpClient.post(url, obj);
  }
  addConditions(obj: any) {
    let url = environment.terms
    return this.httpClient.post(url, obj);
  }
  addCoupon(obj: any) {
    let url = environment.addCoupon
    return this.httpClient.post(url, obj);
  }
  createZones(obj: any){
    let url = environment.createZone
    return this.httpClient.post(url, obj)
  }
  listZones(){
    let url = environment.listZone
    return this.httpClient.get(url)
  }
  updateZones(obj: any){
    let url = environment.updateZone
    return this.httpClient.put(url, obj)
  }
  deleteZones(obj: any){
    let url = environment.deleteZone
    return this.httpClient.delete(url, obj)
  }
  generateCoupon() {
    let url = environment.generateCoupon
    return this.httpClient.get(url)
  }
  getCoupon(obj: any) {
    debugger
    let url = environment.getCoupon + obj['id']
    return this.httpClient.get(url)
  }
  updateCoupon(obj: any) {
    let url = environment.getCoupon
    return this.httpClient.put(url, obj)
  }
  listCoupon() {
    let url = environment.listCoupon
    return this.httpClient.get(url)
  }
  addFaq(obj: any) {
    let url = environment.faq
    return this.httpClient.post(url, obj);
  }

  getFaq() {
    let url = environment.faq
    return this.httpClient.get(url)
  }

  deleteFaq(obj: any) {
    let url = environment.faq
    return this.httpClient.request('delete', url, { body: obj })
  }

  editFaq(obj: any) {
    let formdata = new FormData();
    formdata.append('Metadata', JSON.stringify(obj));
    let url = environment.faq
    return this.httpClient.put(url, formdata);
  }
  //get product
  // pass email as param
  getProduct() {
    let url = environment.product
    return this.httpClient.get(url)
  }
  //get single product
  getProductById(id: any) {
    let url = environment.singleProduct + id
    return this.httpClient.get(url)
  }
  //update product
  updateProduct(obj: any, files: any, product_file) {
    if (files || files.length != 0) {
      // this.imageFileDict = {}
      // Object.keys(files).map((k) => {
      //   let val = files[k]
      //   console.log(Object.keys(val)[0], 'kkkk')
      //   let filename = Object.keys(val)[0]
      //   this.imageFileDict[filename] = Object.values(val)[0]
      //   this.getImage(Object.keys(val)[0]).then(res => {
      //     this.imageData = res['URL']['fields']
      //     this.postImage(this.imageData, this.imageFileDict[res['URL']['fields'].key]).then(res => {
      //       console.log(res, 'res')
      //     }).catch((err) => {
      //       console.log(err)
      //     })
      //   }).catch((err) => {
      //     console.log(err)
      //   })
      //   // });
      // })
      let url = environment.product
      let formdata = new FormData();
      console.log(obj, 'obj')
      debugger
      // this.postColor(obj.id,obj.color).then(res => {
      // }).catch(err => {
      //   console.log(err)
      // })
      formdata.append('Metadata', JSON.stringify(obj))
      if (product_file) {
        formdata.append('filename', product_file.filename, JSON.stringify(product_file.filename.name));
      }
      return this.httpClient.put(url, formdata)
    }
    else {
      let url = environment.product
      let formdata = new FormData();
      formdata.append('Metadata', JSON.stringify(obj))
      if (product_file) { formdata.append('filename', product_file.filename, JSON.stringify(product_file.filename.name)); }
      return this.httpClient.put(url, formdata)
    }
  }
  async postColor(id, color: any) {
    let colorData = new FormData();
    let data = {
      "color": color,
      "id": id
    }
    colorData.append('Metadata', JSON.stringify(data))
    let url = environment.color
    return await this.httpClient.put(url, colorData).toPromise();
  }
  //admin legder
  getLedger() {
    let url = environment.ledger
    return this.httpClient.get(url)
  }

  //dashboardDetails
  getDashboard(name: any) {
    let url = environment.dashboard + name
    return this.httpClient.get(url)
  }

  getDateDashboard(from: any, to: any) {
    let url = environment.startDate + from + '&end_date=' + to
    return this.httpClient.get(url)
  }
  //delete flavour
  deleteProduct(obj: any) {
    let data = JSON.stringify(obj)
    let url = environment.product
    return this.httpClient.request('delete', url, { body: data })
  }
  //customer list
  getCustomerList() {
    let url = environment.customer
    return this.httpClient.get(url)
  }
  updateCustomer(obj: any) {
    let url = environment.updateStatus
    let formdata = new FormData();
    formdata.append('Metadata', JSON.stringify(obj));
    return this.httpClient.put(url, formdata);
  }
  updateVendorStatus(obj: any) {
    let url = environment.updateStatus
    let formdata = new FormData();
    formdata.append('Metadata', JSON.stringify(obj));
    return this.httpClient.put(url, formdata);
  }
  getNotification() {
    let url = environment.adminNotify
    return this.httpClient.get(url)
  }
  updateNotificationStatus(obj) {
    let url = environment.notification
    return this.httpClient.put(url, obj)
  }
  deleteNotificationById(obj) {
    let url = environment.adminNotify
    return this.httpClient.request('delete', url, { body: obj })
  }
  deleteAllNotifications(obj) {
    let url = environment.batchDelete
    return this.httpClient.request('delete', url, { body: obj })
  }
  addTemplateImage(obj, file) {
    let url = environment.templates
    let formdata = new FormData();
    console.log(file)
    if (file.filename != '') { formdata.append('filename', file.filename, JSON.stringify(file.filename.name)); }
    formdata.append('Metadata', JSON.stringify(obj));
    return this.httpClient.post(url, formdata);

  }
  updateTemplateImage(obj, file) {
    let url = environment.templates
    let formdata = new FormData();
    if (file.filename != '' && file.filename.name != undefined) {
      formdata.append('filename', file.filename, JSON.stringify(file.filename.name));
    }
    else if (file.filename != '') {
      formdata.append('filename', file.filename);
    }
    formdata.append('Metadata', JSON.stringify(obj));
    return this.httpClient.put(url, formdata);
  }
  deleteTemplateImage(obj) {
    let url = environment.templates
    let data = JSON.stringify(obj)
    return this.httpClient.request('delete', url, { body: data })
  }
  //vendor list
  getVendorList() {
    let url = environment.company
    return this.httpClient.get(url)
  }
  //add new vendor
  addVendor(obj: any) {
    let url = environment.company_signup
    let formdata = new FormData();
    formdata.append('Metadata', JSON.stringify(obj));
    return this.httpClient.post(url, formdata);
  }
  getVendorById(id) {
    let url = environment.vendorById + id
    return this.httpClient.get(url)
  }
  //get single company
  getSingleCompany(email) {
    let url = environment.company_details + email;
    return this.httpClient.get(url)
  }
  updateVendor(obj: any) {
    let url = environment.edit_vendor
    let formdata = new FormData();
    formdata.append('Metadata', JSON.stringify(obj));
    return this.httpClient.put(url, formdata);
  }
  getAllOrder() {
    let url = environment.order_details
    return this.httpClient.get(url)
  }
  orderCancel(obj:any){
    let url = environment.orderCancel
   
    return this.httpClient.post(url, obj)
  }
  getOrderDetails(id: any, email: any) {
    let url = environment.orderId + id + '&email=' + email
    return this.httpClient.get(url)
  }
  updateOrderStatus(obj) {
    let url = environment.delivery_status
    return this.httpClient.put(url, obj);
  }
  updateOrderItemMsgStatus(obj) {
    let url = environment.item_status
    return this.httpClient.put(url, obj);
  }
  updateOrderMsgStatus(obj) {
    let url = environment.order_status
    return this.httpClient.put(url, obj);
  }
  getCompanyContact(email) {
    let url = environment.admin_email + email
    return this.httpClient.get(url)
  }
  addCompanyContacts(obj: any) {
    let url = environment.company_contact
    return this.httpClient.post(url, obj)
  }
  updateCompanyContacts(obj: any) {
    let url = environment.company_contact
    return this.httpClient.put(url, obj)
  }
  deleteCompanyContacts(obj: any) {
    let url = environment.company_contact
    return this.httpClient.request('delete', url, { body: obj })
  }
  addVendorDocuments(obj: any, file) {
    let url = environment.comapny_document
    let formdata = new FormData();
    formdata.append('Metadata', JSON.stringify(obj));
    console.log(file.key, file.value, 'sdsd')
    let key = file.key[0]
    let values = file.value[0]
    // key.forEach((num1, index) => {
    //   const num2 = values[index];
    //   console.log(num1, num2);
    //   formdata.append(num1, JSON.stringify(num2))
    // });
    formdata.append(key, values, JSON.stringify(values.name))
    return this.httpClient.post(url, formdata)
  }
  addMessage(obj: any) {
    let formdata = new FormData()
    let data = JSON.stringify(obj)
    formdata.append('Metadata', data)
    // if (file) {
    //   if (file.filename) {
    //     formdata.append('filename', file.filename, JSON.stringify(file.filename.name))
    //   }
    // }
    let url = environment.chat
    return this.httpClient.post(url, formdata)
  }
  getAdminUserMessage(id: any, productId) {
    console.log(id, 'id')
    let url = environment.message + id + '&type=admin-user&value=admin&item_id=' + productId
    return this.httpClient.get(url)
  }
  getAdminMessage(id: any, productId) {
    console.log(id, 'id')
    let url = environment.message + id + '&type=user-admin&item_id=' + productId
    return this.httpClient.get(url)
  }
  getAdminVendorMessage(id: any, productId) {
    console.log(id, 'id')
    let url = environment.message + id + '&type=admin-vendor&item_id=' + productId
    return this.httpClient.get(url)
  }
  getVendorMessage(id: any) {
    console.log(id, 'id')
    let url = environment.message + id + '&type=vendor-admin'
    return this.httpClient.get(url)
  }
  getQueries() {
    let url = environment.contactUs
    return this.httpClient.get(url)
  }
  updateQueries(obj: any, file) {
    let url = environment.generalquery
    let formdata = new FormData();
    if (file.filename != '') { formdata.append('filename', file.filename, JSON.stringify(file.filename.name)); }
    formdata.append('Metadata', JSON.stringify(obj));
    return this.httpClient.put(url, formdata);
  }
  getNewsletter() {
    let url = environment.newsletter
    return this.httpClient.get(url)
  }

  // customize cookie start
  getCart() {
    let url = environment.cart;
    return this.httpClient.get(url)
  }
  getTemplateImage() {
    let url = environment.templates;
    return this.httpClient.get(url)
  }
  addCart(obj: any, files) {
    if (files) {
      this.imageFileDict = {}
      Object.keys(files).map((k) => {
        let val = files[k]
        this.DataURIToBlob(val, k)
        console.log(k, 'kkkk')
        this.getImage(k).then(res => {
          this.imageData = res['URL']['fields']
          this.postImage(this.imageData, this.imageFileDict[res['URL']['fields'].key]).then(res => {
            console.log(res, 'res')
          }).catch((err) => {
            console.log(err)
          })
        }).catch((err) => {
          console.log(err)
        })
        // });
      })
      let url = environment.cart;
      let formdata = new FormData();
      formdata.append('Metadata', JSON.stringify(obj))
      return this.httpClient.post(url, formdata)
    }
  }

  DataURIToBlob(dataURI: string, filename: any) {

    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i)

    this.imageFileDict[filename] = new Blob([ia], { type: mimeString })
  }
  // customize cookie end

}