<div *ngIf="loader == true">
    <app-spinner></app-spinner>
  </div>
<section class="product-list-sec">
    <div class="row p-t-20">
        <div class="col-md-6 col-xs-12 col-lg-6">
            <div class="product-list-heading mob-text-align">
                <h1>Product List</h1>
            </div>
        </div>
        <div class="col-md-6 col-xs-12 col-lg-6">
            <div class="create-btn mob-text-align">
                <button class="btn btn-pink" data-toggle="modal"
                    data-target="#newFlavour" [routerLink]="['/new-product']">New product</button>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 col=lg-12 col-xs-12">
            <div class="table-responsive">
                <table class="table table-borderless">
                    <thead>
                        <tr>
                            <th>Product image</th>
                            <th>Product name</th>
                            <th>No. of cookies<br><span class="per-box-span">per pack</span></th>
                            <th>Price<br><span class="per-box-span">per pack</span></th>
                            <th>Minimum <br> order qty</th>
                            <th>Pack colors</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let products of productArray">
                        <tr class="plist-tr">
                            <td>
                                <img class="plist-tblImg" src="{{products.image}}" />
                            </td>
                            <td>{{products.name}}</td>
                            <td>{{products.cookie_number}}</td>
                            <td>QAR {{products.price}}</td>
                            <td>{{products.min_qty}}</td>
                            <td>
                                <span *ngFor="let colors of products.color" class="boxColor-pl"
                                    [style.background-color]="colors.code"></span>
                            </td>
                            <td>
                                <span class="boxED">
                                    <button type="button" class="btn edit-btn" [routerLink]="['/new-product']"
                                        [queryParams]="{ id: products.id}"><i class="far fa-edit"
                                            aria-hidden="true"></i></button>
                                    <!-- <button type="button" class="btn delete-btn" (click)="deleteProduct(products.id)"><i
                                            class="far fa-trash-alt ml-2" aria-hidden="true"></i></button> -->
                                    <button type="button" class="btn delete-btn" data-toggle="modal"
                                        data-target="#delete" (click)="removeProduct(products.id)">
                                        <i class="far fa-trash-alt ml-1" aria-hidden="true"></i></button>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <!-- delete modal starts-->
                <!-- <div class="modal fade" id="delete" tabindex="-1" role="dialog" aria-labelledby="deleteModalLabel"
                        aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content">
                                <div class="modal-body">
                                    <div class="row">
                                        <div class="col-md-11">
                                            <h6 class="snape-red mt-2" *ngIf="productId">Delete Product</h6>
                                        </div>
                                        <div class="col-md-1">
                                            <button type="button" class="close mt-2" data-dismiss="modal"
                                                aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    </div>
                                    <p *ngIf="productId">Are you sure you want to delete this product?</p>
                                    <div class="row">
                                        <div class="col-md-12 mfooterBtn d-flex justify-content-end">
                                            <button type="button" class="btn btn-cancel-grey mr-2"
                                                data-dismiss="modal">Cancel</button>
                                            <button type="button" class="btn btn-snape-red ml-2" data-dismiss="modal"
                                                (click)="deleteProduct(productId)">Delete</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                <!-- delete modal ends-->
            </div>
        </div>
    </div>
</section>

<!-- delete modal starts-->
<div class="modal fade" id="delete" tabindex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <!-- <div class="row">
                    <div class="col-md-12">
                        <h4 class="snape-red mt-2" *ngIf="productId">Delete Product</h4>
                    </div>
                </div> -->
                <div class="row">
                    <div class="col-md-12">
                        <div class="info-icon-img">
                            <img src="../../../assets/exclamation.png">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div *ngIf="productId" class="confirm-txt">
                            <h6 class="mb-3">Are you sure?</h6>
                            <p class="mb-3"> Do you want to delete this product?</p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 mfooterBtn d-flex justify-content-end">
                        <button type="button" class="btn color-cancel-btn mr-2" data-dismiss="modal">Cancel</button>
                        <button type="button" class="btn submit-btn ml-2" data-dismiss="modal"
                            (click)="deleteProduct(productId)">Delete</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- delete modal end-->