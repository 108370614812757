<div *ngIf="loader == true">
  <app-spinner></app-spinner>
</div>
<section class="bulk-order-details-sec">
  <div class="row pt-3">
    <div class="col-md-12 col-xs-12 col-lg-12">
      <div class="bulk-order-details-heading">
        <h1>Bulk Order</h1>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 mt-4">
      <div class="vendor-list-breadcrumb">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item cursor-pointer" routerLink="/order/list" routerLinkActive="router-link-active">
              <a><i class="fas fa-shopping-cart"></i> Order Details </a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">New bulk order</li>
          </ol>
        </nav>
      </div>
    </div>
    <div class="col-md-6 my-auto">
      <div class="add-new-product-btn">
        <button type="button" class="btn" data-toggle="modal" (click)="addNew()" data-target="#createBulkOrderModal">Add
          new
          product</button>
      </div>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-md-6 col-lg-6 col-xs-12" *ngFor="let item of cartProductList;let i=index">
      <div class="row bg-color m-2">
        <div class="col-md-6 col-lg-4 col-sm-6 col-xs-12 mb-3">
          <div class="cookie-box">
            <img [src]="item.image">
          </div>
        </div>
        <div class="col-md-6 col-lg-8 col-sm-6 col-xs-12 my-auto">
          <div class="cookie-box-caption">
            <h6 class="">{{item.product.name}} <span class="float-right"><i class="fas fa-trash-alt"
                  (click)="delete(i)"></i></span></h6>
            <p>Number of packs : <span>{{item.quantity}}</span></p>
            <p>Total <span>(QAR {{item.product.price}}*{{item.quantity}})</span> <span
                class="pink-color float-right">QAR {{item.product.price*item.quantity | number :
                '1.2-2'}}</span></p>
            <p>Pack Color : <span class="float-right">{{item.box_color}} </span></p>
          </div>
          <div class="mt-2">
            <button type="button" *ngIf="item.cookie_number != item.customize.length" class="btn customize-cookie-btn"
              (click)="designCookie(item)">Customize Cookie</button>
            <button type="button" *ngIf="item.cookie_number == item.customize.length" (click)="designCookie(item)"
              class="btn customized-btn">Customized</button>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="col-md-6 col-lg-6 col-xs-12">
            <div class="row bg-color m-2">
                <div class="col-md-6 col-lg-4 col-sm-6 col-xs-12 mb-3">
                    <div class="cookie-box">
                        <img src="../../../assets/6cookie.jpg">
                    </div>
                </div>
                <div class="col-md-6 col-lg-8 col-sm-6 col-xs-12 my-auto">
                    <div class="cookie-box-caption">
                        <h6 class="">1 COOKIE BOX <span class="float-right"><i class="fas fa-trash-alt"></i></span></h6>
                        <p>Number of boxes : <span>12</span> </p>
                        <p>Total <span>(QAR 14.00)</span> <span class="pink-color float-right">QAR 168.00</span></p>
                        <p>Box Color : <span class="float-right">Pink </span></p>
                    </div>
                    <div class="mt-2">
                        <button type="button" class="btn customized-btn">Customized</button>
                    </div>
                </div>
            </div>
        </div> -->
  </div>
  <div class="row payment-row mt-3">
    <div class="col-md-12 mb-3">
      <div class="float-right">
        <button type="button" *ngIf="!activateBilling" [disabled]="!showCheckoutButton" (click)="proceed()"
          class="btn">Proceed to Payment</button>
      </div>
    </div>
    <div class="col-md-12" *ngIf="activateBilling">
      <div class="row">
        <div class="col-md-12" [formGroup]="checkoutForm">
          <div class="card">
            <div class="card-body">
              <div>
                <h6>Select your preference</h6>
              </div>
              <div>
                <table class="w-100">
                  <tbody>
                    <tr>
                      <td>
                        <input type="radio" id="homed" formControlName="preference" value="home_delivery"
                          (change)="selectedPrefernce($event.target.value)" required>
                        <label for="homed" class="ml-2 pt-2">Home Delivery</label>
                      </td>
                      <td class="text-right fw500" *ngIf="showHomeDelivery">
                        Delivery charges will be calculated during checkout
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <input type="radio" id="self" formControlName="preference" value="self_pickup"
                          (change)="selectedPrefernce($event.target.value)" required>
                        <label for="self" class="ml-2 ColorBOp pt-2">Self Pickup</label>
                      </td>
                      <td class="text-right">
                        Free
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="mt-3">
                <p>Select delivery date & time</p>
              </div>
              <div class="row">
                <div class="col-md-5 col-lg-4 mb-3">
                  <input class="form-control" id="datepicker" min="{{minDate | date:'yyyy-MM-dd'}}" type="date"
                    formControlName="date" (change)="clearTime()" required onfocus="(this.type='date')"
                    placeholder="Date" />
                </div>
                <div class="col-md-5 col-lg-4 mb-3">
                  <div class="mob-m-t-b-20">

                    <p-calendar [timeOnly]="true" inputId="timeonly" [showIcon]="true" icon="fas fa-clock"
                      placeholder="Time" class="p-button-sm" formControlName="time" [hourFormat]="24"
                      (onBlur)="getTime($event)"></p-calendar>

                    <!-- <input class="form-control" type="time" id="appt-time" name="appt-time" formControlName="time"
                      (change)="getTime($event)" required placeholder="Time" /> -->
                  </div>
                </div>
                <div class="col-md-1 col-lg-1">
                  <div class="popover__wrapper">
                    <i class="fas fa-info-circle"></i>
                    <div class="popover__content">
                      <p>Cookies will be delivered 2 hours after placing an order. Please make sure to choose
                        your delivery time
                        accordingly.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12" *ngIf="preference_value">
          <div class="card">
            <div class="card-body" [formGroup]="checkoutForm">
              <div class="discount-coupon-area">
                <h6>Discount section</h6>
                <div class="input-group form-group has-float-label mt-3 mb-3">
                  <input class="form-control" formControlName="discount" type="number" min=0
                    placeholder="Enter amount" />
                  <label>Enter amount</label>
                  <div class="input-group-append">
                    <button class="btn apply-btn" (click)="applyCoupon()" type="button">Apply</button>
                  </div>
                </div>
                <div class="form-group has-float-label mb-3">
                  <textarea class="form-control" formControlName="reason" type="text" rows="5"
                    placeholder="Reason"></textarea>
                  <label>Reason</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12" *ngIf="preference_value">
          <div class="card">
            <div class="card-body">
              <div class="row mt-3 order-summary-row">
                <div class="col-md-12">
                  <h6>Order Summary</h6>
                  <table class="table">
                    <tbody>
                      <tr>
                        <td>Order Amount</td>
                        <td class="fw500 text-right">QAR {{orderAmound | number :
                          '1.2-2'}}</td>
                      </tr>
                      <tr>
                        <td>Delivery Charge</td>
                        <td class="fw500 text-right">QAR {{delivery_charge | number :
                          '1.2-2'}}</td>
                      </tr>
                      <tr>
                        <td *ngIf="savedAmount > 0">Discount</td>
                        <td *ngIf="savedAmount>0" class="fw500 text-right">- QAR {{savedAmount | number :
                          '1.2-2'}}</td>
                      </tr>
                      <tr>
                        <td class="fw500">Total</td>
                        <td class="fw500 snape-red text-right">QAR {{ delivery_charge - savedAmount + orderAmound |
                          number :
                          '1.2-2'}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12" *ngIf="preference_value">
          <div class="card">
            <div class="card-body" [formGroup]="addressForm">
              <div class="row">
                <div class="col-md-12">
                  <div>
                    <h6>Enter Personal Details</h6>
                  </div>
                </div>
                <div class="col-md-4 mb-3">
                  <div class="form-group has-float-label">
                    <input class="form-control" type="text" formControlName="userName" placeholder="Full name" />
                    <label>Full name</label>
                  </div>
                  <span class="text-danger"
                    *ngIf="(addressForm.controls.userName.touched || submitted) && addressForm.controls.userName.errors?.required">
                    Name is required
                  </span>
                </div>
                <div class="col-md-4 mb-3">
                  <div class="form-group has-float-label">
                    <input class="form-control" type="email" formControlName="email" placeholder="Email" />
                    <label>Email</label>
                  </div>
                  <span class="text-danger"
                    *ngIf="(addressForm.controls.email.touched || submitted) && addressForm.controls.email.errors?.required">
                    Email is required
                  </span>
                  <span class="text-danger" *ngIf="addressForm.controls.email.errors?.pattern">
                    Enter a valid email
                  </span>
                </div>
                <div class="col-md-4 mb-3">
                  <div class="d-flex">
                    <div>
                      <span class="input-group-text" id="basic-addon3"> +974</span>
                    </div>
                    <div class="input-group form-group has-float-label">
                      <input class="form-control" type="text" formControlName="phone" placeholder="Phone number" />
                      <label>Phone number</label>
                    </div>
                  </div>
                  <span class="text-danger"
                    *ngIf="(addressForm.controls.phone.touched || submitted) && addressForm.controls.phone.errors?.required">
                    Number is required
                  </span>
                  <span class="text-danger" *ngIf="addressForm.controls.phone.errors?.pattern">
                    Enter valid number
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div>
                    <h6>Enter shipping address</h6>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="form-group has-float-label">
                    <input class="form-control" type="text" placeholder="Full Name" formControlName="fullName"
                      required />
                    <label>Full Name</label>
                  </div>
                  <span class="text-danger"
                    *ngIf="(addressForm.controls.fullName.touched || submitted) && addressForm.controls.fullName.errors?.required">
                    Full Name is required
                  </span>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="form-group has-float-label">
                    <input class="form-control" type="text" placeholder="Building number"
                      formControlName="buildingNumber" required />
                    <label>Building number</label>
                  </div>
                  <span class="text-danger"
                    *ngIf="(addressForm.controls.buildingNumber.touched || submitted) && addressForm.controls.buildingNumber.errors?.required">
                    Building number is required
                  </span>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="form-group has-float-label">
                    <input class="form-control" type="text" placeholder="Flat/Villa number" formControlName="flatVilla"
                      required />
                    <label>Flat/Villa number</label>
                  </div>
                  <span class="text-danger"
                    *ngIf="(addressForm.controls.flatVilla.touched || submitted) && addressForm.controls.flatVilla.errors?.required">
                    Flat/Villa number is required
                  </span>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="form-group has-float-label">
                    <input class="form-control" type="text" placeholder="Zone number" formControlName="zone" (change)="zoneDeliveryCharge()" required />
                    <label>Zone number</label>
                  </div>
                  <span class="text-danger"
                    *ngIf="(addressForm.controls.zone.touched || submitted) && addressForm.controls.zone.errors?.required">
                    Zone is required
                  </span>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="form-group has-float-label">
                    <input class="form-control" type="text" placeholder="Street name" formControlName="streetName"
                      required />
                    <label>Street name</label>
                  </div>
                  <span class="text-danger"
                    *ngIf="(addressForm.controls.streetName.touched || submitted) && addressForm.controls.streetName.errors?.required">
                    Street name is required
                  </span>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="form-group has-float-label">
                    <input class="form-control" type="text" placeholder="Area number" formControlName="areaNumber" />
                    <label>Area number</label>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="form-group has-float-label">
                    <input class="form-control" type="text" placeholder="City" formControlName="city" required />
                    <label>City</label>
                  </div>
                  <span class="text-danger"
                    *ngIf="(addressForm.controls.city.touched || submitted) && addressForm.controls.city.errors?.required">
                    City is required
                  </span>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="d-flex">
                    <div>
                      <span class="input-group-text" id="basic-addon3"> +974</span>
                    </div>
                    <div class="input-group form-group has-float-label">
                      <input class="form-control" type="text" placeholder="Contact number"
                        formControlName="contactNumber" required />
                      <label>Contact number</label>
                    </div>
                  </div>
                  <span class="text-danger"
                    *ngIf="(addressForm.controls.contactNumber.touched || submitted) && addressForm.controls.contactNumber.errors?.required">
                    Contact number is required
                  </span>
                  <span class="text-danger"
                    *ngIf="addressForm.controls.contactNumber.touched && addressForm.controls.contactNumber.errors?.pattern">
                    Enter a valid number
                  </span>
                </div>
                <div class="col-md-12 payment-method" [formGroup]="paymentForm">
                  <div class="row">
                    <div class="col-md-12">
                      <div>
                        <h6>Payment method</h6>
                      </div>
                      <div class="d-flex">
                        <div>
                          <input type="radio" id="payByCash" (change)="selectedMethod($event.target.value)"
                            formControlName="method" value="cash">
                          <label for="payByCash" class="ml-2 pt-2">Pay by
                            cash</label>
                        </div>
                        <div class="ml-3">
                          <input type="radio" id="cheque" (change)="selectedMethod($event.target.value)"
                            formControlName="method" value="cheque">
                          <label for="cheque" class="ml-2 pt-2">Cheque</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3" *ngIf="showCheque">
                    <div class="col-md-4">
                      <div class="form-group has-float-label">
                        <input class="form-control" type="number" min="0" formControlName="chequeNo"
                          placeholder="Cheque Number" />
                        <label>Cheque Number</label>
                        <span class="text-danger"
                          *ngIf="(paymentForm.controls.chequeNo.touched || submitted) && paymentForm.controls.chequeNo.errors?.required">
                          Cheque number is required
                        </span>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group has-float-label">
                        <input class="form-control" type="date" formControlName="chequeDate"
                          placeholder="Cheque Date" />
                        <label>Cheque Date</label>
                      </div>
                      <span class="text-danger"
                        *ngIf="(paymentForm.controls.date.touched || submitted) && paymentForm.controls.date.errors?.required">
                        Date is required
                      </span>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group has-float-label">
                        <input class="form-control" type="text" formControlName="bank" placeholder="Bank" />
                        <label>Bank</label>
                      </div>
                      <span class="text-danger"
                        *ngIf="(paymentForm.controls.bank.touched || submitted) && paymentForm.controls.bank.errors?.required">
                        Bank name is required
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-12 mt-4">
                  <div class="text-right">
                    <button class=" btn btn-snape-red" [disabled]="addressForm.invalid || paymentForm.invalid"
                      (click)="makePayment()">Make
                      Payment</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- createBulkOrderModal start -->
<div class="modal fade" id="createBulkOrderModal" tabindex="-1" role="dialog"
  aria-labelledby="createBulkOrderModalModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row">
          <div class="col-md-4 left-side-area p-3">
            <div class="row">
              <div class="col-md-12">
                <h2>Select cookies</h2>
              </div>
            </div>
            <div class="row" [formGroup]="ledgerForm">
              <div class="col-md-12 mb-3">
                <select class="form-control" formControlName="product" aria-placeholder="Select cookie box"
                  (change)="getSelectedProduct($event)">
                  <option value="" disabled selected hidden>Select cookie box </option>
                  <option *ngFor="let item of productList" [value]="item.id">{{item.name}}</option>
                  <!-- <option value="2cookie" selected>2 cookie box</option>
                  <option value="3cookie">3 cookie box</option>
                  <option value="4cookie">6 cookie box</option> -->
                </select>
              </div>
              <div class="col-md-12 mb-3">
                <select class="form-control" formControlName="color" aria-placeholder="Select color"
                  (change)="getSelectedColor($event)">
                  <option value="" disabled selected hidden>Select color </option>
                  <option *ngFor="let item of colorList" [value]="item.SK">{{item.name}}</option>
                  <!-- <option value="green" selected>Green</option>
                  <option value="blue">Blue</option>
                  <option value="yellow">Yellow</option> -->
                </select>
              </div>
            </div>
            <div class="row" *ngIf="min_qty">
              <div class="col-md-12">
                <div class="select-quantity">
                  <h6 class="mb-1">Select Quantity</h6>
                  <p class="mb-1">Minimum order qty {{min_qty}} pieces</p>
                </div>
                <div class="d-flex mt-3">
                  <i class="far fa-minus-square" [disabled]="min_qty >= quantity" (click)="decrement()"></i>
                  <h5 class="mr-2 ml-2">{{quantity}}</h5>
                  <i class="far fa-plus-square" (click)="increment()"></i>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12">
                <div class="btns" *ngIf="selectedColor">
                  <button type="button" class="btn cancel-btn mr-2" (click)="cancel()">Cancel</button>
                  <button class="btn add-to-list-btn" type="button" (click)="addtoList()">Add to list</button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8 right-side-area p-3">
            <div class="row">
              <div class="col-md-6 col-lg-6 col-xs-12" *ngFor="let item of cartList; let i = index">
                <div class="row bg-color m-2" *ngIf="cartList.length > 0">
                  <div class="col-md-12">
                    <div class="float-right">
                      <i class="fas fa-trash-alt" (click)="deleteItem(i)"></i>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4 col-sm-6 col-xs-12">
                    <div class="cookie-box text-align">
                      <img [src]="item.image">
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-8 col-sm-6 col-xs-12 my-auto">
                    <div class="cookie-box-caption ml-3">
                      <h6>{{item.product.name}}</h6>
                      <p>Cookie Qty : <span>{{item.quantity}}</span> </p>
                      <p>Box Color : <span class="pink-color">{{item.box_color}} </span></p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="row no-item-in-cart-area" *ngIf="cartList.length == 0">
              <div class="col-md-12 mt-5">
                <div class="text-center">
                  <img src="../../../assets/noItem.svg">
                  <p class="mt-3">No item in your cookie cart</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="proceed-to-customize-btn" *ngIf="cartList.length>0">
              <button type="button" class="btn" data-dismiss="modal" (click)="checkout()">Proceed To
                Customize</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- createBulkOrderModal end -->