<div *ngIf="loader == true">
    <app-spinner></app-spinner>
  </div>
<section class="zone-list-sec">
    <div class="row pt-3">
        <div class="col-md-8 col-xs-12 col-lg-8 my-auto">
            <div class="zone-list-heading mob-text-align">
                <h1>Zone</h1>
            </div>
        </div>
        <div class="col-md-4 col-xs-12 col-lg-4">
            <div class="excel-export d-flex justify-content-end">
                <div class="mob-text-align mr-3">
                    <p>Export to excel
                        <i class="fas fa-file-excel" (click)="exportToExcel()"></i>
                    </p>
                </div>
                <div>
                    <button type="button" class="btn create-zone" data-toggle="modal"
                        data-target="#createZonePopupModal" (click)="openNewZone()">Create zone</button>
                </div>
            </div>
        </div>
    </div>
    <div class="row filter-row mt-3">
        <div class="col-md-10 col-lg-10 col-xs-12">
            <div class="total-zone mob-text-align">
                <p><span>{{zoneArray?.length}}</span> zones found</p>
            </div>
        </div>
        <div class="col-md-2 col-lg-2 col-xs-12">
            <div class="mob-p-b-10">
                <angular2-multiselect [data]="statusArray" (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)"
                    (onSelectAll)="onSelectAll($event)" (onDeSelectAll)="onDeSelectAll($event)" [(ngModel)]="selectedStatus"
                    [settings]="filterByAmountSettings">
                </angular2-multiselect>
            </div>
        </div>
        <!-- <div class="col-md-1 col-lg-1 col-xs-12 col-xl-1">
            <div class="reload-icon">
                <i class="fas fa-sync-alt"></i>
            </div>
        </div> -->
    </div>
    <div class="row mt-3">
        <div class="col-md-12 col-xs-12 col-lg-12">
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Zone number</th>
                            <th scope="col">Amount</th>
                            <th scope="col" class="text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let zone of zoneArray | paginate:  { itemsPerPage: 10,
                            currentPage: p,
                            totalItems: zoneArray?.length}">
                            <td>{{zone?.zone}}</td>
                            <td>QAR {{zone?.deliverycharge}}</td>
                            <td class="text-nowrap text-center">
                                <span>
                                    <i class="far fa-edit mr-2" data-toggle="modal" data-target="#editZonePopupModal" (click)="editZone(zone)"></i>
                                </span>
                                <span>
                                    <i class="far fa-trash-alt" data-toggle="modal" data-target="#deleteModalPopup"
                                    (click)="removeZone(zone?.id)"></i>
                                </span>
                            </td>
                        </tr>
                        <!-- <tr>
                            <td>1</td>
                            <td>QAR 15</td>
                            <td class="text-nowrap text-center">
                                <span>
                                    <i class="far fa-edit mr-2"></i>
                                </span>
                                <span>
                                    <i class="far fa-trash-alt" data-toggle="modal" data-target="#deleteModalPopup"></i>
                                </span>
                            </td>
                        </tr> -->
                    </tbody>
                </table>
                <hr>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 col-xs-12 col-lg-6">
          <div class="pagination mob-text-align">
            <p>Showing {{(p-1)*10}} - {{((p-1)*10)+10}} of {{zoneArray?.length}}</p>
          </div>
        </div>
        <pagination-controls (pageChange)="p = $event"></pagination-controls>
      </div>
</section>

<!-- createZonePopupModal start -->
<div class="modal fade" id="createZonePopupModal" tabindex="-1" role="dialog"
    aria-labelledby="createZonePopupModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <h2>Add zone</h2>
                    </div>
                    <div class="col-md-12 mt-3" [formGroup]="zoneForm">
                        <div class="form-group has-float-label mb-0">
                            <input class="form-control" type="text" placeholder="Enter zone" formControlName="zone"/>
                            <label>Enter zone</label>
                        </div>
                        <div class="mb-3 mt-1">
                            <p>Separate zones with comma</p>
                        </div>
                        <div class="form-group has-float-label mb-3">
                            <input class="form-control" type="text" placeholder="Enter price" formControlName="deliverycharge"/>
                            <label>Enter price</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="float-right">
                            <button type="button" class="btn cancel-btn" data-dismiss="modal" (click)="cancelZone()">Cancel</button>
                            <button type="button" class="btn add-zone-btn" data-dismiss="modal" (click)="addZone()"
                            [disabled]="zoneForm.invalid">Add zone</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- createZonePopupModal end -->

<!-- editZonePopupModal start -->
<div class="modal fade" id="editZonePopupModal" tabindex="-1" role="dialog"
    aria-labelledby="editZonePopupModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <h2>Edit zone</h2>
                    </div>
                    <div class="col-md-12 mt-3" [formGroup]="zoneForm">
                        <div class="form-group has-float-label mb-0">
                            <input class="form-control" type="text" placeholder="Enter zone" formControlName="zone"/>
                            <label>Enter zone</label>
                        </div>
                        <div class="mb-3 mt-1">
                            <p>Separate zones with comma</p>
                        </div>
                        <div class="form-group has-float-label mb-3">
                            <input class="form-control" type="text" placeholder="Enter price" formControlName="deliverycharge"/>
                            <label>Enter price</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="float-right">
                            <button type="button" class="btn cancel-btn" data-dismiss="modal" (click)="cancelZone()">Cancel</button>
                            <button type="button" class="btn edit-zone-btn" data-dismiss="modal" (click)="updateZone()"
                            [disabled]="zoneForm.invalid">update zone</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- editZonePopupModal end -->

<!-- deleteModalPopup start-->
<div class="modal fade" id="deleteModalPopup" tabindex="-1" role="dialog" aria-labelledby="deleteModalPopupLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="info-icon-img">
                            <img src="../../../assets/exclamation.png">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="confirm-txt mt-3">
                            <h6 class="mb-3">Are you sure?</h6>
                            <p class="mb-3"> Do you want to delete this zone?</p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 mfooterBtn d-flex justify-content-end">
                        <button type="button" class="btn cancel-btn mr-2" data-dismiss="modal">Cancel</button>
                        <button type="button" class="btn submit-btn" data-dismiss="modal" (click)="deleteZone(deleteZoneId)">Delete</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- deleteModalPopup end-->