<section class="message-center-sec">
    <div class="row p-t-20">
        <div class="col-md-12 col-xs-12 col-lg-12">
            <div class="message-center-heading">
                <h1>Message Center</h1>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 mt-4">
            <div class="vendor-list-breadcrumb">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item cursor-pointer" routerLink="/order/list"
                            routerLinkActive="router-link-active">
                            <a><i class="fas fa-shopping-cart"></i> Order list</a>
                        </li>
                        <li class="breadcrumb-item cursor-pointer" routerLink="/order/list"
                            routerLinkActive="router-link-active">Order id : {{orderDetails?.order_id}}</li>
                        <li class="breadcrumb-item active" aria-current="page">Message</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
    <!-- tab view -->

    <!-- customer review -->
    <div class="row panel panel-default m-t-30">
        <div class="col-md-12 col-xs-12 col-lg-12">
            <form [formGroup]="messageForm">
                <div class="tree">
                    <ul class="to-customer" *ngFor="let message of allMessages">
                        <li>
                            <span> <i class="fas fa-user-cog"></i></span>
                            <span>{{message.from}}</span>
                            <span *ngIf="message.type == 'admin-user' && message.from != loginUser"> / Customer</span>
                            <span
                                *ngIf="(message.type == 'admin-user' && message.from == loginUser) || (message.type == 'admin-vendor' && message.from == loginUser)">
                                / Admin</span>
                            <span *ngIf="message.type == 'admin-vendor'&& message.from != loginUser"> /
                                Manufacturer</span>
                            <div class="float-right">
                                <span class="time-span">{{message.datetime | date:'h:mm a'}}</span>
                                <span class="date-span">{{message.datetime | date:'dd MMM YYYY'}}</span>
                            </div>
                            <div class="m-l-50">
                                <p>{{message.message}}</p>
                                <div class="forwarded-images">
                                    <div *ngFor="let item of message.image">
                                        <img [src]="item">
                                    </div>
                                </div>
                                <!-- <div>
                                    <h6>
                                        <i class="fas fa-share-square"></i>
                                        Forward to cookie manufacturer
                                    </h6>
                                </div> -->
                            </div>
                        </li>
                    </ul>
                    <!-- <ul class="to-vendor">
                        <li>
                            <span> <i class="fas fa-user-cog"></i></span>
                            <span>Snape cookie</span>
                            <span> / Manufacturer</span>
                            <div class="float-right">
                                <span class="time-span">03-45 pm</span>
                                <span class="date-span">20-Jan-2021</span>
                            </div>
                            <div>
                                <p class="forwarded-message">Forwarded message</p>
                            </div>
                            <div class="m-l-50">
                                <p>Lorem ex tempor sit officia nisi consectetur et dolor voluptate officia nostrud. Non
                                    dolor sint magna cillum laborum veniam labore esse officia anim. Culpa aliquip
                                    eiusmod
                                    velit nostrud nisi cillum sunt sit reprehenderit. Ex velit tempor excepteur nulla
                                    exercitation et id. Sint aliquip cupidatat deserunt adipisicing cillum. Incididunt
                                    ullamco do ex duis Lorem consectetur do. </p>
                                <div class="forwarded-images">
                                    <img src="../../../assets/6cookie.jpg">
                                    <img src="../../../assets/6cookie.jpg">
                                    <img src="../../../assets/6cookie.jpg">
                                    <img src="../../../assets/6cookie.jpg">
                                </div>
                                <div>
                                    <h6>
                                        <i class="fas fa-share-square"></i>
                                        Forward to cookie manufacturer
                                    </h6>
                                </div>
                            </div>
                        </li>
                    </ul> -->
                    <!-- <div class="row">
                        <div class="col-md-12">
                            <div class="uploaded-images">
                                <img src="../../../assets/6cookie.jpg">
                                <i class="far fa-trash-alt delete ml-3" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div> -->
                    <div class="row">
                        <div class="col-md-12">
                            <div class="textarea-container">
                                <div>
                                    <textarea name="foo" class="form-control" formControlName="customer_message"
                                        id="exampleFormControlTextarea1" rows="6"
                                        placeholder="Add your Message..."></textarea>
                                    <i class="far fa-images" (click)="openFile2()">
                                        <input type="file" formControlName="fileSource2" id="files2" name="files2[]"
                                            multiple style="display:none" (change)="handle($event)">
                                    </i>
                                </div>
                                <div class="d-flex">
                                    <div *ngFor='let url of urls; let i=index'>
                                        <div class="box mt-3" *ngIf="urls">
                                            <img [src]="url" class="uploading-img mr-2 mb-2" height="75">
                                            <i class="far fa-trash-alt" (click)=deleteUploadedImage(i)></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="submit-btn">
                                <button type="button" class="btn cancel-btn">Cancel</button>
                                <button type="button" class="btn send-btn" type="button" id="dropdownMenuButton"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Send</button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a class="dropdown-item" (click)="customerSend()">Send to customer</a>
                                    <a class="dropdown-item" (click)="companySend()">Send to manufacturer</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>