import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../../common/common.service';
import * as _ from 'underscore';
@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css']
})
export class ProductListComponent implements OnInit {

  productArray
  productId: any;
  loader = false
  constructor(
    public api: CommonService,
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.loader = true
    this.getProducts();
  }
  getProducts() {
    this.api.getProduct().subscribe(re => {
      this.productArray = re['data']['Items']
      this.loader = false
      this.productArray = _.sortBy(this.productArray, function(o){
        return o.cookie_number
      })
      console.log('res', this.productArray)
    })
  }
  updateProduct(product:any){
    console.log(product)
    this.router.navigate(['new-product/' +product.id])
  }
  removeProduct(id:any){
    this.productId = id
  }
  deleteProduct(id:any){
    this.api.deleteProduct({"id":id}).subscribe(re=>{
      console.log(re, 're')
      let message = re['message']
      this.toastr.success(message)
      this.getProducts();
    })
  }
}
