import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/common/common.service';
import { ExcelService } from 'src/app/common/excel.service';
import { CONTACT_STATUS } from 'src/app/config/commonConfig';
import * as _ from 'underscore';

@Component({
  selector: 'app-general-queries',
  templateUrl: './general-queries.component.html',
  styleUrls: ['./general-queries.component.css']
})
export class GeneralQueriesComponent implements OnInit {


  // variable declaration 
  statusDropdownSettings;
  selectedStatus = [];
  statusArray = CONTACT_STATUS;
  queryForm: FormGroup
  selectedItems = [];
  activeList = [];
  inactiveList = [];
  filteredDate = []
  popupText: string;
  config: any;
  p: number = 1;
  total: number = 50
  currentPage = 1
  startPage: number = 1;
  lastPage: number = 5;
  queryArray = []
  queryArrayCopy = []
  queryCount = 1
  queryId: any;
  status = 'Open'
  email: any;
  imageURL: string;
  loader = false
  filterName:any
  dateFilter:any

  constructor(
    private api: CommonService,
    private excelService: ExcelService,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.queryForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      message: ['', Validators.required],
      reply: ['', Validators.required],
      fileSource: [''],
      image: '',
      status: ['', Validators.required]
    })
    this.queryForm.controls.status.disable()
    this.getQueryList();

    this.getdropDownSettings();
  }
  getdropDownSettings() {
    this.statusDropdownSettings = {
      singleSelection: true,
      primaryKey: 'id',
      labelKey: 'name',
      text: "Filter by Status",
      enableSearchFilter: false,
      lazyLoading: true,
      showCheckbox: true,
      clearAll: false,
      badgeShowLimit: 1,
      classes: "myclass custom-class"
    };
  }
  getToday(): string {
    return new Date().toISOString().split('T')[0]
  }
  cancel() {
    this.queryForm.reset()
    this.imageURL = ''
  }
  onItemSelect(item: any) {
    debugger
    this.filterName = item.name
    if(this.dateFilter){
      this.queryArray = _.filter(this.queryArrayCopy, (element) => {
        return element.query_status == item.name 
      })
      this.queryArray = _.filter(this.queryArray, (element) => {
        return element.SK.slice(0, 10) === this.dateFilter
      })
    }else{
      this.queryArray = _.filter(this.queryArrayCopy, (element) => {
        return element.query_status == item.name
      })
    }
   
    this.queryCount = this.queryArray.length
  }
  OnItemDeSelect(item: any) {
    this.queryArray = _.sortBy(this.queryArray, (item) => {
      return item.SK
    })
  }
  onSelectAll(items: any) {

  }
  onDeSelectAll(items: any) {

  }
  getQueryList() {
    this.api.getQueries().subscribe(item => {
      console.log(item, 'item')
      this.queryArray = item['data']['Items']
      this.queryArray = _.sortBy(this.queryArray, (item) => {
        return item.SK
      })
      this.queryArrayCopy = this.queryArray.reverse();
      this.queryCount = this.queryArray?.length
    })
  }
  reply(query) {
    this.queryForm.patchValue({
      status: 'closed'
    })
    this.queryForm.controls.reply.enable()
    console.log(query, 'query')
    this.queryId = query['SK']
    this.queryForm.patchValue({
      name: query['name'],
      email: query['email'],
      message: query['message'],
      reply: query['reply']

    })
    this.queryForm.controls.name.disable()
    this.queryForm.controls.email.disable()
    this.queryForm.controls.message.disable()
    if (query['query_status'] != 'Open') {
      this.queryForm.controls.reply.disable()
    }
  }
  send() {
    debugger
    if (this.queryForm.controls.reply?.value) {
      if (this.queryForm.controls.status.value) { this.status = 'Closed'; }
      let data = {
        "SK": this.queryId,
        "is_admin": "true",
        "email": this.queryForm.controls.email.value,
        "message": this.queryForm.controls.message.value,
        "query_status": this.status,
        "reply": this.queryForm.controls.reply.value,
        "filename": this.queryForm.get('image').value.name

      }
      console.log(data['filename'])

      let file = { "filename": this.queryForm.get('image').value }
      this.api.updateQueries(data, file).subscribe(res => {
        this.ngOnInit();
        this.toastr.success(res['message'])
      })
    } else {
      this.toastr.error('Please enter reply')
    }

  }
  remove(query) {
    this.queryId = query.SK
    this.email = query.email
  }
  cancelQuery(id) {
    console.log(id)
    let data = {
      "SK": id,
      "is_admin": "true",
      "email": this.email,
      "query_status": 'Cancelled'
    }
    let file = { "filename": '' }
    this.api.updateQueries(data, file).subscribe(res => {
      this.ngOnInit();
      this.toastr.success('Query has been cancelled')
    })
  }
  filterDate(queryDate: any) {
    debugger
    let filter_date = this.formatDate(queryDate)
    this.dateFilter = filter_date
    let name  = this.filterName
    if(this.filterName){
      this.queryArray = _.filter(this.queryArrayCopy, function (res) {
        return res?.SK.slice(0, 10) === filter_date 
      })
      this.queryArray = _.filter(this.queryArray, function (res) {
        return res.query_status == name
      })
    }else{
      this.queryArray = _.filter(this.queryArrayCopy, function (res) {
        return res?.SK.slice(0, 10) === filter_date
      })
    }
   
    this.queryCount = this.queryArray?.length
    this.p = 1
  }
  clearAll() {
    window.location.reload();
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
  showPreview(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.queryForm.patchValue({
      image: file
    });
    this.queryForm.get('image').updateValueAndValidity()

    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.imageURL = reader.result as string;
      console.log(this.imageURL, 'img url')
    }
    reader.readAsDataURL(file)
  }
}
