import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/common/common.service';
import { OrderServiceService } from 'src/app/common/order-service.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-vendor-list',
  templateUrl: './vendor-list.component.html',
  styleUrls: ['./vendor-list.component.css']
})
export class VendorListComponent implements OnInit, AfterViewInit {

  @ViewChild('closebutton') closebutton;
  companyDetailsForm: FormGroup;
  bankDetailsForm: FormGroup;
  documentDetailsForm: FormGroup;
  contactForm: FormGroup;
  activeTab = 'home';
  submitted = false;
  save_contact = false
  contact_submitted = false
  loader = false
  contactList = []
  fileToUpload: File;
  array = []
  keys = []
  values = []
  vendor_email
  vendorDetails: any;
  index = 0;
  contactId
  filter_date: string;
  doc_arr = []
  today:Date
  constructor(
    private fb: FormBuilder,
    private api: CommonService,
    private activatedRoute: ActivatedRoute,
    private customValidator: OrderServiceService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.loader = true
    this.today = new Date()
    this.activatedRoute.queryParams.subscribe(res => {
      this.vendor_email = res.email_id
      this.loader = false
      console.log(res.id, 'params')
    })
    if (this.vendor_email) {
      this.getVendor();
      this.api.getCompanyContact(this.vendor_email).subscribe(res => {
        this.contactList = res['data']['Items']
        this.loader = false
        console.log(res, 'res')
      })
    }
    this.getCompanyDetailsFormArray();
    this.getBankDetailsFormArray();
    this.getDocumentDetailsFormArray();
    this.getContactFormArray();
    localStorage.removeItem("contacts");
  }
  getVendor() {
    debugger
    this.api.getSingleCompany(this.vendor_email).subscribe(res => {
      this.vendorDetails = res['users']['Item']
      let doc = res['doc_data']['Item']
      console.log(doc,'doc')
      if(doc !== undefined){
        this.vendorDetails['doc_data'] = res['doc_data']['Item']['doc_data']
        this.documentDetailsForm.patchValue({
          choose_document: doc.type,
          expiry_date:doc.date,
        })
        this.filter_date = doc.date
      }
     
      console.log(this.vendorDetails, 'vendor detls')
      this.companyDetailsForm.patchValue({
        company_name: this.vendorDetails?.company_name,
        cr_number: this.vendorDetails?.cr_number,
        company_address: this.vendorDetails?.company_address,
        contact_name: this.vendorDetails?.contact_name,
        phone_number: this.vendorDetails?.phone_number,
        mobile_number: this.vendorDetails?.mobile_number,
        email_id: this.vendorDetails?.email,
        username: this.vendorDetails?.username,
        website: this.vendorDetails?.website,
        password: this.vendorDetails?.password
      })
      this.bankDetailsForm.patchValue({
        bank_name: this.vendorDetails?.bank_name,
        branch_name: this.vendorDetails?.branch_name,
        branch_address: this.vendorDetails?.branch_address,
        account_number: this.vendorDetails?.account_number,
        account_holder_name: this.vendorDetails?.account_holder_name,
        iban: this.vendorDetails?.iban
      })
      localStorage.removeItem('Vendor')
      let data = {
        "company_name": this.vendorDetails?.company_name,
        "email": this.vendorDetails?.email,
        "is_primary": false
      }
      localStorage.setItem('Vendor', JSON.stringify(data))
    })
  }
  ngAfterViewInit() {

  }
  getCompanyDetailsFormArray() {
    this.companyDetailsForm = this.fb.group({
      "company_name": ['', Validators.required],
      'cr_number': ['', Validators.required],
      'company_address': ['', Validators.required],
      'phone_number': ['', [Validators.required, Validators.pattern("^[0-9]{8}$")]],
      'mobile_number': ['', [Validators.required, Validators.pattern("^[0-9]{8}$")]],
      'email_id': ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      'contact_name': ['', Validators.required],
      'website': ['', Validators.required],
      //'username': ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      'password': ['', Validators.compose([Validators.required, this.customValidator.patternValidator()])],
    })
  }
  getBankDetailsFormArray() {
    this.bankDetailsForm = this.fb.group({
      'bank_name': ['', Validators.required],
      'branch_name': ['', Validators.required],
      'branch_address': ['', Validators.required],
      'account_number': ['', Validators.required],
      'account_holder_name': ['', Validators.required],
      'iban': ['', Validators.required],
    })
  }
  getDocumentDetailsFormArray() {
    this.documentDetailsForm = this.fb.group({
      'fileSource': ['', Validators.required],
      'choose_document': ['', Validators.required],
      'expiry_date': ['', Validators.required],
    })
  }
  getContactFormArray() {
    this.contactForm = this.fb.group({
      'contact_name': ['', Validators.required],
      'contact_number': ['', [Validators.required, Validators.pattern("^[0-9]{8}$")]]
    })
  }
  saveCompanyBankDetails() {
    debugger
    console.log(this.companyDetailsForm.get('company_name').value, 'gh')
    if (this.companyDetailsForm.valid) {
      let data = {
        'company_name': this.companyDetailsForm.controls.company_name.value,
        'cr_number': this.companyDetailsForm.controls.cr_number.value,
        'company_address': this.companyDetailsForm.controls.company_address.value,
        'phone_number': this.companyDetailsForm.controls.phone_number.value,
        'mobile_number': this.companyDetailsForm.controls.mobile_number.value,
        'email': this.companyDetailsForm.controls.email_id.value,
        'contact_name': this.companyDetailsForm.controls.contact_name.value,
        'website': this.companyDetailsForm.controls.website.value,
        // 'username': this.companyDetailsForm.controls.username.value,
        'password': this.companyDetailsForm.controls.password.value,
        'bank_name': this.bankDetailsForm.controls.bank_name.value,
        'branch_name': this.bankDetailsForm.controls.branch_name.value,
        'branch_address': this.bankDetailsForm.controls.branch_address.value,
        'account_number': this.bankDetailsForm.controls.account_number.value,
        'account_holder_name': this.bankDetailsForm.controls.account_holder_name.value,
        'iban': this.bankDetailsForm.controls.iban.value,
      }
      let email = this.companyDetailsForm.controls.email_id.value
      let company = this.companyDetailsForm.controls.company_name.value
      localStorage.removeItem('Vendor')
      if (!this.vendorDetails) {
        this.api.addVendor(data).subscribe(res => {
          this.submitted = true
          let data = {
            "company_name": company,
            "email": email
          }
          localStorage.setItem('Vendor', JSON.stringify(data))
          console.log(res, 'res')
          let message = res['message']
          this.toastr.success(message);
          if (this.contactList) {
            for (var i in this.contactList) {
              this.contactList[i].is_admin = true
              this.contactList[i].email = email
              this.contactList[i].mobile = this.contactList[i].mobile.toString()
            }
            console.log(this.contactList, 'new ')
            if (this.contactList) {
              this.api.addCompanyContacts(this.contactList[0]).subscribe(item => {
                console.log(item, 'item')
                localStorage.removeItem("contacts");
                this.contactForm.reset();
              })
            }
          }
          this.activeTab = 'contact'
          this.router.navigate(['/vendor/list'])
        })
      }
      this.cancelBank();
      this.cancelCompany();
      
    }
    else {
      this.submitted = true
      this.toastr.error('please fill all required fields')
    }
  }

  updateCompanyBankDetails() {
    let data = {
      'id': this.vendorDetails.id,
      'company_name': this.companyDetailsForm.controls.company_name.value,
      'cr_number': this.companyDetailsForm.controls.cr_number.value,
      'company_address': this.companyDetailsForm.controls.company_address.value,
      'phone_number': this.companyDetailsForm.controls.phone_number.value,
      'mobile_number': this.companyDetailsForm.controls.mobile_number.value,
      'email': this.companyDetailsForm.controls.email_id.value,
      'contact_name': this.companyDetailsForm.controls.contact_name.value,
      'website': this.companyDetailsForm.controls.email_id.value,
      // 'username': this.companyDetailsForm.controls.username.value,
      'password': this.companyDetailsForm.controls.password.value,
      'bank_name': this.bankDetailsForm.controls.bank_name.value,
      'branch_name': this.bankDetailsForm.controls.branch_name.value,
      'branch_address': this.bankDetailsForm.controls.branch_address.value,
      'account_number': this.bankDetailsForm.controls.account_number.value,
      'account_holder_name': this.bankDetailsForm.controls.account_holder_name.value,
      'iban': this.bankDetailsForm.controls.iban.value,
    }
    let email = this.companyDetailsForm.controls.email_id.value
    let company = this.companyDetailsForm.controls.company_name.value
    this.api.updateVendor(data).subscribe(res => {
      let data = {
        "company_name": company,
        "email": email
      }
      localStorage.setItem('Vendor', JSON.stringify(data))
      console.log(res, 'res')
      let message = res['message']
      this.toastr.success(message);
      if (this.contactList) {
        for (var i in this.contactList) {
          this.contactList[i].is_admin = true
          this.contactList[i].email = email
          this.contactList[i].mobile = this.contactList[i].mobile.toString()
        }
        console.log(this.contactList, 'new ')
        this.api.addCompanyContacts(this.contactList[0]).subscribe(item => {
          console.log(item, 'item')
          localStorage.removeItem("contacts");
          this.contactForm.reset();
        })
      }
      this.activeTab = 'contact'
      this.router.navigate(['/vendor/list'])
    })
  }
  addContact() {
    if (this.contactForm.valid) {
      let contactFiles = []
      let data = {
        "name": this.contactForm.controls.contact_name.value,
        "mobile": this.contactForm.controls.contact_number.value,
        "id": Date.now()
      }
      if (localStorage.getItem('contacts') == null) {
        contactFiles.push(data)
        localStorage.setItem('contacts', JSON.stringify(contactFiles));
      }
      else {
        if (data) {
          contactFiles.push(data)
        }
        this.contactList.forEach(function (item) {
          contactFiles.push(item);
        })
        this.contactList = contactFiles
        localStorage.setItem('contacts', JSON.stringify(contactFiles));
      }
      this.getContact();
    }
  }
  getContact() {
    this.contactList = JSON.parse(localStorage.getItem('contacts'));
    console.log(this.contactList, 'contact')
  }
  removeContact(id) {
    this.contactId = id
  }
  deleteContact(id) {
    let index
    for (var i in this.contactList) {
      if (this.contactList[i].id == id) {
        index = i
        this.contactList.splice(index, 1)
      }
    }
    localStorage.setItem('contacts', JSON.stringify(this.contactList));
  }
  editContact(contact) {
    this.contactForm.patchValue({
      contact_name: contact['name'],
      contact_number: contact['mobile']
    })
    this.contactId = contact.id
  }
  updateContact(id) {
    for (var i in this.contactList) {
      if (this.contactList[i].id == id) {
        this.contactList[i].name = this.contactForm.controls.contact_name.value,
          this.contactList[i].mobile = this.contactForm.controls.contact_number.value
      }
    }
    localStorage.setItem('contacts', JSON.stringify(this.contactList));
  }
  filterDate(queryDate: any) {
    this.filter_date = this.formatDate(queryDate)
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }
  upload(){
    let vendor_details = JSON.parse(localStorage.getItem('Vendor'))
    let temp_doc_array = [];
    let arr = []
    let obj = {}
    let str = environment.s3bucket//'https://snape-cookie.s3.amazonaws.com/'
    obj['image_url'] = str+this.fileToUpload.name+ vendor_details.email
    obj['file_type'] = this.documentDetailsForm.controls.choose_document.value
    obj['file_expiry'] = this.filter_date
    debugger
    if(this.doc_arr == null){
      this.doc_arr.push(obj)
    }
    else{
      if(obj){
        this.doc_arr.push(obj)
      }
      arr.forEach(function(item){
        this.doc_arr.push(item)
      })
      this.doc_arr = this.doc_arr
      console.log(temp_doc_array, 'temp array')
    }
  }
  documentSubmit() {
    debugger
    let docArray = []
    let filesArray = []
    let filen = this.documentDetailsForm.controls.fileSource.value
    console.log(filen.name, 'files')
    this.keys.push(this.fileToUpload.name)
    this.values.push(this.fileToUpload)
    console.log(this.keys, this.values, 'array')
    docArray.push(this.fileToUpload.name)

    console.log(docArray, 'docArray')
    let vendor_details = JSON.parse(localStorage.getItem('Vendor'))
    let data = {
      doc_data: [this.fileToUpload.name],
      email: vendor_details.email,
      company_name: vendor_details.company_name,
      type:this.documentDetailsForm.controls.choose_document.value,
      date:this.documentDetailsForm.controls.expiry_date.value
    }
    let file = {
      "key": this.keys,
      "value": this.values
    }
    debugger
    this.api.addVendorDocuments(data, file).subscribe(data => {
      if (data) {
        this.toastr.success(data['message'])
        this.router.navigate(['/vendor/list'])
        
      }
      console.log(data, 'data')
      localStorage.removeItem('Vendor')
    })
  }
  search(activeTab) {
    this.activeTab = activeTab;
    console.log(this.activeTab)
  }

  bank(activeTab) {
    this.activeTab = 'bank'
    console.log(this.activeTab)
  }
  contact(activeTab) {
    this.activeTab = 'contact'
    console.log(this.activeTab)
  }
  cancelCompany() {
    if(this.vendorDetails){
      this.router.navigate(['vendor/list'])
    }
    else{
      this.companyDetailsForm.reset();
    }
  }
  cancelBank() {
    if(this.vendorDetails){
      this.router.navigate(['vendor/list'])
    }
    else{
      this.bankDetailsForm.reset(); 
    }
  }
}
