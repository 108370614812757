<div class="wrapper">
  <app-admin-head-menu>
  </app-admin-head-menu>
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <a class="brand-link">
      <!-- <img src="../../../../assets/user-image.png" alt="Snape Cookies" class="brand-image img-circle elevation-3"
        style="opacity: .8"> -->
      <img src="../../../../assets/1-01.png" alt="Snape Cookies" class="brand-image img-circle">
      <span class="brand-text font-weight-light admin-color">Admin</span>
    </a>
    <div class="sidebar">
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" role="menu" data-widget="treeview" data-accordion="false">
          <li class="nav-item cursor-pointer">
            <a class="nav-link nav-link-color" routerLink="/dashboard" routerLinkActive="router-link-active">
              <i class="nav-icon fas fa-tachometer-alt m-l-2"></i>
              <p class="p-l-9">
                Dashboard
              </p>
            </a>
          </li>

          <li class="nav-item has-treeview cursor-pointer">
            <a class="nav-link nav-link-color" routerLink="/customer-list" routerLinkActive="router-link-active">
              <i class="fas fa-user m-l-7"></i>
              <p class="p-l-19">
                Customer List
              </p>
            </a>
          </li>

          <li class="nav-item has-treeview cursor-pointer">
            <a class="nav-link nav-link-color" routerLink="/vendor/list" routerLinkActive="router-link-active">
              <i class="fas fa-building m-l-7"></i>
              <p class="p-l-19">
                Vendor List
              </p>
            </a>
          </li>
          <li class="nav-item has-treeview cursor-pointer">
            <a class="nav-link nav-link-color" routerLink="/order/list" routerLinkActive="router-link-active">
              <i class="fas fa-shopping-cart m-l-7"></i>
              <p class="p-l-15">
                Order List
              </p>
            </a>
          </li>
          <!-- <li class="nav-item has-treeview cursor-pointer">
            <a class="nav-link nav-link-color" routerLink="/order-details" routerLinkActive="router-link-active">
              <i class="fas fa-shopping-cart m-l-7"></i>
              <p class="p-l-15">
                Order Details
              </p>
            </a>
          </li> -->

          <li class="nav-item has-treeview cursor-pointer">
            <a class="nav-link nav-link-color" routerLink="/product-list" routerLinkActive="router-link-active">
              <i class="fas fa-box m-l-7"></i>
              <p class="p-l-18">
                Product List
              </p>
            </a>
          </li>

          <li class="nav-item has-treeview cursor-pointer">
            <a class="nav-link nav-link-color" routerLink="/manage-flavours" routerLinkActive="router-link-active">
              <i class="fas fa-cart-plus m-l-7"></i>
              <p class="p-l-18">
                Manage Flavours
              </p>
            </a>
          </li>
          <li class="nav-item has-treeview cursor-pointer">
            <a class="nav-link nav-link-color" routerLink="/ledger-details" routerLinkActive="router-link-active">
              <i class="fas fa-university m-l-7"></i>
              <p class="p-l-18">
                Ledger Details
              </p>
            </a>
          </li>
          <li class="nav-item has-treeview cursor-pointer">
            <a class="nav-link nav-link-color" routerLink="/zone" routerLinkActive="router-link-active">
              <!-- <i class="fas fa-location-dot m-l-7"></i> -->
              <i class="fas fa-map-marker-alt m-l-7"></i>
              <p class="p-l-18">
                Zone
              </p>
            </a>
          </li>
          <li class="nav-item has-treeview cursor-pointer">
            <a class="nav-link nav-link-color" routerLink="/notifications" routerLinkActive="router-link-active">
              <i class="far fa-bell m-l-7"></i>
              <p class="p-l-21">
                Notification
              </p>
            </a>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link nav-link-color cursor-pointer" routerLink="/content-management"
              routerLinkActive="router-link-active">
              <i class="fas fa-bars m-l-7"></i>
              <p class="p-l-21">
                Content Mgt
              </p>
            </a>
          </li> -->
          <li class="nav-item">
            <a class="nav-link nav-link-color cursor-pointer" routerLink="/faq" routerLinkActive="router-link-active">
              <i class="fas fa-question m-l-7"></i>
              <p class="p-l-21">
                FAQ
              </p>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link nav-link-color cursor-pointer" routerLink="/template/images"
              routerLinkActive="router-link-active">
              <i class="fas fa-image m-l-7"></i>
              <p class="p-l-16">
                Template Images
              </p>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link nav-link-color cursor-pointer" routerLink="/news/letter"
              routerLinkActive="router-link-active">
              <i class="fas fa-file-alt m-l-7"></i>
              <p class="p-l-21">
                News letter
              </p>
            </a>
          </li>
          <li class="nav-item has-treeview cursor-pointer">
            <a class="nav-link nav-link-color" routerLink="/general/queries" routerLinkActive="router-link-active">
              <i class="fas fa-comments m-l-7"></i>
              <p class="p-l-16">
                General Queries
              </p>
            </a>
          </li>
          <li class="nav-item has-treeview cursor-pointer">
            <a class="nav-link nav-link-color" routerLink="/coupons" routerLinkActive="router-link-active">
              <i class="fas fa-tag m-l-7"></i>
              <p class="p-l-16">
                Coupons
              </p>
            </a>
          </li>
          <li class="nav-item has-treeview cursor-pointer">
            <a class="nav-link nav-link-color" routerLink="/settings" routerLinkActive="router-link-active">
              <i class="fas fa-cog m-l-7"></i>
              <p class="p-l-16">
                Settings
              </p>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </aside>
  <div class="content-wrapper">
    <div class="container-fluid">
      <div class="main">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>