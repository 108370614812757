<div class="row">
    <div class="col-md-12 mt-4">
        <div class="print-cookie-breadcrumb">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item cursor-pointer" routerLinkActive="router-link-active"
                        (click)="orderDetailPage()">
                        <i class="fas fa-shopping-cart"></i> Order details
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">Order id : {{orderId}}</li>
                </ol>
            </nav>
        </div>
    </div>
</div>
<div size="A4" class="page" *ngIf="currentImage">
    <div class="p-3" (click)="back()">
        <i class="far fa-arrow-alt-circle-left"></i>
    </div>
    <table id="singleImage" class="table-margin-top">
        <tr>
            <td>
                <img src="{{currentImage}}">
            </td>
            <td style="visibility: hidden;"><img src="{{images}}"></td>
        </tr>
    </table>
    <table style="width:100%">
        <tr>
            <th>
                <button class="print-btn" (click)="printOneCookie(currentImage)">Print Cookie</button>
            </th>
        </tr>
    </table>
</div>
<div size="A4" class="page" *ngIf="!currentImage">
    <div class="p-3" (click)="back()">
        <i class="far fa-arrow-alt-circle-left"></i>
    </div>
    <table id="printDiv" class="table-margin-top">
        <!-- <tr *ngFor="let item of counter(itemsToPrint.length/2); let i=index">
            <th *ngFor="let count of counter(2); let j=index">
                <img src="{{itemsToPrint[i*2+j]}}">
            </th>
        </tr> -->
        <!-- <tr *ngFor="let item of itemsToPrint">
            <th>
                <img src="{{item}}">
            </th>
        </tr> -->
        <ng-container *ngFor="let item of itemsToPrint; let i = index">
            <tr *ngIf="i % 2 == 0">
                <td><img src="{{item}}"></td>
                <td *ngIf="itemsToPrint.length == 1" style="visibility: hidden;"><img src="{{item['image']}}"></td>
                <td *ngIf="i < itemsToPrint.length - 1 ? itemsToPrint[i + 1] : undefined">
                    <img src='{{i < itemsToPrint.length - 1 ? itemsToPrint[i + 1] : "" }}'>
                </td>
            </tr>
        </ng-container>
    </table>
    <table style="width:100%">
        <tr>
            <th>
                <button class="print-btn" (click)="printAllCookie()">Print Cookie</button>
            </th>
        </tr>
    </table>
</div>
<div>

</div>