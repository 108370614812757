import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { OrderServiceService } from 'src/app/common/order-service.service'; 
import { STATUS } from 'src/app/config/commonConfig';
import * as _ from 'underscore';

@Component({
  selector: 'app-message-center',
  templateUrl: './message-center.component.html',
  styleUrls: ['./message-center.component.css']
})
export class MessageCenterComponent implements OnInit {

  urlName=[];
  messageForm: FormGroup
  orderId: any;
  order_ref
  order_date: any;
  delivery_date
  current_status
  delivery_address
  customize = []
  productList = []
  productArray = []
  orderData
  orderDetails
  i
  cookieData: any;
  flavorArray = [];
  flavorName: any;
  flArr = [];
  itemId
  index: any;
  cookie_image: any;
  userMessages: any;
  adminMessages: any;
  vendorMessages
  admin_vendorMessages
  allMessages = [];
  allVendorChats = []
  vendorEmail
  productId
  imageURL: string;
  urls = []
  urls2 = []
  id: any;
  extra: { order_id: any; user_email: any; };
  constructor(
    private route: ActivatedRoute,
    private api: CommonService,
    private orderService: OrderServiceService,
    private router: Router,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    debugger
    this.route.queryParams.subscribe(item=>{
      this.orderDetails= item
      this.productId = this.orderDetails?.product_id.split(/#(.*)/)[1]
      this.id = item.id
      console.log(item, 'item')
    })
    console.log(this.orderId,'ref')
    let data = {
      "PK": this.orderDetails?.PK,
      "id": this.id,
      "message_status": "read",
      "email_id":this.orderDetails.user_email,
      "is_admin":true
    }
    this.api.updateOrderItemMsgStatus(data).subscribe(res=>{
      console.log(res, 'res')
    })
    this.getMessages();
    this.getVendorChat();
    this.getMessageForm();
  }
  getMessageForm() {
    this.messageForm = this.fb.group({
      customer_message: [''],
      company_message: [''],
      fileSource: [''],
      image: '',
      fileSource2: [''],
      image2: ''
    })
  }
  getMessages() {
    this.api.getAdminUserMessage(this.orderDetails?.order_id,this.productId).subscribe(res => {
        this.allMessages = res['data']
        this.allMessages =_.sortBy(this.allMessages, function(o){ return o['datetime']; });
        console.log(this.allMessages, 'allMesg')
    })
    
  }
  getVendorChat(){
      this.api.getAdminVendorMessage(this.orderDetails?.order_id, this.productId).subscribe(res => {
        this.allVendorChats = res['data']
        this.allVendorChats =_.sortBy(this.allVendorChats, function(o){ return o['datetime']; });
        console.log(this.allVendorChats, 'allVendorChats')
      })
  }
  customerSend() {
    console.log(this.messageForm.controls.customer_message.value, 'message')
    let data = {
      "prod_id": this.id,
      "order_id": this.orderDetails?.order_id,
      "item_id": this.productId,
      "ordered_user":this.orderDetails.user_email,
      // "to": "cohani@tafmail.com",
      // "from":this.orderDetails.user_email,
      "from": "cohani@tafmail.com",
      "to": this.orderDetails.user_email,
      "message": this.messageForm.controls.customer_message.value,
      "type": "admin-user"
    }
    let file ={
      "filename": this.messageForm.get('image').value
    }
    // this.api.addMessage(data).subscribe(res => {
    //   console.log(res, 'res')
    //   if(res){
    //     this.getMessages();
    //     this.getVendorChat();
    //     this.cancelCustomer();
    //   }
    // })
  }
  companySend() {
    console.log(this.messageForm.controls.company_message.value, 'message')
    let data = {
      "prod_id": this.id,
      "order_id": this.orderDetails?.order_id,
      "item_id": this.productId,
      "ordered_user":this.orderDetails.user_email,
      "from": "cohani@tafmail.com",
      "to": this.orderDetails?.vendor_email,
      "message": this.messageForm.controls.company_message.value,
      "type": "admin-vendor"
    }
    let file ={
      "filename": this.messageForm.get('image').value
    }
    this.api.addMessage(data).subscribe(res => {
      console.log(res, 'res')
      if(res){
        this.ngOnInit();
        this.cancelVendor();
      }
    })
  }
  cancelCustomer(){
    this.messageForm.get('customer_message').setValue('')
    this.urls = []
  }
  cancelVendor(){
    this.messageForm.get('company_message').setValue('')
    this.urls2 = []
  }
  openFile(){
    document.querySelector('input').click()
  }
  
  handle(event) {
    console.log('Change input file')
    const file = (event.target as HTMLInputElement).files[0];
    console.log(file)
    this.urlName.push(file.name)
    this.messageForm.patchValue({
      image: file
    });
    // if (file == null) {
    //   this.imageURL = ''
    // }
    this.messageForm.get('image').updateValueAndValidity()
    // File Preview
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();

        reader.onload = (event: any) => {
          this.urls.push(event.target.result);
        };

        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }
  openFile2(){
    document.querySelector('input').click()
  }
  
  handle2(event) {
    console.log('Change input file')
    const file = (event.target as HTMLInputElement).files[0];
    this.messageForm.patchValue({
      image2: file
    });
    // if (file == null) {
    //   this.imageURL = ''
    // }
    this.messageForm.get('image2').updateValueAndValidity()
    // File Preview
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();

        reader.onload = (event: any) => {
          this.urls2.push(event.target.result);
        };

        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }
  orderDetailPage(){
    this.extra = {
      "order_id": this.orderDetails?.order_id,
      "user_email": this.orderDetails.user_email
    }
    this.router.navigate(['/order-details'], {
      queryParams: this.extra
    });
  }

}


