<div class="container">
    <div class="row p-t-20">
      <div class="col-md-12 col-xs-12 col-lg-12">
        <div class="heading">
          <h1>Manage Profile</h1>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-2 col-lg-3 col-xs-12">
      </div>
      <div class="col-md-8 col-lg-6 col-xs-12">
        <div class="card">
          <div class="box">
              <div>
                <button type="button" class="btn btn-link pink-color float-right">Resend OTP</button>
                <h3>Change password</h3>
              </div>
              <p class="text-muted"> Please enter the OTP send to snapecookie@gmail.com</p>
            <input type="text" class="form-control" placeholder="Enter OTP">
            <button type="submit" class="btn btn-snape-bak btn-block text-uppercase">Continue</button>
          </div>
        </div>
      </div>
      <div class="col-md-2 col-lg-3 col-xs-12">
      </div>
    </div>
  </div>
  