import { Component, OnInit } from '@angular/core';
import { Form } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/common/common.service';

@Component({
  selector: 'app-template-images',
  templateUrl: './template-images.component.html',
  styleUrls: ['./template-images.component.css']
})
export class TemplateImagesComponent implements OnInit {
  sectionName: string
  activeTabIndex = 0
  tabs
  imageUploaded: boolean;
  imageURL: string;
  newTab
  tabDetails
  selectedKey: any;
  tempId: any;
  activeTab: any;
  tabId: any;
  constructor(
    private api: CommonService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    debugger
    this.api.getTemplateImages().subscribe(res => {
      this.tabs = res['data']
      this.tabs = this.tabs.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
      this.tabs = this.groupBy(this.tabs, temp => temp.category);
      console.log(this.tabs)

    })
  }
  groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }
  key(category_name) {
    this.selectedKey = category_name
  }
  new() {
    this.sectionName = ''
  }
  addTab() {
    this.newTab = [{ category: this.sectionName, image: '', language: 'ar' }];
    this.tabs.set(this.sectionName, this.newTab)
    this.activeTabIndex = this.tabs.size - 1
  }
  tabIndex(i, tabId) {
    this.tabId = tabId;
    this.activeTab = !this.activeTab;
    this.activeTabIndex = i
    console.log("sam", this.activeTab)
  }
  showPreview(event, i, tab) {
    debugger
    const file = (event.target as HTMLInputElement).files[0];
    if (file == null) {
      this.imageUploaded = false
      this.imageURL = ''
    }
    // File Preview
    let nTab = {}
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.imageURL = e.target.result;
      nTab['category'] = tab.value[0].category
      nTab['language'] = tab.value[0].language
      this.api.addTemplateImage(nTab, { 'filename': file }).subscribe(res => {
        this.toastr.success(res['message'])
        this.ngOnInit();
      })
    };
    reader.readAsDataURL(file)
    this.imageUploaded = true
    this.newTab = ''
  }
  remove(tab) {
    this.tempId = tab.SK
  }
  deleteImage(tempId) {
    this.api.deleteTemplateImage({ "SK": tempId }).subscribe(res => {
      this.toastr.success(res['message']);
      this.ngOnInit();
    })
  }
}
