<section class="news-letter-sec">
        <div class="row p-t-20">
            <div class="col-md-12 col-xs-12 col-lg-12">
                <div class="news-letter-heading mob-text-align">
                    <h1>Newsletter subscribers</h1>
                </div>
            </div>
        </div>
        <div class="row row-border" [formGroup]="filterForm">
            <div class="col-md-5 col-lg-7 col-xs-12">
                <div class="total-subscribers mob-text-align">
                    <p><span>{{totalnewsLetter}}</span> Newletters</p>
                </div>
            </div>
            <div class="col-md-3 col-lg-2 col-xs-12">
                <div class="mob-p-b-10">
                    <input type="text" (change)="applyFilter()" formControlName="filterDate" class="form-control cursor-pointer" [max]="getToday()"
          placeholder="Choose date" onfocus="(this.type='date')">
                </div>
            </div>
            <div class="col-md-3 col-lg-2 col-xs-12">
                <div class="mob-p-b-10">
                    <angular2-multiselect [data]="statusArray" [(ngModel)]="selectedStatus" formControlName="status"
                        [settings]="statusDropdownSettings" (onSelect)="applyFilter()"
                        (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
                        (onDeSelectAll)="onDeSelectAll($event)">
                    </angular2-multiselect>
                </div>
            </div>
            <div class="col-md-1 col-lg-1 col-xs-12 col-xl-1">
                <div class="reload-icon">
                  <i class="fas fa-sync-alt" (click)="clearAll()"></i>
                </div>
              </div>
        </div>

        <div class="row p-t-20">
            <div class="col-md-12 col-xs-12 col-lg-12">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Join date</th>
                                <th scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor=" let newsLetters of newsletterList  | paginate:  { itemsPerPage: 10,
                                currentPage: p,
                                totalItems: totalnewsLetter}">
                                <td>{{newsLetters.name}}</td>
                                <td>{{newsLetters.email}}</td>
                                <td>{{newsLetters.created_at | date:'dd-MMM-YYYY'}}</td>
                                <td class="active-color" *ngIf="newsLetters.subscription_status == 'Subscribed'">Subscribed</td>
                                <td class="inactive-color" *ngIf="newsLetters.subscription_status == 'Unsubscribed'">Unsubscribed</td>
                            </tr>
                        </tbody>
                    </table>
                    <hr>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 col-xs-12 col-lg-6">
                <div class="pagination mob-text-align">
                    <p>Showing {{(p-1)*10}} - {{((p-1)*10)+10}} of {{totalnewsLetter}}</p>
                </div>
            </div>
            <pagination-controls (pageChange)="p = $event"></pagination-controls>
        </div>
</section>