<div *ngIf="loader == true">
    <app-spinner></app-spinner>
</div>
<section class="ledger-details-sec">
    <div class="row p-t-20">
        <div class="col-md-10 col-xs-12 col-lg-10">
            <div class="ledger-list-heading mob-text-align">
                <h1>Ledger List</h1>
            </div>
        </div>
        <div class="col-md-2 col-xs-12 col-lg-2">
            <div class="excel-export cursor-pointer mob-text-align" (click)="exportToExcel()">
                <p>Export to excel
                    <i class="fas fa-file-excel cursor-pointer"></i>
                </p>
            </div>
        </div>
    </div>
    <div class="row p-t-20 row-border" [formGroup]="ledgerForm">
        <div class="col-md-2 col-lg-2 col-xs-12">
            <div class="total-ledger text-align">
                <p><span>{{ledgerCount}} </span>Transactions</p>
            </div>
        </div>
        <div class="col-md-4 col-lg-5 col-xs-12" >
            <div class="mob-p-b-10">
              <input type="text" class="form-control height-30" placeholder="Search by payment reference" name="search"
                formControlName="searchText" (keyup.enter)="applyFilter()"/>
            </div>
          </div>
        <div class="col-md-2 col-lg-1 col-xs-12">
        </div>
        <div class="col-md-3 col-lg-3 col-xs-12">
            <div class="p-b-10 mob-m-t-13">
                <!-- <input type="text" #queryDate (change)="filterDate(queryDate.valueAsDate)"
                        class="form-control cursor-pointer" placeholder="Choose date" onfocus="(this.type='date')"> -->
                <input type="text" placeholder="From date - To date" [maxDate]="today" formControlName="filterdate" class="form-control" bsDaterangepicker
                    (bsValueChange)="applyFilter()" [bsConfig]="dpConfig">

            </div>
        </div>
        <div class="col-md-1 col-lg-1 col-xs-12 col-xl-1">
            <div class="reload-icon">
                <i class="fas fa-sync-alt" (click)="clearAll()"></i>
            </div>
        </div>
    </div>
    <div class="row p-t-20">
        <div class="col-md-12 col-xs-12 col-lg-12">
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Date</th>
                            <th scope="col">Transaction ref</th>
                            <th scope="col">Payment ref</th>
                            <th scope="col">Payment mode</th>
                            <th scope="col">Customer name</th>
                            <th scope="col">Manufacture name</th>
                            <!-- <th scope="col">Transaction details</th> -->
                            <th scope="col">Amount</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let ledger of ledgerArray| filter:searchText | paginate:{
                            itemsPerPage: 10, currentPage: p, totalItems: ledgerCount }">
                            <td class="p-t-18">{{ledger?.SK | date:'dd-MMM-YYYY'}}</td>
                            <td class="p-t-18">{{ledger?.trans_ref}}</td>
                            <td class="p-t-18">{{ledger?.payment_ref}}</td>
                            <td class="p-t-18">{{ledger?.payment_mode}}</td>
                            <td class="p-t-18">{{ledger?.customer_name}}</td>
                            <td class="p-t-18">{{ledger?.manufacture_name}}</td>
                            <!-- <td class="p-t-18">{{ledger.order_ref}}</td> -->
                            <td class="p-t-18 float-right">{{ledger?.amount}}</td>
                            <td style="text-align: center;">
                                <div>
                                    <i class="fas fa-external-link-alt" data-toggle="modal"
                                        data-target="#deactivateModal" (click)="getOrderDetails(ledger)"></i>
                                    <!-- <button type="button" class="btn btn-link cursor-pointer" data-toggle="modal"
                                        data-target="#deactivateModal" (click)="getOrderDetails(ledger)">View
                                        order</button> -->
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <hr>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 col-lg-10 col-xs-12">
            <p class="pink-colr font-weight text-align">Total Amount</p>
        </div>
        <div class="col-md-6 col-lg-2 col-xs-12">
            <div class="align-text">
                <p class="font-weight">QAR {{total_revenue}}</p>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 col-xs-12 col-lg-6">
            <div class="pagination mob-text-align">
                <p>Showing {{(p-1)*10}} - {{((p-1)*10)+10}} of {{ledgerCount}}</p>
            </div>
        </div>
        <pagination-controls (pageChange)="p = $event"></pagination-controls>
    </div>
</section>