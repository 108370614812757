import { Component, OnInit } from '@angular/core';
import { COMPANY_NAME, PREFERENCE, STATUS } from 'src/app/config/commonConfig';
import { PrimeNGConfig } from 'primeng/api';
import { CommonService } from 'src/app/common/common.service';
import { ExcelService } from '../../common/excel.service';
@Component({
  selector: 'app-temp-order-details',
  templateUrl: './temp-order-details.component.html',
  styleUrls: ['./temp-order-details.css']
})
export class TempOrderDetailsComponent implements OnInit {

  // variable declaration
   
  constructor( ) { }

  ngOnInit(): void { 

  }
   

}
