<!-- https://stackblitz.com/edit/ckeditor4-orm88s?file=src%2Fapp%2Fapp.component.html -->
<section class="content-management-sec">
    <div class="row">
        <div class="col-md-12 mt-4">
            <div class="content-management-heading">
                <h1>Content Management</h1>
            </div>

            <ul class="nav nav-pills nav-fill" id="myTab" role="tablist">
                <li class="nav-item active">
                    <a class="nav-link active btn-arrow-right left-arrow" id="company-tab" data-toggle="tab"
                        href="#home" role="tab" aria-controls="home" aria-selected="true">About <span
                            class="mob-display-none">Us</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link btn-arrow-right" id="bank-tab" data-toggle="tab" href="#bank" role="tab"
                        aria-controls="bank" aria-selected="false">Privacy <span
                            class="mob-display-none">Policy</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link btn-arrow-right right-arrow" id="flavour-tab" data-toggle="tab" href="#contact"
                        role="tab" aria-controls="contact" aria-selected="false">Conditions of <span
                            class="mob-display-none">use</span> </a>
                </li>
            </ul>

            <div class="tab-content m-t-20" id="myTabContent">
                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="company-tab">
                    <div class="card" style="height: 100%;">
                        <div class="card-body">
                            <form [formGroup]="contentDetailsForm">
                                <div class="row mt-4">
                                    <div class="col-md-12">
                                        <!-- <form role="form" #myForm="ngForm" accept-charset="UTF-8" novalidate> -->
                                            <div class="form-group has-feedback">
                                                <ckeditor 
                                                    name="myckeditor" required [config]="ckeConfig" debounce="500"
                                                    (ngModelChange)="onChange($event)" formControlName = "mycontent">
                                                </ckeditor>
                                                <!-- <div *ngIf="myckeditor.invalid && myckeditor.touched"
                                                    class="help-block">Required field.</div> -->
                                            </div>
                                            <!-- <button [disabled]="myForm.invalid" class="btn btn-primary">Save</button> -->
                                        <!-- </form> -->
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 d-flex justify-content-end">
                                        <span class="cancelbtn" (click)="cancelAbout()">Cancel</span>
                                        <button class="btn-snape-red button-style cursor-pointer btn-pink" id="bank-tab"
                                            data-toggle="tab" href="#bank" role="tab" aria-controls="bank"
                                            aria-selected="false" (click)="addAboutUs(mycontent)">Save</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="bank" role="tabpanel" aria-labelledby="bank-tab">
                    <div class="card" style="height: 100%;">
                        <div class="card-body">
                            <form [formGroup]="privacyDetailsForm">
                                <div class="row mt-4">
                                    <div class="col-md-12">
                                        <!-- <form role="form" #myForm="ngForm" accept-charset="UTF-8" novalidate> -->
                                            <div class="form-group has-feedback">
                                                <ckeditor
                                                    name="myckeditor" required [config]="ckeConfig" debounce="500"
                                                    (ngModelChange)="onChange($event)" formControlName = "mypolicy">
                                                </ckeditor>
                                                <!-- <div *ngIf="myckeditor.invalid && myckeditor.touched"
                                                    class="help-block">Required field.</div> -->
                                            </div>
                                            <!-- <button [disabled]="myForm.invalid" class="btn btn-primary">Save</button> -->
                                            <div class="row">
                                                <div class="col-md-12 d-flex justify-content-end">
                                                    <span class="cancelbtn" (click)="cancelPolicy()">Cancel</span>
                                                    <button class="btn-snape-red button-style cursor-pointer btn-pink" id="bank-tab"
                                                        data-toggle="tab" href="#bank" role="tab" aria-controls="bank"
                                                        aria-selected="false" (click)="addPrivacyPolicy(mypolicy)">Save</button>
                                                </div>
                                            </div>
                                        <!-- </form> -->
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="flavour-tab">
                    <div class="card" style="height: 100%;">
                        <div class="card-body">
                            <form [formGroup]="documentDetailsForm">
                                <div class="row mt-4">
                                    <div class="col-md-12">
                                        <!-- <form role="form" #myForm="ngForm" accept-charset="UTF-8" novalidate> -->
                                            <div class="form-group has-feedback">
                                                <ckeditor name="myckeditor" required [config]="ckeConfig" debounce="500"
                                                    (ngModelChange)="onChange($event)" formControlName = "myconditions">
                                                </ckeditor>
                                                <!-- <div *ngIf="myckeditor.invalid && myckeditor.touched"
                                                    class="help-block">Required field.</div> -->
                                            </div>
                                            <!-- <button [disabled]="myForm.invalid" class="btn btn-primary">Save</button> -->
                                            <div class="row">
                                                <div class="col-md-12 d-flex justify-content-end">
                                                    <span class="cancelbtn" (click)="cancelCondition()">Cancel</span>
                                                    <button class="btn-snape-red button-style cursor-pointer btn-pink" id="bank-tab"
                                                        data-toggle="tab" href="#bank" role="tab" aria-controls="bank"
                                                        aria-selected="false" (click)="addConditions(myconditions)">Save</button>
                                                      
                                                </div>
                                            </div>
                                        <!-- </form> -->
                                    </div>
                                </div> 
                            </form> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>