import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { type } from 'os';
import { PrintItem } from 'projects/ngx-printer/src/lib/print-item';
import { ngxPrintMarkerPosition } from 'projects/ngx-printer/src/public_api';
import { Observable } from 'rxjs';
import { NgxPrinterService } from '../../../../projects/ngx-printer/src/lib/ngx-printer.service';
@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.css', './print.css'],
})
export class PrintComponent implements OnInit {
  currentImage
  productArray = []
  itemsToPrint = []
  print_all
  $printItems: Observable<PrintItem[]>;
  orderDetails: any;
  orderId: any;
  type:any
  extra: { order_id: any; user_email: any; };
  user_email: any;
  
  constructor(
    private printerService: NgxPrinterService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private route: ActivatedRoute
  ) {

    this.$printItems = this.printerService.$printItems;
  }

  ngOnInit(): void {
    debugger
    this.activatedRoute.queryParams.subscribe(res => {
      this.currentImage = res['cookie_image']
      this.orderId = res['order_id']
      this.user_email = res['user_email']
      this.type = res['ordertype']
      console.log(res, 'res')
    })
    this.print_all = localStorage.getItem('PrintAll')
    this.productArray = JSON.parse(localStorage.getItem('products'))
    let printArr = []
    if (this.type == 'bulkorder') {
      for (var i in this.productArray) {
        let customize = this.productArray[i]?.customize
        for (let k = 0; k < this.productArray[i]?.quantity; k++) {
          for (var j in customize) {
            printArr.push(customize[j]?.image_url)
          }
        }
      }
    } else {
      for (var i in this.productArray) {
        let customize = this.productArray[i]?.customize
        for (var j in customize) {
          printArr.push(customize[j]?.image_url)
        }
      }
    }
    
    console.log(printArr, 'printArr')
    this.itemsToPrint = printArr
  }
  orderDetailPage() {
    this.extra = {
      "order_id": this.orderId,
      "user_email": this.user_email
    }
    this.router.navigate(['/order-details'], {
      queryParams: this.extra
    });
  }
  counter(i: number) {
    return new Array(i);
  }
  printOneCookie(img) {
    debugger
    this.printerService.printDiv('singleImage');
    localStorage.removeItem('currentImage')
  }
  printAllCookie() {
    debugger
    let itemsToPrint = this.itemsToPrint
    this.printerService.printDiv('printDiv');
  }
  back() {
    this.router.navigate(['order-details'], { queryParams: { 'order_id': this.orderId, 'user_email': this.user_email } })
  }
}
