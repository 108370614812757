<div class="container-fluid">
  <section class="confirm-password-sec">
    <div class="container">
      <div class="row">
        <div class="col-md-6 logintxt">
          <!-- Forget password -->
          <div class="card">
            <div class="card-body d-flex align-items-center justify-content-center">
              <div class="login-width">
                <div class="ml-3">
                  <h5 class="snape-red">Reset Password</h5>
                  <p>Enter the verification code we just send you on your email address</p>
                </div>
                <form class="pt-2" [formGroup]="confirmPasswordForm">
                  <div class="position-relative form-group">
                    <div class="col-md-12">
                      <input formControlName="code" id="inputEmail" placeholder="Enter OTP" type="text"
                        class="form-control">
                    </div>
                  </div>
                  <div class="position-relative form-group">
                    <div class="d-flex justify-content-center col-md-12 mt-4">
                      <button type="submit" (click)="continue()" class="btn btn-snape-bak btn-block">CONTINUE</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 login-img">
          <img src="../../../assets/cover.png" />
        </div>
      </div>
    </div>
  </section>
</div>