import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from '../../common/common.service'
@Component({
  selector: 'app-confirm-password',
  templateUrl: './confirm-password.component.html',
  styleUrls: ['./confirm-password.component.css']
})
export class ConfirmPasswordComponent implements OnInit {
  
  confirmPasswordForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    public api: CommonService
  ) { }

  ngOnInit(): void {
    this.confirmPasswordForm = this.fb.group({
      code: ['', Validators.required]
    })
  }
  continue() {
    debugger
    let data = 
    { 
      "code": this.confirmPasswordForm.controls.code.value
    }
  localStorage.setItem('code',this.confirmPasswordForm.controls.code.value)
   this.api.forgotOtp(data)
   this.router.navigate(['reset-password'])
  }
}
