import { Component, OnInit, ViewChild } from '@angular/core';
// import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/common/common.service';
// import { Component, OnInit } from '@angular/core';
// import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { parse } from 'path';
// import { CommonService } from 'src/app/common/common.service';
import { COMPANY_NAME } from 'src/app/config/commonConfig';
import * as _ from 'underscore';
import { any, filter } from 'underscore';
import { formatWithOptions } from 'util';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  // editor
  faq_edit;
  faqArray;
  faq_details;
  faq_add = false;
  sk: any
  faqDetailsForm: FormGroup;
  name = 'ng2-ckeditor';
  ckeConfig: any;
  mycontent: string;
  log: string = '';
  submitted = false;
  loader = false

  @ViewChild("myckeditor") ckeditor: any;
  // editor
  constructor(private fb: FormBuilder,
    private router: Router,
    public api: CommonService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    // editor
    this.ckeConfig = {
      removePlugins: 'undo'
    };
    this.getfaqform()
    this.getFaq()
    // this.addFaq()

  }
  getfaqform() {
    this.faqDetailsForm = this.fb.group({
      question: ['', Validators.required],
      answer: ['', Validators.required]
    })
  }
  faqFormSwitch() {
    this.faq_add = true;
  }

  cancelFaqAdd() {
    this.faq_add = false;
    this.faqDetailsForm.reset()
  }

  // editor
  onEditorChange(event) {
  }

  onChange(event: any): void {
  }
  addFaq() {
    if (this.faqDetailsForm.valid) {
      let data = {
        "category": "order",
        "question": this.faqDetailsForm.controls.question.value,
        "answer": this.faqDetailsForm.controls.answer.value,
        "order_key": "123456"
      }
      this.api.addFaq(data).subscribe(re => {
        console.log(re)
        if (re['statusCode'] == 200) {
          this.faq_add = false;
          this.faq_edit = false;
        }
        this.faqDetailsForm.reset()
        this.ngOnInit()
      })
    }
    else {
      this.submitted = true;
    }
  }
  getFaq() {
    this.api.getFaq().subscribe(re => {
      this.faqArray = re['data']
      console.log(this.faqArray)
    })

  }
  getSk(obj: any) {
    this.sk = obj
  }
  deleteFaq() {
    let data = {
      "SK": this.sk
    }
    this.api.deleteFaq(data).subscribe(re => {
      // this.getFaq()
      // console.log(re)
      this.ngOnInit()
    })

  }

  editMode(obj: any) {
    this.faq_details = obj;
    this.faq_add = true;
    this.faq_edit = true;
    this.faqDetailsForm = this.fb.group({
      question: [obj.question],
      answer: [obj.answer]
    })
  }

  editFaq(obj: any) {
    if (this.faqDetailsForm.valid) {
      this.faq_edit = true;
      let data = {
        "PK": this.faq_details['PK'],
        "question": this.faqDetailsForm.controls.question.value,
        "answer": this.faqDetailsForm.controls.answer.value,
        "SK": this.faq_details['SK']
      }
      this.api.editFaq(data).subscribe(re => {
        console.log(re)
        if (re['statusCode'] == 200) {
          this.faq_add = false;
          this.faq_edit = false;
        }
        this.faqDetailsForm.reset()
        this.ngOnInit()
      })
    }
    else {
      this.submitted = true;
    }
  }
}
