<!-- Button trigger modal -->
<button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter">
  Launch demo modal
</button>

<!-- Modal -->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content"> 
      <div class="modal-header">
        <h5 class="modal-title font-weight" id="exampleModalLongTitle">Flavour : <span class="flavour-span">Double Chocolate</span></h5>
         
        <p>Update Cookie</p>

      </div>
      <div class="modal-body m-t-minus25">
        <div class="row">
          <div class="col-md-12 col-xs-12 col-lg-12">
            <div class="cookie-img">
              <div class="align-center">
                <img src="../../../assets/smile.svg" alt="">
              </div>
            </div>
            <div class="align-center">
              <h3 class="font-weight p-t-20">Print Cookie</h3>
              <p class="m-t-minus-15">Do you want to print this image</p>
            </div>
            <div class="align-center">
              <button type="button" class="btn btn-grey" data-dismiss="modal">Cancel</button>
              <button type="button" class="btn btn-pink">Print Cookie</button> 
            </div>
          </div> 
        </div>
      </div> 
    </div>
  </div>
</div>
<!-- Button trigger modal finished-->
