import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../common/common.service';
import * as _ from 'underscore';
import { ToastrService } from 'ngx-toastr';

import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-create-new-coupon',
  templateUrl: './create-new-coupon.component.html',
  styleUrls: ['./create-new-coupon.component.css']
})
export class CreateNewCouponComponent implements OnInit {

  statusArray = [{
    id: 1, name: 'Percentage discount'
  }, { id: 2, name: 'Fixed product discount' }];
  limitArray = [{
    id: 1, name: 'One time use'
  }, { id: 2, name: 'Fixed use' }, { id: 3, name: 'Multiple use' }];
  statusDropdownSettings;
  limitDropdownSettings;
  selectedStatus = [];
  selectedLimit = [];
  loader = false
  id: any
  // date range picker start
  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();    // date range picker   
  placeholder = 'From date - To date'
  maxDate: Date;
  bsValue: Date[]
  bsDateValue: Date[]
  dateActive = ''
  submitted = false
  editCheck = false
  showCount=false
  oneTimeUse = false
  multiTimeUse = false
  status = ''
  usage:any
  public today = new Date();

  // date range picker end

  couponForm: FormGroup;


  constructor(
    private fb: FormBuilder,
    private router: Router,
    public api: CommonService,
    private cd: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService
  ) {
    this.couponForm = this.fb.group({
      couponCode: ['', [Validators.required]],
      description: [''],
      type: ['', [Validators.required]],
      couponValue: ['', [Validators.required]],
      validity: ['', [Validators.required]],
      limit: ['', [Validators.required]],
      count: ['', [Validators.required]],
      userMail: ['',[Validators.required]]
    })
    this.dpConfig.isAnimated = true; // date range picker
    this.dpConfig.showWeekNumbers = false; // date range picker
  }

  ngOnInit(): void {
    debugger
    let today = new Date();
    this.couponForm.controls.couponCode.enable()
    let newDate:any
    newDate = this.formatDate(today)
    console.log(today)
    this.activatedRoute.queryParamMap.subscribe((params)=>{
      console.log(params)
      this.id = params.get('id')
      if (this.id) {
        this.editCheck = true
        this.couponForm.controls.couponCode.disable()
        let data:any={"id":this.id}
        this.api.getCoupon(data).subscribe((resp)=>{
          console.log(resp)
          if(resp['status']==true){
            let data:any = resp['couponcode'][0]
            this.couponForm.patchValue({
              couponCode:data.couponCode,
              description:data.details,
              type:data.type,
              couponValue:data.typeValue,
              limit:data.promotionType,
              count:data.usageCount,
              userMail:data.userEmail
            })
            this.usage = parseInt(data.couponUsage)
            this.selectedStatus = [{name:data.type}]
            this.selectedLimit = [{name:data.promotionType}]
            if(data.promotionType == 'One time use'){
              this.oneTimeUse = true
              this.multiTimeUse = false
            }else if(data.promotionType == 'Fixed use'){
              this.oneTimeUse = false
              this.multiTimeUse = true
            }else{
              this.oneTimeUse = false
              this.multiTimeUse = true
            }
            let durationDate = new Date(data.durationFrom)
            let durationFrom = durationDate
            let expiryDate = new Date(data.expiry)
            let expiry = expiryDate
            let dateArray:any=[]
            dateArray[0]=durationFrom
            dateArray[1]=expiry
            this.couponForm.patchValue({
              validity:dateArray
            })
            // this.couponFormControl.validity.value[0] = durationFrom
            // this.couponFormControl.validity.value[1] = expiry
            if(data.promotionType == 'Fixed use'){
              this.showCount = true
            }
            if(data.expiry < newDate){
              this.status = 'Inactive'
            }else{
              this.status = 'Active'
            }
          }
        })
      }

    })
    
    this.statusDropdownSettings = {
      singleSelection: true,
      primaryKey: 'id',
      labelKey: 'name',
      text: "Select",
      clearAll: false,
      badgeShowLimit: 1,
      classes: "myclass custom-class",
      position: 'bottom',
      autoPosition: false
    };
    this.limitDropdownSettings = {
      singleSelection: true,
      primaryKey: 'id',
      labelKey: 'name',
      text: "Select",
      clearAll: false,
      badgeShowLimit: 1,
      classes: "myclass custom-class",
      position: 'bottom',
      autoPosition: false
    };
  }
  get couponFormControl() {
    return this.couponForm.controls
  }
  cancel() {
    if (!this.editCheck) {
      this.couponForm.reset()
    } else {
      this.router.navigate(['coupons'])
    }

  }
  onSelectAll(event) {

  }
  onDeSelectAll(event) {

  }
  onItemDeSelect(event) {

  }
  onItemSelect(event) {

  }
  onlimitDeSelect(event) {
    this.selectedLimit = []
    this.couponForm.patchValue({
      limit: ''
    })
    this.showCount = false
  }
  onlimitSelect(event) {
    debugger
    this.couponForm.controls['userMail'].clearValidators()
    this.couponForm.controls['userMail'].updateValueAndValidity();
    if (this.selectedLimit[0].name == 'Fixed use') {
      this.showCount = true
      this.multiTimeUse = true
      this.oneTimeUse = false
      this.couponForm.patchValue({
        count: ''
      })
      this.usage = 0
    } else if (this.selectedLimit[0].name == 'One time use') {
      this.showCount = false
      this.oneTimeUse = true
      this.multiTimeUse = false
      this.couponForm.controls['userMail'].setValidators([Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$')]);
      this.couponForm.controls['userMail'].updateValueAndValidity();
      this.couponForm.patchValue({
        count: 1
      })
      this.usage = 0
    } else {
      this.showCount = false
      this.multiTimeUse = true
      this.oneTimeUse = false
      this.couponForm.patchValue({
        count: 9999
      })
      this.usage = 0
    }
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
  dateRangeCreated($event) {

  }
  generateCoupon() {
    this.api.generateCoupon().subscribe((resp) => {
      console.log(resp)
      this.couponForm.patchValue({
        couponCode: resp['couponcode']
      })
      this.couponForm.controls.couponCode.disable()
    })
  }
  publish() {
    debugger
    if (this.couponForm.valid) {
      this.loader = true
      if (!this.editCheck) {
        console.log(this.couponForm.controls)
        let data :any= {"couponCode":this.couponFormControl.couponCode.value,
        "details":this.couponFormControl.description.value,
        "type":this.couponFormControl.type.value[0].name,
        "typeValue":this.couponFormControl.couponValue.value,
        "durationFrom":this.formatDate(this.couponFormControl.validity.value[0]),
        "expiry":this.formatDate(this.couponFormControl.validity.value[1]),
         "usageCount":this.couponFormControl.count.value,
         "userEmail":this.couponFormControl.userMail.value,
         "couponUsage":0,
         "promotionType":this.selectedLimit[0].name,
         "status":"Active"}
         this.api.addCoupon(data).subscribe((result)=>{
           console.log(result)
           this.loader = false
           this.toastr.success('Coupon added successfully')
           this.router.navigate(['coupons'])
         },(error)=>{
           console.log(error)
           this.loader = false
         })
      }else{
        let data :any= {"id":this.id,
          "couponCode":this.couponFormControl.couponCode.value,
        "details":this.couponFormControl.description.value,
        "type":this.couponFormControl.type.value[0].name,
        "typeValue":this.couponFormControl.couponValue.value,
        "durationFrom":this.formatDate(this.couponFormControl.validity.value[0]),
        "expiry":this.formatDate(this.couponFormControl.validity.value[1]),
         "usageCount":this.couponFormControl.count.value,
         "couponUsage":this.usage,
         "userEmail":this.couponFormControl.userMail.value,
         "promotionType":this.selectedLimit[0].name,
         "status":this.status}
         this.api.updateCoupon(data).subscribe((result)=>{
          this.loader = false
          this.toastr.success('Coupon updated successfully')
          this.router.navigate(['coupons'])
        })
      }

    } else {
      this.toastr.error('Please fill all required fields')
    }
  }
}

