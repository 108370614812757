<div *ngIf="loader == true">
    <app-spinner></app-spinner>
  </div>
<section class="create-product-sec">
    <div class="row">
        <div class="col-md-12 mt-4">
            <div class="product-list-heading mob-text-align">
                <h1 *ngIf="!productId">Create Product</h1>
                <h1 *ngIf="productId">Edit Product</h1>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 mt-4">
            <div class="vendor-list-breadcrumb">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a routerLink="/product-list"><i class="fab fa-product-hunt"></i>
                                Product List</a>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page" *ngIf="!productId">Create new product
                        </li>
                        <li class="breadcrumb-item active" aria-current="page" *ngIf="productId">Edit product</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
    <form [formGroup]="productForm">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mob-m-b-20 ">
                                    <div class="form-group has-float-label mobile-m-t-20">
                                        <input class="form-control" type="text" formControlName="name"
                                            placeholder="Product name" />
                                        <label>Product name</label>
                                        <div class="text-danger"
                                            *ngIf="(productForm.controls.name.touched || submitted) && productForm.controls.name.errors?.required">
                                            Product name is required
                                        </div>
                                    </div>
                                    <!-- <input class="form-control" formControlName="name" type="text" placeholder="Product name" /> -->
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mob-m-b-20 ">
                                    <!-- <input class="form-control" formControlName="no_of_cookies" type="text"
                                            placeholder="No. of cookies" /> -->
                                    <div class="form-group has-float-label mobile-m-t-20">
                                        <input class="form-control" type="number" min="1" max="6" formControlName="no_of_cookies"
                                            placeholder="No. of cookies" />
                                        <label>No. of cookies</label>
                                        <div class="text-danger"
                                            *ngIf="(productForm.controls.no_of_cookies.touched || submitted) && productForm.controls.no_of_cookies.errors?.required">
                                            No. of cookies is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mob-m-b-20 ">
                                    <!-- <input class="form-control" formControlName="price" type="text" placeholder="Price" /> -->
                                    <div class="form-group has-float-label mobile-m-t-20">
                                        <input class="form-control" type="text" formControlName="price"
                                            placeholder="Price" />
                                        <label>Price</label>
                                        <div class="text-danger"
                                            *ngIf="(productForm.controls.price.touched || submitted) && productForm.controls.price.errors?.required">
                                            Price is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mob-m-b-20 ">
                                    <div class="form-group has-float-label mobile-m-t-20">
                                        <input class="form-control" type="text" formControlName="order_qnty"
                                            placeholder="Minimum order qty" />
                                        <label>Minimum order qty</label>
                                        <div class="text-danger"
                                            *ngIf="(productForm.controls.order_qnty.touched || submitted) && productForm.controls.order_qnty.errors?.required">
                                            Order quantity is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="mob-m-b-20 ">
                                    <div class="form-group has-float-label">
                                        <textarea class="form-control" type="text" rows="4"
                                            formControlName="description" placeholder="Description"></textarea>
                                        <label>Description</label>
                                        <div class="text-danger"
                                            *ngIf="(productForm.controls.description.touched || submitted) && productForm.controls.description.errors?.required">
                                            Description is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="box-color-txt">
                    <p>Pack Color</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-4 height-275">
                        <div class="row row-border mr-2" *ngFor="let colors of colorArray; let i=index">
                            <div class="col-md-2" *ngIf="!productId">
                                <div class="box" [style.background-color]="colors.code" id="colors.SK"
                                    (click)="changeSelection(colors)"
                                    [class.highlighted]="highlightedBatch.indexOf(colors) !== -1"></div>
                            </div>
                            <div class="col-md-2" *ngIf="productId">
                                <div class="box" [style.background-color]="colors.code" id="colors.code"
                                    (click)="changeSelection(colors)"
                                    [class.highlighted]="highlightedBatch.indexOf(colors) !== -1"></div>
                            </div>
                            <div class="col-md-10">
                                <input id="{{colors.SK}}" type="file" accept="image/*"
                                    (change)="showPreview($event, colors.SK, i)" />
                                <div class="imagePreview" *ngIf="colors">
                                    <div *ngIf="!colors.image_url">
                                        <img *ngIf="!colors.image" alt="" id="file-image {{colors.SK}}"
                                            [value]="colors.SK" name="{{colors.SK}}">

                                        <img *ngIf="colors.image" alt="" id="file-image {{colors.SK}}"
                                            [value]="colors.SK" name="{{colors.SK}}" [src]="colors.image"
                                            class="color-image">
                                    </div>
                                    <div *ngIf="colors.image_url && colors.image_name">
                                        <img alt="" id="file-image {{colors.code}}" [value]="colors.code"
                                            name="{{colors.code}}" [src]="colors.image" class="color-image">
                                    </div>
                                    <div *ngIf="colors.image_url && !colors.image_name">
                                        <img alt="" id="file-image {{colors.code}}" [value]="colors.code"
                                            name="{{colors.code}}" [src]="colors.image_url" class="color-image">
                                    </div>
                                </div>
                                <div id="start">
                                    <label for="{{colors.SK}}" class="label1">
                                        <div class="upload-icon"><i class="fas fa-upload"
                                                aria-hidden="true"></i>
                                        </div>
                                        <div class="upload-doc" *ngIf="!colors.image">Upload Image</div>
                                    </label>
                                </div>
                                <!-- </form> -->
                                <!-- Upload  ends-->
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="row upload-row-border">
                            <div class="col-md-5">
                                <div>
                                    <!-- Upload  start-->
                                    <input type="file" accept="image/*" formControlName="product_image"
                                        (change)="showProdPreview($event)" id="prod_file" />
                                    <div class="text-danger"
                                        *ngIf="(productForm.controls.product_image.touched || submitted) && productForm.controls.product_image.errors?.required">
                                        Product image is required
                                    </div>
                                    <label for="prod_file" id="file-drag" class="label2">

                                        <div class="imagePreview">
                                            <img id="file-image" alt="">
                                        </div>
                                        <div id="start2">
                                            <div class="upload-image">
                                                <img src="../../../assets/Icon.png">
                                            </div>
                                            <div class="image-upload">Image Upload</div>
                                            <div class="image-upload">Drag and drop a file here or click</div>
                                        </div>
                                    </label>
                                    <!-- Upload  ends-->
                                </div>
                            </div>
                            <div class="col-md-7">
                                <div class="product-image" *ngIf="prod_imageURL">
                                    <img [src]="prod_imageURL" height="100">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="can-save-btns">
                            <button type="button" class="btn btn-cancel" data-dismiss="modal"
                                (click)="cancel()">Cancel</button>
                            <!-- <button type="button" class="btn btn-save" (click)="alert()" *ngIf="!colorSelected"
                                >Save</button> -->
                                <button type="button" *ngIf="!processing" class="btn btn-save" (click)="addProduct()" 
                                >Save</button>
                                <button *ngIf="processing" type="button" class="btn btn-save" 
                                >Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</section>
<script>
    function readURL(input) {
        if (input.files && input.files[0]) {

            var reader = new FileReader();

            reader.onload = function (e) {
                $('.image-upload-wrap').hide();

                $('.file-upload-image').attr('src', e.target.result);
                $('.file-upload-content').show();

                $('.image-title').html(input.files[0].name);
            };

            reader.readAsDataURL(input.files[0]);

        } else {
            removeUpload();
        }
    }

    function removeUpload() {
        $('.file-upload-input').replaceWith($('.file-upload-input').clone());
        $('.file-upload-content').hide();
        $('.image-upload-wrap').show();
    }
    $('.image-upload-wrap').bind('dragover', function () {
        $('.image-upload-wrap').addClass('image-dropping');
    });
    $('.image-upload-wrap').bind('dragleave', function () {
        $('.image-upload-wrap').removeClass('image-dropping');
    });

</script>