import { Component, NgZone } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'snape-cookies-front';
  showSidemenu = false
  constructor(
    private zone: NgZone,
    private router: Router,
    private activatedRoute: ActivatedRoute) {

    // on route change to '/login', set the variable showHead to false

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        if (event.url === "/" || event.url == "/login" || event.url === "/forgotpassword"
          || event.url === "/confirm-password" || event.url === "/reset-password" || 
          event.url === "/verify-email" || event.url === "/how-we-work") {
            this.showSidemenu = false;
            console.log(this.showSidemenu)
        } 
        else{
          this.showSidemenu = true
          console.log(this.showSidemenu)
        }
      }
    });
  }
}
