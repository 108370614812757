<div *ngIf="loader == true">
    <app-spinner></app-spinner>
  </div>
<section class="order-list-sec">
    <div class="row p-t-20">
        <div class="col-md-12 col-xs-12 col-lg-12">
            <div class="flavour-list-heading">
                <h1>Manage Flavour</h1>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <h3 class="snape-red ml-1 pt-2 sub-heading">Cookie's Flavours</h3>
        </div>
        <div class="col-md-8 d-flex justify-content-end">
            <button class="flavr-btn button-style" data-toggle="modal" data-target="#newFlavour" (click)="addFlav()">Add
                new flavour</button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 mt-4">
            <div class="row text-center">
                <div class="col-sm-6 col-md-2" *ngFor="let flavour of flavoursArray">
                    <div class="flavr-img">
                        <img src="{{flavour.image}}" />
                        <i class="far fa-edit edit" aria-hidden="true" data-toggle="modal" data-target="#newFlavour"
                            (click)="editFlavour(flavour)"></i>
                        <i class="far fa-trash-alt delete ml-3" aria-hidden="true" data-toggle="modal"
                            data-target="#delete" (click)="removeFlavour(flavour.SK)"></i>
                    </div>
                    <p class="text-center mt-2 flavour-name">{{flavour.name}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="row p-t-20">
        <div class="col-md-4">
            <h3 class="snape-red ml-1 pt-2 sub-heading">Box Color</h3>
        </div>
        <div class="col-md-8 d-flex justify-content-end mob-m-b-15">
            <button class="flavr-btn button-style" data-toggle="modal" data-target="#boxColour">Add
                box color</button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-1" *ngFor="let colors of colorArray">
            <div class="boxColor" [style.background-color]="colors.code">

                <div class="float-right m-r-5">
                    <i class="far fa-edit color-box-edit cursor-pointer" aria-hidden="true" data-toggle="modal"
                        data-target="#boxColour" (click)="editColour(colors)"></i>
                    <i class="far fa-trash-alt color-box-delete ml-1 cursor-pointer" aria-hidden="true"
                        data-toggle="modal" data-target="#delete" (click)="removeColor(colors.SK)"></i>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="newFlavour" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
            <div class="modal-content modal-border">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <h4 class="modal-title" id="exampleModalLabel" *ngIf="!flavoursId">New Flavour
                            </h4>
                            <h4 class="modal-title" id="exampleModalLabel" *ngIf="flavoursId">Update Flavour
                            </h4>
                        </div>
                        <form [formGroup]="flavourForm" class="form-width">
                            <div class="col-md-12 mt-2 mb-2">
                                <div class="form-group has-float-label mobile-m-t-20">
                                    <input class="form-control" type="text" formControlName="name"
                                        placeholder="Enter cookie flavour" />
                                    <label>Enter cookie flavour</label>
                                    <span class="text-danger"
                                        *ngIf="(flavourForm.controls.name.touched || submitted) && flavourForm.controls.name.errors?.required">
                                        Flavour name is required
                                    </span>
                                    </div>
                                </div>
                                <div class="col-md-12 mt-2 mb-2">

                                    <div class="form-group has-float-label mobile-m-t-20">
                                        <input class="form-control" type="text" formControlName="extra_price"
                                            placeholder="Enter flavour price" />
                                        <label>Enter flavour price</label>
                                        <span class="text-danger"
                                            *ngIf="(flavourForm.controls.extra_price.touched || submitted) && flavourForm.controls.extra_price.errors?.required">
                                            Extra price is required
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-12 mt-2 mb-2">
                                    <div>
                                        <!-- Upload  start-->
                                        <input id="file-upload" type="file" name="fileUpload" accept="image/*"
                                            formControlName="fileSource" (change)="showPreview($event)" />
                                        <label for="file-upload" id="file-drag" class="label2">
                                            <span class="text-danger"
                                                *ngIf="(flavourForm.controls.fileSource.touched || submitted) && flavourForm.controls.fileSource.errors?.required">
                                                Image is required
                                            </span>
                                            <div class="imagePreview">
                                                <img id="file-image" alt="">
                                            </div>
                                            <div id="start">
                                                <span>
                                                    <div class="upload-icon"><i class="fas fa-upload"
                                                            aria-hidden="true"></i>
                                                    </div>
                                                </span>
                                                <div class="upload-doc">
                                                    <span *ngIf="!imageURL">Upload cookie image</span>
                                                    <span *ngIf="imageURL">
                                                        <img class="flavor-img" alt="" [src]="imageURL">
                                                    </span>

                                                </div>
                                            </div>
                                        </label>
                                        <!-- Upload  ends-->
                                    </div>
                                </div>
                        </form>
                        <div class="col-md-12">
                            <div class="float-right">
                                <button type="button" class="btn cancel-btn" data-dismiss="modal"
                                    (click)="cancel()">Cancel</button>
                                <button type="button" class="btn submit-btn" data-dismiss="modal" (click)="addFlavour()"
                                    *ngIf="!flavour_id" [disabled]="flavourForm.invalid">Add
                                    Flavour</button>
                                <button type="button" class="btn submit-btn" data-dismiss="modal"
                                    (click)="updateFlavour()" *ngIf="flavour_id"
                                    [disabled]="flavourForm.invalid">Update
                                    Flavour</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="delete" tabindex="-1" role="dialog" aria-labelledby="deleteModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="info-icon-img">
                                <img src="../../../assets/exclamation.png">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div *ngIf="flavourId" class="confirm-txt">
                                <h6 class="mb-3">Are you sure?</h6>
                                <p class="mb-3"> Do you want to delete this flavour?</p>
                            </div>
                            <div *ngIf="ColorId" class="confirm-txt">
                                <h6 class="mb-3">Are you sure?</h6>
                                <p class="mb-3">Do you want to delete this colour?</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 mfooterBtn d-flex justify-content-end">
                            <button type="button" class="btn color-cancel-btn mr-2" data-dismiss="modal"
                                (click)="cancel()">Cancel</button>
                            <button type="button" class="btn submit-btn ml-2" data-dismiss="modal" *ngIf="flavourId"
                                (click)="deleteFlavour(flavourId)">Delete</button>
                            <button type="button" class="btn submit-btn ml-2" data-dismiss="modal" *ngIf="ColorId"
                                (click)="deleteColor(ColorId)">Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="boxColour" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <h4 class="modal-title snape-red mb-1" id="exampleModalLabel" *ngIf="!color_id">New box
                                color
                            </h4>
                            <h4 class="modal-title snape-red mb-1" id="exampleModalLabel" *ngIf="color_id">Update box
                                color
                            </h4>
                        </div>
                        <form [formGroup]="boxColorForm" class="form-width">
                            <div class="col-md-12 mt-2 mb-3">
                                <div class="form-group has-float-label mobile-m-t-20">
                                    <input class="form-control" type="text" formControlName="name"
                                        placeholder="Color name" />
                                    <label>Color name</label>
                                    <div class="text-danger"
                                        *ngIf="(boxColorForm.controls.name.touched || submitted) && boxColorForm.controls.name.errors?.required">
                                        Colour name is required
                                </div>
                                </div>
                            </div>
                            <div class="col-md-12 mt-2 mb-2">
                                <div class="form-group has-float-label mobile-m-t-20">
                                    <input class="form-control" type="color" formControlName="code"
                                        placeholder="Color code" />
                                    <label>Color code</label>
                                    <div class="text-danger"
                                    *ngIf="(boxColorForm.controls.code.touched || submitted) && boxColorForm.controls.code.errors?.required">
                                    Colour code is required
                            </div>
                                </div>
                            </div>
                        </form>
                        <div class="col-md-12">
                            <div class="float-right">
                                <button type="button" class="btn color-cancel-btn" data-dismiss="modal"
                                    (click)="cancel()">Cancel</button>
                                <button type="button" class="btn submit-btn" data-dismiss="modal" (click)="addColor()"
                                    *ngIf="!color_id"
                                    [disabled]="boxColorForm.invalid">Apply</button>
                                <button type="button" class="btn submit-btn" data-dismiss="modal"
                                    (click)="updateColor(color_id)" *ngIf="color_id"
                                    [disabled]="boxColorForm.invalid">Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>