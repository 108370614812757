<section class="message-center-sec">
    <div class="row p-t-20">
        <div class="col-md-12 col-xs-12 col-lg-12">
            <div class="message-center-heading">
                <h1>Message Center</h1>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 mt-4">
            <div class="vendor-list-breadcrumb">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item cursor-pointer" routerLink="/order/list"
                            routerLinkActive="router-link-active">
                            <a><i class="fas fa-shopping-cart"></i> Order list</a>
                        </li>
                        <li class="breadcrumb-item cursor-pointer" (click)="orderDetailPage()"
                            routerLinkActive="router-link-active">Order id : {{orderDetails?.order_id}}</li>
                        <li class="breadcrumb-item active" aria-current="page">Message</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
    <!-- tab view -->

    <!-- customer review -->
    <!-- ref : https://www.codeply.com/go/bp/phf8mnMtpe -->
    <form [formGroup]="messageForm">
        <div class="row panel panel-default m-t-30">
            <div class="col-md-6 col-xs-12 col-lg-6">
                <div class="tree">
                    <ul *ngFor="let message of allMessages">
                        <li *ngIf="message.to == 'cohani@tafmail.com'"> <span><i class="fas fa-user-cog"></i>
                            </span> Customer
                            <span class="date-span">{{message.datetime | date:'h:mm a'}}
                                <span class="span-bold">{{message.datetime | date:'dd MMM YYYY'}}
                                </span></span>
                            <div class="m-l-42">
                                <p>
                                    {{message.message}}
                                </p>
                                <div *ngIf="message.image">
                                    <img width="100%" class="uploaded-img" src="{{message.image}}"
                                        height="75" width="75" />
                                </div>
                                <!-- <button type="button" class="btn btn-link">Reply</button> -->
                            </div>
                        </li>
                        <!-- </ul>
                    <ul> -->
                        <li *ngIf="message.from == 'cohani@tafmail.com'"> <span><i class="fas fa-user"></i>
                            </span> Admin <span class="date-span2">{{message.datetime
                                | date:'h:mm a'}}
                                <span class="span-bold">{{message.datetime | date:'dd MMM YYYY'}}</span></span>
                            <div class="m-l-42">
                                <p>
                                    {{message.message}}
                                </p>
                                <div *ngIf="message.image">
                                    <img width="100%" class="uploaded-img" src="{{message.image}}"
                                        height="75" width="75" />
                                </div>
                            </div>
                        </li>
                    </ul>
                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="6"
                        placeholder="Add your Message..." formControlName="customer_message"></textarea>
                        <button class="btn mCenterAttach" (click)="openFile()">
                            <input type="file" formControlName="fileSource" id="files" name="files[]"
                                multiple style="display:none" (change)="handle($event)">Upload
                        </button>
                        <div class="submit-btn">
                            <button type="button" class="btn btn-light" (click)="cancelCustomer()">Cancel</button>
                            <button type="button" class="btn btn-royal-pink" (click)="customerSend()">Send</button>
                            <div class="d-flex">
                                <div *ngFor='let url of urls'>
                                    <div class="box" *ngIf="urls" >
                                        <img [src]="url" class="uploading-img" height="75">
                                        <br />
                                        <!-- <div class="cover">
                                            <div class="social"><a><i class="far fa-trash-alt"></i></a></div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>  
                        </div>
                </div>
                <!-- <div>
                    <table class="table table-borderless">
                      <thead>
                        <tr *ngIf="urlName">
                          <td scope="col"><i class="far fa-file-image"></i> <span
                              class="font-size-14">{{urlName}}</span></td> 
                          <td><i class="fas fa-times"></i></td>
                        </tr>
                      </thead>
                      <tbody> 
                        <div *ngIf="vendorDetails?.doc_data">
                          <tr *ngFor="let doc of vendorDetails?.doc_data">
                            <td scope="col"><i class="far fa-file-pdf"></i> <span class="font-size-14">{{doc}} 
                              </span></td>
                            <td scope="col" class="font-size-14">Agreement with company</td>
                            <td scope="col" class="font-size-14">Exp date : 05-Feb-2021</td>
                            <td><i class="fas fa-times"></i></td>
                          </tr>
                        </div>
                        <tr>
                        </tr>
                      </tbody>
                    </table>
                  </div> -->
            </div>
            <div class="col-md-6 col-xs-12 col-lg-6">
                <div class="tree">
                    <ul *ngFor="let message of allVendorChats">
                        <li *ngIf="message.to == 'cohani@tafmail.com'">
                            <span><i class="fas fa-user-cog"></i> </span> Company <span
                                class="date-span">{{message.datetime |
                                date:'h:mm a'}}
                                <span class="span-bold">{{message.datetime | date:'dd MMM YYYY'}}</span></span>
                            <div class="m-l-42">
                                <p>
                                    {{message.message}}
                                </p>
                                <div *ngIf="message.image">
                                    <img width="100%" class="uploaded-img" src="{{message.image}}"
                                        height="75" width="75" />
                                </div>
                                <!-- <button type="button" class="btn btn-link">Reply</button> -->
                            </div>
                        </li>
                        <li *ngIf="message.from == 'cohani@tafmail.com'">
                            <span><i class="fas fa-user"></i> </span> Admin <span class="date-span2">{{message.datetime
                                |
                                date:'h:mm a'}}
                                <span class="span-bold">{{message.datetime | date:'dd MMM YYYY'}}</span></span>
                            <div class="m-l-42">
                                <p>
                                    {{message.message}}
                                </p>
                                <div *ngIf="message.image">
                                    <img width="100%" class="uploaded-img" src="{{message.image}}"
                                        height="75" width="75" />
                                </div>
                            </div>
                    </ul>
                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="6"
                        placeholder="Add your Message..." formControlName="company_message"></textarea>
                        <button class="btn mCenterAttach" (click)="openFile2()">
                            <input type="file" formControlName="fileSource2" id="files2" name="files2[]"
                                multiple style="display:none" (change)="handle2($event)">Upload
                        </button>
                        <div class="submit-btn">
                                                      <button type="button" class="btn btn-light" (click)="cancelVendor()">Cancel</button>
                            <button type="button" class="btn btn-royal-pink" (click)="companySend()"
                            [disabled]="messageForm.controls.company_message.invalid">Send</button>
                            <div class="d-flex">
                                <div *ngFor='let url of urls2'>
                                    <div class="box" *ngIf="urls2" >
                                        <img [src]="url" class="uploading-img" height="75">
                                        <br />
                                        <!-- <div class="cover">
                                            <div class="social"><a><i class="far fa-trash-alt"></i></a></div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>  
                        </div>
                </div>
            </div>
        </div>
    </form>
</section>