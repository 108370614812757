import { Component, OnInit } from '@angular/core';
import { COMPANY_NAME, PREFERENCE, STATUS } from 'src/app/config/commonConfig';

import { CommonService } from 'src/app/common/common.service';
import { ExcelService } from '../../common/excel.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { OrderServiceService } from '../../common/order-service.service'
import * as _ from 'underscore';
import { Observable } from 'rxjs';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { formatDate, Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as uuid from 'uuid';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-temp-order-list',
  templateUrl: './temp-order-list.component.html',
  styleUrls: ['./temp-order-list.component.scss']
})
export class TempOrderListComponent implements OnInit {

  // variable declaration
  companyDropdownSettings;
  preferenceDropdownSettings;
  statusDropdownSettings;
  selectedCompany = [];
  selectedPreference = [];
  selectedStatus = [];
  selectedProduct: any = []
  loader = false
  min_qty: any

  companyArray = COMPANY_NAME;
  preferenceArray = PREFERENCE;
  statusArray = STATUS;
  orderTotal: number
  orderList
  config: any;
  p: number = 1;
  total: number = 50
  startPage: number = 1;
  lastPage: number = 5;
  itemsPerPage = 5;
  totalItems: any;
  pageOfItems: Array<any>;
  order_id: any;
  extra: any = [];
  orderData = []
  orderListCopy = []
  searchText;
  searchDate
  status = ""
  productList: any = []
  colorList: any = []
  cartList: any = []
  quantity: any
  selectedColor: any
  navigationExtras: NavigationExtras;
  ledgerForm: FormGroup
  filterForm:FormGroup
  today: Date
  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig(); // date range picker

  constructor(
    private api: CommonService,
    private excelService: ExcelService,
    private router: Router,
    private orderService: OrderServiceService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) {
    this.dpConfig.rangeInputFormat = 'DD/MM/YYYY'; // date range picker
    this.dpConfig.isAnimated = true; // date range picker
    this.dpConfig.showWeekNumbers = false; // date range picker
    this.ledgerForm = this.fb.group({
      searchText: [''],
      product: [''],
      color: ['']
    })
    this.filterForm = this.fb.group({
      searchText: [''],
      orderDate: [''],
      preference: [''],
      status: ['']
    })
  }

  ngOnInit(): void {
    debugger
    this.loader = true
    this.today = new Date()
    this.activatedRoute.queryParamMap.subscribe(result => {
      console.log(result['params']['status'])
      if (result['params']['status'] != null) {
        let filterStatus = result['params']['status']
        this.status = filterStatus
        let fromDate = localStorage.getItem('fromDate')
        let endDate = localStorage.getItem('endDate')
        let name = localStorage.getItem('filterName')
        console.log('name', name)
        console.log('status', filterStatus)
        console.log('fromDate', fromDate)
        if (fromDate != null) {
          this.getDashboardData(fromDate, endDate)
        } else {
          this.getByName(name)

        }
      } else {
        this.getAllOrdersList();
      }

      //if()

    })
    this.listCookies()
    this.companyDropdownSettings = {
      singleSelection: true,
      primaryKey: 'id',
      labelKey: 'name',
      text: "Filter by Company",
      enableSearchFilter: false,
      lazyLoading: true,
      showCheckbox: true,
      clearAll: false
    };

    this.preferenceDropdownSettings = {
      singleSelection: true,
      primaryKey: 'id',
      labelKey: 'name',
      text: "Filter by Preference",
      enableSearchFilter: false,
      lazyLoading: true,
      showCheckbox: true,
      clearAll: false
    };

    this.statusDropdownSettings = {
      singleSelection: true,
      primaryKey: 'id',
      labelKey: 'name',
      text: "Filter by Status",
      enableSearchFilter: false,
      lazyLoading: true,
      showCheckbox: true,
      clearAll: false,
      badgeShowLimit: 1,
      classes: "myclass custom-class"
    };

  }
  getByName(name) {
    debugger
    this.api.getDashboard(name).subscribe(resp => {
      console.log(resp)
      this.orderListCopy = resp['order_data']
      if (this.status != 'Revenue') {
        let status = this.status
        if (this.status == 'Order Placed') {
          this.orderList = _.filter(this.orderListCopy, function (res) {
            return res.orderstatus == 'Order Placed' || res.orderstatus == 'Order Accepted'
          })
        } else {
          this.orderList = _.filter(this.orderListCopy, function (res) {
            return res.orderstatus == status
          })
        }
      } else {
        this.orderList = _.filter(this.orderListCopy, function (res) {
          return res.orderstatus != 'Order Cancelled'
        })
      }
      this.totalItems = this.orderList.length
      this.loader = false
    })
  }
  getByReference() {
    debugger
    let searchText = this.ledgerForm.controls.searchText.value.replace(/\s+/g, ' ').trim()
    this.orderList = _.filter(this.orderListCopy, function (res) {
      return res.reference_id == searchText
    })
    this.orderData = this.orderList
    this.totalItems = this.orderList?.length
  }
  getDashboardData(fromDate, endDate) {
    debugger
    this.api.getAllOrder().subscribe(res => {
      this.orderList = res['order_data']['Items']
      this.loader = false
      this.orderListCopy = this.orderList.sort((a: any, b: any) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
      // this.orderList = this.orderList.reverse();

      if (this.status != 'Revenue') {
        let status = this.status
        if (this.status == 'Order Placed') {
          this.orderList = _.filter(this.orderListCopy, function (res) {
            return res.orderstatus == 'Order Placed' || res.orderstatus == 'Order Accepted'
          })
        } else {
          this.orderList = _.filter(this.orderListCopy, function (res) {
            return res.orderstatus == status
          })
        }

      } else {
        this.orderList = _.filter(this.orderListCopy, function (res) {
          return res.orderstatus != 'Order Cancelled'
        })
      }
      this.orderList = _.filter(this.orderList, function (res) {
        return res.created_at.slice(0, 10) >= fromDate && res.created_at.slice(0, 10) <= endDate
      })
      this.totalItems = this.orderList?.length
      this.orderData = this.orderList
      console.log(this.orderList)

    })
  }
  onItemSelect(event) {
    debugger
    console.log(event, 'evny')
    this.p = 1
    if (event.name == 'Home Delivery' || event.name == 'Self Pickup') {
      let name
      if (event.name == 'Home Delivery') {
        name = 'home_delivery'
      }
      else {
        name = 'self_pickup'
      }
      this.orderList = _.filter(this.orderListCopy, function (res) {
        return res.home_delivery == name
      })
      this.totalItems = this.orderList?.length
    }
    else {
      this.orderList = _.filter(this.orderListCopy, function (res) {
        return res.orderstatus == event.name
      })
      this.totalItems = this.orderList?.length
    }
  }
  applyFilter() {
    debugger
    console.log(this.filterForm.controls)
    this.orderList = this.orderListCopy
    if (this.filterForm.controls.searchText.value) {
      let searchText = this.filterForm.controls.searchText.value.replace(/\s+/g, ' ').trim()
      this.orderList = _.filter(this.orderListCopy, function (res) {
        return res.reference_id == searchText
      })
    }
    if (this.filterForm.controls.orderDate.value) {
      let fromDate = new Date(this.filterForm.controls.orderDate.value[0]).toISOString()
      let endDate = new Date(this.filterForm.controls.orderDate.value[1]).toISOString()
      this.orderList = _.filter(this.orderList, function (res) {
        return res.created_at >= fromDate && res.created_at <= endDate
      })
    }
    if (this.filterForm.controls.preference.value.length > 0) {

      let name
      if (this.filterForm.controls.preference.value[0].name == 'Home Delivery') {
        name = 'home_delivery'
      }
      else {
        name = 'self_pickup'
      }

      this.orderList = _.filter(this.orderList, function (res) {
        return res.home_delivery == name
      })

    }
    if (this.filterForm.controls.status.value.length > 0) {
      let status = this.filterForm.controls.status.value[0].name
      this.orderList = _.filter(this.orderList, function (res) {
        return res.orderstatus == status
      })
    }
    this.totalItems = this.orderList.length
  }
  download(url: any) {
    window.open(url, '_blank')
    // debugger
    // // var parentElement = parent.qrcElement.nativeElement.querySelector("canvas")
    // // var dataURL = parentElement.toDataURL();
    // // var tmpLink = document.createElement('a');
    // var parentElement = url
    // const a: any = document.createElement('a');
    // a.href = parentElement;
    // a.download = '';
    // document.body.appendChild(a);
    // //a.style = 'width: 500%';
    // a.click();
    // a.remove()

  }
  getSelectedProduct(event: any) {
    // console.log(this.ledgerForm.controls.product.value)
    let id = this.ledgerForm.controls.product.value
    this.selectedProduct = _.filter(this.productList, function (res) {
      return res.id == id
    })
    console.log(this.selectedProduct)
    this.colorList = this.selectedProduct[0].color
    this.min_qty = parseInt(this.selectedProduct[0].min_qty)
    this.quantity = this.min_qty
    this.selectedProduct[0].quantity = this.min_qty
  }
  getSelectedColor(event: any) {
    let id = this.ledgerForm.controls.color.value
    this.selectedColor = _.filter(this.colorList, function (res) {
      return res.SK == id
    })
    this.selectedProduct[0].selectedColor = this.selectedColor[0].name
    this.selectedProduct[0].image = this.selectedColor[0].image
    this.selectedProduct[0]['customize'] = []
    console.log(this.selectedColor)
  }
  listCookies() {
    this.api.listProduct().subscribe((result) => {
      console.log(result, 'products')
      this.productList = result['data']['Items']
      this.productList = _.sortBy(this.productList, function(o){
        return o.cookie_number
      })
    })
  }
  increment() {

    this.selectedProduct[0].quantity = this.selectedProduct[0].quantity + 1
    this.quantity = this.selectedProduct[0].quantity
    console.log(this.selectedProduct)
  }
  decrement() {


    if (this.selectedProduct[0].quantity > this.selectedProduct[0].min_qty) {
      this.selectedProduct[0].quantity = this.selectedProduct[0].quantity - 1
      this.quantity = this.selectedProduct[0].quantity
    }


  }
  addtoList() {
    debugger
    if (this.selectedProduct[0]?.selectedColor) {
      let myid = uuid.v4()
      console.log(this.selectedProduct)
      this.cancel()
      this.cartList.push({
        "PK": this.selectedProduct[0].PK,
        "SK": this.selectedProduct[0].SK,
        "customize": [],
        "quantity": this.selectedProduct[0].quantity,
        "box_color": this.selectedProduct[0].selectedColor,
        "id": myid,
        "cookie_number": this.selectedProduct[0].cookie_number,
        "min_qty": this.selectedProduct[0].min_qty,
        "box_img_url": this.selectedProduct[0].image,
        "flavours": this.selectedProduct[0].flavours,
        "image": this.selectedProduct[0].image,
        "product": {
          "id": this.selectedProduct[0].id,
          "name": this.selectedProduct[0].name,
          "price": this.selectedProduct[0].price,
          "item_total": this.selectedProduct[0].price * this.selectedProduct[0].quantity,
          "image": this.selectedProduct[0].image,
          "cookie_number": this.selectedProduct[0].cookie_number
        }
      })
      this.selectedColor = null
      this.selectedProduct = []
    } else {
      this.toastr.error('Please choose a box color')
    }
  }
  cancel() {
    this.ledgerForm.patchValue({
      product: '',
      color: ''
    })
    this.min_qty = ''
    this.quantity = ''
    this.selectedColor = null
  }
  checkout() {
    // const queryParams: any = {};
    // queryParams.myArray = JSON.stringify(this.cartList)
    // const navigationExtras: NavigationExtras = {
    //   queryParams
    // };
    localStorage.setItem('cartItems', JSON.stringify(this.cartList))
    this.router.navigate(['/bulk-order'])
  }
  deleteItem(index: any) {
    this.cartList.splice(index, 1)
  }
  OnItemDeSelect(event) {
    this.p = 1
    this.orderList = _.filter(this.orderListCopy, function (res) {
      return res.orderstatus == event.name
    })
    this.totalItems = this.orderList?.length
  }
  onSelectAll(event) {
    this.p = 1
    this.orderList = _.sortBy(this.orderListCopy, function (o) {
      return o.created_at
    })
    this.orderList.reverse();
    this.totalItems = this.orderList?.length
  }
  onDeSelectAll(event) {
    this.p = 1
    this.orderList = _.sortBy(this.orderListCopy, function (o) {
      return o.created_at
    })
    this.orderList.reverse();
    this.totalItems = this.orderList?.length
  }
  dateInReverse(date) {
    debugger
    if (date) {
      var datetime = date
      var bits = datetime.split(/\D/);
      var dateObject = new Date(bits[0], --bits[1], bits[2], bits[3], bits[4]);
      const formattedDate = dateObject.toLocaleDateString('en-GB', {
        day: 'numeric', month: 'short', year: 'numeric'
      }).replace(/ /g, '-');
      return formattedDate
    }
  }
  dateRangeCreated($event) {
    debugger;
    this.p = 1;
    let fromDate = this.convert($event[0]);
    let endDate = this.convert($event[1]);
    this.orderList = _.filter(this.orderListCopy, function(res) {
      if (res.created_at && typeof res.created_at === 'string') {
        let createdAtDate = res.created_at.slice(0, 10);
        return createdAtDate >= fromDate && createdAtDate <= endDate;
      }
      return false;
    });
    this.totalItems = this.orderList?.length;
    console.log(this.orderList, 'res');
  }
  
  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  clearAll() {
    this.location.replaceState("/order/list")
    window.location.reload()
  }
  getAllOrdersList() {
    this.api.getAllOrder().subscribe(res => {
      this.orderList = res['order_data']['Items']
      this.loader = false
      this.orderList = _.sortBy(this.orderList, function (o) {
        return o.created_at
      })
      this.orderListCopy = this.orderList.sort((a: any, b: any) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
      // this.orderList = this.orderList.reverse();

      console.log(this.orderList, 'res')
      this.orderData = this.orderList
      this.totalItems = this.orderList?.length
      console.log(this.totalItems, 'order')
    })
  }
  filterDate(queryDate: any) {
    this.p = 1
    let filter_date = this.formatDate(queryDate)
    console.log(filter_date, this.orderListCopy, 'csdfsfsf')
    this.orderList = _.filter(this.orderListCopy, function (res) {
      return res.confirmed_date.slice(0, 10) == filter_date
    })
    this.p = 1
  }
  filterDeliveryDate(queryDate: any) {
    this.p = 1
    let filter_date = this.formatDate(queryDate)
    console.log(filter_date, this.orderList, 'csdfsfsf')
    this.orderList = _.filter(this.orderListCopy, function (res) {
      return res.delivery_date == filter_date
    })
    this.p = 1
  }

  orderReferenceFilter(event): Observable<any> {
    this.p = 1
    console.log(event.target.value, 'val')
    let reference = event.target.value
    console.log(this.orderList, 'ordere')
    // this.orderList = _.filter(this.orderList, function (res) {
    //   return res.reference_id == reference
    // })
    console.log(this.orderList, 'ordere')
    return this.orderList
  }
  filterItem(value) {
    this.p = 1
    if (!value) {
    } // when nothing has typed
    this.orderList = Object.assign([], this.orderListCopy).filter(
      item => item.reference_id.toLowerCase().indexOf(value.toLowerCase()) > -1
    )
    this.p = 1
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
  exportToExcel() {
    console.log(this.orderList, 'dsd')
    let obj = []
    let exel_sheet_data = []
    const date = new Date();
    let data = {}
    let header = ['Ref ID', 'Customer Name', 'Order date', 'Delivery date', 'Amount(QAR)', 'Preference', 'Order Status'];
    let title = 'Order list of snape Cookies';
    let worksheet = 'Order Data'
    let merge_cells = 'A1:G2'
    let footer_row = 'Snape cookies order list.'
    let filename = 'order_list'
    let excel_array = [
      data['header'] = header,
      data['title'] = title,
      data['worksheet'] = worksheet,
      data['merge_cells'] = merge_cells,
      data['footer_row'] = footer_row,
      data['filename'] = filename
    ]
    const formattedDate = date.toLocaleDateString('en-GB', {
      day: '2-digit', month: 'short', year: 'numeric'
    }).replace(/ /g, '-');
    debugger
    for (var i in this.orderList) {
      obj = [this.orderList[i].reference_id, this.orderList[i]?.order_user['name'],
      this.dateInReverse(this.orderList[i].created_at), this.orderList[i].delivery_date,
      this.orderList[i].order_total, this.orderList[i].home_delivery, this.orderList[i].orderstatus]
      exel_sheet_data.push(obj)
      console.log(exel_sheet_data, 'rtr')
    }
    this.excelService.exportAsExcelFile(exel_sheet_data, formattedDate, excel_array);
  }
  getOrderDetails(order) {
    debugger
   if(order.PK.includes('@')) {
    this.extra = {
      "order_id": order.id,
      "user_email": order['order_user'].email
    }
   }else{
    let str = order.PK.split('#')
    let number = str[1]
    this.extra = {
      "order_id": order.id,
      "user_email": number
    }
   }
    
    this.router.navigate(['/order-details'])

    this.navigationExtras = {
      queryParams: this.extra,
      skipLocationChange: true,
      queryParamsHandling: 'merge'
    };
    localStorage.removeItem('products')
    localStorage.removeItem('edited')
    this.router.navigate(['/order-details'], {
      queryParams: this.extra
    });
  }
}
