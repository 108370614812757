import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { TokenStorageService } from 'src/app/common/token/token-storage.service';
import * as _ from 'underscore';
@Component({
  selector: 'app-admin-head-menu',
  templateUrl: './admin-head-menu.component.html',
  styleUrls: ['./admin-head-menu.component.css']
})

export class HeadMenuComponent implements OnInit {
  notificationArray: any;
  unreadNotification
  constructor(
    private commonService: CommonService,
    private tokenStorage: TokenStorageService,
    private route: Router
  ) {
  }

  ngOnInit() {
    this.commonService.getNotification().subscribe(res=>{
      this.notificationArray = res['data']['Items']
      this.notificationArray = _.filter(this.notificationArray, msg => {
        return msg.status == 'unread'
      })
      this.unreadNotification = this.notificationArray?.length
    })
  }
  logout() {
		this.commonService.loggedIn.next(false);
		this.tokenStorage.signOut()
		this.route.navigate(["/login"])
	}
}

