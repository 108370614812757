import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from '../../common/common.service';
import { TokenStorageService } from '../../common/token/token-storage.service';
import { CustomvalidationService } from '../../common/customvalidation.service';
import { ToastrService } from 'ngx-toastr';
import { AngularFireMessaging } from '@angular/fire/messaging';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  password;
  show = false;

  loginForm: FormGroup
  submitted: boolean = false;
  fcm_token: string;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    public api: CommonService,
    private token: TokenStorageService,
    private customValidator: CustomvalidationService,
    private toastr: ToastrService,
    private angularFireMessaging: AngularFireMessaging
  ) { }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.compose([Validators.required, this.customValidator.patternValidator()])]
    })

    this.password = 'password';

  }

  login() {
    this.submitted = true;
    if (this.loginForm.valid) {
      this.angularFireMessaging.requestToken.subscribe(
        (token) => {
          this.fcm_token = token
          console.log('fcm_token', token);
          let data = {
            "username": this.loginForm.controls.email.value,
            "password": this.loginForm.controls.password.value,
            "fcm_token": { "web": this.fcm_token },
            "userType": "admin"
          }
          this.api.Signin(data).subscribe(res => {
            console.log(res)
            localStorage.setItem('loginEmail', this.loginForm.controls.email.value)
            this.token.saveToken(res['access_token']);
            this.token.saveRefreshToken(res['refresh_token']);
            this.token.saveIdToken(res['id_token']);
            this.token.saveUser(res['username'])
            let status = res['status']
            if (status == true) {
              let fullName = res['data']['Item'].name
              this.toastr.success('Welcome ' + fullName);
              this.router.navigate(['dashboard'])
            }
            else if (!res['data'] && res['message']) {
              this.toastr.info(res['message'])
            }
            else {
              let message = res['message'];
              this.toastr.error(message);
            }
          })
        },
        (err) => {
          console.error('Unable to get permission to notify.', err);
        }
      );
    }
  }

  showPasswordOnClick() {
    if (this.password === 'password') {
      this.password = 'text';
      this.show = true;
    } else {
      this.password = 'password';
      this.show = false;
    }
  }

}
