<div *ngIf="loader == true">
    <app-spinner></app-spinner>
</div>
<section class="coupon-list-sec">
    <div class="row p-t-20">
        <div class="col-md-8 col-xs-12 col-lg-8">
            <div class="coupon-list-heading mob-text-align">
                <h1>Coupons</h1>
            </div>
        </div>
        <div class="col-md-4 col-lg-4 col-xs-12">
            <div class="create-btn mob-text-align">
                <button type="button" class="btn btn-pink mob-m-b-5 height-35 line-height tab-btn-width"
                    [routerLink]="['/new-coupon']">Create new coupon</button>
            </div>
        </div>
    </div>
    <div class="row p-t-20 row-border">
        <div class="col-md-5 col-lg-5 col-xs-12">
            <div class="total-coupon mob-text-align">
                <p><span>{{total}}</span> coupons found</p>
            </div>
        </div>
        <div class="col-md-3 col-lg-3 col-xs-12">
            <div class="mob-p-b-10">
                <input type="text" placeholder="Search by couponcode" [(ngModel)]="searchText"
                    class="form-control cursor-pointer height-30">
            </div>
        </div>
    </div>
    <div class="row p-t-20">
        <div class="col-md-12 col-xs-12 col-lg-12">
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Code</th>
                            <th scope="col">Coupon type</th>
                            <th scope="col" class="text-nowrap">Coupon discount</th>
                            <th scope="col">Description</th>
                            <th scope="col">Usage/Limit</th>
                            <th scope="col">Expiry</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor=" let item of couponList  | filter : searchText | paginate:  { itemsPerPage: 10,
                            currentPage: p,
                            totalItems: total}">
                            <td>{{item.couponCode}}
                            </td>
                            <td class="text-nowrap">{{item.type}}</td>
                            <td *ngIf="item.type == 'Percentage discount'">{{item.typeValue}} %</td>
                            <td *ngIf="item.type != 'Percentage discount'">{{item.typeValue}}</td>
                            <td>
                                <span [ngClass]="{'limitTextHeight': isReadMore}"
                                    *ngIf="item.details != ''">{{item.details}}
                                </span>
                                <button type="button" class="btn btn-link float-right" (click)="showText()"
                                    *ngIf='item.details?.length > 51'>
                                    {{ isReadMore ? 'View more': 'View less' }}
                                </button>
                                <span *ngIf="item.details == ''"> - </span>
                            </td>
                            <td *ngIf="item.usageCount == 0">----</td>
                            <td *ngIf="item.usageCount != 0" class="text-nowrap">{{item.promotionType}}
                                ({{item.usageCount}})</td>
                            <td class="text-nowrap">
                                <span *ngIf="item.expired"[ngClass]="{'expiryRedColor': item.expired}">{{item.expiry | date: 'dd/MM/yyyy'}}</span>
                                <span *ngIf="!item.expired">{{item.expiry | date: 'dd/MM/yyyy'}}</span>
                                
                            </td>
                            <td class="text-center">
                                <i class="far fa-edit" aria-hidden="true" [routerLink]="['/new-coupon']"
                                    [queryParams]="{id:item.SK}"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <hr>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12 col-xs-12 col-lg-12">
            <div class="pagination mob-text-align">
                <p>Showing {{(p-1)*10}} - {{((p-1)*10)+10}} of {{total}}</p>
                <pagination-controls (pageChange)="p = $event"></pagination-controls>
            </div>
        </div>
    </div>
</section>