import { Injectable } from '@angular/core';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';
const ID_TOKEN = 'id-token';
const REFRESH_TOKEN_KEY = 'refresh-token'
@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  constructor() { }
  signOut() {
    window.sessionStorage.clear();
    window.localStorage.clear()
    localStorage.clear()
  }

  public saveToken(token: string) {
    localStorage.removeItem(TOKEN_KEY);
   localStorage.setItem(TOKEN_KEY, token);
  }

  public saveRefreshToken(token: string) {
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    localStorage.setItem(REFRESH_TOKEN_KEY, token);
  }

  public saveIdToken(token:string) {
    localStorage.removeItem(ID_TOKEN);
    localStorage.setItem(ID_TOKEN,token);
  }
  public getToken(): string {
    return localStorage.getItem(TOKEN_KEY);
  }

  public getIdToken():string {
    return localStorage.getItem(ID_TOKEN);
  }
  public saveUser(user) {
  localStorage.removeItem(USER_KEY);
  localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser() {
    return JSON.parse(localStorage.getItem(USER_KEY));
  }

}
