export const COMPANY_NAME = [
  {
    id: 1,
    name: 'Snape Cookie'
  },
  {
    id: 2,
    name: 'Cookie Snape'
  }
];

export const PREFERENCE = [
  {
    id: 1,
    name: 'Home Delivery'
  },
  {
    id: 2,
    name: 'Self Pickup'
  }
];

export const STATUS = [
  {
    id: 1,
    name: 'Order Placed'
  },
  
  {
    id: 2,
    name: 'Order Accepted'
  },
  {
    id: 3,
    name: 'Processing'
  },
  {
    id: 4,
    name: 'Ready for delivery'
  },
  {
    id: 5,
    name: 'Out for delivery'
  },
  {
    id: 6,
    name: 'Delivered'
  },
  {
    id: 7,
    name: 'Delayed'
  },
  {
    id: 8,
    name: 'Order Cancelled'
  },
];
export const CUSTOMER_STATUS = [
  {
    id: 1,
    name: 'Active'
  },
  {
    id: 2,
    name: 'Inactive'
  }
]
export const ZONE_STATUS = [
  {
    id: 1,
    name: '15'
  },
  {
    id: 2,
    name: '20'
  },
  {
    id: 3,
    name: '25'
  },
  {
    id: 4,
    name: '30'
  },
  {
    id: 5,
    name: '35'
  },
  {
    id: 6,
    name: '40'
  }
]
export const CONTACT_STATUS = [
  {
    id: 1,
    name: 'Open'
  },
  {
    id: 2,
    name: 'Closed'
  },
  {
    id: 3,
    name: 'Cancelled'
  }
]
  export const NEWSLETTER_STATUS = [
    {
      id: 1,
      name: 'Subscribed'
    },
    {
      id: 2,
      name: 'Unsubscribed'
    }
]
export const NOTIFY_STATUS = [
  {
    id: 1,
    name: 'Read'
  },
  {
    id: 2,
    name: 'Unread'
  },
]