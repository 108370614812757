<div *ngIf="loader == true">
    <app-spinner></app-spinner>
</div>
<section class="faq-management-sec">
    <div class="row">
        <div class="col-md-6 col-xs-12 col-lg-6">
            <div class="faq-heading">
                <h1>Frequently asked questions</h1>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="faq-btn">
                <button class="button-style btn-faq" (click)="faqFormSwitch()">Add new FAQ</button>
            </div>
        </div>
    </div>
    <div class="row border" *ngIf="faq_add">
        <div class="col-md-12 p-20">
            <form [formGroup]="faqDetailsForm">
                <div class="row">
                    <div class="col-md-6 col-lg-6 col-xs-12">
                        <div class="new-qus">
                            <p>New Question</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xs-12">
                        <div class="btns-position">
                            <button class="button-style cancel-btn cursor-pointer"
                                (click)="cancelFaqAdd()">Cancel</button>
                            <button class="button-style btn-pink cursor-pointer" data-toggle="modal"
                                data-target="#sucessModal" (click)="addFaq()" *ngIf="!faq_edit"
                                [disabled]="faqDetailsForm.invalid">Save</button>
                            <button class="button-style btn-pink cursor-pointer" data-toggle="modal"
                                data-target="#sucessModal" (click)="editFaq(faq)" *ngIf="faq_edit"
                                [disabled]="faqDetailsForm.invalid">Save</button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="title-area">
                            <p>Title</p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div>
                            <input type="text" formControlName="question" class="form-control"
                                placeholder="Enter question here">
                            <span class="text-danger"
                                *ngIf="(faqDetailsForm.controls.question.touched || submitted) && faqDetailsForm.controls.question.errors?.required">
                                Question is required
                            </span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="content-area">
                            <p>Content</p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div>
                            <!-- <form role="form" #myForm="ngForm" accept-charset="UTF-8" novalidate> -->
                            <div class="form-group has-feedback">
                                <!-- <ckeditor formControlName="answer" name="myckeditor" required
                                        [config]="ckeConfig" debounce="500" (ngModelChange)="onChange($event)">
                                    </ckeditor> -->
                                <ckeditor name="myckeditor" required [config]="ckeConfig" debounce="500"
                                    (ngModelChange)="onChange($event)" formControlName="answer">
                                </ckeditor>
                                <span class="text-danger"
                                    *ngIf="(faqDetailsForm.controls.answer.touched || submitted) && faqDetailsForm.controls.answer.errors?.required">
                                    Answer is required
                                </span>
                                <!-- <div *ngIf="myckeditor.invalid && myckeditor.touched" class="help-block">Required field.
                                    </div> -->
                            </div>
                            <!-- <button [disabled]="myForm.invalid" class="btn btn-primary">Save</button> -->
                            <!-- </form> -->
                        </div>
                    </div>
                </div>
            </form>

        </div>
    </div>
    <div class="row mt-3" *ngFor="let faq of faqArray">
        <div class="col-md-12">
            <div class="accordion" id="accordionExample">
                <div class="card">
                    <div class="card-header" id="headingOne">
                        <h2 class="mb-0">
                            <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#{{faq.SK}}">
                                {{faq.question}} ?
                            </button>
                            <i class="fas fa-chevron-down" data-toggle="collapse" data-target="#{{faq.SK}}"></i>
                        </h2>
                    </div>

                    <div id="{{faq.SK}}" class="collapse show">
                        <div class="card-body" [innerHtml]="faq.answer">
                            <p>{{faq.answer}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="span-btn-position">
                    <span><i class="far fa-edit" (click)="editMode(faq)"></i></span>
                    <span><i class="far fa-trash-alt" (click)="getSk(faq.SK)" data-toggle="modal"
                            data-target="#delete"></i></span>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- sucess modal start -->

<div class="modal fade" id="sucessModal" tabindex="-1" role="dialog" aria-labelledby="sucessModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="success-icon-img mb-3">
                            <img src="../../../assets/success.png">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="confirm-txt">
                            <h6 class="mb-3">Sucess</h6>
                            <p class="mb-3">Added new FAQ sucessfully</p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="submit-btn">
                            <button type="button" class="btn submit-btn" data-dismiss="modal">Done</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- sucess modal end -->
<!-- Modal -->
<!-- <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog"
                aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div class="text-align">
                                <i class="far fa-thumbs-up"></i>
                                <h2 class="pink-colr m-t-25 font-weight">Success</h2>
                                <p class="font-weight">Added new FAQ sucessfully</p>
                                <button type="button" class="btn btn-pink btn-pink-done font-weight"
                                    data-dismiss="modal">Done</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->

<!-- delete modal starts-->
<div class="modal fade" id="delete" tabindex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <!-- <div class="row">
                    <div class="col-md-12">
                        <h4 class="snape-red mt-2">Delete FAQ</h4>
                    </div>
                </div> -->
                <div class="row">
                    <div class="col-md-12">
                        <div class="info-icon-img">
                            <img src="../../../assets/exclamation.png">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="confirm-txt">
                            <h6 class="mb-3">Are you sure?</h6>
                            <p class="mb-3"> Do you want to delete this FAQ?</p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 mfooterBtn d-flex justify-content-end">
                        <button type="button" class="btn color-cancel-btn mr-2" data-dismiss="modal">Cancel</button>
                        <button type="button" class="btn submit-btn ml-2" data-dismiss="modal"
                            (click)="deleteFaq()">Delete</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- delete modal end-->