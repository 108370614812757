<div class="container-fluid">
  <section class="reset-password-sec">
    <div class="container">
      <div class="row">
        <div class="col-md-6 logintxt">
          <!-- Reset -->
          <div class="card">
            <div class="card-body d-flex align-items-center justify-content-center">
              <div class="login-width">
                <div class="ml-3">
                  <h5 class="snape-red">Reset Password</h5>
                </div>

                <form class="pt-2" [formGroup]="resetPasswordForm">
                  <div class="position-relative form-group">
                    <div class="col-md-12">
                      <div class="input-group">
                        <input formControlName="password" id="inputEmail" placeholder="Enter new password" required=""
                          [type]="password" class="form-control">
                        <div class="input-group-append">
                          <button (click)="showPasswordOnClick()">
                            <i class="fa fa-eye" aria-hidden="true" *ngIf="show"></i>
                            <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="!show"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="position-relative form-group">
                    <div class="col-md-12">
                      <div class="input-group">
                        <input name="password" [type]="showPassword ? 'text' : 'password'" class="form-control"
                          id="inputPass" placeholder="Re-enter new password" formControlName="passwordConfirm"
                          required="">
                        <div class="eye-icons">
                          <i alt="show" class="fa fa-eye eye-show" (click)="showPassword = !showPassword"
                            [class.hide]="!showPassword"></i>
                          <i alt="hide" class="fa fa-eye-slash eye-hide" (click)="showPassword = !showPassword"
                            [class.hide]="showPassword"></i>
                        </div>
                      </div>
                      <span class="text-danger"
                        *ngIf="(resetPasswordForm.controls.passwordConfirm.touched || submitted) && resetPasswordForm.controls.passwordConfirm.errors?.required">
                        Confirm Password is required
                      </span>
                      <span class="text-danger"
                        *ngIf="resetPasswordForm.controls.passwordConfirm.touched && resetPasswordForm.controls.passwordConfirm.errors?.passwordMismatch">
                        Passwords doesnot match
                      </span>
                    </div>
                  </div>
                  <div class="position-relative form-group">
                    <div class="d-flex justify-content-center col-md-12 mt-4">
                      <button type="submit" (click)="send()" [disabled]="resetPasswordForm.invalid"
                        class="btn btn-snape-bak btn-block text-uppercase">Change Password</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 login-img">
          <img src="../../../assets/cover.png" />
        </div>
      </div>
    </div>
  </section>
</div>