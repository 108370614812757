<section class="message-center-sec">
  <div class="row mt-3">
    <div class="col-md-12 col-xs-12 col-lg-12">
      <div class="message-center-heading">
        <h1>Update cookie image</h1>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 mt-4">
      <div class="vendor-list-breadcrumb">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item cursor-pointer" routerLink="/order/list" routerLinkActive="router-link-active">
              <a><i class="fas fa-shopping-cart"></i> Order list</a>
            </li>
            <li class="breadcrumb-item cursor-pointer" (click)="orderDetailPage()" routerLinkActive="router-link-active">
              <a>Order id : {{orderId}}</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Update cookie</li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 col-lg-5 col-xl-6 col-xs-12">
      <div class="canvaDiv">
        <span class="dot">
          <div>
            <canvas id="canvas" width="300" height="300" style="border-radius: 50%;"></canvas>
          </div>
        </span>
        <span class="remove">
          <i class="far fa-trash-alt remove-btn" (click)="remove()"></i>
        </span>
      </div>
    </div>

    <div class="col-md-6 col-lg-7 col-xl-6 col-xs-12"
      style="margin: auto; height: 85vh; overflow-y: scroll; overflow-x: clip;">

      <div class="row">
        <div class="col-md-12 col-xs-12 col-lg-11">
          <form [formGroup]="cookieForm">
            <div class="" *ngIf="!imageAdded && !showText">
              <div (dragover)="false" (dragend)="false" (drop)="handleDrop($event)">
                <br />
                <div class="row d-flex justify-content-center">
                  <div class="col-md-6 col-xs-12 col-lg-6">
                    <div class="upload-btn-align">
                      <div class="file-uploadC" id="newtarget">
                        <label for="upload" class="file-uploadC__label">
                          <!-- <span><img width="14%" src="../../../assets/Icon.png" />
                                  </span> -->
                          <!-- <span class="material-icons-outlined upload-icon">
                                backup
                              </span> -->
                          Upload image
                        </label>
                        <input id="upload" class="file-uploadC__input" (change)="uploadImage($event)" #fileInput
                          type="file" formControlName="filesource" accept=".jpg,.jpeg,.png,.jfif,.svg,.tiff,.gif">
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-xs-12 col-lg-6 my-auto">
                    <div class="add-txt-btn-align">
                      <button type="button" class="btn add-text-btn" (click)="addText()">Add Text</button>
                    </div>
                  </div>
                </div>
                <div class="row d-flex justify-content-center">
                  <div class="col-md-12 col-xs-12 col-lg-12">
                    <p class="text-center mt-4 font-weight font-12">OR</p>
                    <p class="text-center font-weight font-12">Choose from template image</p>
                    <ul class="nav nav-tabs " role="tablist">
                      <li class="nav-item ">
                        <a class="nav-link" data-toggle="tab" href="#myImages" (click)="key(myImages)">My
                          Images</a>
                      </li>
                      <li class="nav-item" *ngFor="let recipient of categories | keyvalue">
                        <a class="nav-link" style="text-transform: capitalize;" data-toggle="tab"
                          href="#{{recipient.key}}" (click)="key(recipient.key)">{{recipient.key}}</a>
                      </li>
                    </ul>
                    <div class="tab-content">
                      <div id="myImages" class="container tab-pane" [ngClass]="{'active': selectedKey == myImages}">
                        <br>
                        <div class="row" *ngIf="myImages == null">
                          <div class="col-md-8">
                            <div class="text-align">
                              No images found
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-3 m-t-20" *ngFor="let image of myImages">
                            <div class="text-align">
                              <img class="bRadius50" (click)="getMyImage(image)" src="{{image.image}}" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div *ngFor="let recipient of categories | keyvalue">
                        <div id="{{recipient.key}}" class="container tab-pane fade"
                          *ngIf="selectedKey == recipient.key">
                          <br>
                          <div class="row">
                            <div class="col-md-3 m-t-20" *ngFor="let image of recipient.value">
                              <div class="text-align">
                                <img class="bRadius50" (click)="getTemplate(image)" src="{{image.image}}" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="showEditBtn" class="col-md-12 mt-4">
              <div class="row">
                <div class="col-md-12 d-flex justify-content-center">
                  <div class="upload-btn-wrapper">
                    <input (change)="uploadImage($event)" #fileInput type="file" id="file"
                      accept=".jpg,.jpeg,.png,.jfif,.svg,.tiff,.gif" />
                    <button type="button" class="btn btn-snap-redOut m-3">Change
                      Image</button>
                  </div>
                  <button class="btn btn-snap-redOut m-3" type="button" (click)="addText()"> <i class="fa fa-text-width"
                      aria-hidden="true"></i> Add Text</button>
                  <!-- <button type="button" class="btn btn-snape-red text-uppercase m-3" (click)="saveDesign()">SAVE
                        DESIGN
                      </button> -->
                  <button type="button" class="btn btn-snap-redOut m-3 text-center" (click)="remove()">Delete</button>
                </div>
                <div class="col-md-12" *ngIf="imageAdded && !showText">
                  <div class="row mHeightCus mt-4">
                    <div class="col-md-3">
                      <span><i class="far fa-sun brightness-span lit-gray-color"></i>
                      </span>
                      <div class="lit-gray-color">Brightness</div>
                    </div>
                    <div class="col-md-9 m-t-10">
                      <input class="rangeImg" type="range" formControlName="brightness" value="0.1" min="-1" max="1"
                        step="0.003921" (ngModelChange)="setBrightness()">{{brightness}}
                    </div>
                  </div>
                  <div class="row mHeightCus">
                    <div class="col-md-3">
                      <span><i class="fas fa-adjust contrast-span lit-gray-color"></i></span>
                      <div class="lit-gray-color">Contrast</div>
                    </div>
                    <div class="col-md-9 m-t-10">
                      <input type="range" value="0.1" min="-1" max="1" step="0.003921" formControlName="contrast"
                        (ngModelChange)="setContrast()">{{contrast}}
                    </div>
                  </div>
                  <div class="row mHeightCus">
                    <div class="col-md-3">
                      <!-- <span class="material-icons-outlined saturation-span lit-gray-color">
                            flaky
                          </span> -->
                      <span><i class="fas fa-tint contrast-span lit-gray-color"></i></span>
                      <div class="lit-gray-color">Saturation</div>
                    </div>
                    <div class="col-md-9 m-t-10">
                      <input type="range" value="0.1" min="-1" max="1" step="0.003921" formControlName="saturation"
                        (ngModelChange)="setSaturation()">{{saturation}}
                    </div>
                  </div>
                </div>
                <div class="col-md-12" *ngIf="showText && !imageAdded">
                  <p class="mt-4rem text-center font-weight fontsiz18">ADD/EDIT TEXT</p>
                  <hr class="hr-2">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="d-flex" id="addText">
                        <input type="text" placeholder="Add Text Here" class="form-control pink-border"
                          formControlName="textString">
                        <button class="btn btn-snape-red apply-btn" id="apply-text" data-toggle="tooltip"
                          data-placement="bottom" type="button" (click)="applyText()">Apply</button>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-md-12">
                      <select formControlName="fontFamily" class="form-control pink-border select-form-control"
                        value="props.fontFamily" (change)="setFontFamily()" aria-placeholder="Select your Font">
                        <option value="" disabled selected hidden>Select your font</option>
                        <option value="arial">Arial</option>
                        <option value="helvetica" selected>Helvetica</option>
                        <option value="verdana">Verdana</option>
                        <option value="courier">Courier</option>
                        <option value="Roboto">Roboto</option>
                        <option value="Open Sans">Open Sans</option>
                        <option value="Zilla Slab">Zilla Slab</option>
                        <option value="Lato">Lato</option>
                        <option value="Times New Roman">Times New Roman</option>
                        <option value="Fresca">Fresca</option>
                        <option value="Raleway">Raleway</option>
                        <option value="Open Sans Condensed">Open Sans Condensed</option>
                        <option value="Indie Flower">Indie Flower</option>
                        <option value="Josefin Sans">Josefin Sans</option>
                        <option value="VT323">VT323</option>
                        <option value="Pacifico">Pacifico</option>
                        <option value="Gloria Hallelujah">Gloria Hallelujah</option>
                      </select>
                    </div>
                  </div>

                  <div class="row mt-4">
                    <div class="col-md-4 col-lg-4">
                      <div class="txt-box-pink-border background-width mob-m-b-20">
                        <span class="p-r-10">Background</span>
                        <input type="color" formControlName="BgFill" (ngModelChange)="setBgFill()">
                      </div>
                    </div>
                    <div class="col-md-4 col-lg-4">
                      <div class="txt-box-pink-border text-width mob-m-b-20">
                        <span class="p-r-10">Text color</span>
                        <input type="color" formControlName="fill" (ngModelChange)="setFill()">
                      </div>
                    </div>
                    <div class="col-md-1">
                    </div>
                    <div class="col-md-1 col-lg-1">
                      <div class="text-align mob-m-b-20 b-t-u-btn">
                        <button type="button" class="btn btn-snap-redOut height-36" (click)="setBold()">
                          B
                        </button>
                      </div>
                    </div>
                    <div class="col-md-1 col-lg-1">
                      <div class="text-align italic-btn-width mob-m-b-20 b-t-u-btn">
                        <button type="button" class="btn btn-snap-redOut height-36" (click)="setFontStyle()">
                          I
                        </button>
                      </div>
                    </div>
                    <div class="col-md-1 col-lg-1">
                      <div class="text-align b-t-u-btn">
                        <button type="button" class="btn btn-snap-redOut height-36"
                          (click)="setTextDecoration('underline')">
                          U
                        </button>
                      </div>
                    </div>
                  </div>
                  <br>
                  <div class="d-flex mt-4">
                    <div class="font-opacity">Font size</div>
                    <input type="range" formControlName="fontSize" (ngModelChange)="setFontSize()" step="1" min="1"
                      max="120">{{fontSize}}
                  </div>
                  <br>
                  <div class="d-flex mt-4">
                    <div class="font-opacity">Text opacity</div>
                    <input type="range" formControlName="opacity" (ngModelChange)="setOpacity()">{{opacity}}
                  </div>
                </div>
              </div>
              <div class="row d-flex justify-content-center">
                <button type="button" class="btn btn-snape-red text-uppercase m-3" (click)="saveDesign()">SAVE DESIGN
                </button>
              </div>
            </div>
            <!-- set brightness, contrast, saturation -->
          </form>
        </div>
      </div>
    </div>
  </div>
</section>