<div class="container-fluid">
  <section class="verify-email-sec">
    <div class="container">
      <div class="row">
        <div class="col-md-6 logintxt">
          <div class="card">
            <div class="card-body d-flex align-items-center justify-content-center">
              <div class="login-width">
                <div class="ml-3">
                  <h5 class="snape-red">Verify Email</h5>
                  <p>Please enter the One time password sent to your email id</p>
                </div>
                <form [formGroup]="emailVerifyForm" class="pt-2">
                  <div class="position-relative form-group">
                    <div class="col-md-12">
                      <input formControlName="code" id="inputEmail" placeholder="Enter OTP" required="" type="text"
                        class="form-control form-control">
                    </div>
                  </div>
                  <div class="position-relative form-group">
                    <div class="d-flex justify-content-center col-md-12 mt-4">
                      <button type="submit" (click)="sendEmail()"
                        class="btn btn-snape-bak btn-block text-uppercase">Continue</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 login-img">
          <img src="../../../assets/cover.png" />
        </div>
      </div>
    </div>
  </section>
</div>