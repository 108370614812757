<div *ngIf="loader == true">
    <app-spinner></app-spinner>
</div>
<section class="general-queries-sec">
    <div class="row p-t-20">
        <div class="col-md-12 col-xs-12 col-lg-12">
            <div class="general-queries mob-text-align">
                <h1>General Queries</h1>
            </div>
        </div>
    </div>
    <div class="row row-border">
        <div class="col-md-5 col-lg-7 col-xs-12">
            <div class="total-subscribers mob-text-align">
                <p><span>{{queryCount}}</span> General queries found</p>
            </div>
        </div>
        <div class="col-md-3 col-lg-2 col-xs-12">
            <div class="mob-p-b-10">
                <input type="text" #queryDate (change)="filterDate(queryDate.valueAsDate)" [max]="getToday()"
                    class="form-control cursor-pointer h-30" placeholder="Date" onfocus="(this.type='date')">
            </div>
        </div>
        <div class="col-md-3 col-lg-2 col-xs-12">
            <div class="mob-p-b-10">
                <angular2-multiselect [data]="statusArray" [(ngModel)]="selectedStatus"
                    [settings]="statusDropdownSettings" (onSelect)="onItemSelect($event)"
                    (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
                    (onDeSelectAll)="onDeSelectAll($event)">
                </angular2-multiselect>
            </div>
        </div>
        <div class="col-md-1 col-lg-1 col-xs-12 col-xl-1">
            <div class="reload-icon">
                <i class="fas fa-sync-alt" (click)="clearAll()"></i>
            </div>
        </div>
    </div>

    <div class="row p-t-20">
        <div class="col-md-12 col-xs-12 col-lg-12">
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Sl No</th>
                            <th scope="col">Date</th>
                            <th scope="col">User name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let query of queryArray | paginate:{
                            itemsPerPage: 10, currentPage: p, totalItems: queryCount}; let i = index">
                            <td>{{i+1}}</td>
                            <td>{{query.SK| date:'dd-MMM-YYYY'}}</td>
                            <td>{{query.name}}</td>
                            <td>{{query.email}}</td>
                            <td class="active-color" *ngIf="query?.query_status == 'Open'">Open</td>
                            <td class="inactive-color" *ngIf="query.query_status == 'Cancelled'">Cancelled</td>
                            <td class="inactive-color" *ngIf="query.query_status == 'Closed'">Closed</td>
                            <td class="action-icons">
                                <span class="m-r-10"><i class="fas fa-envelope" data-toggle="modal"
                                        data-target="#queryPopup" (click)="reply(query)"></i></span>
                                <span><i class="far fa-trash-alt" data-toggle="modal" data-target="#delete"
                                        (click)="remove(query)"></i></span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <hr>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6 col-xs-12 col-lg-6">
            <div class="pagination mob-text-align">
                <p>Showing {{(p-1)*10}} - {{((p-1)*10)+10}} of {{queryCount}}</p>
            </div>
        </div>
        <pagination-controls (pageChange)="p = $event"></pagination-controls>
    </div>
</section>

<!-- query Popup Modal start-->
<div class="modal fade" id="queryPopup" tabindex="-1" role="dialog" aria-labelledby="queryPopupLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="queryPopupLabel">Customer query</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="queryForm">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group has-float-label m-b-20">
                                <input class="form-control" type="text" formControlName="name"
                                    placeholder="Full name" />
                                <label>Full name</label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group has-float-label m-b-20">
                                <input class="form-control" type="text" formControlName="email" placeholder="Email" />
                                <label>Email</label>
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group has-float-label m-b-20">
                                <textarea class="form-control h-150" type="text" formControlName="message"
                                    placeholder="Message"></textarea>
                                <label>Message</label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group has-float-label m-b-20">
                                <textarea class="form-control h-150" type="text" formControlName="reply"
                                    placeholder="Resolution"></textarea>
                                <label>Resolution</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="mb-3">
                                <!-- Upload  -->
                                <div class="input-group" *ngIf="!imageURL">
                                    <input id="file-upload" type="file" name="fileUpload" accept="image/*"
                                        formControlName="fileSource" (change)="showPreview($event)" />
                                    <label for="file-upload" id="file-drag" class="label2">
                                        <div id="start">
                                            <div class="upload-icon"><i class="fas fa-upload" aria-hidden="true"></i>
                                            </div>
                                            <div class="upload-doc">Upload Document</div>
                                        </div>
                                    </label>
                                </div>
                                <div class="input-group" *ngIf="imageURL">
                                    <label id="file-drag" class="label2">
                                        <div class="imagePreview">
                                            <img class="flavor-img" alt="" [src]="imageURL">
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-check mob-text-align m-t-10">
                                <input type="checkbox" class="form-check-input" formControlName="status"
                                    id="exampleCheck1" value="closed">
                                <label class="form-check-label" for="exampleCheck1">Mark as closed</label>
                            </div>
                        </div>
                    </div>
                    <div class="row p-t-20">
                        <div class="col-md-12">
                            <div class="send-btn">
                                <button type="button" class="btn btn-link" data-dismiss="modal"
                                    (click)="cancel()">Cancel</button>
                                <button type="button" class="btn btn-pink" data-dismiss="modal"
                                    (click)="send()">Update</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- query Popup Modal end-->

<!-- delete modal starts-->
<div class="modal fade" id="delete" tabindex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <!-- <div class="row">
                    <div class="col-md-12">
                        <h4 class="snape-red mt-2">Delete Query</h4>
                    </div>
                </div> -->
                <div class="row">
                    <div class="col-md-12">
                        <div class="info-icon-img">
                            <img src="../../../assets/exclamation.png">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="confirm-txt">
                            <h6 class="mb-3">Are you sure?</h6>
                            <p class="mb-3"> Do you want to delete this Query?</p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 mfooterBtn d-flex justify-content-end">
                        <button type="button" class="btn color-cancel-btn mr-2" data-dismiss="modal">Cancel</button>
                        <button type="button" class="btn submit-btn ml-2" data-dismiss="modal"
                            (click)="cancelQuery(queryId)">Delete</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- delete modal end-->