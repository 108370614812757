<div *ngIf="loader == true">
    <app-spinner></app-spinner>
</div>
<section class="bulk-order-details-sec">
    <div class="row pt-3">
        <div class="col-md-12 col-xs-12 col-lg-12">
            <div class="bulk-order-details-heading">
                <h1>Bulk Order</h1>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 mt-4">
            <div class="vendor-list-breadcrumb">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item cursor-pointer" routerLink="/order/list"
                            routerLinkActive="router-link-active">
                            <a><i class="fas fa-shopping-cart"></i> Order Details </a>
                        </li>
                        <li class="breadcrumb-item cursor-pointer" aria-current="page" routerLink="/bulk-order"
                            routerLinkActive="router-link-active">
                            <a>New bulk order</a>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Customize cookie</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
    <section class="large-screen">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-xs-12">
                            <div class="bgred-side">
                                <div class="canvaDiv">
                                    <span class="dot" [hidden]="hideCustomizeACookieText">
                                        <div>
                                            <span class="customize-cookie-span">Please select a pack to <br> customize a
                                                cookie</span>
                                        </div>
                                    </span>
                                    <span class="dot2" [ngStyle]="{'background-image':' url('+ flavorImage +')'}"
                                        [hidden]="hideFullCookiePic">
                                    </span>
                                    <span class="dot" [ngStyle]="{'background-image':' url('+ flavorImage +')'}"
                                        [hidden]="hideEditableCookiePic">
                                        <div class="canvas-contain">
                                            <canvas id="canvas" width="300" height="300"
                                                style="border-radius: 50%;"></canvas>
                                        </div>
                                        <span class="remove" *ngIf="showRemoveBtn == true">
                                            <i class="far fa-trash-alt remove-btn" (click)="remove()"
                                                *ngIf="showRemoveBtn == true"
                                                [disabled]="!textAdded || !deleteBtnDisabled"></i>
                                        </span>
                                        <!-- <span class="trash-icon">
                        <button type="button" class="btn m-3 trash-btn-icon">
                          <span>
                            <i class="far fa-trash-alt"></i>
                          </span> 
                        </button>
                      </span> -->
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-1 col-lg-1 col-md-1 col-sm-6 col-xs-12"></div>

                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12"
                            style="margin: auto; height: 67vh; overflow-y: scroll; overflow-x: clip; margin-top: 11rem;">
                            <p>Select <img class="flaVrImg2" src="../../../assets/img/adDesign.svg"> to design a cookie
                            </p>
                            <!-- show 2d array -->
                            <!-- <div class="row">
                          <div class="col-md-2" *ngFor="let in of cookieBoxArray; let i=index">
                              <span *ngFor=" let k of in; let j=index">
                                  {{i}},{{j}}
                                  <span *ngIf="cookieBoxArray[i][j].flavor">                     
                                      <img class="flaVrImg" src="{{cookieBoxArray[i][j].flavor.image}}" (click)="design(i,j)" />
                                  </span>
                                  <span *ngIf="!cookieBoxArray[i][j]?.flavor && cookieBoxArray[i][j]?.flavor==null ">
                                      <img class="flaVrImg" src="../../../assets/adDesign.PNG" (click)="design(i,j)" />
                                  </span>
                                  
                              </span>
                          </div>  
                      </div> -->

                            <!-- 1 cookies -->
                            <div class="row" *ngIf="no_of_cookies == 1">
                                <div class="col-md-2" *ngFor="let in of cookieBoxArray;  let i=index">
                                    <div
                                        class="d-flex align-items-center justify-content-center snape-bgColor1 one-ckieAdd">
                                        <div *ngIf="cookieBoxArray[i]?.image_url" class="ckieAdd-bg-image-one-cookie"
                                            [ngStyle]="{'background-image':' url('+ cookieBoxArray[i]?.flavor?.image +')'}">
                                            <div class="ckieAdd-bg-color-one-cookie">
                                                <img class="flaVrImg-one-cookie cursor-pointer"
                                                    src="{{cookieBoxArray[i]?.image_url}}" (click)="design(i,in)"
                                                    [ngStyle]="boxIndex == i ? {'border': '5px solid #77d1e4'}: null" />
                                            </div>
                                        </div>
                                        <div *ngIf="cookieBoxArray[i]?.flavor && !cookieBoxArray[i].image_url">
                                            <img class="flaVrImg cursor-pointer"
                                                src="{{cookieBoxArray[i].flavor.image}}" (click)="design(i,in)"
                                                [ngStyle]="boxIndex == i ? {'border': '5px solid #77d1e4'}: null" />
                                        </div>
                                        <div *ngIf="!cookieBoxArray[i]?.flavor && cookieBoxArray[i]?.flavor==null ">
                                            <img class="flaVrImg cursor-pointer" src="../../../assets/img/adDesign.svg"
                                                (click)="design(i,in)"
                                                [ngStyle]="boxIndex == i ? {'border': '5px solid #77d1e4'}: null" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- 2 cookies -->
                            <div class="row" *ngIf="no_of_cookies == 2">
                                <div *ngFor="let in of counter(cookieBoxArray.length  / 2); let i=index;">
                                    <div class="col-md-3">
                                        <div
                                            class="d-flex align-items-center justify-content-center snape-bgColor2 ckieAdd">
                                            <span *ngFor="let num of counter(2);let j=index">
                                                <!-- <div *ngIf="cookieBoxArray[i*2+j]?.image_url" class="ckieAdd-bg-image-testing"> -->
                                                <div *ngIf="cookieBoxArray[i*2+j]?.image_url"
                                                    class="ckieAdd-bg-image-testing"
                                                    [ngStyle]="{'background-image':' url('+ cookieBoxArray[i*2+j]?.flavor?.image +')'}">

                                                    <div class="ckieAdd-bg-color-testing">
                                                        <img class="flaVrImg-two-cookie cursor-pointer"
                                                            src="{{cookieBoxArray[i*2+j]?.image_url}}"
                                                            (click)="design(i*2+j,cookieBoxArray[i*2+j])"
                                                            [ngStyle]="boxIndex == i*2+j? {'border': '5px solid #77d1e4'}: null" />
                                                    </div>
                                                </div>

                                                <span
                                                    *ngIf="cookieBoxArray[i*2+j]?.flavor && !cookieBoxArray[i*2+j].image_url">
                                                    <img class="flaVrImg cursor-pointer"
                                                        src="{{cookieBoxArray[i*2+j].flavor.image}}"
                                                        (click)="design(i*2+j,cookieBoxArray[i*2+j])"
                                                        [ngStyle]="boxIndex == i*2+j? {'border': '5px solid #77d1e4'}: null" />
                                                </span>
                                                <span
                                                    *ngIf="!cookieBoxArray[i*2+j]?.flavor && cookieBoxArray[i*2+j]?.flavor==null ">
                                                    <img class="flaVrImg cursor-pointer"
                                                        src="../../../assets/img/adDesign.svg"
                                                        (click)="design(i*2+j,cookieBoxArray[i*2+j])"
                                                        [ngStyle]="boxIndex == i*2+j? {'border': '5px solid #77d1e4'}: null" />
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- 3 cookies start-->
                            <div class="row" *ngIf="no_of_cookies == 3">
                                <div>
                                    <div class="col-md-3">
                                        <div
                                            class="d-flex align-items-center justify-content-center snape-bgColor3 ckieAdd">
                                            <span *ngFor="let num of counter(3);let j=index;">
                                                <!-- <div *ngIf="cookieBoxArray[3*((i+1)-1)+((j+1)-1)]?.image_url" class="ckieAdd-bg-image-testing"> -->
                                                <div *ngIf="cookieBoxArray[(j+1)-1]?.image_url"
                                                    class="ckieAdd-bg-image-testing"
                                                    [ngStyle]="{'background-image':' url('+ cookieBoxArray[((j+1)-1)]?.flavor?.image +')'}">

                                                    <div class="ckieAdd-bg-color-testing">
                                                        <img class="flaVrImg-three-cookie cursor-pointer"
                                                            src="{{cookieBoxArray[((j+1)-1)]?.image_url}}"
                                                            (click)="design(((j+1)-1),cookieBoxArray[(j+1)-1])"
                                                            [ngStyle]="boxIndex == ((j+1)-1)? {'border': '5px solid #77d1e4'}: null" />
                                                    </div>
                                                </div>

                                                <span
                                                    *ngIf="cookieBoxArray[((j+1)-1)]?.flavor && !cookieBoxArray[((j+1)-1)].image_url">
                                                    <img class="flaVrImg cursor-pointer"
                                                        src="{{cookieBoxArray[((j+1)-1)].flavor.image}}"
                                                        (click)="design(((j+1)-1),cookieBoxArray[((j+1)-1)])"
                                                        [ngStyle]="boxIndex == ((j+1)-1)? {'border': '5px solid #77d1e4'}: null" />
                                                </span>
                                                <span
                                                    *ngIf="!cookieBoxArray[((j+1)-1)]?.flavor && cookieBoxArray[((j+1)-1)]?.flavor==null ">
                                                    <img class="flaVrImg cursor-pointer"
                                                        src="../../../assets/img/adDesign.svg"
                                                        (click)="design((j+1)-1,cookieBoxArray[(j+1)-1])"
                                                        [ngStyle]="boxIndex == ((j+1)-1)? {'border': '5px solid #77d1e4'}: null" />
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- 6 cookies large screen start-->
                            <div class="row large-screen" *ngIf="no_of_cookies == 6">
                                <div class="col-md-4"
                                    *ngFor="let in of counter(cookieBoxArray.length  / 6); let i=index;">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="row snape-bgColor6 six-ckieAdd">
                                                <div class="col-md-4 p-l-r" *ngFor="let num of counter(6);let j=index;">
                                                    <!-- <div *ngIf="cookieBoxArray[6*((i+1)-1)+((j+1)-1)]?.image_url" class="ckieAdd-bg-image-6cookie"> -->
                                                    <div *ngIf="cookieBoxArray[6*((i+1)-1)+((j+1)-1)]?.image_url"
                                                        class="ckieAdd-bg-image-6cookie"
                                                        [ngStyle]="{'background-image':' url('+ cookieBoxArray[6*((i+1)-1)+((j+1)-1)]?.flavor?.image +')'}">

                                                        <div class="ckieAdd-bg-color-6cookie">
                                                            <img class="flaVrImg-six-cookie cursor-pointer"
                                                                src="{{cookieBoxArray[6*((i+1)-1)+((j+1)-1)]?.image_url}}"
                                                                (click)="design(6*((i+1)-1)+((j+1)-1),cookieBoxArray[6*((i+1)-1)+((j+1)-1)])"
                                                                [ngStyle]="boxIndex == 6*((i+1)-1)+((j+1)-1)? {'border': '5px solid #77d1e4'}: null" />
                                                        </div>
                                                    </div>

                                                    <div
                                                        *ngIf="cookieBoxArray[6*((i+1)-1)+((j+1)-1)]?.flavor && !cookieBoxArray[6*((i+1)-1)+((j+1)-1)].image_url">
                                                        <img class="flaVrImg cursor-pointer"
                                                            src="{{cookieBoxArray[6*((i+1)-1)+((j+1)-1)].flavor.image}}"
                                                            (click)="design(6*((i+1)-1)+((j+1)-1),cookieBoxArray[6*((i+1)-1)+((j+1)-1)])"
                                                            [ngStyle]="boxIndex == 6*((i+1)-1)+((j+1)-1)? {'border': '5px solid #77d1e4'}: null" />
                                                    </div>

                                                    <div
                                                        *ngIf="!cookieBoxArray[6*((i+1)-1)+((j+1)-1)]?.flavor && cookieBoxArray[6*((i+1)-1)+((j+1)-1)]?.flavor==null ">
                                                        <img class="flaVrImg cursor-pointer"
                                                            src="../../../assets/img/adDesign.svg"
                                                            (click)="design(6*((i+1)-1)+((j+1)-1),cookieBoxArray[6*((i+1)-1)+((j+1)-1)])"
                                                            [ngStyle]="boxIndex == 6*((i+1)-1)+((j+1)-1)? {'border': '5px solid #77d1e4'}: null" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- 6 cookies large screen start end-->


                            <span *ngIf="firstCookie == true">
                                <input class="mt-3 mb-4" type="checkbox" (change)="mirrorCookie($event.target)"
                                    [checked]="isCheckedfirst" /> Mirror first
                                cookie
                            </span>
                            <!-- <span *ngIf="firstBox == true">
                                <input class="mt-3 mb-4" type="checkbox" (change)="mirrorFirstBox($event.target)"
                                    [checked]="isCheckedbox" /> Mirror first
                                Box
                            </span> -->
                            <div *ngIf="showFlavour == true" class="mt-3" id="choosedFlavour">
                                <form [formGroup]="cookieBoxForm">
                                    <p *ngIf="!flavorName">Select a flavour: <span class="snape-red font-weight">
                                            {{flavorName}}</span></p>
                                    <p *ngIf="flavorName">Selected flavour: <span
                                            class="snape-red font-weight">{{flavorName}}</span></p>
                                    <!-- <p>Selected flavour: <span class="snape-red font-weight"> {{flavorName}}</span></p> -->

                                    <div class="row radDesign">
                                        <div class="col-md-2 mt-3" *ngFor="let flavour of FlavourArray">
                                            <div
                                                [ngStyle]="flavorName == flavour.name ? {'border': '5px solid #fbc7d0',
                  'background': '#fbc7d0', 'margin-bottom':'1rem', 'height': 'auto', 'width': '85px', 'height': '85px', 'border-radius': '50%'}: null">
                                                <!-- <input formControlName="flavour" type="radio" name="flavour" [value]="flavour.SK"
                  (change)="selectFlavour(flavour)" /> -->

                                                <img class="adDesign cursor-pointer"
                                                    (click)="getFlavorImg($event, flavour)" src="{{flavour.image}}" />
                                            </div>
                                            <p
                                                [ngStyle]="flavorName == flavour.name ? {'color': '#FF5C7B', 'font-weight':'600'}: null">
                                                {{flavour.name}}</p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="row m-t-20" *ngIf="showImage == true">
                                <div class="col-md-12 col-xs-12 col-lg-12">
                                    <div class="text-align" id="addImageSection">
                                        <button class="btn add-image-btn m-3 font-weight" type="button"
                                            (click)="addImage()">
                                            <i class="far fa-images m-r-10" aria-hidden="true"></i> Add Image</button>

                                        <button class="btn add-text-btn m-3 font-weight" type="button"
                                            (click)="addText()"> <i class="fa fa-text-width m-r-10"
                                                aria-hidden="true"></i> Add Text</button>
                                        <!-- <button type="button" class="btn btn-snape-red m-3" (click)="saveDesign()">Save Design
                            </button> -->
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="text-center mt-4" *ngIf="showSave==true && showImage == false">
                          <button type="button" class="btn btn-snape-red text-center" (click)="saveDesign()">Save Design
                          </button>
                      </div> -->
                            <div class="row">
                                <div class="col-md-12 col-xs-12 col-lg-12">
                                    <form [formGroup]="cookieForm">
                                        <div class=""
                                            *ngIf="imageSelected == true && showText == false && editImage ==false">
                                            <div (dragover)="false" (dragend)="false" (drop)="handleDrop($event)">
                                                <br />
                                                <!-- <div class="drag-text">
                                                      <img width="20%" src="../../../assets/Icon.png" />
                                                      <p class="mt-2" style="margin: unset;">Image Upload</p>
                                                      <p>Drag and drop a file here or click</p>
                                                  </div>
                                                  <input class="cus-imgIn" (change)="uploadImage($event)" #fileInput type="file"
                                                      id="file" formControlName="filesource"><br /><br /> -->
                                                <div class="row d-flex justify-content-center">
                                                    <div class="col-md-12">
                                                        <div class="edit-info-bg">
                                                            <p class="edit-info-txt">If you are adding image. Please
                                                                make sure your image sits within
                                                                the red circle</p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 col-xs-12 col-lg-6">
                                                        <div class="upload-btn-align">
                                                            <div class="file-uploadC" id="newtarget">
                                                                <label for="upload" class="file-uploadC__label">
                                                                    <!-- <span><img width="14%" src="../../../assets/Icon.png" />
                                      </span> -->
                                                                    <span class="material-icons-outlined upload-icon">
                                                                        backup
                                                                    </span>
                                                                    Upload image
                                                                </label>
                                                                <input id="upload" class="file-uploadC__input"
                                                                    (change)="uploadImage($event)" #fileInput
                                                                    type="file" formControlName="filesource"
                                                                    accept=".jpg,.jpeg,.png,.jfif,.svg,.tiff,.gif">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row d-flex justify-content-center">
                                                    <div class="col-md-12 col-xs-12 col-lg-12">
                                                        <p class="text-center mt-4 font-weight">OR</p>
                                                        <p class="text-center font-weight">Choose from template image
                                                        </p>
                                                        <ul class="nav nav-tabs " role="tablist">
                                                            <li class="nav-item active">
                                                                <a class="nav-link active temp-img" data-toggle="tab"
                                                                    href="#myImages" (click)="key(myImages)">My
                                                                    Images</a>
                                                            </li>
                                                            <li class="nav-item" *ngIf="favBtn == true">
                                                                <a class="nav-link temp-img" data-toggle="tab"
                                                                    href="#fav" (click)="key(fav)">Favourites</a>
                                                            </li>
                                                            <li class="nav-item"
                                                                *ngFor="let recipient of categories | keyvalue">
                                                                <a class="nav-link temp-img" data-toggle="tab"
                                                                    href="#{{recipient.key}}"
                                                                    (click)="key(recipient.key)">{{recipient.key}}</a>
                                                            </li>
                                                        </ul>
                                                        <div class="tab-content active">
                                                            <div id="myImages" class="container tab-pane "
                                                                [ngClass]="{'active': selectedKey == myImages}">
                                                                <br>
                                                                <div class="row active" *ngIf="myImages == null">
                                                                    <div class="col-md-6">
                                                                        <div class="no-images-found">
                                                                            No images found
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row active">
                                                                    <div class="col-md-3 m-t-20"
                                                                        *ngFor="let image of myImages">
                                                                        <img class="bRadius50 cursor-pointer"
                                                                            (click)="getMyImage(image)"
                                                                            src="{{image.image}}" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div id="fav" class="container tab-pane fade"
                                                                [ngClass]="{'active': selectedKey == fav}"
                                                                *ngIf="favBtn == true"><br>
                                                                <div class="row" *ngIf="images == null">
                                                                    <div class="col-md-3">
                                                                        No images found
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-md-3 m-t-20"
                                                                        *ngFor="let image of images">
                                                                        <img class="bRadius50 cursor-pointer"
                                                                            (click)="getTemplate(image)"
                                                                            src="{{image.image}}" />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div *ngFor="let recipient of categories | keyvalue">
                                                                <div id="{{recipient.key}}"
                                                                    class="container tab-pane fade"
                                                                    *ngIf="selectedKey == recipient.key"><br>
                                                                    <div class="row">
                                                                        <div class="col-md-3 m-t-20"
                                                                            *ngFor="let image of recipient.value">
                                                                            <img class="bRadius50 cursor-pointer"
                                                                                (click)="getTemplate(image)"
                                                                                src="{{image.image}}" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <ng-template #chooseFlav let-modal>
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h6 class="modal-title" id="deleModalLabel">Are you sure?</h6>
                                                    <button type="button" class="close" data-dismiss="modal"
                                                        aria-label="Close" (click)="modal.close(false)">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div class="modal-body">
                                                    <div class="col-md-12">
                                                        <div class="row d-flex justify-content-center mb-4">
                                                            Are you really want to change flavour
                                                        </div>
                                                        <div class="row form-group mt-4">
                                                            <div
                                                                class="col-md-6 d-flex justify-content-end align-items-center">
                                                                <button type="button"
                                                                    class="btn-cancel-grey cursor-pointer form-control"
                                                                    data-dismiss="modal"
                                                                    (click)="modal.close(false)">Cancel</button>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <button data-dismiss="modal"
                                                                    class="btn-snape-red cursor-pointer form-control"
                                                                    data-dismiss="modal" (click)="modal.close(true)"
                                                                    type="button">Apply</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                        <ng-template #selectnew let-modal>
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h6 class="modal-title" id="deleModalLabel">Are you sure?</h6>
                                                    <button type="button" class="close" data-dismiss="modal"
                                                        aria-label="Close" (click)="modal.close()">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div class="modal-body">
                                                    <div class="col-md-12">
                                                        <div class="row d-flex justify-content-center mb-4">
                                                            Customization is not completed, would you like to continue ?
                                                        </div>
                                                        <div class="row form-group mt-4">
                                                            <div
                                                                class="col-md-6 d-flex justify-content-end align-items-center">
                                                                <button type="button"
                                                                    class="btn-cancel-grey cursor-pointer form-control"
                                                                    data-dismiss="modal"
                                                                    (click)="modal.close()">Cancel</button>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <button data-dismiss="modal"
                                                                    class="btn-snape-red cursor-pointer form-control"
                                                                    data-dismiss="modal" (click)="modal.close(true)"
                                                                    type="button">Continue</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                        <ng-template #modifyimage let-modal>
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h6 class="modal-title" id="deleModalLabel">Are you sure?</h6>
                                                    <button type="button" class="close" data-dismiss="modal"
                                                        aria-label="Close" (click)="modal.close(false)">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div class="modal-body">
                                                    <div class="col-md-12">
                                                        <div class="row d-flex justify-content-center mb-4">
                                                            Are you really want to modify this cookie ?
                                                        </div>
                                                        <div class="row form-group mt-4">
                                                            <div
                                                                class="col-md-6 d-flex justify-content-end align-items-center">
                                                                <button type="button"
                                                                    class="btn-cancel-grey cursor-pointer form-control"
                                                                    data-dismiss="modal"
                                                                    (click)="modal.close(false)">Cancel</button>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <button data-dismiss="modal"
                                                                    class="btn-snape-red cursor-pointer form-control"
                                                                    data-dismiss="modal" (click)="modal.close(true)"
                                                                    type="button">Modify</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                        <div *ngIf="showText ==true" class="col-md-12 mt-4">
                                            <!-- <button class="btn cnp-btn ml-4 h38btn" style="margin-top: -1.8rem;" (click)="remove()">Remove</button>
                                  <br /><br /> -->
                                            <div class="row" *ngIf="showText == true">
                                                <div class="col-md-12">
                                                    <p class="mt-4rem text-center font-weight fontsiz18">ADD/EDIT TEXT
                                                    </p>
                                                    <hr class="hr-2">
                                                    <div class="edit-info-bg">
                                                        <p class="edit-info-txt">If you are adding text to this image.
                                                            Please make sure your text sits
                                                            within the red circle</p>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="d-flex" id="addText">
                                                                <input type="text" placeholder="Add Text Here"
                                                                    class="form-control pink-border" id="textinput"
                                                                    formControlName="textString" value="{{textString}}"
                                                                    (keyup)="enableApplyBtn()">
                                                                <button class="btn btn-snape-red apply-btn"
                                                                    id="apply-text" data-toggle="tooltip"
                                                                    data-placement="bottom" type="button"
                                                                    (click)="applyText()"
                                                                    [disabled]="!enableApply">Apply</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-4">
                                                        <div class="col-md-12">
                                                            <select formControlName="fontFamily"
                                                                class="form-control pink-border"
                                                                value="props.fontFamily" (change)="setFontFamily()"
                                                                aria-placeholder="Select your Font">
                                                                <option value="" disabled selected hidden>Select your
                                                                    Font</option>
                                                                <option value="arial">Arial</option>
                                                                <option value="helvetica" selected>Helvetica</option>
                                                                <option value="verdana">Verdana</option>
                                                                <option value="courier">Courier</option>
                                                                <option value="Roboto">Roboto</option>
                                                                <option value="Open Sans">Open Sans</option>
                                                                <option value="Zilla Slab">Zilla Slab</option>
                                                                <option value="Lato">Lato</option>
                                                                <option value="Times New Roman">Times New Roman</option>
                                                                <option value="Fresca">Fresca</option>
                                                                <option value="Raleway">Raleway</option>
                                                                <option value="Open Sans Condensed">Open Sans Condensed
                                                                </option>
                                                                <option value="Indie Flower">Indie Flower</option>
                                                                <option value="Josefin Sans">Josefin Sans</option>
                                                                <option value="VT323">VT323</option>
                                                                <option value="Pacifico">Pacifico</option>
                                                                <option value="Gloria Hallelujah">Gloria Hallelujah
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="row mt-4">
                                                        <div class="col-md-4 col-lg-4">
                                                            <div
                                                                class="txt-box-pink-border background-width mob-m-b-20">
                                                                <span class="p-r-10">Background</span>
                                                                <input type="color" formControlName="BgFill"
                                                                    (ngModelChange)="setBgFill()">
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4 col-lg-4">
                                                            <div class="txt-box-pink-border text-width mob-m-b-20">
                                                                <span class="p-r-10">Text color</span>
                                                                <input type="color" formControlName="fill"
                                                                    (ngModelChange)="setFill()">
                                                            </div>
                                                        </div>
                                                        <div class="col-md-1">
                                                        </div>
                                                        <div class="col-md-1 col-lg-1">
                                                            <div class="text-align mob-m-b-20 b-t-u-btn">
                                                                <button type="button"
                                                                    class="btn btn-snap-redOut height-36"
                                                                    (click)="setBold()">
                                                                    B
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-1 col-lg-1">
                                                            <div
                                                                class="text-align italic-btn-width mob-m-b-20 b-t-u-btn">
                                                                <button type="button"
                                                                    class="btn btn-snap-redOut height-36"
                                                                    (click)="setFontStyle()">
                                                                    I
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-1 col-lg-1">
                                                            <div class="text-align b-t-u-btn">
                                                                <button type="button"
                                                                    class="btn btn-snap-redOut height-36"
                                                                    (click)="setTextDecoration('underline')">
                                                                    U
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br>
                                                    <div class="d-flex mt-4">
                                                        <div class="font-opacity">Font size</div>
                                                        <input type="range" formControlName="fontSize"
                                                            (ngModelChange)="setFontSize()" step="1" min="1"
                                                            max="120">{{fontSize}}
                                                    </div>
                                                    <br>
                                                    <div class="d-flex mt-4">
                                                        <div class="font-opacity">Text opacity</div>
                                                        <input type="range" formControlName="opacity"
                                                            (ngModelChange)="setOpacity()">{{opacity}}
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- end -->
                                            <div class="text-center mt-4 mb-4rem">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div *ngIf="showText == true">
                                                            <button class="btn btn-snap-redOut m-r-10" id="add-text"
                                                                data-toggle="tooltip" data-placement="bottom"
                                                                type="button" (click)="editAddImage()"
                                                                *ngIf="editImage==true">Edit
                                                                Image</button>
                                                            <button class="btn btn-snap-redOut m-r-10" id="edit-text"
                                                                data-toggle="tooltip" data-placement="bottom"
                                                                type="button" (click)="addImage()"
                                                                *ngIf="editImage==false">Add
                                                                Image</button>
                                                            <button type="button" class="btn btn-snape-red text-center"
                                                                (click)="saveDesign()" [disabled]="!textAdded">SAVE
                                                                DESIGN</button>
                                                            <!-- <button type="button" *ngIf="showRemoveBtn == true"
                                  class="btn btn-snap-redOut m-l-10 text-center" (click)="remove()"
                                  [disabled]="!textAdded">Delete</button> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- set brightness, contrast, saturation -->
                                        <div class="col-md-12"
                                            *ngIf="showText==false && imageSelected==true && editImage==true">
                                            <p class="mt-4rem text-center font-weight fontsiz18">EDIT IMAGE
                                            </p>
                                            <hr class="hr-2">

                                            <div class="row mHeightCus mt-4">
                                                <div class="col-md-3">
                                                    <span
                                                        class="material-icons-outlined brightness-span lit-gray-color">
                                                        light_mode
                                                    </span>
                                                    <div class="lit-gray-color">Brightness</div>
                                                </div>
                                                <div class="col-md-9 m-t-10">
                                                    <input class="rangeImg" type="range" formControlName="brightness"
                                                        value="0.1" min="-1" max="1" step="0.003921"
                                                        (ngModelChange)="setBrightness()">{{brightness}}
                                                </div>
                                            </div>
                                            <div class="row mHeightCus">
                                                <div class="col-md-3">
                                                    <span><i
                                                            class="fas fa-adjust contrast-span lit-gray-color"></i></span>
                                                    <div class="lit-gray-color">Contrast</div>
                                                </div>
                                                <div class="col-md-9 m-t-10">
                                                    <input type="range" value="0.1" min="-1" max="1" step="0.003921"
                                                        formControlName="contrast"
                                                        (ngModelChange)="setContrast()">{{contrast}}
                                                </div>
                                            </div>
                                            <div class="row mHeightCus">
                                                <div class="col-md-3">
                                                    <span
                                                        class="material-icons-outlined saturation-span lit-gray-color">
                                                        flaky
                                                    </span>
                                                    <div class="lit-gray-color">Saturation</div>
                                                </div>
                                                <div class="col-md-9 m-t-10">
                                                    <input type="range" value="0.1" min="-1" max="1" step="0.003921"
                                                        formControlName="saturation"
                                                        (ngModelChange)="setSaturation()">{{saturation}}
                                                </div>
                                            </div>
                                            <div class="row d-flex justify-content-center">
                                                <button class="btn btn-snap-redOut m-3" type="button"
                                                    (click)="addText()"> <i class="fa fa-text-width"
                                                        aria-hidden="true"></i> Add Text</button>
                                                <button type="button" class="btn btn-snape-red text-uppercase m-3"
                                                    (click)="saveDesign()">SAVE
                                                    DESIGN
                                                </button>
                                                <!-- <button type="button" *ngIf="showRemoveBtn == true" class="btn btn-snap-redOut m-3 text-center"
                            (click)="remove()">Delete</button> -->
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div class="row d-flex justify-content-center">
                                <button type="button" class="btn btn-snape-red  m-3" *ngIf="enableCartBtn == true"
                                    [hidden]="activateButton == true" (click)="revisedArray()">Save Image
                                </button>
                                <button type="button" class="btn btn-snape-red  m-3" *ngIf="activateButton == true"
                                    (click)="gotoCart()">Continue to Cart
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>