import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { TokenStorageService } from './token/token-storage.service';
import { catchError, retry } from 'rxjs/operators';


const TOKEN_HEADER_KEY = 'Authorization';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private route: Router,
    private tokenStorageService: TokenStorageService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    const token = this.tokenStorageService.getIdToken();
    if (token != null) {
      // for Spring Boot back-end
      if (request.headers.get("skip")) {
        const skipIntercept = request.headers.has('skip');
        if (skipIntercept) {
          request = request.clone({
            headers: request.headers.delete('skip')
          });
        }
      }
      else{
        request = request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) })
      }
    }
    else{
      if (request.headers.get("skip")) {
        const skipIntercept = request.headers.has('skip');
        if (skipIntercept) {
          request = request.clone({
            headers: request.headers.delete('skip')
          });
        }
      }
    }

    return next.handle(request)
      .pipe(
        catchError((error) => {
          console.log("Error in interceptor")
          console.log(error)
          if (error.status == 401) {
            this.tokenStorageService.signOut()
            this.route.navigate(["/login"])
          }
          return throwError(error)
        })
      )
  }
}
