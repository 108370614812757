<div *ngIf="loader == true">
  <app-spinner></app-spinner>
</div>
<section class="customer-list-sec">
  <div class="row p-t-20">
    <div class="col-md-10 col-xs-12 col-lg-10">
      <div class="customer-list-heading mob-text-align">
        <h1>Customer List</h1>
      </div>
    </div>
    <div class="col-md-2 col-xs-12 col-lg-2">
      <div class="excel-export cursor-pointer mob-text-align" (click)="exportToExcel()">
        <p>Export to excel
          <i class="fas fa-file-excel cursor-pointer"></i>
        </p>
      </div>
    </div>
  </div>
  <div class="row row-border" [formGroup]="filterForm">
    <div class="col-md-3 col-lg-5 col-xs-12">
      <div class="total-customer mob-text-align">
        <p><span>{{totalCustomer}}</span> users</p>
      </div>
    </div>
    <!-- <div class="col-md-2 col-lg-2 col-xs-12">
      <div class="mob-p-b-10">
      <button type="button" (click)="clearAll()" class="form-control cursor-pointer">clear all</button>
      </div>
    </div> -->
    <div class="col-md-3 col-lg-2 col-xs-12">
      <div class="mob-p-b-10">
        <input type="text" #queryDate (change)="applyFilter()" formControlName="joiningDate" class="form-control cursor-pointer"
          placeholder="Joining date" onfocus="(this.type='date')">
      </div>
    </div>
    <div class="col-md-3 col-lg-2 col-xs-12">
      <div class="mob-p-b-10">
        <input type="text" #queryLastDate (change)="applyFilter()" formControlName="orderDate"
          class="form-control cursor-pointer" placeholder="Last order date" onfocus="(this.type='date')">
      </div>
    </div>
    <div class="col-md-2 col-lg-2 col-xs-12">
      <div class="mob-p-b-10">
        <angular2-multiselect [data]="statusArray" [(ngModel)]="selectedStatus" [settings]="statusDropdownSettings" formControlName="status"
          (onSelect)="applyFilter()" (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
          (onDeSelectAll)="onDeSelectAll($event)">
        </angular2-multiselect>
      </div>
    </div>
    <div class="col-md-1 col-lg-1 col-xs-12 col-xl-1">
      <div class="reload-icon">
        <i class="fas fa-sync-alt" (click)="clearAll()"></i>
      </div>
    </div>
  </div>

  <div class="row p-t-20">
    <div class="col-md-12 col-xs-12 col-lg-12">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">User name</th>
              <th scope="col">Email id</th>
              <th scope="col">Contact number</th>
              <th scope="col">Joining date</th>
              <th scope="col">Last login time</th>
              <th scope="col">Last order date</th>
              <th scope="col">Status</th>
              <th scope="col">Permission</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor=" let customers of customerList  | paginate:  { itemsPerPage: 10,
                currentPage: p,
                totalItems: totalCustomer}">
              <td>{{customers.name}}</td>
              <td>{{customers.email}}</td>
              <td>{{customers?.phone_number}}</td>
              <td>{{customers.created_at | date:'dd MMM YYYY'}}</td>
              <td>{{customers?.Last_login_date| date:'dd MMM YYYY'}}
                <br>
                {{customers?.Last_login_date| date:'h:mm a'}}
              </td>
              <td>{{customers?.Last_order_date| date:'dd MMM YYYY'}}</td>
              <td class="active-color" *ngIf="customers.user_status == 'active'">Active</td>
              <td class="inactive-color" *ngIf="customers.user_status == 'inactive'">Inactive</td>
              <td>
                <div>
                  <button type="button" class="btn deactivate-btn" *ngIf="customers.user_status == 'active'"
                    data-toggle="modal" data-target="#deactivateModal"
                    (click)="deactiveOrRevokeAccount(customers.id, customers.user_status, customers.SK)">Deactivate
                    account</button>
                  <button type="button" class="btn revoke-btn" *ngIf="customers.user_status == 'inactive'"
                    data-toggle="modal" data-target="#deactivateModal"
                    (click)="deactiveOrRevokeAccount(customers.id, customers.user_status, customers.SK)">Revoke
                    account</button>
                </div>
                <!-- <div *ngIf="customers.user_status == 'active'">
                  <label class="switch">
                    <input type="checkbox" checked  data-toggle="modal" data-target="#deactivateModal"
                    (click)="deactiveOrRevokeAccount(customers.id, customers.user_status, customers.email)">
                    <span class="slider round"></span>
                  </label>
                </div>
                <div *ngIf="customers.user_status == 'inactive'">
                  <label class="switch">
                    <input type="checkbox" checked data-toggle="modal" data-target="#deactivateModal"
                    (click)="deactiveOrRevokeAccount(customers.id, customers.user_status, customers.email)">
                    <span class="slider round"></span>
                  </label>
                </div> -->
              </td>
            </tr>
          </tbody>
        </table>
        <hr>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 col-xs-12 col-lg-6">
      <div class="pagination mob-text-align">
        <p>Showing {{(p-1)*10}} - {{((p-1)*10)+10}} of {{totalCustomer}}</p>
      </div>
    </div>
    <pagination-controls (pageChange)="p = $event"></pagination-controls>
  </div>
</section>

<!-- delete modal start -->
<div class="modal fade" id="deactivateModal" tabindex="-1" role="dialog" aria-labelledby="deactivateModalModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <!-- <div class="row">
          <div class="col-md-12">
            <div class="text-center">
              <h6 class="modal-title" id="exampleModalLongTitle" *ngIf="customerStatus =='active'">Confirm deactivate?
              </h6>
              <h6 class="modal-title" id="exampleModalLongTitle" *ngIf="customerStatus =='inactive'">Confirm activate?
              </h6>
            </div>
          </div>
        </div> -->
        <div class="row">
          <div class="col-md-12">
            <div class="info-icon-img">
              <img src="../../../assets/exclamation.png">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="confirm-txt">
              <h6 class="mb-3">Are you sure?</h6>
              <p class="mb-3"> Do you really want to {{popupText}} this account?</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mfooterBtn d-flex justify-content-end">
            <button type="button" class="btn color-cancel-btn" data-dismiss="modal">No</button>

            <button data-dismiss="modal" class="btn submit-btn" data-dismiss="modal" type="button"
              (click)="changeUserStatus()">Yes</button>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- delete modal end -->


<!-- jhhhhhhhhhhhhhhhhhhhh -->
<!-- <div class="modal fade" id="deactivateModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="exampleModalLongTitle" *ngIf="customerStatus =='active'">Confirm deactivate?
            </h6>
            <h6 class="modal-title" id="exampleModalLongTitle" *ngIf="customerStatus =='inactive'">Confirm activate?
            </h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-center">
            Are you sure you want to {{popupText}} this account?
          </div>
          <div class="row margin">
            <div class="col-md-6 d-flex justify-content-end align-items-center">
              <button type="button" class="btn-no-grey cursor-pointer form-control" data-dismiss="modal">No</button>
            </div>
            <div class="col-md-6">
              <button data-dismiss="modal" class="btn-snape-red cursor-pointer form-control" data-dismiss="modal"
                type="button" (click)="changeUserStatus()">Yes</button>
            </div>
          </div>
        </div>
      </div>
    </div> -->