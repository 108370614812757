import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonService } from 'src/app/common/common.service';
import { ExcelService } from 'src/app/common/excel.service';
import { NEWSLETTER_STATUS } from 'src/app/config/commonConfig';
import * as _ from 'underscore';

@Component({
  selector: 'app-news-letter',
  templateUrl: './news-letter.component.html',
  styleUrls: ['./news-letter.component.css']
})
export class NewsLetterComponent implements OnInit {


  // variable declaration 
  statusDropdownSettings;
  selectedStatus = [];
  statusArray = NEWSLETTER_STATUS;
  newsletterList = [];
  totalnewsLetter = 1
  selectedItems = [];
  activeList = [];
  inactiveList = [];
  filteredDate = []
  currentList = []
  newsLetterId: any
  newsLetterStatus
  popupText: string;
  newsLetterEmail: any;
  config: any;
  p: number = 1;
  total: number = 50
  currentPage = 1
  startPage: number = 1;
  lastPage: number = 5;
  newLetterListArray: any;
  newsLetterCopy = []
  today:Date
  dateFilter:any
  filterName:any
  filterForm:FormGroup
  constructor(
    private api: CommonService,
    private excelService: ExcelService,
    private fb:FormBuilder
  ) {
    this.filterForm = this.fb.group({
      filterDate:[''],
      status:['']
    })
   }

  ngOnInit(): void {
    this.getnewsLetterList();
    this.getdropDownSettings();
    this.today = new Date()
  }
  getToday(): string {
    return new Date().toISOString().split('T')[0]
  }
  getdropDownSettings() {
    this.statusDropdownSettings = {
      singleSelection: true,
      primaryKey: 'id',
      labelKey: 'name',
      text: "Filter by Status",
      enableSearchFilter: false,
      lazyLoading: true,
      showCheckbox: true,
      clearAll: false,
      badgeShowLimit: 1,
      classes: "myclass custom-class"
    };
  }
  
  onItemSelect(item: any) {
    debugger
    this.p = 1
    this.filterName = item
    if (item.id == '1' && this.selectedStatus?.length == 1) {
      this.newsletterList = this.activeList
    }
    else if (item.id == '2' && this.selectedStatus?.length == 1) {
      console.log(item, 'iii');
      this.newsletterList = this.inactiveList
    }
    if (this.selectedStatus?.length == 2)
      this.newsletterList = this.activeList.concat(this.inactiveList)
      if(this.dateFilter){
        this.newsletterList = _.filter(this.newsletterList, function (res) {
          return res.created_at.slice(0, 10) === this.dateFilter
        })
      }
    this.totalnewsLetter = this.newsletterList?.length
  }
  OnItemDeSelect(item: any) {
    this.p = 1
    console.log(this.selectedStatus, 'sele status')
    if (item.id == '1' && this.selectedStatus?.length == 1) {
      this.newsletterList = this.inactiveList
    }
    else if (item.id == '2' && this.selectedStatus?.length == 1) {
      console.log(item, 'iii');
      this.newsletterList = this.activeList
    }
    if (this.selectedStatus?.length == 0)
      this.newsletterList = this.activeList.concat(this.inactiveList)
    this.totalnewsLetter = this.newsletterList?.length
  }
  onSelectAll(items: any) {
    this.p = 1
    this.newsletterList = this.activeList.concat(this.inactiveList)
    this.totalnewsLetter = this.newsletterList?.length
  }
  onDeSelectAll(items: any) {
    this.p = 1
    this.newsletterList = this.activeList.concat(this.inactiveList)
    this.totalnewsLetter = this.newsletterList?.length
  }
  applyFilter(){
    debugger
    this.newsletterList = this.newLetterListArray
   
    if(this.filterForm.controls.status.value.length > 0){
      console.log(this.filterForm.controls.status.value)
      if(this.filterForm.controls.status.value[0].name == 'Unsubscribed'){
        this.newsletterList = _.filter(this.newsletterList, function (res) {
          return res.subscription_status === 'Unsubscribed'
        })
      }else{
        this.newsletterList = _.filter(this.newsletterList, function (res) {
          return res.subscription_status === 'Subscribed'
        })
      }
      
    }
    if(this.filterForm.controls.filterDate.value){
      console.log(this.filterForm.controls.filterDate.value)
      let filter_date = this.formatDate(this.filterForm.controls.filterDate.value)
      this.newsletterList = _.filter(this.newsletterList, function (res) {
        return res.created_at.slice(0, 10) === filter_date
      })

    }
    this.totalnewsLetter = this.newsletterList?.length
  }
  getnewsLetterList() {
    this.api.getNewsletter().subscribe(item => {
      this.newLetterListArray = item['data']['Items']
      this.newsletterList = _.filter(this.newLetterListArray, function (res) {
        return res.subscription_status == 'Subscribed' || res.subscription_status == 'Unsubscribed'
      })
      this.newsletterList = _.sortBy(this.newsletterList, function (o) {
        return o.created_at
      })
      this.newsLetterCopy = this.newsletterList.reverse();
      this.totalnewsLetter = this.newsletterList?.length
      console.log(this.newsletterList, 'list', this.totalnewsLetter)
      this.activeList = _.filter(this.newsletterList, function (res) {
        return res.subscription_status === 'Subscribed'
      })
      this.inactiveList = _.filter(this.newsletterList, function (res) {
        return res.subscription_status === 'Unsubscribed'
      })
    })
    this.totalnewsLetter = this.newsletterList?.length
  }
  filterDate(queryDate: any) {
    debugger
    this.p = 1
    let filter_date = this.formatDate(queryDate)
    this.dateFilter = filter_date
    if(this.filterName){
      if (this.filterName.id == '1' && this.selectedStatus?.length == 1) {
        this.newsletterList = this.activeList
      }
      else if (this.filterName.id == '2' && this.selectedStatus?.length == 1) {
        
        this.newsletterList = this.inactiveList
      }
      this.newsletterList = _.filter(this.newsletterList, function (res) {
        return res.created_at.slice(0, 10) === filter_date
      })
    }else{
      this.newsletterList = _.filter(this.newsLetterCopy, function (res) {
        return res.created_at.slice(0, 10) === filter_date
      })
    }
    
    this.totalnewsLetter = this.newsletterList?.length
  }
  clearAll() {
      window.location.reload();
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
  dateInReverse(date) {
    var datetime = date
    var bits = datetime.split(/\D/);
    var dateObject = new Date(bits[0], --bits[1], bits[2], bits[3], bits[4]);
    const formattedDate = dateObject.toLocaleDateString('en-GB', {
      day: 'numeric', month: 'short', year: 'numeric'
    }).replace(/ /g, '-');
    return formattedDate
  }

}
