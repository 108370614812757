import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/common/common.service';
import { NOTIFY_STATUS } from 'src/app/config/commonConfig';
import * as _ from 'underscore';
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {


  // variable declaration 
  statusDropdownSettings;
  selectedStatus = [];
  loader = false
  statusArray = NOTIFY_STATUS;
  notificationArray = []
  notificationId: any;
  config: any;
  p: number = 1;
  total: number = 50
  currentPage = 1
  startPage: number = 1;
  lastPage: number = 5;
  totalNotification: number;
  notificationArrayCopy:any=[]
  type: any;
  filterName:any
  dateFilter:any
  extra: { product_id: string; order_id: any; };
  constructor(
    private api: CommonService,
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.loader = true
    this.getNotifications()
    this.statusDropdownSettings = {
      singleSelection: true,
      primaryKey: 'id',
      labelKey: 'name',
      text: "Filter by Category",
      enableSearchFilter: false,
      lazyLoading: true,
      showCheckbox: true,
      clearAll: false,
      badgeShowLimit: 1,
      classes: "myclass custom-class"
    };

  }
  getToday(): string {
    return new Date().toISOString().split('T')[0]
  }
 
  onSelectAll(event) {

  }
  onDeSelectAll(event) {

  }
  getNotifications(){
    this.api.getNotification().subscribe(res=>{
      this.notificationArray = res['data']['Items']
      this.notificationArrayCopy = this.notificationArray
      this.loader = false
      this.notificationArray = _.sortBy(this.notificationArray, msg => {
        return msg.created_at
      })
      this.notificationArray.reverse();
      console.log(this.notificationArray, 'res')
      this.totalNotification = this.notificationArray?.length
    })
  }
  onItemSelect(event) {
    debugger
    this.p = 1
    if (event.name == 'Read' || event.name == 'Unread') {
      let name
      if (event.name == 'Read') {
        name = 'read'
      }
      else {
        name = 'unread'
      }
     
      if(this.dateFilter){
        let date = this.dateFilter
        this.notificationArray = _.filter(this.notificationArrayCopy, function (res) {
          return res.created_at.slice(0, 10) === date && res.status == name
        })
        
      }else{
        this.notificationArray = _.filter(this.notificationArrayCopy, (item) => {
          return item.status == name
         })
      }
      this.filterName = name
      this.totalNotification = this.notificationArray?.length
    }
  }
  OnItemDeSelect(event) {

  }
  filterDate(queryDate: any) {
    debugger
    let filter_date = this.formatDate(queryDate)
    this.dateFilter = filter_date
   
    if(this.filterName){
      this.notificationArray = _.filter(this.notificationArrayCopy, (item) => {
        return item.status == this.filterName && item.created_at.slice(0, 10) === filter_date
       })
    }else{
      this.notificationArray = _.filter(this.notificationArrayCopy, function (res) {
        return res.created_at.slice(0, 10) === filter_date
      })
    }
    this.totalNotification = this.notificationArray?.length
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
  updateStatus(notification) {
    debugger
    let email = notification?.buyer_email
    if(notification.type != 'message'){
      email = email.substring(email.indexOf('#') + 1);
    console.log(email, 'noti')
    }
    
    
    let data = {
      "id": notification.id,
      // "email": email,
      "status": "read",
      "is_admin": true
    }
    this.api.updateNotificationStatus(data).subscribe(res => {
      this.redirectPage(notification)
    })
  }
  redirectPage(notification) {
    this.type = notification.type
    if(this.type == 'message'){
      this.extra = {
        "product_id": 'ORDE#'+notification?.item_id,
        "order_id": notification?.order_id,
      }
      this.router.navigate(['/message'], {
        queryParams: this.extra
      });
    }
    else if(this.type == 'order'){
      console.log(notification)
      let extra = {
        "order_id": notification?.order_id,
        "user_email": notification?.buyer_email
      }
      this.router.navigate(['order-details'], {
        queryParams: extra
      });
    }  
  }
  remove(id){
    this.notificationId = id
  }
  deleteNotification(id) {
    debugger
    this.api.deleteNotificationById({ "id": id }).subscribe(res => {
      this.toastr.success(res['message'])
        this.getNotifications()
      console.log(res, 'res')
    })
  }
  clear(){
   // this.ngOnInit();
   window.location.reload()
  }
  clearAll() {
    let deleteArray = []
    _.each(this.notificationArray, (item) => {
      deleteArray.push({PK:item['PK'], id:item['id']})
    })
    console.log(deleteArray, 'deleteArray')
    this.api.deleteAllNotifications(deleteArray).subscribe(res=>{
        this.toastr.success(res['message'])
        this.ngOnInit();
      console.log(res, 'res')
    })
  }
}
