<div *ngIf="loader == true">
  <app-spinner></app-spinner>
</div>
<section class="order-list-sec">
  <div class="row pt-3">
    <div class="col-md-8 col-xs-12 col-lg-8 my-auto">
      <div class="order-list-heading mob-text-align">
        <h1>Order List</h1>
      </div>
    </div>
    <div class="col-md-4 col-xs-12 col-lg-4">
      <div class="excel-export d-flex justify-content-end">
        <div class="mob-text-align mr-3" (click)="exportToExcel()">
          <p>Export to excel
            <i class="fas fa-file-excel"></i>
          </p>
        </div>
        <div>
          <button type="button" class="btn create-bulk-order" data-toggle="modal"
            data-target="#createBulkOrderModal">Create bulk order</button>
        </div>
      </div>
    </div>
  </div>

  <div class="row row-border mt-3" [formGroup]="filterForm">
    <div class="col-md-2 col-lg-2 col-xs-12">
      <div class="total-order mob-text-align">
        <p>{{totalItems}} orders found</p>
      </div>
    </div>
    <div class="col-md-2 col-lg-2 col-xs-12" >
      <div class="mob-p-b-10">
        <input type="text" class="form-control height-30" placeholder="Order reference" name="search"
          formControlName="searchText" (keyup.enter)="applyFilter()" />
      </div>
    </div>
    <div class="col-md-2 col-lg-2 col-xs-12">
      <div class="mob-p-b-10">
        <!-- <input type="text" #queryDate
        (change)="filterDate(queryDate.valueAsDate)" class="form-control cursor-pointer height-30" placeholder="Order date" onfocus="(this.type='date')"> -->
        <input type="text" placeholder="Order date" formControlName="orderDate" [maxDate]="today" class="form-control height-30" bsDaterangepicker
          [bsConfig]="dpConfig" (bsValueChange)="applyFilter()">

      </div>
    </div>
    <!-- <div class="col-md-2 col-lg-2 col-xs-12">
      <div class="mob-p-b-10">
        <input type="text" class="form-control height-30" placeholder="Delivery Date"
        #queryDeliveryDate  (change)="filterDeliveryDate(queryDeliveryDate.valueAsDate)" onfocus="(this.type='date')">
      </div>
    </div> -->
    <!-- <div class="col-md-2 col-lg-2 col-xs-12">
      <div class="mob-p-b-10">
        <angular2-multiselect [data]="companyArray" [(ngModel)]="selectedCompany" [settings]="companyDropdownSettings"
          (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
          (onDeSelectAll)="onDeSelectAll($event)">
        </angular2-multiselect>
      </div>
    </div> -->
    <div class="col-md-2 col-lg-2 col-xs-12">
      <div class="mob-p-b-10">
        <angular2-multiselect [data]="preferenceArray" formControlName="preference" [(ngModel)]="selectedPreference"
          (onSelect)="applyFilter()" (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
          (onDeSelectAll)="onDeSelectAll($event)" [settings]="preferenceDropdownSettings">
        </angular2-multiselect>
      </div>
    </div>
    <div class="col-md-2 col-lg-2 col-xs-12">
      <div class="mob-p-b-10">
        <angular2-multiselect [data]="statusArray" formControlName="status" [(ngModel)]="selectedStatus" [settings]="statusDropdownSettings"
          (onSelect)="applyFilter()" (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
          (onDeSelectAll)="onDeSelectAll($event)">
        </angular2-multiselect>
      </div>
    </div>
    <div class="col-md-1 col-lg-1 col-xs-12 col-xl-1">
      <div class="reload-icon">
        <i class="fas fa-sync-alt" (click)="clearAll()"></i>
      </div>
    </div>
  </div>
  <div class="row p-t-20">
    <div class="col-md-12 col-xs-12 col-lg-12">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Ref ID</th>
              <!-- <th scope="col">Company</th> -->
              <th scope="col">Customer Name</th>
              <th scope="col">Contact Number</th>
              <th scope="col">Order Date</th>
              <th scope="col">Delivery Date</th>
              <th scope="col">Amount</th>
              <th scope="col">Preference</th>
              <th scope="col">Order Status</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let order of orderList | filter:searchText | paginate:{
              itemsPerPage: 10, currentPage: p, totalItems: totalItems }">
              <td>{{order?.reference_id}}</td>
              <!-- <td></td> -->
              <td>{{order?.order_user?.name}}</td>
              <td>{{order?.phone}}</td>
              <td class="text-nowrap">{{order?.created_at | date:'dd-MM-YYYY'}}</td>
              <td class="text-nowrap">{{order?.delivery_date}}</td>
              <td class="float-right">{{order?.order_total | number :
                '1.2-2'}}</td>
              <td *ngIf="order?.home_delivery == 'self_pickup'">Self Pickup</td>
              <td *ngIf="order?.home_delivery == 'home_delivery'">Home Delivery</td>
              <td class="text-nowrap">
                <span class="" *ngIf="order?.orderstatus == 'Order Placed'">
                  <i class="far fa-dot-circle pr-2"></i>Order Placed
                </span>
                <span class="blue" *ngIf="order?.orderstatus == 'Out for delivery'">
                  <i class="far fa-dot-circle pr-2"></i>Out for delivery
                </span>
                <span class="green" *ngIf="order?.orderstatus == 'Delivered'">
                  <i class="far fa-dot-circle pr-2"></i>Delivered
                </span>
                <span class="yellow" *ngIf="order?.orderstatus == 'Ready for delivery'">
                  <i class="far fa-dot-circle pr-2"></i>Ready for delivery
                </span>
                <span class="pink" *ngIf="order?.orderstatus == 'Processing'">
                  <i class="far fa-dot-circle pr-2"></i>Processing
                </span>
                <span class="gray" *ngIf="order?.orderstatus == 'Delayed'">
                  <i class="far fa-dot-circle pr-2"></i>Delayed
                </span>
                <span class="brown" *ngIf="order?.orderstatus == 'Order Accepted'">
                  <i class="far fa-dot-circle pr-2"></i>Order Accepted
                </span>
                <span class="red" *ngIf="order?.orderstatus == 'Order Cancelled'">
                  <i class="far fa-dot-circle pr-2"></i>Order Cancelled
                </span>
              </td>
              <!-- <td [ngClass]="{ 'green': order?.orderstatus == 'Order Placed'}"> <i
                  class="far fa-dot-circle p-r-10"></i>{{order?.orderstatus}}</td> -->
              <td style="text-align: center;" class="text-nowrap">
                <span>
                  <i class="fas fa-external-link-alt mr-2" (click)="getOrderDetails(order)"></i>
                </span>
                <!-- <span *ngIf="order?.message_status == 'unread'">
                  <i class="far fa-envelope mr-2"></i>
                </span> -->
                <span *ngIf="order?.orderpdf">
                  <i class="fas fa-print mr-2" (click)="download(order.orderpdf)"></i>
                </span>
                <span *ngIf="order?.invoiceurl">
                  <i class="fas fa-file-alt" (click)="download(order.invoiceurl)"></i>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <hr>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 col-xs-12 col-lg-6">
      <div class="pagination mob-text-align">
        <p>Showing {{(p-1)*10}} - {{((p-1)*10)+10}} of {{totalItems}}</p>
      </div>
    </div>
    <pagination-controls (pageChange)="p = $event"></pagination-controls>
  </div>
</section>

<!-- createBulkOrderModal start -->
<div class="modal fade" id="createBulkOrderModal" tabindex="-1" role="dialog"
  aria-labelledby="createBulkOrderModalModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row">
          <div class="col-md-4 left-side-area p-3">
            <div class="row">
              <div class="col-md-12">
                <h2>Select cookies</h2>
              </div>
            </div>
            <div class="row" [formGroup]="ledgerForm">
              <div class="col-md-12 mb-3">
                <select class="form-control" formControlName="product" aria-placeholder="Select cookie pack"
                  (change)="getSelectedProduct($event)">
                  <option value="" disabled selected hidden>Select cookie pack </option>
                  <option *ngFor="let item of productList" [value]="item.id">{{item.name}}</option>
                </select>
              </div>
              <div class="col-md-12 mb-3">
                <select class="form-control" formControlName="color" aria-placeholder="Select color"
                  (change)="getSelectedColor($event)">
                  <option value="" disabled selected hidden>Select color </option>
                  <option *ngFor="let item of colorList" [value]="item.SK">{{item.name}}</option>
                </select>
              </div>
            </div>
            <div class="row" *ngIf="min_qty">
              <div class="col-md-12">
                <div class="select-quantity">
                  <h6 class="mb-1">Select Quantity</h6>
                  <p class="mb-1">Minimum order qty {{min_qty}} pieces</p>
                </div>
                <div class="d-flex mt-3">
                  <i class="far fa-minus-square" [disabled]="min_qty >= quantity" (click)="decrement()"></i>
                  <h5 class="mr-2 ml-2">{{quantity}}</h5>
                  <i class="far fa-plus-square" (click)="increment()"></i>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12" *ngIf="selectedColor">
                <div class="btns">
                  <button type="button" class="btn cancel-btn mr-2" (click)="cancel()">Cancel</button>
                  <button class="btn add-to-list-btn" [disabled]="ledgerForm.invalid" type="button" (click)="addtoList()">Add to list</button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8 right-side-area p-3">
            <div class="row">
              <div class="col-md-6 col-lg-6 col-xs-12" *ngFor="let item of cartList; let i = index">
                <div class="row bg-color m-2" *ngIf="cartList.length > 0">
                  <div class="col-md-12">
                    <div class="float-right">
                      <i class="fas fa-trash-alt" (click)="deleteItem(i)"></i>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4 col-sm-6 col-xs-12">
                    <div class="cookie-box text-align">
                      <img [src]="item.image">
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-8 col-sm-6 col-xs-12 my-auto">
                    <div class="cookie-box-caption ml-3">
                      <h6>{{item.product.name}}</h6>
                      <p>Cookie Qty : <span>{{item.quantity}}</span> </p>
                      <p>Pack Color : <span class="pink-color">{{item.box_color}} </span></p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="row no-item-in-cart-area" *ngIf="cartList.length == 0">
              <div class="col-md-12 mt-5">
                <div class="text-center">
                  <img src="../../../assets/noItem.svg">
                  <p class="mt-3">No item in your cookie cart</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="proceed-to-customize-btn">
              <button type="button" class="btn" *ngIf="cartList.length>0" data-dismiss="modal" (click)="checkout()">Proceed To
                Customize</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- createBulkOrderModal end -->