<nav class="main-header navbar navbar-expand navbar-white navbar-blue-theme navbar-light">
  <!-- Right navbar links -->
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link nav-link-blue-theme" id="side-menu-collapse" data-widget="pushmenu"><i
          class="fas fa-bars cursor-pointer"></i></a>
    </li>
    <li class="nav-item d-none d-sm-inline-block">
      <!-- <span class="nav-link title">{{pageTitle$}}</span> -->
    </li>
  </ul>
  <ul class="navbar-nav ml-auto">

    <!-- Notifications Dropdown Menu -->
    <li class="nav-item dropdown user user-menu">
      <a class="nav-link" data-toggle="dropdown" routerLink="/notifications">
        <i class="far fa-bell"></i>
        <span class="badge badge-pink navbar-badge">{{unreadNotification}}</span>
      </a>
      <!-- <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right m-t-9">
        <span class="dropdown-item dropdown-header">15 Notifications</span>
        <div class="dropdown-divider"></div>
        <a routerLink="/notifications" class="dropdown-item"> New messages
          <span class="float-right text-muted text-sm">3 mins</span>
          <br>
          <span class="text-muted text-sm">Mins</span>
        </a>
        <div class="dropdown-divider"></div>
        <a routerLink="/notifications" class="dropdown-item">New messages
          <span class="float-right text-muted text-sm">12 hours</span>
          <br>
          <span class="text-muted text-sm">Mins</span>
        </a>
        <div class="dropdown-divider"></div>
        <a routerLink="/notifications" class="dropdown-item">New messages
          <span class="float-right text-muted text-sm">2 days</span>
          <br>
          <span class="text-muted text-sm">Mins</span>
        </a>
        <div class="dropdown-divider"></div>
        <a routerLink="/notifications" class="dropdown-item dropdown-footer">See All Notifications</a>
      </div> -->
    </li>
    <!-- <li class="nav-item dropdown user user-menu">
      <a class="nav-link cursor-pointer" data-toggle="dropdown">
        <i class="fas fa-user-cog m-r-33"></i>
      </a>
      <ul class="dropdown-menu user-profile">
        <li class="user-header user-header-blue-theme">
          <img src="../../../../assets/user-image.png" class="img-circle img-circle-blue-theme" alt="User Image">
          <p>Admin</p>
        </li>
        <li class="user-footer">
          <div class="pull-left">
            <a class="btn btn-default btn-flat">Profile</a>
          </div>
          <div style="text-align: center;">
            <a class="btn btn-default btn-flat" (click)="logout()">Sign out</a>
          </div>
        </li>
      </ul>
    </li> -->
    <!-- Messages Dropdown Menu -->

    <li class="nav-item dropdown user user-menu">
      <a class="nav-link cursor-pointer">
        <i class="fas fa-sign-out-alt m-r-33" (click)="logout()"></i>
      </a>
    </li>
  </ul>
</nav>
