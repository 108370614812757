import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { parse } from 'path';
import { CommonService } from 'src/app/common/common.service';
import { COMPANY_NAME } from 'src/app/config/commonConfig';
import * as _ from 'underscore';
import { any, filter } from 'underscore';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { event } from 'jquery';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  // variable declaration
  loader: boolean = false
  public today = new Date();
  companyDropdownSettings;
  preferenceDropdownSettings;
  statusDropdownSettings;
  selectedCompany = [];
  selectedPreference = [];
  selectedStatus = [];
  dashboardArray;
  name;
  new_order;
  number_neworder;
  ready_order;
  number_readyorder;
  processing_order;
  number_processingorder
  delivery_order;
  number_deliveryorder;
  date1;
  nam;
  total_revenue;
  revenue;
  delayed_order;
  number_delayed_order;
  activeBtn = 'activeBtn'
  dateActive = ''
  maxDate: Date;
  companyArray = COMPANY_NAME;
  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();    // date range picker   
  placeholder = 'From date - To date'
  bsValue: Date[]
  bsDateValue: Date[]
  constructor(private fb: FormBuilder,
    private router: Router,
    public api: CommonService,
    private toastr: ToastrService) {
    this.dpConfig.rangeInputFormat = 'MMM DD, YYYY'; // date range picker
    this.dpConfig.isAnimated = true; // date range picker
    this.dpConfig.showWeekNumbers = false; // date range picker
  }

  ngOnInit(): void {
    
    this.loader  = true
    this.maxDate = new Date()
    this.companyDropdownSettings = {
      singleSelection: true,
      primaryKey: 'id',
      labelKey: 'name',
      text: "Select a company",
      enableSearchFilter: false,
      lazyLoading: true,
      showCheckbox: true,
      clearAll: false,
      classes: "myclass custom-class"
    };
    this.getDashboard(this.name);
    

  }
  onItemSelect(event) {

  }
  OnItemDeSelect(event) {

  }
  onSelectAll(event) {

  }
  onDeSelectAll(event) {

  }
  getDashboard(name: any) {
    localStorage.removeItem('filterName')
    localStorage.removeItem('fromDate')
    localStorage.removeItem('endDate')
    console.log(this.bsValue, 'bs valuebefore')
    this.placeholder = 'From date - To date'
    this.dateActive = ''
    this.bsValue = this.bsDateValue;
    console.log(this.bsValue, 'bs value aftsr')
    if (name == null) {
      name = 'today';
    }
    if (name == 'week' || name == 'month') {
      this.activeBtn = 'btn-light'
    }
    debugger
    localStorage.setItem('filterName',name)
    this.api.getDashboard(name).subscribe(re => {
      this.dashboardArray = re['order_data']
      this.loader = false
      let filterData = _.filter(this.dashboardArray,
        function (num) {
          return num.orderstatus != 'Order Cancelled';
        });
      this.total_revenue = _.reduce(filterData, function (memo, num) {
        memo += parseInt(num.order_total)
        return memo;
      }, 0);

      this.new_order = _.filter(this.dashboardArray,
        function (num) {
          return num.orderstatus == 'Order Placed' || num.orderstatus == 'Order Accepted';
        });
      this.ready_order = _.filter(this.dashboardArray,
        function (num) {
          return num.orderstatus == 'Ready for delivery' || num.orderstatus == 'Out for delivery';
        });
      this.processing_order = _.filter(this.dashboardArray,
        function (num) {
          return num.orderstatus == 'Processing';
        });
      this.delivery_order = _.filter(this.dashboardArray,
        function (num) {
          return num.orderstatus == 'Delivered';
        });
      this.delayed_order = _.filter(this.dashboardArray,
        function (num) {
          return num.orderstatus == 'Delayed';
        });
      this.number_neworder = this.new_order.length;
      this.number_readyorder = this.ready_order.length;
      this.number_processingorder = this.processing_order.length;
      this.number_deliveryorder = this.delivery_order.length;
      this.number_delayed_order = this.delayed_order.length;
    })

  }

  dateRangeCreated($event) {
    localStorage.removeItem('fromDate')
      localStorage.removeItem('endDate')
      localStorage.removeItem('filterName')
    if ($event) {
      this.loader = true
      this.activeBtn = ''
      this.dateActive = 'activeBtn'
      let fromDate = $event[0]
      fromDate = this.convert($event[0])
      let endDate = $event[1]
      endDate = this.convert($event[1])
      localStorage.setItem('fromDate', fromDate)
      localStorage.setItem('endDate', endDate)
      this.api.getDateDashboard(fromDate, endDate).subscribe(re => {
        this.dashboardArray = re['order_data']
        
        let filterData = _.filter(this.dashboardArray,
          function (num) {
            return num.orderstatus != 'Order Cancelled';
          });
        this.total_revenue = _.reduce(filterData, function (memo, num) {
          memo += parseInt(num.order_total)
          return memo;
        }, 0);
        // this.total_revenue = _.reduce(this.dashboardArray, function (memo, num) {
        //   memo += parseInt(num.order_total)
        //   return memo;
        // }, 0);
        this.new_order = _.filter(this.dashboardArray,
          function (num) {
            return num.orderstatus == 'Order Placed' || num.orderstatus == 'Order Accepted';
          });
        this.ready_order = _.filter(this.dashboardArray,
          function (num) {
            return num.orderstatus == 'Ready for delivery' || num.orderstatus == 'Out for delivery';
          });
        this.processing_order = _.filter(this.dashboardArray,
          function (num) {
            return num.orderstatus == 'Processing';
          });
        this.delivery_order = _.filter(this.dashboardArray,
          function (num) {
            return num.orderstatus == 'Delivered';
          });
        this.delayed_order = _.filter(this.dashboardArray,
          function (num) {
            return num.orderstatus == 'Delayed';
          });
        this.number_neworder = this.new_order.length;
        this.number_readyorder = this.ready_order.length;
        this.number_processingorder = this.processing_order.length;
        this.number_deliveryorder = this.delivery_order.length;
        this.number_delayed_order = this.delayed_order.length;
        this.loader = false
      })
    }
  }
  clearAll() {
    window.location.reload();
  }
  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
}
