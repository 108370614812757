<!-- <div *ngIf="loader == true">
    <app-spinner></app-spinner>
</div> -->
<div *ngIf="loader == true">
    <app-spinner></app-spinner>
</div>
<section class="create-product-sec">
    <div class="row">
        <div class="col-md-12 mt-4">
            <div class="product-list-heading mob-text-align">
                <h1 *ngIf="!editCheck">Add new coupon</h1>
                <h1 *ngIf="editCheck">Edit coupon</h1>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 mt-4">
            <div class="vendor-list-breadcrumb">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a routerLink="/coupons"><i class="fas fa-columns"></i>
                                Coupon List</a>
                        </li>
                        <li *ngIf="!editCheck" class="breadcrumb-item active" aria-current="page">New coupon
                        </li>
                        <li *ngIf="editCheck" class="breadcrumb-item active" aria-current="page">Edit coupon</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-body">
            <form [formGroup]="couponForm">
                <div class="row">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="mob-m-b-20 ">
                                    <label>Coupon code <span class="mandatory">*</span></label>
                                    <input class="form-control" type="text" formControlName="couponCode"
                                        placeholder="Coupon code" />
                                    <div class="text-danger"
                                        *ngIf="(couponFormControl.couponCode.touched || submitted) && couponFormControl.couponCode.errors?.required">
                                        Couponcode is required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1" *ngIf="!couponFormControl.couponCode.value">
                                <div class="text-center mt-35">
                                    <p>OR</p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="mob-m-b-20 mt-25">
                                    <button type="button" (click)="generateCoupon()"
                                        *ngIf="!couponFormControl.couponCode.value"
                                        class="btn generate-coupon-code">Auto generate coupon
                                        code</button>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-12">
                                <div class="mob-m-b-20 ">
                                    <label>Description</label>
                                    <textarea class="form-control" type="text" rows="4" formControlName="description"
                                        placeholder="Description"></textarea>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row coupon-details-row mt-3">
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                        <div>
                            <label>Discount type <span class="mandatory">*</span></label>
                            <angular2-multiselect [data]="statusArray" [(ngModel)]="selectedStatus"
                                formControlName="type" (onSelect)="onItemSelect($event)"
                                (onDeSelect)="onItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
                                (onDeSelectAll)="onDeSelectAll($event)" [settings]="statusDropdownSettings">
                            </angular2-multiselect>
                            <div class="text-danger"
                                *ngIf="(couponFormControl.type.touched || submitted) && couponFormControl.type.errors?.required">
                                Discount type is required
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                        <div>
                            <label>Coupon discount <span class="mandatory">*</span></label>
                            <input type="number" class="form-control" formControlName="couponValue" placeholder="Value"
                                min="0">
                            <i class="fas fa-question-circle">
                                <span class="tooltip">Value of the coupon</span>
                            </i>
                            <div class="text-danger"
                                *ngIf="(couponFormControl.couponValue.touched || submitted) && couponFormControl.couponValue.errors?.required">
                                Value is required
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                        <div>
                            <label>Coupon validity <span class="mandatory">*</span></label>
                            <div class="m-t-10 m-l-68 btn-light-gray mob-text-align">
                                <input type="text" formControlName="validity" placeholder="{{placeholder}}"
                                    class="form-control cursor-pointer {{dateActive}} " bsDaterangepicker
                                    [bsConfig]="dpConfig">
                            </div>
                            <div class="text-danger"
                                *ngIf="(couponFormControl.validity.touched || submitted) && couponFormControl.validity.errors?.required">
                                Validity is required
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-2 col-xs-12">
                        <div>
                            <label>User limit per coupon<span class="mandatory">*</span></label>
                            <angular2-multiselect [data]="limitArray" [(ngModel)]="selectedLimit"
                                formControlName="limit" (onSelect)="onlimitSelect($event)"
                                (onDeSelect)="onlimitDeSelect($event)" (onSelectAll)="onSelectAll($event)"
                                (onDeSelectAll)="onDeSelectAll($event)" [settings]="limitDropdownSettings">
                            </angular2-multiselect>
                            <div class="text-danger"
                                *ngIf="(couponFormControl.limit.touched || submitted) && couponFormControl.limit.errors?.required">
                                Limit is required
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-1 col-md-1 col-sm-2 col-xs-12" *ngIf="showCount">
                        <div>
                            <label>Count <span class="mandatory">*</span></label>
                            <input type="number" class="form-control" min=1 formControlName="count" placeholder="Enter">
                            <div class="text-danger"
                                *ngIf="(couponFormControl.count.touched || submitted) && couponFormControl.count.errors?.required">
                                Count is required
                            </div>
                            <!-- <div class="text-danger"*ngIf="couponFormControl.count.errors.pattern">Enter numbers greater than zero</div> -->
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="multiTimeUse">
                    <div class="col-md-12 mt-3">
                        <div class="mob-m-b-20 ">
                            <label>Tag user (Use comma to enter multiple mail Id)</label>
                            <textarea class="form-control" type="text" formControlName="userMail" rows="4"
                                formControlName="userMail" placeholder="Email"></textarea>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="oneTimeUse">
                    <div class="col-md-12 mt-3">
                        <!-- <div class="mob-m-b-20 "> -->
                            <label>Tag user</label>
                            <textarea class="form-control" type="Email"  rows="1"
                                formControlName="userMail" placeholder="Email"></textarea>
                        <!-- </div> -->
                    </div>
                    <div class="text-danger"
                                *ngIf="(couponFormControl.userMail.touched || submitted) && couponFormControl.userMail.errors?.required || couponFormControl.userMail.errors?.pattern">
                                Enter valid email id
                            </div>
                   
                </div>
                <div class="row mt-5">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-6 my-auto">
                                <div *ngIf="editCheck">
                                    <p class="mb-0 status-p-tag">Status :
                                        <span *ngIf="status=='Active'" class="active-span">Active</span>
                                        <span *ngIf="status=='Inactive'" class="expired-span"> Expired</span>
                                    </p>
                                </div>
                            </div>

                            <div class="col-md-6 my-auto">
                                <div class="can-save-btns">
                                    <button type="button" class="btn btn-cancel" data-dismiss="modal"
                                        (click)="cancel()">Cancel</button>
                                    <!-- <button type="button" class="btn btn-save" (click)="alert()" *ngIf="!colorSelected"
                                >Save</button> -->
                                    <button *ngIf="!editCheck" type="button" class="btn btn-save"
                                        (click)="publish()">Publish</button>
                                    <button *ngIf="editCheck" type="button" class="btn btn-save"
                                        (click)="publish()">Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>