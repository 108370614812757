import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
// import { NgxPrinterService } from 'ngx-printer';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/common/common.service';
import { OrderServiceService } from 'src/app/common/order-service.service';
import { STATUS } from 'src/app/config/commonConfig';
import * as _ from 'underscore';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.css']
})
export class OrderDetailsComponent implements OnInit {

  statusDropdownSettings;
  selectedStatus;
  statusArray = STATUS;
  messageForm: FormGroup
  orderId: any;
  order_ref
  order_date: any;
  delivery_date
  current_status
  delivery_address
  customize = []
  productList = []
  productArray = []
  orderData
  orderDetails
  i
  cookieData: any;
  flavorArray = [];
  flavorName: any;
  flArr = [];
  itemId
  index: any;
  loader = false
  cookie_image: any;
  allVendorChats = []
  vendorEmail
  imageURL: string;
  orderStatus = [{ 'id': '1', 'status': 'Order Placed' }, { 'id': '2', 'status': 'Order Accepted' },
  { 'id': '3', 'status': 'Processing' }, { 'id': '4', 'status': 'Delayed' }, { 'id': '5', 'status': 'Ready for delivery' },
  { 'id': '6', 'status': 'Out for delivery' }, { 'id': '7', 'status': 'Delivered' }]
  extra = {};
  disableStatus = ''
  disableStatus2 = ''
  disableStatus3 = ''
  disableStatus4 = ''
  disableStatus5 = ''
  disableStatus6 = ''
  disableStatus7 = ''
  categories: any;
  constructor(
    private route: ActivatedRoute,
    private api: CommonService,
    private orderService: OrderServiceService,
    private router: Router,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    debugger
    this.loader = true
    this.route.queryParams.subscribe(item => {
      this.orderDetails = item
      console.log(item, 'item')
    })
    this.getOrderedItems();
    this.counter(this.i)

    this.statusDropdownSettings = {
      singleSelection: false,
      primaryKey: 'id',
      labelKey: 'name',
      text: "Filter by Status",
      enableSearchFilter: false,
      lazyLoading: true,
      showCheckbox: true,
      clearAll: false,
      badgeShowLimit: 1,
      classes: "myclass custom-class"
    };
  }
  getOrderedItems() {
    debugger
    this.api.getOrderDetails(this.orderDetails?.order_id, this.orderDetails?.user_email).subscribe(item => {
      this.productArray = item['item_data']['Items']
      this.loader = false
      // this.vendorEmail = this.productArray[0].company_id.split(/#(.*)/)[1]
      this.vendorEmail = ''
      this.orderData = item['order_data']['Items'][0]
      console.log('Sam Sajin', this.orderData)
      let currentStatus = this.orderData?.orderstatus
      if (currentStatus == 'Order Placed') {
        this.disableStatus2 = 'Processing'
        this.disableStatus3 = 'Ready for delivery'
        this.disableStatus4 = 'Order Placed'
        this.disableStatus5 = 'Out for delivery'
        this.disableStatus6 = 'Delayed'
        this.disableStatus7 = 'Delivered'
      }
      else if (currentStatus == 'Order Accepted') {
        this.disableStatus4 = 'Order Placed'
        this.disableStatus3 = 'Ready for delivery'
        this.disableStatus5 = 'Out for delivery'
        this.disableStatus7 = 'Delivered'
      }
      else if (currentStatus == 'Processing') {
        this.disableStatus = 'Order Accepted'
        this.disableStatus4 = 'Order Placed'
        this.disableStatus5 = 'Out for delivery'
        this.disableStatus7 = 'Delivered'
      }
      else if (currentStatus == 'Ready for delivery') {
        this.disableStatus = 'Order Accepted'
        this.disableStatus2 = 'Processing'
        this.disableStatus4 = 'Order Placed'
        this.disableStatus6 = 'Delayed'
        this.disableStatus7 = 'Delivered'
      }
      else if (currentStatus == 'Out for delivery') {
        this.disableStatus4 = 'Order Placed'
        this.disableStatus = 'Order Accepted'
        this.disableStatus2 = 'Processing'
        this.disableStatus3 = 'Ready for delivery'
      }
      else if (currentStatus == 'Delivered') {
        this.disableStatus4 = 'Order Placed'
        this.disableStatus = 'Order Accepted'
        this.disableStatus2 = 'Processing'
        this.disableStatus3 = 'Ready for delivery'
        this.disableStatus5 = 'Out for delivery'
        this.disableStatus6 = 'Delayed'
      }
      else {
        this.disableStatus4 = 'Order Placed'
        this.disableStatus = 'Order Accepted'
      }
      console.log(item['item_data']['Items'], 'item data')
      console.log(item['order_data']['Items'], 'order date')
      let itemArray = item['item_data']['Items']
      let flavorCategory = []
      for (var j in itemArray) {
        let customize = itemArray[j].customize
        console.log(customize, 'customize array')
        this.categories = this.groupBy(customize, flavor => flavor.flavor.name);
        itemArray[j]['category'] = this.categories
      }
      console.log(this.categories, flavorCategory)
      localStorage.setItem('products', JSON.stringify(itemArray))
      this.productList = itemArray
      let count = 0
      for (var i in this.productArray) {
        if (this.productArray[i]?.message_status == 'unread') {
          count += 1
        }
      }
      if (count == 0) {
        let data = {
          "PK": this.orderData.PK,
          "id": this.orderDetails?.order_id,
          "message_status": "read",
          "email_id": this.orderDetails?.user_email,
          "is_admin": "true"
        }
        this.api.updateOrderMsgStatus(data).subscribe(res => {
          console.log(res, 'order msg status')
        })
      }
      console.log('success')
    })
  }
  counter(i: number) {
    return new Array(i);
  }
  cancelOrder() {
    debugger
    this.loader = true
    let data: any = { "id": this.orderData.id, "PK": this.orderData.PK,'cancelledby': 'admin' }
    this.api.orderCancel(data).subscribe((resp) => {
      console.log(resp)
      if (resp['status'] == true) {
        this.toastr.success(resp['message'],null, { timeOut: 5000 })
        this.loader = false
        this.ngOnInit()
        this.router.navigate(['order/list'])
      } else {
        this.toastr.error('Something went wrong' ,null, { timeOut: 5000 })
        this.loader = false
      }

    })
  }
  groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }
  boxNumber(i, item, products) {
    this.cookieData = item
    console.log(i, item, products, 'dfghj')
    console.log(this.flArr, 'dfghj')
    this.itemId = products.id
    this.index = i
    this.cookie_image = this.cookieData.image_url
    this.flavorName = item.flavor.name
  }
  changeOrderStatus(event) {
    debugger
    console.log(event, 'event')
    let orderStatus = event.target.value
    let data = {
      "PK": this.orderData.PK,
      "id": this.orderDetails?.order_id,
      "orderstatus": orderStatus,
      "email_id": this.orderDetails?.user_email,
      "is_admin": true
    }
    if (orderStatus == 'Order Accepted') {
      data['accepted_date'] = new Date();
    }
    else if (orderStatus == 'Processing') {
      data['processed_date'] = new Date();
    }
    else if (orderStatus == 'Ready for delivery') {
      data['ready_for_delivery_date'] = new Date();
    }
    else if (orderStatus == 'Out for delivery') {
      data['out_for_delivery_date'] = new Date();
    }
    else if (orderStatus == 'Delivered') {
      data['delivered_date'] = new Date();
    }

    this.api.updateOrderStatus(data).subscribe(res => {
      console.log(res, 'order res')
      this.toastr.success(res['message'])
      window.location.reload();
    })
  }

  updateCookie(id, index) {
    let orderDetails = {
      "order_id": this.orderDetails?.order_id,
      "user_email": this.orderDetails?.user_email
    }
    localStorage.setItem('orderDetails', JSON.stringify(orderDetails))
    this.router.navigate(['/edit-image/' + id + '/' + index]).then(() => {
      window.location.reload();
    });
  }
  printAll() {
    debugger
    let orderDetails = {
      "order_id": this.orderDetails?.order_id,
      "user_email": this.orderDetails?.user_email,
      "ordertype": this.orderData?.ordertype
    }
    localStorage.setItem('PrintAll', "true")
    this.router.navigate(['print-preview'], { queryParams: orderDetails })
  }
  printById() {
    let extra = {
      "order_id": this.orderDetails?.order_id,
      "user_email": this.orderDetails?.user_email,
      "cookie_image": this.cookie_image
    }
    localStorage.setItem('currentImage', JSON.stringify(this.cookie_image))
    this.router.navigate(['print-preview'], {
      queryParams: extra
    }).then(() => {
      window.location.reload();
    });

  }
  message(products) {
    debugger
    this.extra = {
      "PK": products.PK,
      "product_id": products.SK,
      "id": products.id,
      "order_id": this.orderDetails?.order_id,
      "user_email": this.orderDetails?.user_email,
      "vendor_email": products.company_id.split(/#(.*)/)[1]
    }
    this.router.navigate(['/message'], {
      queryParams: this.extra
    });
  }
}


