import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/common/common.service';
import { OrderServiceService } from 'src/app/common/order-service.service';
import { STATUS } from 'src/app/config/commonConfig';
import * as _ from 'underscore';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit {

  urlName = [];
  messageForm: FormGroup
  orderId: any;
  order_ref
  order_date: any;
  delivery_date
  current_status
  delivery_address
  customize = []
  productList = []
  productArray = []
  orderData
  orderDetails
  i
  cookieData: any;
  flavorArray = [];
  flavorName: any;
  flArr = [];
  itemId
  index: any;
  cookie_image: any;
  userMessages: any;
  adminMessages: any;
  vendorMessages
  admin_vendorMessages
  allMessages = [];
  allVendorChats = []
  vendorEmail
  productId
  imageURL: string;
  urls = []
  urls2 = []
  id: any;
  loginUser: any
  constructor(
    private route: ActivatedRoute,
    private api: CommonService,
    private orderService: OrderServiceService,
    private router: Router,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.loginUser = localStorage.getItem('loginEmail')
    this.startingFunction()
  }
  startingFunction() {
    debugger
    this.route.queryParams.subscribe(item => {
      this.orderDetails = item
      this.productId = this.orderDetails?.product_id.split(/#(.*)/)[1]
      this.id = item.id
      console.log(item, 'item')
    })
    console.log(this.orderId, 'ref')
    let data = {
      "PK": this.orderDetails?.PK,
      "id": this.id,
      "message_status": "read",
      "email_id": this.orderDetails.user_email,
      "is_admin": true
    }
    this.api.updateOrderItemMsgStatus(data).subscribe(res => {
      console.log(res, 'res')
    })
    this.getMessages();
    // this.getVendorChat();
    this.getMessageForm();
  }
  getMessageForm() {
    this.messageForm = this.fb.group({
      customer_message: [''],
      company_message: [''],
      fileSource: [''],
      image: '',
      fileSource2: [''],
      image2: ''
    })
  }
  getMessages() {
    debugger
    this.api.getAdminUserMessage(this.orderDetails?.order_id, this.productId).subscribe(res => {
      this.allMessages = res['data']
      this.allMessages = _.sortBy(this.allMessages, function (o) { return o['datetime']; });
      console.log(this.allMessages, 'allMesg')
    })

  }
  getVendorChat() {
    debugger
    this.api.getAdminVendorMessage(this.orderDetails?.order_id, this.productId).subscribe(res => {
      this.allVendorChats = res['data']
      this.allVendorChats = _.sortBy(this.allVendorChats, function (o) { return o['datetime']; });
      let data1: any = this.allMessages
      let data2: any = this.allVendorChats
      let finalList: any = data1.concat(data2)
      // this.allMessages.concat(this.allVendorChats)
      this.allMessages = finalList
      console.log(this.allVendorChats, 'allVendorChats')
      console.log('newlist', finalList)
    })
  }
  customerSend() {
    debugger
    if (this.messageForm.controls.customer_message.value != '' && this.messageForm.controls.customer_message.value != null) {
      console.log(this.messageForm.controls.customer_message.value, 'message')
      let data = {
        "prod_id": this.id,
        "order_id": this.orderDetails?.order_id,
        "item_id": this.productId,
        "ordered_user": this.orderDetails.user_email,
        // "to": "cohani@tafmail.com",
        // "from":this.orderDetails.user_email,
        "from": this.loginUser,
        "to": this.orderDetails.user_email,
        "message": this.messageForm.controls.customer_message.value,
        "type": "admin-user",
        "image": this.urls
      }
      let file = {
        "filename": this.messageForm.get('image').value
      }
      this.api.addMessage(data).subscribe(res => {
        console.log(res, 'res')
        if (res) {
          this.getMessages();
          this.getVendorChat();
          this.cancelCustomer();
          this.messageForm.reset()
          this.startingFunction()
        }
      })
    } else {
      this.toastr.error('Please enter a message')
    }

  }
  companySend() {
    debugger
    console.log(this.messageForm.controls.customer_message.value, 'message')
    if (this.messageForm.controls.customer_message.value != '' && this.messageForm.controls.customer_message.value != null) {
      let data = {
        "prod_id": this.id,
        "order_id": this.orderDetails?.order_id,
        "item_id": this.productId,
        "ordered_user": this.orderDetails.user_email,
        "from": this.loginUser,
        "to": this.orderDetails?.vendor_email,
        "message": this.messageForm.controls.customer_message.value,
        "type": "admin-vendor",
        "image": this.urls
      }
      let file = {
        "filename": this.messageForm.get('image').value
      }
      this.api.addMessage(data).subscribe(res => {
        console.log(res, 'res')
        if (res) {
          // this.ngOnInit();
          this.cancelVendor();
          this.messageForm.reset()
          this.startingFunction()
        }
      })
    } else {
      this.toastr.error('Please enter a message')
    }

  }
  cancelCustomer() {
    this.messageForm.get('customer_message').setValue('')
    this.urls = []
  }
  cancelVendor() {
    this.messageForm.get('company_message').setValue('')
    this.urls = []
  }
  openFile() {
    document.querySelector('input').click()
  }

  handle(event) {
    debugger
    console.log('Change input file')
    const file = (event.target as HTMLInputElement).files[0];
    console.log(file)
    this.urlName.push(file.name)
    this.messageForm.patchValue({
      image: file
    });
    // if (file == null) {
    //   this.imageURL = ''
    // }
    this.messageForm.get('image').updateValueAndValidity()
    // File Preview
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();

        reader.onload = (event: any) => {
          this.api.uploadBoxcolor({ "imagedata": event.target.result, "filename": file.name }).subscribe((resp) => {
            console.log(resp)
            this.urls.push(resp['body'])
          })
        };

        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }
  openFile2() {
    document.querySelector('input').click()
  }

  handle2(event) {
    debugger
    console.log('Change input file')
    const file = (event.target as HTMLInputElement).files[0];
    this.messageForm.patchValue({
      image2: file
    });
    // if (file == null) {
    //   this.imageURL = ''
    // }
    this.messageForm.get('image2').updateValueAndValidity()
    // File Preview
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();

        reader.onload = (event: any) => {
          this.urls2.push(event.target.result);
        };

        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  deleteUploadedImage(index: any) {
    debugger
    this.urls.splice(index, 1)
  }
}


