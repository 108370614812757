import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/common/common.service';
// import { Component, OnInit } from '@angular/core';
// import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { parse } from 'path';
// import { CommonService } from 'src/app/common/common.service';
import { COMPANY_NAME } from 'src/app/config/commonConfig';
import * as _ from 'underscore';
import { any, filter } from 'underscore';

@Component({
  selector: 'app-content-management',
  templateUrl: './content-management.component.html',
  styleUrls: ['./content-management.component.css']
})
export class ContentManagementComponent implements OnInit {


  // companyDetailsForm: FormGroup;
  contentDetailsForm: FormGroup;
  privacyDetailsForm: FormGroup;
  documentDetailsForm: FormGroup;
  contactForm: FormGroup;
  activeTab = 'home';
  contactList: any[];
// editor
  name = 'ng2-ckeditor';
  ckeConfig: any;
  mycontent: string;
  mypolicy: string;
  myconditions: string;
  log: string = '';
  @ViewChild("myckeditor") ckeditor: any;
  obj;
// editor

  constructor(private fb: FormBuilder,
    private router: Router,
    public api: CommonService,
    private toastr: ToastrService ) { 
    // this.mycontent = `<p>My html content</p>`;
  }

  ngOnInit(): void { 
// editor
    this.ckeConfig = {
      removePlugins: 'undo'
    };
    this.contentDetailsForm = this.fb.group({
      mycontent : ['']
    })
    this.privacyDetailsForm = this.fb.group({
      mypolicy : ['']
    })
    this.documentDetailsForm = this.fb.group({
      myconditions : ['']
    })
    this.api.getAboutUs().subscribe(res=>{
      console.log(res, 'res')
    })
  } 

// editor
   onEditorChange(event){
  }

  onChange(event: any): void { 
    // console.log(this.mycontent)
    
  }

  addAboutUs(obj:any){
    console.log(this.contentDetailsForm.controls.mycontent.value)
    this.api.addAboutUs({"content":this.contentDetailsForm.controls.mycontent.value}).subscribe(re => {
      this.documentDetailsForm.reset()
      
    })
  }

  addPrivacyPolicy(obj:any){
    console.log(this.privacyDetailsForm.controls.mypolicy.value)
    this.api.addPrivacyPolicy(this.privacyDetailsForm.controls.mypolicy.value).subscribe(re => {
      this.documentDetailsForm.reset()
      
    })
  }
  addConditions(obj:any){
    console.log(this.documentDetailsForm.controls.myconditions.value)
    this.api.addConditions(this.documentDetailsForm.controls.myconditions.value).subscribe(re => {
      this.documentDetailsForm.reset()
      
    })
  }
  cancelAbout(){
    this.contentDetailsForm.reset();
  }
  cancelPolicy(){
    this.privacyDetailsForm.reset();
  }
  cancelCondition(){
    this.documentDetailsForm.reset();
  }
}
