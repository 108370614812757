import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from '../../common/common.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    public api: CommonService,
    private toastr: ToastrService

  ) { }

  ngOnInit(): void {
    this.forgotPasswordForm = this.fb.group({
      email: ['', Validators.required]
    })
  }
  send() {
    debugger
    let data = { "username": this.forgotPasswordForm.controls.email.value }
    localStorage.setItem('userName',this.forgotPasswordForm.controls.email.value)
    this.api.forgotPassword(data).subscribe(res => {
      let message = res['message'];
      this.toastr.success(message);
      let status = res['status']
      if (status == true) {
        this.router.navigate(['confirm-password']);
        this.api.forgetEmail(data);
      }
    })
  }
}
