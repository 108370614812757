<!-- https://getbootstrap.com/docs/5.0/components/navs-tabs/ -->
<!-- https://www.w3schools.com/bootstrap4/tryit.asp?filename=trybs_nav_tabs_toggleable&stacked=h -->
<section class="template-images-sec">
  <div class="row">
    <div class="col-md-12">
      <div class="template-heading">
        <h1>Template Images</h1>
      </div>
    </div>
  </div>
  <div class="row p-20">
    <div class="col-md-12">
      <div class="add-new-btn">
        <button class="btn" data-toggle="modal" data-target="#createNewSection" (click)="new()">Add new section</button>
      </div>
    </div>
  </div>
  <section class="create-new-sec">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="row m-t-20">
                  <div class="col-md-12 col-lg-12 col-xs-12">
                    <div>
                      <!-- Nav tabs -->
                      <ul class="nav nav-tabs" role="tablist">
                        <li class="nav-item" *ngFor="let tab of tabs | keyvalue; let i=index">
                          <button class="nav-link" [ngClass]="{ 'active':i == 0}" data-toggle="tab"
                            active="activeTabIndex" href="#{{i}}" (click)="tabIndex(i,tab.id)">{{tab.key}}
                            <!-- <i class="far fa-edit"></i> -->
                          </button>
                        </li>
                        <!-- <li class="nav-item">
                            <button class="nav-link" data-toggle="tab" href="#menu1">Mother's day </button>
                          </li>
                          <li class="nav-item">
                            <button class="nav-link" data-toggle="tab" href="#menu2">Friendship</button>
                          </li> -->
                      </ul>
                      <!-- Tab panes -->
                      <div class="tab-content" *ngFor="let tab of tabs | keyvalue; let i=index">
                        <div id="{{activeTabIndex}}" class="tab-pane active" *ngIf="activeTabIndex == i"><br>
                          <!-- {{activeTabIndex}} -->
                          <div class="row">
                            <div class="col-md-2">
                              <input id="file-upload" type="file" name="fileUpload" accept="image/*"
                                (change)="showPreview($event,i, tab)" />
                              <label for="file-upload" id="file-drag" class="label2">
                                <!-- <div class="imagePreview">
                                  <img id="file-image" src="" alt="">
                                </div> -->
                                <div id="start" class="mt-4">
                                  <div class="upload-logo"><i class="fas fa-upload" aria-hidden="true"></i></div>
                                  <div class="upload-caption">Upload Image </div>
                                </div>
                              </label>
                            </div>
                            <div class="col-md-2" *ngFor="let value of tab.value">
                              <div class="circle" data-toggle="modal" data-target="#delete" (click)="remove(value)"
                                *ngIf="value.image!=''">
                                <img [src]="value.image">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>
<!-- delete modal starts-->
<div class="modal fade" id="delete" tabindex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div class="info-icon-img">
              <img src="../../../assets/exclamation.png">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="confirm-txt">
              <h6 class="mb-3">Are you sure?</h6>
              <p class="mb-3"> Do you want to delete this image?</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mfooterBtn d-flex justify-content-end">
            <button type="button" class="btn color-cancel-btn mr-2" data-dismiss="modal">Cancel</button>
            <button type="button" class="btn submit-btn ml-2" data-dismiss="modal"
              (click)="deleteImage(tempId)">Delete</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- delete modal end-->

<!-- ==================================================== -->

<!-- Modal -->
<div class="modal fade" id="createNewSection" tabindex="-1" role="dialog" aria-labelledby="createNewSectionTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div class="new-sect-caption mb-3">
              <h6 class="modal-title" id="exampleModalLongTitle">New section name</h6>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="mb-3">
              <div class="form-group has-float-label mobile-m-t-20">
                <input class="form-control" type="text" [(ngModel)]="sectionName" placeholder="Enter section name" />
                <label>Enter section name</label>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="text-align">
              <button type="button" class="btn cancel-btn" data-dismiss="modal">Cancel</button>
              <button type="button" class="btn create-btn" data-dismiss="modal" (click)="addTab()"
                [disabled]="!sectionName">Create</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="text-align">
          <i class="far fa-thumbs-up"></i>
          <h2 class="success-heading">Success</h2>
          <p class="msg-body">Added new FAQ sucessfully</p>
          <button type="button" class="btn done-btn font-weight" data-dismiss="modal">Done</button>
        </div>
      </div>
    </div>
  </div>
</div>