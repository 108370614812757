import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import 'fabric';
import { findIndex, indexOf } from 'underscore';
import { CommonService } from 'src/app/common/common.service';
import { Console } from 'console';
import { environment } from 'src/environments/environment';

declare const fabric: any;
@Component({
  selector: 'app-edit-image',
  templateUrl: './edit-image.component.html',
  styleUrls: ['./edit-image.component.css']
})
export class EditImageComponent implements OnInit {

  canvas
  cookieForm: FormGroup;
  fontSize;
  opacity;
  brightness;
  contrast;
  saturation;
  fontWeight;
  fill;
  temp_images: any;
  categories
  selectedKey: any;
  props = {
    canvasFill: '#ffffff',
    canvasImage: '',
    id: null,
    opacity: null,
    brightness: null,
    fill: null,
    fontSize: null,
    lineHeight: null,
    charSpacing: null,
    fontWeight: null,
    fontStyle: null,
    textAlign: null,
    fontFamily: null,
    TextDecoration: ''
  };
  imageAdded = false
  showText = false
  showEditBtn = false
  itemId
  cookie_index
  imageFileDict = []
  orderId: any;
  orderDetails: any;
  extra ={}
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _location: Location,
    private api: CommonService,
    private cd: ChangeDetectorRef
  ) {

  }
  ngOnInit(): void {
    debugger
    this.orderDetails = JSON.parse(localStorage.getItem('orderDetails'))
    this.orderId = this.orderDetails['order_id']
    this.itemId = this.activatedRoute.snapshot.params.id
    this.cookie_index = this.activatedRoute.snapshot.params.index
    this.getCanvas();
    this.getTempImg();
  }
  orderDetailPage(){
    this.extra = {
      "order_id": this.orderDetails?.order_id,
      "user_email": this.orderDetails.user_email
    }
    this.router.navigate(['/order-details'], {
      queryParams: this.extra
    });
  }

  reLoad() {
    this.router.navigate([this.router.url])
  }
  getTempImg() {
    this.api.getTemplateImages().subscribe(img => {
      this.temp_images = img['data']
      this.categories = this.groupBy(this.temp_images, temp => temp.category);
    })
  }
  groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }
  key(category_name) {
    this.selectedKey = category_name
    this.cd.detectChanges();
    document.querySelector('#' + this.selectedKey).scrollIntoView({ behavior: 'smooth' })
  }
  getCanvas() {
    this.canvas = new fabric.Canvas('canvas', { selection: false, targetFindTolerance: 0 });
    this.cookieForm = this.fb.group({
      textString: [''],
      filesource: [''],
      image: '',
      brightness: [''],
      contrast: [''],
      fontFamily: [''],
      fontSize: [''],
      opacity: [''],
      fill: [''],
      BgFill: [''],
      saturation: ['']
    })
  }
  handleDrop(e: any) {
    this.showEditBtn = true
    this.imageAdded = true
    this.showText = false
    console.log(e, 'e')
    const file = e.dataTransfer.files[0];
    console.log(file, 'file')
    const reader = new FileReader();

    reader.onload = (imgFile: any) => {
      console.log(imgFile, 'imgFile');
      const data = imgFile.target["result"];
      console.log(data, 'data')
      fabric.Image.fromURL(data,
        (img: {
          set: (arg0: { left: number; top: number; angle: number; }) =>
            { (): any; new(): any; scale: { (arg0: number): any; new(): any; }; };

        }) => {
          console.log(img, 'img')
          let oImg = img.set({
            left: 50,
            top: 100,
            angle: 0
          }).scale(1);
          oImg.scaleToHeight(150);
          oImg.scaleToWidth(150);
          this.canvas.add(oImg).renderAll();
          this.selectItemAfterAdded(oImg);
          var a = this.canvas.setActiveObject(oImg);
          var dataURL = this.canvas.toDataURL({ format: 'png', quality: 0.8 });
        });
    };
    reader.readAsDataURL(file);
    return false;
  }
  uploadImage(event: any) {
    this.remove();
    this.imageAdded = true
    this.showText = false
    this.showEditBtn = true
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = (e: any) => {
        const data = e.target.result;
        fabric.Image.fromURL(data, img => {
          img.set({
            left: 100,
            top: 350,
            angle: 0,
            backgroundColor: "white"
          }).scale(1);
          img.scaleToHeight(200);
          img.scaleToWidth(200);
          console.log(data, 'da')
          this.canvas.add(img);
          this.selectItemAfterAdded(img);
          this.canvas.centerObject(img);
        });
      };

      reader.readAsDataURL(file);
    }
  }
  selectItemAfterAdded(obj) {
    console.log(obj.text, 'dfdsfs')
    this.canvas.discardActiveObject().renderAll();
    this.canvas.setActiveObject(obj);
  }
  //remove object
  remove() {
    this.canvas.remove(this.canvas.getActiveObject());
  }
  //set brightness
  setBrightness() {
    console.log(this.cookieForm.controls.brightness.value, 'brightness');
    this.applyFilter(5, new fabric.Image.filters.Brightness({
      brightness: parseInt(this.cookieForm.controls.brightness.value, 10)
    }));
    this.applyFilterValue(5, 'brightness', parseFloat(this.cookieForm.controls.brightness.value));
  }
  //set contrast
  setContrast() {
    this.applyFilter(6, new fabric.Image.filters.Contrast({
      contrast: parseInt(this.cookieForm.controls.contrast.value, 10)
    }));
    this.applyFilterValue(6, 'contrast', parseFloat(this.cookieForm.controls.contrast.value));
  }
  setSaturation() {
    this.applyFilter(7, new fabric.Image.filters.Saturation({
      saturation: parseInt(this.cookieForm.controls.saturation.value, 10)
    }));
    this.applyFilterValue(7, 'saturation', parseFloat(this.cookieForm.controls.saturation.value));
  }
  applyFilter(index, filter) {
    var obj = this.canvas.getActiveObject();
    obj.filters[index] = filter;
    obj.applyFilters();
    this.canvas.renderAll();
  }
  applyFilterValue(index, prop, value) {
    var obj = this.canvas.getActiveObject();
    if (obj.filters[index]) {
      obj.filters[index][prop] = value;
      var timeStart = +new Date();
      obj.applyFilters();
      this.canvas.renderAll();
    }
  }
  //change image with existing
  changeImage(event) {
    this.ngOnInit();
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const data = e.target.result;
      this.canvas.clear().renderAll();
      fabric.Image.fromURL(data +'?not-from-cache-please', img => {
        img.set({
          left: 100,
          top: 350
        });
        img.scaleToHeight(150);
        img.scaleToWidth(150);
        console.log(data, 'da')
        this.canvas.add(img);
        this.selectItemAfterAdded(img);
        this.canvas.centerObject(img);
      });
    };

    reader.readAsDataURL(file);
  }
  getTemplate(fav: any) {
    this.imageAdded = true
    this.showText = false
    this.showEditBtn = true
    let image = fav.image
    fabric.Image.fromURL(image+ '?not-from-cache-please',
      (img) => {
        let oImg = img.set({
          left: 16,
          top: 14,
          angle: 0,
          padding: 0
        })
        this.canvas.clear();
        oImg.scaleToHeight(200);
        oImg.scaleToWidth(270);
        this.selectItemAfterAdded(oImg);
        this.canvas.add(oImg).renderAll();
      }, { crossOrigin: 'anonymous' });
    console.log(this.canvas, 'sds')
  }
  //set font family
  setFontFamily() {
    console.log(this.cookieForm.controls.fontFamily.value, 'font')
    this.setActiveProp('fontFamily', this.cookieForm.controls.fontFamily.value);
  }
  setActiveProp(name, value) {
    const object = this.canvas.getActiveObject();
    if (!object) { return; }
    object.set(name, value).setCoords();
    this.canvas.renderAll();
  }
  //set font size
  setFontSize() {
    this.setActiveStyle('fontSize', parseInt(this.cookieForm.controls.fontSize.value, 10), null);
  }
  setActiveStyle(styleName, value: string | number, object) {
    object = object || this.canvas.getActiveObject();
    if (!object) { return; }

    if (object.setSelectionStyles && object.isEditing) {
      const style = {};
      style[styleName] = value;

      if (typeof value === 'string') {
        if (value.includes('underline')) {
          object.setSelectionStyles({ underline: true });
        } else {
          object.setSelectionStyles({ underline: false });
        }

        if (value.includes('overline')) {
          object.setSelectionStyles({ overline: true });
        } else {
          object.setSelectionStyles({ overline: false });
        }

        if (value.includes('line-through')) {
          object.setSelectionStyles({ linethrough: true });
        } else {
          object.setSelectionStyles({ linethrough: false });
        }
      }

      object.setSelectionStyles(style);
      object.setCoords();

    } else {
      if (typeof value === 'string') {
        if (value.includes('underline')) {
          object.set('underline', true);
        } else {
          object.set('underline', false);
        }

        if (value.includes('overline')) {
          object.set('overline', true);
        } else {
          object.set('overline', false);
        }

        if (value.includes('line-through')) {
          object.set('linethrough', true);
        } else {
          object.set('linethrough', false);
        }
      }

      object.set(styleName, value);
    }

    object.setCoords();
    this.canvas.renderAll();
  }
  //set bold
  setBold() {
    this.props.fontWeight = !this.props.fontWeight;
    console.log(this.props.fontWeight, 'weight')
    this.setActiveStyle('fontWeight', this.props.fontWeight ? 'bold' : '', null);
  }
  //set Italics
  setFontStyle() {
    this.props.fontStyle = !this.props.fontStyle;
    if (this.props.fontStyle) {
      this.setActiveStyle('fontStyle', 'italic', null);
    } else {
      this.setActiveStyle('fontStyle', 'normal', null);
    }
  }
  //set text decoration
  setTextDecoration(value) {
    let iclass = this.props.TextDecoration;
    if (iclass.includes(value)) {
      iclass = iclass.replace(RegExp(value, 'g'), '');
    } else {
      iclass += ` ${value}`;
    }
    this.props.TextDecoration = iclass;
    this.setActiveStyle('textDecoration', this.props.TextDecoration, null);
  }
  //set bg opacity
  setOpacity() {
    this.setActiveStyle('opacity', parseInt(this.cookieForm.controls.opacity.value, 10) / 100, null);
  }
  getFill() {
    this.props.fill = this.getActiveStyle('fill', null);
  }
  getActiveStyle(styleName, object) {
    object = object || this.canvas.getActiveObject();
    if (!object) { return ''; }

    if (object.getSelectionStyles && object.isEditing) {
      return (object.getSelectionStyles()[styleName] || '');
    } else {
      return (object[styleName] || '');
    }
  }

  //set text color
  setFill() {
    console.log(this.cookieForm.controls.fill.value, 'text color')
    this.setActiveStyle('fill', this.cookieForm.controls.fill.value, null);
  }
  //set bg color
  setBgFill() {
    console.log(this.cookieForm.controls.BgFill.value, 'imah')
    if (!this.props.canvasImage) {
      this.canvas.backgroundColor = this.cookieForm.controls.BgFill.value;
      this.canvas.renderAll();
    }
  }
  addText() {
    this.showEditBtn = true
    this.showText = true
    this.imageAdded = false
  }
  applyText() {
    if (this.cookieForm.controls.textString.value) {
      const text = new fabric.IText(this.cookieForm.controls.textString.value, {
        left: 75,
        top: 175,
        fontFamily: 'helvetica',
        angle: 0,
        fill: 'red',
        scaleX: 0.8,
        scaleY: 0.8,
        fontWeight: '',
        hasRotatingPoint: true
      });

      this.extend(text, this.randomId());
      this.canvas.add(text);
      this.canvas.setActiveObject(text);
      text.on('mousedown', function () {
        this.textString = text.text
        console.log(this.textString, 'sdsaf')
      });
    }
    this.cookieForm.get('textString').reset();
  }
  extend(obj, id) {
    obj.toObject = ((toObject) => {
      return function () {
        return fabric.util.object.extend(toObject.call(this), {
          id
        });
      };
    })(obj.toObject);
  }
  randomId() {
    return Math.floor(Math.random() * 999999) + 1;
  }
  saveDesign() {
    debugger
    let productList = []
    productList = JSON.parse(localStorage.getItem('products'))
    for (var i in productList) {
      if (productList[i].id == this.itemId) {
        let customize = productList[i].customize
        let imageUrl = customize[this.cookie_index]?.image_url
        let str = environment.s3bucket //'https://snape-cookie.s3.amazonaws.com/'
        let newImgUrl = imageUrl.slice(imageUrl.indexOf(str) + str.length);
        let extension = imageUrl.split(".");
        extension = extension[extension.length - 1]
        console.log(extension)
        const image = new Image();
        image.crossOrigin = 'Anonymous';
        image.src = this.canvas.toDataURL("image/jpg");
        let data:any={"imagedata":image.src}
        this.api.uploadBoxcolor(data).subscribe((res)=>{
         let reqData:any= {"orderid":this.orderId,"image_url":res['body'],"tempId":customize[this.cookie_index]?.tempId,"orderitemid":this.itemId}
         this.api.updateCookie(reqData).subscribe((resp)=>{
          console.log('new',resp)
          if(resp['status']==true){
            let extra = JSON.parse(localStorage.getItem('orderDetails'))
            this.router.navigate(['order-details'],{queryParams: extra})
            localStorage.removeItem('orderDetails')
          }
         })
        })
        // let fileData = this.DataURIToBlob(image.src, newImgUrl)
        // console.log(image.src,'fileData')
        // let extra = JSON.parse(localStorage.getItem('orderDetails'))
        // console.log(extra, 'path')
        // this.api.getImage(newImgUrl).then(res => {
        //   let res_data = res['URL']['fields']
        //   this.api.postImage(res_data, fileData).then(res => {
        //     this.router.navigate(['order-details'],{queryParams: extra})
        //     localStorage.removeItem('orderDetails')
        //     // this._location.back();
        //   }).catch((err)=>{
        //     console.log(err)
        //   })
        // }).catch((err)=>{
        //   console.log(err)
        // })
        // console.log(fileData, 'j')
        // // customize[this.cookie_index].image_url = image.src
      }
    }
  }
  DataURIToBlob(dataURI: string, filename: any) {

    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i)
    return new Blob([ia], { type: mimeString })
  }
}
