<div class="container">
  <div class="row p-t-20">
    <div class="col-md-12 col-xs-12 col-lg-12">
      <div class="heading">
        <h1>Manage Profile</h1>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-2 col-lg-3 col-xs-12">
    </div>
    <div class="col-md-8 col-lg-6 col-xs-12">
      <div class="card">
        <div class="box">
          <h3>Change email address</h3>
          <h4>snapecookie@gmail.com</h4>
          <p class="text-muted"> Enter the new email address you would like to associate with your account below.
            <br>
            We will send a One Time Password (OTP)to that address.
          </p>
          <input type="email" class="form-control" placeholder="Enter new email id">

          <button type="submit" class="btn btn-snape-bak btn-block text-uppercase">Continue</button>
        </div>
      </div>
    </div>
    <div class="col-md-2 col-lg-3 col-xs-12">
    </div>
  </div>
</div>
