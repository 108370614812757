<!-- <div *ngIf="loader == true">
  <app-spinner></app-spinner>
</div> -->
<div *ngIf="loader == true">
  <app-spinner></app-spinner>
</div>
<section class="dashboard-sec">
  <div class="row p-t-20">
    <div class="col-md-12 col-xs-12 col-lg-12">
      <div class="dashboard-heading">
        <h1>Dashboard</h1>
      </div>
    </div>
  </div>
  <section class="total-summary-sec">
    <div class="row p-t-20">
      <!-- <div class="col-md-1 col-lg-4 col-xs-12">
        <div class="total-summary mob-text-align p-t-8">
          <p>Total summary</p>
        </div>
      </div> -->
      <div class="col-md-1 col-lg-3 col-xs-12 col-xl-3"></div>
      <div class="col-md-2 col-lg-1 col-xs-12 col-xl-1">
        <div class="mob-p-b-10 mob-text-align">
          <button type="button" class="btn btn-light tab-today-btn-size {{activeBtn}}"
            (click)="getDashboard('today')">Today</button>
        </div>
      </div>
      <div class="col-md-2 col-lg-1 col-xs-12 col-xl-1">
        <div class="mob-p-b-10 mob-text-align">
          <button type="button" class="btn btn-light tab-week-btn-size" (click)="getDashboard('week')">This
            week</button>
        </div>
      </div>
      <div class="col-md-2 col-lg-1 col-xs-12 col-xl-1">
        <div class="mob-p-b-10 m-l-30 mob-text-align">
          <button type="button" class="btn btn-light tab-month-btn-size" (click)="getDashboard('month')">This
            month</button>
        </div>
      </div>
      <div class="col-md-3 col-lg-3 col-xs-12 col-xl-3">
        <div class="m-t-10 m-l-68 btn-light-gray mob-text-align">
          <input type="text" [maxDate]="today" placeholder="{{placeholder}}"
            class="form-control cursor-pointer {{dateActive}} " bsDaterangepicker [bsConfig]="dpConfig"
            (bsValueChange)="dateRangeCreated($event)" [(bsValue)]="bsValue">
        </div>
      </div>
      <div class="col-md-2 col-lg-2 col-xs-12 col-xl-2">
        <div class="mob-p-b-10 mob-text-align mob-m-t-15">
          <!-- <angular2-multiselect [data]="companyArray" [(ngModel)]="selectedCompany" [settings]="companyDropdownSettings"
            (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
            (onDeSelectAll)="onDeSelectAll($event)">
          </angular2-multiselect> -->
        </div>
      </div>
      <div class="col-md-1 col-lg-1 col-xs-12 col-xl-1">
        <div class="reload-icon">
          <i class="fas fa-sync-alt" (click)="clearAll()"></i>
        </div>
      </div>
    </div>
  </section>
  <section class="dashboard-body-sec">
    <div class="row p-t-20">
      <div class="col-md-3 col-xs-12 col-lg-3 col-xl-2" *ngIf="number_neworder > 0">
        <div class="row card-border m-r-10 m-t-10 p-t-35 mob-p-l-0" [routerLink]="['/order/list']"
        [queryParams]="{ status: 'Order Placed'}">
          <div class="col-md-6 col-xs-12 col-lg-7">
            <div class="light-orange mob-text-align font-weight">
              <p>{{number_neworder}}</p>
              <p>New Orders </p>
            </div>
          </div>
          <div class="col-md-6 col-xs-12 col-lg-5">
            <div class="cookie-png mob-text-align">
              <img src="../../../assets/Group 18051.svg">
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-xs-12 col-lg-3 col-xl-2" *ngIf="number_neworder == 0">
        <div class="row card-border m-r-10 m-t-10 p-t-35 mob-p-l-0">
          <div class="col-md-6 col-xs-12 col-lg-7">
            <div class="light-orange mob-text-align font-weight">
              <p>{{number_neworder}}</p>
              <p>New Orders </p>
            </div>
          </div>
          <div class="col-md-6 col-xs-12 col-lg-5">
            <div class="cookie-png mob-text-align">
              <img src="../../../assets/Group 18051.svg">
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-xs-12 col-lg-3 col-xl-2 mob-p-t-20" *ngIf="number_processingorder > 0">
        <div class="row card-border m-r-10 m-t-10 p-t-35 mob-p-l-0" [routerLink]="['/order/list']"
        [queryParams]="{ status: 'Processing'}">
          <div class="col-md-8 col-xs-12 col-lg-7">
            <div class="light-green mob-text-align font-weight">
              <p>{{number_processingorder}}</p>
              <p>On processing orders</p>
            </div>
          </div>
          <div class="col-md-4 col-xs-12 col-lg-5">
            <div class="cookie-png mob-text-align">
              <img src="../../../assets/Group 18053.svg">
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-xs-12 col-lg-3 col-xl-2 mob-p-t-20" *ngIf="number_processingorder == 0">
        <div class="row card-border m-r-10 m-t-10 p-t-35 mob-p-l-0">
          <div class="col-md-8 col-xs-12 col-lg-7">
            <div class="light-green mob-text-align font-weight">
              <p>{{number_processingorder}}</p>
              <p>On processing orders</p>
            </div>
          </div>
          <div class="col-md-4 col-xs-12 col-lg-5">
            <div class="cookie-png mob-text-align">
              <img src="../../../assets/Group 18053.svg">
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-xs-12 col-lg-3 col-xl-2 mob-p-t-20" *ngIf="number_readyorder > 0">
        <div class="row card-border m-r-10 m-t-10 p-t-35 mob-p-l-0" [routerLink]="['/order/list']"
        [queryParams]="{ status: 'Ready for delivery'}">
          <div class="col-md-8 col-xs-12 col-lg-7">
            <div class="light-yellow mob-text-align font-weight">
              <p>{{number_readyorder}}</p>
              <p>Ready for delivery</p>
            </div>
          </div>
          <div class="col-md-4 col-xs-12 col-lg-5">
            <div class="cookie-png mob-text-align">
              <img src="../../../assets/Group 18055.svg">
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-xs-12 col-lg-3 col-xl-2 mob-p-t-20" *ngIf="number_readyorder == 0">
        <div class="row card-border m-r-10 m-t-10 p-t-35 mob-p-l-0">
          <div class="col-md-8 col-xs-12 col-lg-7">
            <div class="light-yellow mob-text-align font-weight">
              <p>{{number_readyorder}}</p>
              <p>Ready for delivery</p>
            </div>
          </div>
          <div class="col-md-4 col-xs-12 col-lg-5">
            <div class="cookie-png mob-text-align">
              <img src="../../../assets/Group 18055.svg">
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-xs-12 col-lg-3 col-xl-2 mob-p-t-20" *ngIf="number_deliveryorder > 0">
        <div class="row card-border m-r-10  mob-m-r-10 m-t-10 p-t-35 mob-p-l-0" [routerLink]="['/order/list']"
        [queryParams]="{ status: 'Delivered'}">
          <div class="col-md-8 col-xs-12 col-lg-7">
            <div class="light-red mob-text-align font-weight">
              <p>{{number_deliveryorder}}</p>
              <p>Delivered</p>
            </div>
          </div>
          <div class="col-md-4 col-xs-12 col-lg-5">
            <div class="cookie-png mob-text-align">
              <img src="../../../assets/Group 18056.svg">
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-xs-12 col-lg-3 col-xl-2 mob-p-t-20" *ngIf="number_deliveryorder == 0">
        <div class="row card-border m-r-10  mob-m-r-10 m-t-10 p-t-35 mob-p-l-0">
          <div class="col-md-8 col-xs-12 col-lg-7">
            <div class="light-red mob-text-align font-weight">
              <p>{{number_deliveryorder}}</p>
              <p>Delivered</p>
            </div>
          </div>
          <div class="col-md-4 col-xs-12 col-lg-5">
            <div class="cookie-png mob-text-align">
              <img src="../../../assets/Group 18056.svg">
            </div>
          </div>
        </div>
      </div>
      <!-- </div>
    <div class="row p-t-20"> -->
      <div class="col-md-3 col-xs-12 col-lg-3 col-xl-2 mob-p-t-20" *ngIf="number_delayed_order > 0">
        <div class="row card-border m-r-10 m-t-10 p-t-35 mob-p-l-0 tab-m-t-20" [routerLink]="['/order/list']"
        [queryParams]="{ status: 'Delayed'}">
          <div class="col-md-8 col-xs-12 col-lg-7">
            <div class="dark-yellow mob-text-align font-weight">
              <p>{{number_delayed_order}}</p>
              <p>Delayed</p>
            </div>
          </div>
          <div class="col-md-4 col-xs-12 col-lg-5">
            <div class="cookie-png mob-text-align">
              <img src="../../../assets/Group 18052.svg">
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-xs-12 col-lg-3 col-xl-2 mob-p-t-20" *ngIf="number_delayed_order == 0">
        <div class="row card-border m-r-10 m-t-10 p-t-35 mob-p-l-0 tab-m-t-20">
          <div class="col-md-8 col-xs-12 col-lg-7">
            <div class="dark-yellow mob-text-align font-weight">
              <p>{{number_delayed_order}}</p>
              <p>Delayed</p>
            </div>
          </div>
          <div class="col-md-4 col-xs-12 col-lg-5">
            <div class="cookie-png mob-text-align">
              <img src="../../../assets/Group 18052.svg">
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-xs-12 col-lg-3 col-xl-2 mob-p-t-20 mob-p-b-20 tab-m-t-20" *ngIf="total_revenue > 0">
        <div class="row card-border m-r-10 m-t-10 p-t-35 mob-p-l-0" [routerLink]="['/order/list']"
        [queryParams]="{ status: 'Revenue'}">
          <div class="col-md-8 col-xs-12 col-lg-7">
            <div class="light-blue mob-text-align font-weight">
              <p>{{total_revenue}}</p>
              <p>Revenue</p>
            </div>
          </div>
          <div class="col-md-4 col-xs-12 col-lg-5">
            <div class="cookie-png mob-text-align">
              <img src="../../../assets/Group 18054.svg">
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-xs-12 col-lg-3 col-xl-2 mob-p-t-20 mob-p-b-20 tab-m-t-20" *ngIf="total_revenue == 0">
        <div class="row card-border m-r-10 m-t-10 p-t-35 mob-p-l-0">
          <div class="col-md-8 col-xs-12 col-lg-7">
            <div class="light-blue mob-text-align font-weight">
              <p>{{total_revenue}}</p>
              <p>Revenue</p>
            </div>
          </div>
          <div class="col-md-4 col-xs-12 col-lg-5">
            <div class="cookie-png mob-text-align">
              <img src="../../../assets/Group 18054.svg">
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>