<div class="container">
  <div class="row p-t-20">
    <div class="col-md-12 col-xs-12 col-lg-12">
      <div class="heading">
        <h1>Manage Profile</h1>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-2 col-lg-3 col-xs-12">
    </div>
    <div class="col-md-8 col-lg-6 col-xs-12">
      <div class="card">
        <div class="box">
          <h3>Password reset sucessfull</h3>
          <h4>You have sucessfully updated your password</h4>
          <img src="../../../assets/successful.png">
          <button type="submit" class="btn btn-snape-bak btn-block text-uppercase">Login</button>
        </div>
      </div>
    </div>
    <div class="col-md-2 col-lg-3 col-xs-12">
    </div>
  </div>
</div>
