import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/common/common.service';
import * as _ from 'underscore';
import * as uuid from 'uuid';

@Component({
  selector: 'app-bulk-order',
  templateUrl: './bulk-order.component.html',
  styleUrls: ['./bulk-order.component.scss']
})
export class BulkOrderComponent implements OnInit {

  loader = false
  productList: any = []
  cartProductList: any = []
  minDate: Date;
  total
  colorList: any = []
  cartList: any = []
  quantity: any
  min_qty: any
  activateBilling = false
  showHomeDelivery = false
  showCheckoutButton = false
  shippingAddress = false
  showCheque = false
  selectedColor: any
  selectedProduct: any
  navigationExtras: NavigationExtras;
  ledgerForm: FormGroup
  checkoutForm: FormGroup
  addressForm: FormGroup
  paymentForm: FormGroup
  preference_value: any;
  delivery_charge: number = 0.00
  savedAmount: number = 0.00
  todayDate: any
  deliveryDate: any
  deliveryTime: any
  orderAmound: number = 0.00
  zoneArray;

  date8: Date;

  constructor(private activatedRoute: ActivatedRoute, private fb: FormBuilder, private api: CommonService, private router: Router, private toastr: ToastrService) {
    this.ledgerForm = this.fb.group({
      searchText: [''],
      product: [''],
      color: ['']
    })
    this.checkoutForm = this.fb.group({
      preference: [''],
      date: [''],
      time: [''],
      reason: [''],
      discount: ['']
    })
    this.paymentForm = this.fb.group({
      method: ['', Validators.required],
      chequeNo: [''],
      chequeDate: [''],
      bank: ['']
    })
    this.addressForm = this.fb.group({
      userName: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern("^([0-9]{9})|([A-Za-z0-9._%\+\-]+@[a-z0-9.\-]+\.[a-z]{2,3})$")]],
      phone: ['', [Validators.required, Validators.pattern("^[0-9]{8}$")]],
      fullName: ['', Validators.required],
      buildingNumber: ['', Validators.required],
      flatVilla: ['', Validators.required],
      streetName: ['', Validators.required],
      city: ['', Validators.required],
      zone: ['', Validators.required],
      areaNumber: [''],
      contactNumber: ['', [Validators.required, Validators.pattern("^[0-9]{8}$")]],
      // map:['']
    })
  }

  ngOnInit(): void {
    // const myArray = this.activatedRoute.snapshot.queryParamMap.get('myArray');
    // console.log(myArray, 'mmm')
    // let arrayOfValues:any
    // if (myArray === null) {
    //   arrayOfValues = new Array<string>();
    // } else {
    // arrayOfValues = JSON.parse(myArray);
    // }
    // console.log(arrayOfValues)
    // this.cartProductList = arrayOfValues
    //localStorage.sItem('cartItems',JSON.stringify(this.cartProductList))
    this.cartProductList = JSON.parse(localStorage.getItem('cartItems'))
    console.log(this.cartProductList)
    this.minDate = new Date();
    this.listCookies()
    this.enableCheckout()
    this.calculateAmount()
  }
  listCookies() {
    this.api.listProduct().subscribe((result) => {
      console.log(result, 'products')
      this.productList = result['data']['Items']
      this.productList = _.sortBy(this.productList, function (o) {
        return o.cookie_number
      })
    })
  }
  increment() {

    this.selectedProduct[0].quantity = this.selectedProduct[0].quantity + 1
    this.quantity = this.selectedProduct[0].quantity
    console.log(this.selectedProduct)
  }
  decrement() {


    if (this.selectedProduct[0].quantity > this.selectedProduct[0].min_qty) {
      this.selectedProduct[0].quantity = this.selectedProduct[0].quantity - 1
      this.quantity = this.selectedProduct[0].quantity
    }


  }
  checkout() {
    this.cartProductList = this.cartProductList.concat(this.cartList)
    localStorage.setItem('cartItems', JSON.stringify(this.cartProductList))
    this.enableCheckout()
    window.location.reload()
  }
  addtoList() {
    debugger
    if (this.selectedProduct[0]?.selectedColor) {
      let myid = uuid.v4()
      console.log(this.selectedProduct)
      this.cancel()
      this.cartList.push({
        "PK": this.selectedProduct[0].PK,
        "SK": this.selectedProduct[0].SK,
        "customize": [],
        "quantity": this.selectedProduct[0].quantity,
        "box_color": this.selectedProduct[0].selectedColor,
        "id": myid,
        "cookie_number": this.selectedProduct[0].cookie_number,
        "min_qty": this.selectedProduct[0].min_qty,
        "box_img_url": this.selectedProduct[0].image,
        "flavours": this.selectedProduct[0].flavours,
        "image": this.selectedProduct[0].image,
        "product": {
          "id": this.selectedProduct[0].id,
          "name": this.selectedProduct[0].name,
          "price": this.selectedProduct[0].price,
          "item_total": this.selectedProduct[0].price * this.selectedProduct[0].quantity,
          "image": this.selectedProduct[0].image,
          "cookie_number": this.selectedProduct[0].cookie_number
        }
      })
      this.selectedColor = null
      this.selectedProduct = []
    } else {
      this.toastr.error('Please choose a box color')
    }
  }
  enableCheckout() {
    debugger
    let customizedCount = 0
    if (this.cartProductList.length > 0) {
      for (var i in this.cartProductList) {
        let totaldesignedItem = this.cartProductList[i].product.cookie_number
        if (this.cartProductList[i].customize.length == totaldesignedItem) {
          customizedCount = customizedCount + 1
        }
      }
      if (customizedCount == this.cartProductList?.length) {
        this.showCheckoutButton = true
      }
    }

  }
  designCookie(item) {
    debugger
    let cookieId = item.id
    this.router.navigate(['customize-cookie'], { queryParams: { id: cookieId } })
  }
  cancel() {
    this.ledgerForm.patchValue({
      product: '',
      color: ''
    })
    this.min_qty = ''
    this.quantity = ''
  }
  deleteItem(index: any) {
    this.cartList.splice(index, 1)
  }
  delete(index: any) {
    debugger
    this.cartProductList.splice(index, 1)
    this.activateBilling = false
    this.showCheque = false
    localStorage.setItem('cartItems', JSON.stringify(this.cartProductList))
    this.enableCheckout()
    window.location.reload()
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
  getTime(event: any) {
    debugger
    console.log(this.checkoutForm.controls.date.value)
    let userDate = this.checkoutForm.controls.date.value
    let currentdate: any = new Date()
    currentdate = formatDate(currentdate, 'yyyy-MM-dd', 'en-US');
    // userDate = formatDate(userDate, 'yyyy-MM-dd', 'en-US');
    // currentdate = currentdate.getFullYear() + '-' + currentdate.getMonth() + '-' + currentdate.getDate()
    console.log(currentdate)
    let currentTime = new Date().getTime()
    // let updatedTIme = new Date()
    // updatedTIme.setTime(currentTime.getTime() + (3 * 60 * 60 * 1000));
    let updatedTIme = new Date(currentTime + 2 * 60 * 60 * 1000);
    let date = updatedTIme.getHours() + ':' + updatedTIme.getMinutes()
    this.todayDate = date
    console.log(this.todayDate)
    let time = event.target.value;

    this.deliveryTime = time
    this.deliveryDate = userDate
    let newtime = time.split(':')
    let hours = newtime[0]
    let meridian = ''
    if (parseInt(hours) >= 12) {
      meridian = 'PM'
    } else if (parseInt(hours) < 12) {
      meridian = 'AM'
    }
    if (userDate == currentdate) {
      if (time < this.todayDate) {
        this.toastr.error('select  valid time', null, { timeOut: 5000 })
        this.checkoutForm.controls.time.reset()
      }
    }
    if (meridian == 'PM') {
      if (time > '7:30' || time > '19:30') {
        this.toastr.error('Please choose time between 9:00 AM to 7:30 PM', null, { timeOut: 5000 })
        //this.clearTime()
      }
    } else if (meridian == 'AM') {
      if (time < '09:00' || '21:00' < time) {
        this.toastr.error('Please choose time between 9:00 AM to 7:30 PM', null, { timeOut: 5000 })
        //this.clearTime()
      }
    }


  }
  clearTime() {
    this.checkoutForm.controls.time.reset()
  }
  selectedPrefernce(value) {
    debugger
    this.preference_value = value
    if (this.preference_value == 'home_delivery') {
      // this.delivery_charge = 15.00
      // this.toastr.success('Delivery charges will be calculated during checkout')
      this.showHomeDelivery = true;
    }
    else {
      this.delivery_charge = 0.00
      this.showHomeDelivery = false;
    }
  }
  zoneDeliveryCharge(){
    debugger
     const deliveryamount = this.addressForm.controls.zone.value
    this.api.listZones().subscribe(res=>{
      this.zoneArray = res['zone'].filter(zone => zone.zone === deliveryamount)
      const deliveryZoneCharge = this.zoneArray[0].deliverycharge
      console.log(deliveryZoneCharge,'deliverycharge')
       this.delivery_charge = deliveryZoneCharge
    })
  }
  selectedMethod(value) {
    let method = value
    debugger
    if (method == 'cheque') {
      this.showCheque = true
      this.paymentForm.controls['chequeNo'].updateValueAndValidity()
      this.paymentForm.controls['chequeDate'].updateValueAndValidity();
      this.paymentForm.controls['bank'].updateValueAndValidity();
      this.paymentForm.controls['chequeNo'].setValidators([Validators.required]);
      this.paymentForm.controls['chequeDate'].setValidators([Validators.required]);
      this.paymentForm.controls['bank'].setValidators([Validators.required]);
    }
    else {
      this.showCheque = false
      this.paymentForm.controls.chequeNo.reset()
      this.paymentForm.controls.chequeDate.reset()
      this.paymentForm.controls.bank.reset()
      this.paymentForm.controls['chequeNo'].clearValidators();
      this.paymentForm.controls['chequeDate'].clearValidators();
      this.paymentForm.controls['bank'].clearValidators();
      this.paymentForm.controls['chequeNo'].updateValueAndValidity()
      this.paymentForm.controls['chequeDate'].updateValueAndValidity();
      this.paymentForm.controls['bank'].updateValueAndValidity();
    }
  }
  addNew() {
    this.activateBilling = false
    this.showCheque = false
  }
  getSelectedProduct(event: any) {
    // console.log(this.ledgerForm.controls.product.value)
    let id = this.ledgerForm.controls.product.value
    this.selectedProduct = _.filter(this.productList, function (res) {
      return res.id == id
    })
    console.log(this.selectedProduct)
    this.colorList = this.selectedProduct[0].color
    this.min_qty = parseInt(this.selectedProduct[0].min_qty)
    this.quantity = this.min_qty
    this.selectedProduct[0].quantity = this.min_qty
  }
  getSelectedColor(event: any) {
    let id = this.ledgerForm.controls.color.value
    this.selectedColor = _.filter(this.colorList, function (res) {
      return res.SK == id
    })
    this.selectedProduct[0].selectedColor = this.selectedColor[0].name
    this.selectedProduct[0].image = this.selectedColor[0].image
    this.selectedProduct[0]['customize'] = []
    console.log(this.selectedColor)
  }
  calculateAmount() {
    let amount = 0
    for (var i in this.cartProductList) {
      amount = amount + this.cartProductList[i].product.item_total
    }
    this.orderAmound = amount
  }
  proceed() {
    debugger
    this.activateBilling = true
    let amount = 0
    for (var i in this.cartProductList) {
      amount = amount + this.cartProductList[i].product.item_total
    }
    this.orderAmound = amount
  }
  applyCoupon() {
    if (this.checkoutForm.controls.discount.valid && this.checkoutForm.controls.discount.value > 0) {
      this.savedAmount = this.checkoutForm.controls.discount.value
    } else {
      this.toastr.error('Enter a valid value')
    }
  }
  makePayment() {
    debugger
    if (this.deliveryDate != null && this.deliveryTime != null && this.paymentForm.valid) {
      this.loader = true
      debugger
      let data: any = {
        cartItems: this.cartProductList,
        userName: this.addressForm.controls.userName.value,
        email: this.addressForm.controls.email.value,
        phone: this.addressForm.controls.phone.value,
        fullName: this.addressForm.controls.fullName.value,
        buildingNumber: this.addressForm.controls.buildingNumber.value,
        flatVilla: this.addressForm.controls.flatVilla.value,
        streetName: this.addressForm.controls.streetName.value,
        city: this.addressForm.controls.city.value,
        zone: this.addressForm.controls.zone.value,
        areaNumber: this.addressForm.controls.areaNumber.value,
        contactNumber: this.addressForm.controls.contactNumber.value,
        paymentMethod: this.paymentForm.controls.method.value,
        chequeNo: this.paymentForm.controls.chequeNo.value,
        date: this.paymentForm.controls.chequeDate.value,
        bank: this.paymentForm.controls.bank.value,
        deliveryCharge: this.delivery_charge,
        preference: this.checkoutForm.controls.preference.value,
        deliveryDate: this.deliveryDate,
        deliveryTime: this.checkoutForm.controls.time.value,
        orderAmount: this.orderAmound,
        discount: this.savedAmount,



      }
      this.api.addBulkOrder(JSON.stringify(data)).subscribe((result) => {
        console.log(result)
        if (result['status'] == true) {
          localStorage.removeItem('cartItems')
          this.loader = false
          this.toastr.success('Order placed successfully')
          this.router.navigate(['order/list'])
        } else {
          this.loader = false
          this.toastr.error('Something went wrong')


        }
      })
    } else {
      this.toastr.error("Please fill all required fields")
    }

  }
}
