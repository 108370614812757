// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBsKkezOqHChiTg05tOXbqEwc7r75dMeGk",
    authDomain: "snape-cookies.firebaseapp.com",
    databaseURL: "https://snape-cookies-default-rtdb.firebaseio.com",
    projectId: "snape-cookies",
    storageBucket: "snape-cookies.appspot.com",
    messagingSenderId: "903439747243",
    appId: "1:903439747243:web:453c62b9ecf946cbc94fee",
    measurementId: "G-K6BNWYJGGP"
  },
  baseUrl: 'https://39xj4qskj7.execute-api.ap-south-1.amazonaws.com/',
  memberSignup: 'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/dev/user',
  verifyEmail: 'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/dev/email-confirmation',
  signIn: 'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/dev/signin',
  forgotPassword: 'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/dev/forget-password',
  confirmPassword: 'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/dev/confirm-password',
  getProfile: "https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/dev/user",
  flavour: 'https://7sbe4r5nb0.execute-api.us-east-2.amazonaws.com/dev/flavour',
  favourite: 'https://zg8eoeiiz2.execute-api.us-east-2.amazonaws.com/dev/favourite',
  templates: 'https://dng7c1zt1j.execute-api.us-east-2.amazonaws.com/images/templates',
  color: 'https://7sbe4r5nb0.execute-api.us-east-2.amazonaws.com/dev/color',
  product: 'https://8ihex1qk65.execute-api.us-east-2.amazonaws.com/dev/products',
  imageUpload: 'https://6cn2qm94lj.execute-api.us-east-2.amazonaws.com/image-upload/snape-cookie?file=',
  s3bucket: 'https://snape-cookie.s3.us-east-2.amazonaws.com/',
  about: 'https://df5qtwchle.execute-api.us-east-2.amazonaws.com/about/',
  privacy: 'https://ejbka6l6v7.execute-api.us-east-2.amazonaws.com/privacy/',
  terms: 'https://9657im5lgl.execute-api.us-east-2.amazonaws.com/terms/',
  faq: 'https://ri09cq2uob.execute-api.us-east-2.amazonaws.com/dev/',
  singleProduct: 'https://8ihex1qk65.execute-api.us-east-2.amazonaws.com/dev/single-product?id=',
  ledger: 'https://tpi4vbltj1.execute-api.us-east-2.amazonaws.com/ledger/',
  dashboard: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/dev/orders/admin?',
  startDate: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/dev/orders/admin?start_date=',
  customer: 'https://l9jbttxtrj.execute-api.us-east-2.amazonaws.com/dev/admin/customer-list',
  updateStatus: 'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/dev/user/update-status',
  adminNotify: 'https://z2i19t7ici.execute-api.us-east-2.amazonaws.com/notifications/admin-notifications',
  notification: 'https://z2i19t7ici.execute-api.us-east-2.amazonaws.com/notifications/',
  batchDelete: 'https://z2i19t7ici.execute-api.us-east-2.amazonaws.com/notifications/admin-batch-delete',
  company: 'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/dev/company',
  company_signup: 'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/dev/company-signup',
  vendorById: 'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/dev/company?id=',
  company_details: 'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/dev/company/single-company-details?email_id=',
  edit_vendor: 'https://l9jbttxtrj.execute-api.us-east-2.amazonaws.com/dev/admin/edit-vendor',
  order_details: 'https://l9jbttxtrj.execute-api.us-east-2.amazonaws.com/dev/admin/order-details',
  orderId: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/dev/orders/order-details?id=',
  delivery_status: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/dev/change-delivery-status',
  item_status: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/dev/orders/item-status',
  order_status: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/dev/orders/order-status',
  admin_email: 'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/dev/company-contact?is_admin=true&email=',
  company_contact: 'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/dev/company-contact',
  comapny_document: 'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/dev/company/document',
  chat: 'https://evngndsua9.execute-api.us-east-2.amazonaws.com/chat/',
  message: 'https://evngndsua9.execute-api.us-east-2.amazonaws.com/dev/?id=',
  contactUs: 'https://puq9chx22m.execute-api.us-east-2.amazonaws.com/dev/contact-us',
  generalquery: 'https://r6knfnh9u6.execute-api.us-east-2.amazonaws.com/dev/general-queries',
  newsletter: 'https://0ir44ib3pb.execute-api.us-east-2.amazonaws.com/dev/news-letter',
  boxcolor: 'https://8ihex1qk65.execute-api.us-east-2.amazonaws.com/dev/boxcolorupload',
  updateCookie: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/dev/updateordercookie',
  addCoupon: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/dev/couponcodediscount',
  generateCoupon: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/dev/autogeneratecouponcode',
  listCoupon: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/dev/listcouponcode',
  getCoupon: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/dev/couponcodediscount?id=',
  cart: 'https://bqeejq5z3b.execute-api.us-east-2.amazonaws.com/dev/cart',
  userProducts: 'https://8ihex1qk65.execute-api.us-east-2.amazonaws.com/dev/user-products',
  bulkOrder:'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/dev/addbulkorder',
  orderCancel:'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/dev/ordercancellation',
  createZone: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/dev/zone ',
  listZone: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/dev/listzones',
  updateZone: ' https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/dev/zone',
  deleteZone: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/dev/zone',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
