import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/common/common.service';
import { ExcelService } from 'src/app/common/excel.service';
import { ZONE_STATUS } from 'src/app/config/commonConfig';
import * as _ from 'underscore';

@Component({
  selector: 'app-zone',
  templateUrl: './zone.component.html',
  styleUrls: ['./zone.component.scss']
})
export class ZoneComponent implements OnInit {

  filterByAmountSettings;
  zoneArray;
  deleteZoneId: any;
  selectedStatus = [];
  filterName:any
  zoneArrayCopy = []
  totalZone = 1
  zoneForm: FormGroup;
  editCheck = false
  statusArray = ZONE_STATUS;
  zoneFormSubmitted: boolean = false
  zone : any = ''
  deliverycharge: any = ''
  zoneid: any = ''
  p: number = 1;
  zoneDetails: any;
  loader = false

  constructor( public api: CommonService, private fb: FormBuilder, private toastr: ToastrService,private http: HttpClient,
    private excelService: ExcelService) {
    this.zoneForm = this.fb.group({
      zone : ['', Validators.required],
      deliverycharge : ['', Validators.required],
    })
   }

  ngOnInit(): void {
    this.loader = true
    this.filterByAmountSettings = {
      singleSelection: true,
      primaryKey: 'id',
      labelKey: 'name',
      text: "Filter by amount",
      enableSearchFilter: false,
      lazyLoading: true,
      showCheckbox: true,
      clearAll: false
    };
    this.listZone();
  }
  
  onItemSelect(event) {
    debugger
    this.p = 1
    if (event.name == '15' || event.name == '20' || event.name == '25' || event.name == '30' || event.name == '35' || event.name == '40') {
      let name 
      if (event.name == '15') {
        name = '15'
      }
      else {
        name = '20' || name == '25' || name == '30' || name == '35' || name == '40'
      }
      if (event.name == '20') {
        name = '20'
      }
      else {
        name = '25'|| name == '30' || name == '35' || name == '40' || name == '15'
      }
      if (event.name == '25') {
        name = '25'
      }
      else {
        name = '30'  || name == '35' || name == '40' || name == '15' || name == '20'
      }
      if (event.name == '30') {
        name = '30'
      }
      else {
        name = '35' || name == '40' || name == '15' || name == '20'  || name == '25'
      }
      if (event.name == '35') {
        name = '35'
      }
      else {
        name = '40' || name == '15' || name == '20'  || name == '25' || name == '30'
      }
      if (event.name == '40') {
        name = '40'
      }
      else {
        name = '15' || name == '20'  || name == '25' || name == '30' || name == '35'
      }
     
      if(event.name == null){
        // let date = this.dateFilter
        this.zoneArray = _.filter(this.zoneArrayCopy, function (res) {
          console.log(res,'zone')
          return  res.deliverycharge == event.name
        })
        
      }else{
        this.zoneArray = _.filter(this.zoneArrayCopy, (item) => {
          return item.deliverycharge == event.name
         })
      }
      this.filterName = event.name
      this.totalZone = this.zoneArray?.length
    } 
  }
  openNewZone(){
    this.zoneFormSubmitted = false
    this.zoneForm.reset()
  }
  cancelZone(){
    this.zoneForm.reset()
  }
  listZone(){
    this.api.listZones().subscribe(re => {
      console.log(re,'res')
      this.zoneArray = re['zone']
      this.zoneArrayCopy = re['zone']
      this.zoneArray = _.sortBy(this.zoneArray, function(o){
        return o.createdAt;
      }).reverse();
      this.loader = false
      console.log(this.zoneArray,'this.zonearray')
    })
  }
  exportToExcel() {
    debugger
    console.log(this.zoneArray, 'exportToExcel')
    let obj = []
    let exel_sheet_data = []
    let data = {}
    const header = ['Zone number', 'Amount'];
    let title = 'zone list of snape Cookies';
    let worksheet = 'Customer Data'
    let merge_cells = 'A1:D2'
    let footer_row = 'Snape cookies zone list.'
    let filename = 'zone_list'
    let excel_array = [
      data['header'] = header,
      data['title'] = title,
      data['worksheet'] = worksheet,
      data['merge_cells'] = merge_cells,
      data['footer_row'] = footer_row,
      data['filename'] = filename
    ]
    const date = new Date();
    const formattedDate = date.toLocaleDateString('en-GB', {
      day: '2-digit', month: 'short', year: 'numeric'
    }).replace(/ /g, '-');
    for (var i in this.zoneArray) {
      obj = [this.zoneArray[i].zone, this.zoneArray[i].deliverycharge]
      exel_sheet_data.push(obj)
    }
    this.excelService.exportAsExcelFile(exel_sheet_data, formattedDate, excel_array);
  }
  updateZone(){
    debugger
    this.loader = true
    let data = {
      "zoneid" :  this.zoneDetails.id,
      "deliverycharge" :  this.zoneForm.controls.deliverycharge.value,
      "zonenumber" : this.zoneForm.controls.zone.value
    }
    this.api.updateZones(data).subscribe(respond => {
      console.log(respond,'respond')
      if (respond['status'] == true) {
        this.toastr.success('Zone updated Successfully')
        this.loader = false
        this.ngOnInit()
      } else {
        this.toastr.error('Something went wrong')
      }
    })
  }
  addZone(){
    this.loader = true
    let data ={
      zone : this.zoneForm.controls.zone.value,
      deliverycharge :  this.zoneForm.controls.deliverycharge.value
    }
    this.api.createZones(data).subscribe(val =>{
      console.log(val,'val')
      if (val['status'] == true) {
        this.toastr.success('Zone Added Successfully')
        this.loader = false
        this.ngOnInit()
      } else {
        this.toastr.error('Something went wrong')
      }
    })
  }
  editZone(item:any){
    debugger
    console.log(item)
    this.editCheck = true
    this.zoneFormSubmitted = true
    this.zoneDetails = item
    console.log('zoneDetails',this.zoneDetails)
    this.zoneForm.patchValue({
      zone: item.zone,
      deliverycharge : item.deliverycharge
  })
    }
  removeZone(id: any){
    debugger
    console.log(id,'id')
    this.deleteZoneId = id
  }
   deleteZone(id: any) {
      debugger
      this.loader = true
    const params = new HttpParams().set('zoneid', id);
    this.api.deleteZones({ params }).subscribe(res => {
      console.log(res, 'delete')
      if (res['status'] == true) {
        this.toastr.success('Zone deleted Successfully')
        this.loader = false
        this.ngOnInit()
        } else {
        this.toastr.error('Something went wrong')
      }
    });
    }
  OnItemDeSelect(event) {

  } onSelectAll(event) {

  } onDeSelectAll(event) {

  }
}
