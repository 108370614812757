import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/common/common.service';
import { ExcelService } from 'src/app/common/excel.service';
import { CUSTOMER_STATUS } from 'src/app/config/commonConfig';
import * as _ from 'underscore';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.css']
})
export class CustomerListComponent implements OnInit {

  // variable declaration 
  statusDropdownSettings;
  selectedStatus = [];
  statusArray = CUSTOMER_STATUS;
  customerList = [];
  totalCustomer = 1
  loader = false
  selectedItems = [];
  activeList = [];
  inactiveList = [];
  filteredDate = []
  currentList = []
  customerId: any
  customerStatus
  popupText: string;
  customerEmail: any;
  config: any;
  p: number = 1;
  total: number = 50
  currentPage = 1
  startPage: number = 1;
  lastPage: number = 5;
  customerListArray: any;
  startDate:any
  endDate:any
  filterName:any
  customerListCopy = []
  filterForm:FormGroup
  constructor(
    private api: CommonService,
    private excelService: ExcelService,
    private toastr: ToastrService,
    private fb:FormBuilder
  ) { 
    this.filterForm = this.fb.group({
      joiningDate:[''],
      orderDate:[''],
      status:['']
    })
  }

  ngOnInit(): void {
    this.loader = true
    this.getCustomerList();
    this.getdropDownSettings();
  }
  getdropDownSettings() {
    this.statusDropdownSettings = {
      singleSelection: true,
      primaryKey: 'id',
      labelKey: 'name',
      text: "Filter by Status",
      enableSearchFilter: false,
      lazyLoading: true,
      showCheckbox: true,
      clearAll: false,
      badgeShowLimit: 1,
      classes: "myclass custom-class"
    };
  }
  onItemSelect(item: any) {
    this.filterName= item
    if (item.id == '1' && this.selectedStatus?.length == 1) {
      this.customerList = this.activeList
    }
    else if (item.id == '2' && this.selectedStatus?.length == 1) {
      console.log(item, 'iii');
      this.customerList = this.inactiveList
    }
    if (this.selectedStatus?.length == 2)
      this.customerList = this.activeList.concat(this.inactiveList)
      if(this.startDate){
        let filter_date = this.startDate
        this.customerList = _.filter(this.customerList, function (res) {
          if(res?.created_at){
            return res.created_at.slice(0, 10) === filter_date
          }
        })
      }
      if(this.endDate){
        let filter_date = this.endDate
        this.customerList = _.filter(this.currentList, function (res) {
          if(res?.Last_order_date){
            return res?.Last_order_date.slice(0, 10) === filter_date
          }
        })
      }
      this.totalCustomer = this.customerList?.length
  }
  OnItemDeSelect(item: any) {
    console.log(this.selectedStatus, 'sele status')
    if (item.id == '1' && this.selectedStatus?.length == 1) {
      this.customerList = this.inactiveList
    }
    else if (item.id == '2' && this.selectedStatus?.length == 1) {
      console.log(item, 'iii');
      this.customerList = this.activeList
    }
    if (this.selectedStatus?.length == 0)
      this.customerList = this.activeList.concat(this.inactiveList)
      this.totalCustomer = this.customerList?.length
  }
  onSelectAll(items: any) {
    this.customerList = this.activeList.concat(this.inactiveList)
    this.totalCustomer = this.customerList?.length
  }
  onDeSelectAll(items: any) {
    this.customerList = this.activeList.concat(this.inactiveList)
    this.totalCustomer = this.customerList?.length
  }
  getCustomerList() {
    this.api.getCustomerList().subscribe(item => {
      this.customerListArray = item['users']['Items']
      this.loader = false
      this.customerList = _.filter(this.customerListArray, function(res){
        return res.user_status == 'active' || res.user_status == 'inactive'
      })
      this.customerList = _.sortBy(this.customerList, function(o){
        return o.created_at 
      })
      this.customerListCopy = this.customerList.sort((a: any, b: any) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
      // this.customerList = this.customerList.reverse();
      this.totalCustomer = this.customerList?.length
      console.log(this.customerList, 'list')
      this.activeList = _.filter(this.customerListCopy, function (res) {
        return res.user_status === 'active'
      })
      this.inactiveList = _.filter(this.customerListCopy, function (res) {
        return res.user_status === 'inactive'
      })
    })
    this.totalCustomer = this.customerList?.length
  }
  filterDate(queryDate: any) {
    debugger
    let filter_date = this.formatDate(queryDate)
    this.startDate = filter_date
    this.currentList = this.activeList.concat(this.inactiveList)
    this.customerList = _.filter(this.customerListCopy, function (res) {
      if(res?.created_at){
        return res.created_at.slice(0, 10) === filter_date
      }
    })
    console.log(this.customerList, 'current list')
    this.activeList = _.filter(this.customerList, function (res) {
      return res.user_status === 'active'
    })
    this.inactiveList = _.filter(this.customerList, function (res) {
      return res.user_status === 'inactive'
    })
    if(this.endDate){
      let filter_date = this.endDate
      this.customerList = _.filter(this.customerList, function (res) {
        if(res?.Last_order_date){
          return res?.Last_order_date.slice(0, 10) === filter_date
        }
      })
    }
    this.totalCustomer = this.customerList?.length
    this.p = 1
  }
  filterLastDate(queryLastDate: any){
    let filter_date = this.formatDate(queryLastDate)
    this.endDate = filter_date
    this.currentList = this.activeList.concat(this.inactiveList)
    this.customerList = _.filter(this.currentList, function (res) {
      if(res?.Last_order_date){
        return res?.Last_order_date.slice(0, 10) === filter_date
      }
    })
    if(this.startDate){
      let filter_date = this.startDate
        this.customerList = _.filter(this.customerList, function (res) {
          if(res?.created_at){
            return res.created_at.slice(0, 10) === filter_date
          }
        })
    }
    this.totalCustomer = this.customerList?.length
    this.p = 1
  }
  applyFilter(){
    debugger
    console.log(this.filterForm.controls.joiningDate.value)
    console.log(this.filterForm.controls.orderDate.value)
    console.log(this.filterForm.controls.status.value)
    this.customerList = this.customerListCopy
    if(this.filterForm.controls.joiningDate.value){
      let filter_date = this.formatDate(this.filterForm.controls.joiningDate.value)
      this.customerList = _.filter(this.customerList, function (res) {
        if(res?.created_at){
          return res.created_at.slice(0, 10) === filter_date
        }
      })
    }
    if(this.filterForm.controls.orderDate.value){
      let filter_date = this.formatDate(this.filterForm.controls.orderDate.value)
      this.customerList = _.filter(this.customerList, function (res) {
        if(res?.Last_order_date){
          return res?.Last_order_date.slice(0, 10) === filter_date
        }
      })
    }
    if(this.filterForm.controls.status.value.length > 0){
      if(this.filterForm.controls.status.value[0].name == 'Active'){
        this.customerList = _.filter(this.customerList, function(res){
          return res.user_status == 'active'
        })
      }else{
        this.customerList = _.filter(this.customerList, function(res){
          return res.user_status == 'inactive'
        })
      }
     
    }
    this.totalCustomer = this.customerList?.length
    this.p = 1
  }
  clearAll(){
    window.location.reload();
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
  dateInReverse(date) {
    var datetime = date
    var bits = datetime.split(/\D/);
    var dateObject = new Date(bits[0], --bits[1], bits[2], bits[3], bits[4]);
    const formattedDate = dateObject.toLocaleDateString('en-GB', {
      day: 'numeric', month: 'short', year: 'numeric'
    }).replace(/ /g, '-');
    return formattedDate
  }
  deactiveOrRevokeAccount(id: any, status, SK) {
    this.customerId = id
    this.customerStatus = status
    this.customerEmail = SK
    console.log(this.customerEmail,'email')
    if (this.customerStatus == 'inactive') { this.popupText = 'activate' }
    if (this.customerStatus == 'active') { this.popupText = 'deactivate' }
  }
  changeUserStatus() {
    let data
    if (this.customerStatus == 'active') {
      data = {
        "email": this.customerEmail,
        "user_status": "inactive",
      }
    }
    else {
      data = {
        "email": this.customerEmail,
        "user_status": "active",
      }
    }
    this.api.updateCustomer(data).subscribe(res => {
      this.toastr.success(res['message'])
      this.ngOnInit();
    })
  }
  exportToExcel() {
    debugger
    console.log(this.customerList, 'dsd')
    let obj = []
    let exel_sheet_data = []
    let data = {}
    const header = ['User name', 'Email', 'Joining date', 'Status'];
    let title = 'Customer list of snape Cookies';
    let worksheet = 'Customer Data'
    let merge_cells = 'A1:D2'
    let footer_row = 'Snape cookies customer list.'
    let filename = 'customer_list'
    let excel_array = [
      data['header'] = header,
      data['title'] = title,
      data['worksheet'] = worksheet,
      data['merge_cells'] = merge_cells,
      data['footer_row'] = footer_row,
      data['filename'] = filename
    ]
    const date = new Date();
    const formattedDate = date.toLocaleDateString('en-GB', {
      day: '2-digit', month: 'short', year: 'numeric'
    }).replace(/ /g, '-');
    for (var i in this.customerList) {
      obj = [this.customerList[i].name, this.customerList[i].email, this.customerList[i].created_at, this.customerList[i].user_status]
      exel_sheet_data.push(obj)
    }
    this.excelService.exportAsExcelFile(exel_sheet_data, formattedDate, excel_array);
  }

}
