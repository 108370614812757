import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/common/common.service';
import { CustomvalidationService } from 'src/app/common/customvalidation.service';
import { TokenStorageService } from 'src/app/common/token/token-storage.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  resetPasswordForm: FormGroup;
  resendOtpForm: FormGroup;
  printerForm: FormGroup;
  submitted = false
  password: any;
  userMail:any
  constructor(
    private fb: FormBuilder,
    private router: Router,
    public api: CommonService,
    private customValidator: CustomvalidationService,
    private toastr: ToastrService,
    private token: TokenStorageService
  ) { }

  ngOnInit(): void {
    this.resetPasswordForm = this.fb.group({
      password: ['', Validators.compose([Validators.required, this.customValidator.patternValidator()])],
      passwordConfirm: ['', [Validators.required]],
    },
      {
        validator: this.customValidator.MatchPassword('password', 'passwordConfirm'),
      }
    );
    this.resendOtpForm = this.fb.group({
      otp: ['', Validators.required]
    })
    this.printerForm = this.fb.group({
      name: ['', Validators.required],
      port: ['', Validators.required],
      type: ['', Validators.required]
    })
    this.userMail = localStorage.getItem('loginEmail')
  }
  continue() {
    if (this.resetPasswordForm.valid) {
      this.password = this.resetPasswordForm.controls.password.value
    }
    else {
      this.submitted = true;
    }
    this.resendOtp();
  }
  changePassword() {
    if (this.resendOtpForm.valid) {
      let data = {
        "email": this.userMail,
        "code": this.resendOtpForm.controls.otp.value,
        "password": this.password
      }
      this.api.confirmPassword(data).subscribe(res => {
        let message = res['message'];
        this.toastr.success(message)
        let status = res['status']
        if (status == true) {
          this.api.loggedIn.next(false);
          this.token.signOut()
          this.router.navigate(['/login']).then(() => {
            window.location.reload();
          });
        }
      })
    }
    else {
      this.submitted = true;

    }
  }
  resendOtp() {
    let data = {
      "username": this.userMail
    }
    this.api.forgotPassword(data).subscribe(res => {
      this.toastr.success(res['message'])
    })
  }
}
