import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../common/common.service';
import * as _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { indexOf } from 'underscore';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-new-product',
  templateUrl: './create-new-product.component.html',
  styleUrls: ['./create-new-product.component.css']
})
export class CreateNewProductComponent implements OnInit {

  productForm: FormGroup;
  box_color: FormArray
  imageURL: string;
  prod_imageURL: string
  productArray
  processing = false
  
  colorArray: any;
  message: string;
  imagePath: any;
  imgURL: string | ArrayBuffer;
  colors = []
  selected = false
  highlightedBatch = [];
  files = []
  loader = false
  colorArr = []
  productId: any;
  colorBoxes = []
  productcolorArray: any;
  colorBatch = {}
  saveProduct:boolean = false;
  submitted = false
  colorSelected = false
  
  constructor(
    private fb: FormBuilder,
    private router: Router,
    public api: CommonService,
    private cd: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService
  ) {
  }

  ngOnInit(): void {
    debugger
    
    this.api.getColor().subscribe(color => {
      this.colorArray = color['data']['Items']
      this.colorArray = _.each(this.colorArray, (item) => {
        item.image = ''
        return item
      })
      this.activatedRoute.queryParams.subscribe(res => {
        this.productId = res.id
        this.saveProduct = false
        if (this.productId) {
          this.loader = true
          this.getProduct();
        }
      })
    })
    this.productForm = this.fb.group({
      name: ['', Validators.required],
      no_of_cookies: ['', Validators.required],
      price: ['', Validators.required],
      order_qnty: ['', Validators.required],
      box_color: [''],
      description: [''],
      image: '',
      product_image: [''],
      prod_img: ''
    })
  }
  getProduct() {
    
    this.highlightedBatch = []
    this.api.getProductById(this.productId).subscribe(re => {
      this.productArray = re['data']['Item']
      console.log('product', this.productArray)
      this.productForm.patchValue({
        name: this.productArray?.name,
        no_of_cookies: this.productArray?.cookie_number,
        price: this.productArray?.price,
        order_qnty: this.productArray?.min_qty,
        description: this.productArray?.description,
        prod_img: this.productArray?.image,
        
      })
      _.each(this.productArray.color, item => {
        this.colorBatch[item.SK] = item
      })
      console.log(this.colorBatch, 'color batch')
      _.each(this.colorArray, item => {
        if (item.SK in this.colorBatch) {
          item.image = this.colorBatch[item.SK].image
          item.image_url = this.colorBatch[item.SK].image_url
          this.highlightedBatch.push(item);
        }
      })
      this.prod_imageURL = this.productArray?.image
      console.log('product', this.productArray, this.colorArray)
    })
    this.loader = false
  }
  alert(){
    this.toastr.info('Please select atleast one box')
  }
  changeSelection(color) {
    debugger
    this.colorSelected = true
    this.selected = true
    console.log(color, 'colors')
    let obj = {
      "SK": color.SK,
      "PK": color.PK,
      "id": color.id,
      "code": color.code,
      "name": color.name,
      "extra_price": "2"
    }
    this.colors.push(obj)
    this.toastr.info('Please upload box image')
    if (this.highlightedBatch.indexOf(color) === -1) {
      this.highlightedBatch.push(color);
      console.log('add');
    } else {
      const _index = this.highlightedBatch.indexOf(color);
      let colorArrIndex = this.colorArray.indexOf(color)
      this.colorArray[colorArrIndex].image_url = ''
      console.log(this.colorArray.indexOf(color), 'color array index')
      color.image = ''
      console.log(this.files, 'fdd');
      this.files.map(x => {
        if (Object.keys(x) == color.image_name) {
          console.log(Object.keys(x), 'ssf')
          let file_index = this.files.indexOf(Object.keys(x))
          console.log(this.files.indexOf(Object.keys(x)), 'file index')
          this.files.splice(file_index, 1);
        }
      }
      );
      this.highlightedBatch.splice(_index, 1);
      console.log('remove');
    }
    console.log(this.files, 'fdd');
    console.log(this.colorArray, 'arraya')
  }
  addProduct() {
    debugger
    this.processing = true
    let count = 0
    for(var i in this.highlightedBatch){
      if(this.highlightedBatch[i].image != ""){
        count = count + 1
      }
    }
    if(this.highlightedBatch.length == count){
      if(this.productForm.valid && this.productForm.controls.prod_img.value != "" && this.highlightedBatch.length != 0){
        this.saveProduct = true
        console.log(this.saveProduct)
          if (!this.productArray?.id) {
           // this.colorArr = this.highlightedBatch
            for (var i in this.colorArr) {
              // delete this.colorArr[i].image
              // this.colorArr[i].image = this.colorArr[i].image_name
              // delete this.colorArr[i].image_name
              this.colorArr[i].image_url = this.colorArr[i].image
            }
            let data = {
              "name": this.productForm.controls.name.value,
              "cookie_number": this.productForm.controls.no_of_cookies.value,
              "description": this.productForm.controls.description.value,
              "price": parseFloat(this.productForm.controls.price.value),
              // "flavours": this.productForm.controls.order_qnty.value,
              "min_qty": this.productForm.controls.order_qnty.value,
              "color": this.colorArr,
              "product_status":"active",
              "product_image": "filename"
            }
            console.log(this.files, data, 'dfsdf')
            this.api.addProduct(data, this.files, { "filename": this.productForm.get('prod_img').value }).subscribe(data => {
              console.log(data, 'data')
              this.saveProduct = false
              this.toastr.success(data['message'])
              this.productForm.reset();
              this.prod_imageURL = ''
              this.colorArr = []
              this.router.navigate(['/product-list'])
            })
          } else {
            this.colorArr = this.highlightedBatch
            for (var i in this.colorArr) {
              if (this.colorArr[i]?.image) {
                // delete this.colorArr[i].image
                // this.colorArr[i].image = this.colorArr[i].image_name
                // delete this.colorArr[i].image_name
               // if (this.colorArr[i]?.image_url) { 
                  this.colorArr[i].image_url = this.colorArr[i].image 
               // }
              }
            }
            console.log(this.colorArr, 'color array')
            let filename
            let data = {
              "id": this.productId,
              "name": this.productForm.controls.name.value,
              "cookie_number": this.productForm.controls.no_of_cookies.value,
              "description": this.productForm.controls.description.value,
              "price": parseFloat(this.productForm.controls.price.value),
              // "flavours": this.productForm.controls.order_qnty.value,
              "min_qty": this.productForm.controls.order_qnty.value,
              "color": this.colorArr,
              "product_image": "filename"
            }
            if (typeof (this.productForm.get('prod_img').value) == 'object') {
              if (this.productForm.get('prod_img').value != 'undefined' || this.productForm.get('prod_img').value != null) {
                filename = this.productForm.get('prod_img').value
              }
            }
            else {
              if (this.productForm.get('prod_img').value.startsWith('http')) {
                data['image'] = this.productForm.get('prod_img').value
              }
              else {
                data['image'] = this.productArray?.image
              }
            }
            console.log(this.productForm.get('prod_img').value, 'dfsdf')
            let productFile
            if (filename) {
              // this.files.push({ "filename": filename })
              productFile = { "filename": filename }
            }
            console.log(this.files, data, 'dfsdf')
            this.api.updateProduct(data, this.files, productFile).subscribe(data => {
              console.log(data, 'data')
              this.saveProduct = false
              this.toastr.success(data['message'])
              this.productForm.reset();
              this.prod_imageURL = ''
              this.colorArr = []
              this.router.navigate(['/product-list'])
            })
          }
      }
      else{
        this.submitted = true
        this.toastr.error('Please fill all required fields')
      }
    }else{
      this.toastr.error('Please upload box image')
    }
   this.processing = false
    
  }
  showPreview(event, id, index) {
    debugger
    this.loader = true
    const file = (event.target as HTMLInputElement).files[0];
    
    // File Preview
    const reader = new FileReader();
    

    reader.readAsDataURL(file)
    reader.onload = (e: any) => {
      let imageUrl = e.target.result
     let requestdata = {"imagedata":imageUrl, "filename":file.name}
      this.api.uploadBoxcolor(requestdata).subscribe((resp)=>{
        this.loader = false
        console.log('boxcolor',resp)
        if(resp['status'] == true){
          let data = resp
          this.productForm.patchValue({
            image: data['body'],
          });
          this.colorArray[index].image = data['body']
          this.productForm.get('image').updateValueAndValidity()
          let colorArr = this.highlightedBatch
          let obj = {}
          let imageFile = []
          for (var i in colorArr) {
            if (id == colorArr[i].SK) {
              let image = this.productForm.get('image').value
              let imageName = image.name
              // console.log(image, "img")
              // colorArr[i].image = image.name
              colorArr[i].image_name = image.name
              // this.files.push({imageName:image})
              obj[imageName] = image
            }
            console.log("id")
          }
          if (this.files?.length == 0) {
            imageFile.push(obj)
          }
          else {
            if (obj)
              imageFile.push(obj)
          }
          this.files.forEach(function (item) {
            imageFile.push(item);
          })
          this.files = imageFile
          this.colorArr = colorArr
          console.log(this.files, 'this.file')
          console.log(colorArr, 'colorArray')
          console.log(this.highlightedBatch, 'array');
        }else{
          this.toastr.error('Something went wrong')
        }
        
      })
     // this.colorArray[index].image = e.target.result
      // this.imageURL = e.target.result;
    };
  
    
   
  }
  showProdPreview(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.productForm.patchValue({
      prod_img: file,
    });
    console.log(this.productForm.get('prod_img').value, 'prod img')
    this.productForm.get('prod_img').updateValueAndValidity()
    // File Preview
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.prod_imageURL = e.target.result;
    };
    reader.readAsDataURL(file)
  }
  cancel(){
    if(this.productArray){
      this.router.navigate(['product-list'])
    }
    else{
      this.productForm.reset(); 
      this.prod_imageURL = ''
      this.highlightedBatch = []
      this.ngOnInit();
    }
  }
}

